import { useSelector, useStore } from "react-redux";
import { ViewMode } from "../reducers/gui.default";
import { useIntl } from "react-intl";
import { formatForId } from "../../Lang/IntlHelper";
import { closeDetail, markUserChanged, openDetail, setEditDetail } from "../actions/masterDetail";
import { useNotification } from "../../../Notification";
import { usePostMutate } from "../../../Api";
import { useViewMode } from "./gui";
import { reloadList, resetFilter } from "../actions/dataList";
import _ from "loadsh";

export function useDetailOpen() {
    return useSelector(({ masterDetail }) => masterDetail.detailOpened);
}

export function useOpenedDetailEntity() {
    return useSelector(({ masterDetail }) => masterDetail);
}

export function useDetailEditable() {
    return useSelector(({ masterDetail, gui }) => masterDetail.detailEditable || gui.viewMode === ViewMode.Dialog);
}

export function useUserChanged() {
    return useSelector(({ masterDetail }) => masterDetail.userChanged);
}

export function useMasterDetailForm() {
    return useSelector(({ masterDetail }) => ({ form: masterDetail.form, focusedFieldId: masterDetail.focusedFieldId }));
}

export function useUserDataSafeMasterDetailChange() {
    const intl = useIntl();
    const userChanged = useUserChanged();

    return {
        userChanged,
        confirmWhenNeeded(func) {
            if (userChanged && window.confirm(formatForId(intl, "form.userChangedAlert"))) {
                return func();
            }
            if (!userChanged) {
                return func();
            } else {
                return false;
            }
        },
    };
}

export function useMasterDetailFormSave(url) {
    const notification = useNotification();
    const { postData, loading, error } = usePostMutate(url);
    const store = useStore();
    const { entityType } = useOpenedDetailEntity();
    const viewMode = useViewMode();

    return {
        loading,
        makeSubmitCallback({ onBeforeSubmit, onAfterSubmit, onSuccess, onExtendEntity } = {}) {
            return async (values, { setErrors }) => {
                if (onBeforeSubmit) onBeforeSubmit(values);

                if (values.isNew) {
                    delete values.id;
                }

                const response = await notification.showApiMessage(postData(values));
                if (response.status === 200) {
                    if (viewMode === ViewMode.MasterDetail) {
                        if (values.isNew) {
                            values.id = response.body.id.value;
                        }
                        values.isNew = false;

                        if (onExtendEntity) onExtendEntity(values, response);

                        openDetail(store, { entityType, entity: values });
                        markUserChanged(store, false);
                        setEditDetail(store, false);
                    } else {
                        closeDetail(store);
                    }
                    if (onSuccess) onSuccess(values, response);

                    reloadList(store);
                } else if (!_.isEmpty(response?.body?.validation_errors)) {
                    setErrors(response?.body?.validation_errors);
                }
                if (onAfterSubmit) onAfterSubmit(values, response);

                return response;
            };
        },
    };
}

export function useSafeOpenDetail() {
    const { confirmWhenNeeded } = useUserDataSafeMasterDetailChange();
    const store = useStore();

    return (entityType, entity) => confirmWhenNeeded(() => openDetail(store, { entityType, entity }));
}

export function useSafeDetailClose() {
    const { confirmWhenNeeded } = useUserDataSafeMasterDetailChange();

    const store = useStore();

    return () =>
        confirmWhenNeeded(() => {
            closeDetail(store);
            return true;
        });
}

export function useSafePageExit() {
    const store = useStore();
    const safeExit = useSafeDetailClose();
    return () => {
        if (safeExit()) {
            resetFilter(store);
            return true;
        }
        return false;
    };
}
