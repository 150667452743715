import { Friction, NiraDataTypes, None } from "../../../Components/NiraApi";
import { DEFAULT_APP_FIELDS_ORDER, DEFAULT_DATA_FIELDS_ORDER, SPECIAL_BUTTONS_APP_FIELD, SPECIAL_BUTTONS_LIST } from "../../../Utils/Data/ViewsDefinitions";
import _ from "loadsh";

export const meteoViewConfigFields = [
    {
        id: "initial_view",
        type: "DropdownField",
        props: {
            multiple: false,
            items: ["column", "table", "map", "camera"],
            localizationContext: "forms.view.initial_view.items",
        },
    },
    {
        id: "nira_field",
        type: "DropdownField",
        props: {
            multiple: false,
            items: NiraDataTypes,
            localizationContext: "pages.views.mapView.nira.dataType",
        },
    },
    {
        id: "visible_app_fields",
        type: "DropdownField",
        props: {
            multiple: true,
            allowChangeOrder: true,
            items: DEFAULT_APP_FIELDS_ORDER,
            localizationContext: "forms.view.app_fields.items",
            defaultValue: [],
        },
        onValueChanged(config, formik, value) {
            if (value) {
                const present = value.indexOf(SPECIAL_BUTTONS_APP_FIELD) !== -1;

                const newConfig = _.clone(config);
                const specialButtonsField = _.find(newConfig, (item) => item.id === "special_buttons");
                const index = _.indexOf(newConfig, specialButtonsField);
                const field = _.clone(specialButtonsField);
                field.props.immutable = !present;
                newConfig[index] = field;

                if (!present) {
                    formik.handleChange({
                        target: {
                            name: "special_buttons",
                            value: [],
                        },
                    });
                }
                return newConfig;
            }
        },
    },
    {
        id: "special_buttons",
        type: "DropdownField",
        hidden: true,
        props: {
            multiple: true,
            items: SPECIAL_BUTTONS_LIST,
            localizationContext: "forms.view.special_buttons.items",
            defaultValue: [],
        },
    },
    {
        id: "visible_data_fields",
        type: "DropdownField",
        props: {
            allowChangeOrder: true,

            multiple: true,
            items: DEFAULT_DATA_FIELDS_ORDER,
            localizationContext: "value.meteo_v1.name",
            defaultValue: [],
        },
    },
    {
        id: "geo_alert_groups",
        type: "DropdownField",
        props: {
            multiple: true,
            defaultValue: [],
        },
    },
    {
        id: "data_query",
        type: "QueryField",
        props: {},
        defaultValue: {
            filter: [],
            order: null,
        },
        onValueChanged(config, formik, value) {
            const newConfig = _.clone(config).filter((item) => item.id !== "map_zoom" && item.id !== "map_lat" && item.id !== "map_lon");
            if (_.isEmpty(value?.filter)) {
                return newConfig.concat(mapCustomFieldsConfig);
            } else {
                return newConfig;
            }
        },
    },
];

const onMapFieldValueChanged = (config, formik, value) => {
    if (value) {
        const newConfig = _.clone(config);
        const initialViewField = _.find(newConfig, (item) => item.id === "initial_view");
        const index = _.indexOf(newConfig, initialViewField);
        const field = _.clone(initialViewField);
        field.props.immutable = true;
        newConfig[index] = field;

        formik.handleChange({
            target: {
                name: "initial_view",
                value: "map",
            },
        });

        formik.handleChange({
            target: {
                name: "nira_field",
                value: Friction,
            },
        });

        const niraTypeField = _.find(newConfig, (item) => item.id === "nira_field");
        const niraIndex = _.indexOf(newConfig, niraTypeField);
        const niraField = _.clone(niraTypeField);
        niraField.props.items = niraField.props.items.filter((item) => item !== None);
        newConfig[niraIndex] = niraField;

        return newConfig;
    }
};

export const mapCustomFieldsConfig = [
    {
        id: "map_zoom",
        type: "NumberField",
        props: {
            minValue: 3,
            maxValue: 18,
        },
        onValueChanged: onMapFieldValueChanged,
    },
    {
        id: "map_lat",
        type: "NumberField",
        props: {
            minValue: -90,
            maxValue: 90,
        },
        onValueChanged: onMapFieldValueChanged,
    },
    {
        id: "map_lon",
        type: "NumberField",
        props: {
            minValue: -180,
            maxValue: 180,
        },
        onValueChanged: onMapFieldValueChanged,
    },
];

export const rnrViewConfigFields = [
    {
        id: "initial_view",
        type: "DropdownField",
        props: {
            multiple: false,
            items: ["rnrDebug", "rnrBasic"],
            localizationContext: "forms.view.initial_view.items",
        },
    },
    {
        id: "background_image",
        type: "FileSelectionField",
        props: {
            accept: ".svg",
        },
    },
    {
        id: "rnr_project",
        type: "DataSourceField",
        props: {
            driver: "rnr_driver",
        },
        localizationContext: "forms.view.rnr.rnr_project",
    },
    {
        type: "Group",
        fields: [
            {
                id: "background_color",
                type: "ColorField",
                props: {},
            },
            {
                id: "tooltip_color",
                type: "ColorField",
                props: {},
            },
        ],
    },
];
