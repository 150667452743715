import AppBar from "@material-ui/core/AppBar";
import {Tab, Tabs} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSafePageExit} from "../Utils/Data/hooks/masterDetail";
import {useHistory, useLocation} from "react-router-dom";
import _ from "loadsh";
import {MeteoAppBarBase} from "./MeteoAppBarBase";

const useStyles = makeStyles((theme) => ({
    secondaryBar: {
        zIndex: 0,
    }
}));

export function MeteoAppBar({menu, currentPath}) {

    const selectedItem = React.useMemo(() => {
            let item = menu.find(item => item.path === currentPath)
            if (!item) {
                for (const {items} of menu) {
                    if (items) {
                        item = items.find(item => item.path === currentPath);
                        if (item)
                            return item;
                    }
                }
            }

            return item;
        }
        , [menu, currentPath]);

    const location = useLocation();
    const selectedTabIdx = React.useMemo(() => {
        if (!selectedItem)
            return false;

        const {menu: tabs} = selectedItem;
        if (!tabs)
            return false;

        const pathParts = location.pathname.split("/");
        const tabId = _.findLast(pathParts, part => part.length > 0);


        const tab = tabs.find(({path}) => path === tabId);
        if (tab)
            return _.indexOf(tabs, tab);
        return -1;
    }, [location.pathname, selectedItem?.menu])

    let history = useHistory();

    const classes = useStyles();
    const closeDetail = useSafePageExit();

    const handleTabChange = (event, newValue) => {
        if (closeDetail()) {
            history.push("../" + selectedItem.menu[newValue].path + "/");
        }
    };

    return <div>
        <MeteoAppBarBase>
            <Typography variant="h6" noWrap>
                {selectedItem?.name}
            </Typography>
        </MeteoAppBarBase>


        {selectedItem?.menu &&
            <AppBar
                component="div"
                className={classes.secondaryBar}
                color="primary"
                position="static"
                elevation={0}>

                <Tabs value={selectedTabIdx} onChange={handleTabChange} textColor="inherit">
                    {selectedItem.menu.map((item) => {
                        return <Tab textColor="inherit" label={item.name}/>
                    })}
                </Tabs>
            </AppBar>}

    </div>
}
