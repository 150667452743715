import React, { useMemo } from "react";
import { hasValue } from "../../Utils/Data/ValueMapper";

export function Value({ value, valueInfo, className, shortcut, backgroundColor, textColor, numberOfLines = 0 }) {
    const wrapStyle = useMemo(() => {
        if (numberOfLines === 0) {
            return { whiteSpace: "nowrap" };
        } else {
            return { lineHeight: "1.42" };
        }
    }, [numberOfLines]);

    return (
        <div
            className={className}
            style={{
                ...wrapStyle,
                backgroundColor: backgroundColor || "",
                color: textColor || "",
            }}
        >
            {hasValue(value) && valueInfo.formatter(value, shortcut)}
        </div>
    );
}
