import React, { useMemo, useState } from "react";
import {
    AlertGroup,
    AlertHistoryDialogTitle,
    AlertHistoryDialogWrapper,
    AlertHistoryDialogWrapperContent,
    DataGridProps,
    translateCols,
    useAlertHistoryStyles,
    useAlertRefresh,
    useLocaleComparator,
} from "../../Charts/AlertHistoryDialogWrapper";
import { Chip, Grid, useTheme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faEye } from "@fortawesome/pro-solid-svg-icons";
import DateRangePicker from "../../Forms/DateRangePicker";
import ColorOptionSelect from "../../Forms/ColorOptionSelect";
import { getNiraAlertColor, NIRA_ALERT_RED, NIRA_ALERT_YELLOW, NiraAlertSeverity } from "../../../Utils/Data/AlertFormatter";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import { confirmNiraAlert, useHistoryFilter, useNiraAlertHistory } from "../../../Api";
import { Last24HoursType } from "../../Forms/DateRangeField/hooks";
import { useActiveGeoAlertRefresh, useHistoryGetData } from "../../../Utils/Data/hooks/server";
import { formatTime } from "../../../Utils/Data/Time";
import { useNotification } from "../../../Notification";
import { makeSecurityContext, useHasPermission } from "../../../Utils/Permissions/RequireAnyPermission";
import Button from "@material-ui/core/Button";
import { DataGrid, GridCheckIcon } from "@material-ui/data-grid";
import _ from "loadsh";
import LoadingScope from "../../LoadingScope";
import { NoDataMessage } from "../../Forms/NoDataMessage";
import { useFastTooltipRescan } from "../../FastTooltip";
import { NiraAlertDetailDialog } from "./NiraAlertDetailDialog";
import { SimpleOutlinedGeoAlertQueryField } from "../../Forms/GeoAlertQueryField";
import { formatWithUnit } from "../NiraValues";

function MyChip({ label, color }) {
    return <Chip label={label} style={{ backgroundColor: color.primary, color: color.textColor }} />;
}

function ConfirmationColumn({ params, intl, refetch, context }) {
    const confirm = formatForId(intl, "alertHistory.action.confirm");
    const notConfirmed = formatForId(intl, "alertHistory.notConfirmed");

    const notifications = useNotification();

    const hasPermAlertConfirm = useHasPermission({ permission: "dev__alert_confirm", context });

    const onConfirm = async (ids) => {
        await notifications.showApiMessage(confirmNiraAlert(ids));
        refetch();
    };

    return params.row.confirmation_time ? (
        <span>
            {params.row.confirmation_user?.name}
            <i style={{ color: "lightgray" }}> {formatTime(params.row.confirmation_time)}</i>{" "}
        </span>
    ) : hasPermAlertConfirm && params.row.is_confirmable ? (
        <Button onClick={(event) => onConfirm([params.row.id])}>
            <GridCheckIcon style={{ marginRight: 4 }} /> {confirm}
        </Button>
    ) : (
        <i style={{ color: "lightgray" }}>{notConfirmed}</i>
    );
}

function ViewDetailColumn({ params, intl }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                onClick={(event) => {
                    setOpen(true);
                }}
            >
                <FontAwesomeIcon icon={faEye} size={"lg"} style={{ marginRight: 4 }} /> {formatForId(intl, "niraAlertHistory.action.openInMap")}
            </Button>
            <NiraAlertDetailDialog open={open} onClose={() => setOpen(false)} area={params.row} />
        </>
    );
}

function getAlertsFormatted(intl, alerts) {
    return alerts.map((alert) => formatForId(intl, `alert.type.${alert}.name`)).join(", ");
}

function useColumns(intl, refetch, context) {
    const theme = useTheme();
    const localeColumComparator = useLocaleComparator();

    return useMemo(
        () =>
            translateCols(intl, "niraAlertHistory.cols.", [
                {
                    field: "level",
                    renderCell: (params) => {
                        return <MyChip label={params.value} color={getNiraAlertColor(params.value, theme)} />;
                    },
                    type: "boolean",
                    width: 110,
                },
                {
                    field: "name",
                    minWidth: 300,
                    sortable: true,
                    flex: 1,
                },
                {
                    field: "time_start",
                    valueFormatter: (row) => {
                        return formatTime(row.value);
                    },
                    minWidth: 210,
                    sortable: true,
                    type: "dateTime",
                    flex: 1,
                },
                {
                    field: "time_end",
                    valueFormatter: (row) => formatTime(row.value),
                    minWidth: 210,
                    sortable: true,
                    type: "dateTime",
                    flex: 1,
                },
                {
                    field: "time_last_read",
                    valueFormatter: (row) => formatTime(row.value),
                    minWidth: 210,
                    sortable: true,
                    type: "dateTime",
                    flex: 1,
                },

                {
                    field: "avg_temperature",
                    valueFormatter: (row) => (row.value ? formatWithUnit(row.value, intl, "celsius") : ""),
                    minWidth: 210,
                    sortable: true,
                    type: "dateTime",
                    flex: 1,
                },
                {
                    field: "are_wipers_on",
                    valueFormatter: (row) => formatForId(intl, `value.unit.wipesPerSecond.${row.value ? "1" : "0"}`),
                    minWidth: 210,
                    sortable: true,
                    type: "dateTime",
                    flex: 1,
                },

                {
                    field: "confirmation",
                    renderCell: (params) => <ConfirmationColumn refetch={refetch} intl={intl} params={params} context={context} />,
                    minWidth: 270,
                    flex: 1,
                },

                {
                    field: "openInMap",
                    renderCell: (params) => <ViewDetailColumn intl={intl} params={params} />,
                    minWidth: 270,
                    flex: 1,
                },
            ]),
        []
    );
}

export function NiraAlertHistoryDialog({ open, onClose, singleArea }) {
    const theme = useTheme();
    const intl = useIntl();
    const historyDate = useHistoryGetData();
    const history = useHistoryFilter(Last24HoursType, historyDate);
    const classes = useAlertHistoryStyles();
    const context = makeSecurityContext();
    const [filterSeverity, setFilterSeverity] = useState([NIRA_ALERT_YELLOW, NIRA_ALERT_RED]);
    const filterSeverityFormatted = useMemo(() => filterSeverity.join(","), [filterSeverity]);

    const [deviceFilter, setDeviceFilter] = useState([]);

    const { data, loading, refetch } = useNiraAlertHistory(open, false, filterSeverityFormatted, history, deviceFilter);
    const geoAlertsRefresh = useActiveGeoAlertRefresh();

    const alertRefresh = useAlertRefresh(refetch, geoAlertsRefresh, data, [deviceFilter, history, filterSeverityFormatted]);

    const severityFilterOptions = useMemo(
        () =>
            NiraAlertSeverity.map((severity, idx) => ({
                key: severity,
                name: formatForId(intl, `pages.device.alert.title.${severity}`),
                color: getNiraAlertColor(severity, theme)?.primary,
            })),
        []
    );

    useFastTooltipRescan(data);

    const columns = useColumns(intl, refetch, context);

    return (
        <AlertHistoryDialogWrapper open={open} onClose={onClose} fullWidth={true} scroll={"paper"} fullScreen={true}>
            <AlertHistoryDialogTitle alertRefresh={alertRefresh} icon={faCar} intlId={"pages.appBar.tooltip.geoAlerts"} />

            <AlertHistoryDialogWrapperContent onClose={onClose}>
                <Grid container={true} spacing={1} direction={"row"} alignItems={"center"}>
                    <Grid item={true} xs={12} md={4}>
                        <SimpleOutlinedGeoAlertQueryField
                            outline={true}
                            dense={true}
                            disableDeviceOrdering={true}
                            value={deviceFilter}
                            onChange={setDeviceFilter}
                            allowUnselectLast={true}
                            defaultValue={singleArea}
                        />
                    </Grid>
                    <Grid item={true} xs={12} md={4}>
                        <DateRangePicker dense={true} value={history.value} disabled={false} onChange={(value) => history.setValue(value)} />
                    </Grid>
                    <Grid item={true} xs={12} md={4}>
                        <ColorOptionSelect
                            style={{ width: "100%" }}
                            variant={"outlined"}
                            maring={"normal"}
                            options={severityFilterOptions}
                            value={filterSeverity}
                            onChange={(evt) => {
                                evt.target.value.sort();
                                setFilterSeverity(evt.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <div style={{ height: theme.spacing(2) }}>&nbsp;</div>
                <LoadingScope loading={alertRefresh.showLoading && loading}>
                    {!_.isEmpty(filterSeverityFormatted) && (
                        <>
                            {data.active.length > 0 && history.value.end_time === 0 && (
                                <AlertGroup title={formatForId(intl, "alertHistory.active")} rowCount={data.active.length}>
                                    {data.active.length > 0 && <DataGrid {...DataGridProps} classes={classes} columns={columns} rows={data.active} pagination={false} hideFooter />}
                                </AlertGroup>
                            )}
                            {data.notActive.length > 0 && (
                                <AlertGroup title={formatForId(intl, "alertHistory.notActive")} style={{ paddingTop: theme.spacing(1) }}>
                                    <DataGrid
                                        {...DataGridProps}
                                        classes={classes}
                                        columns={columns}
                                        rows={data.notActive}
                                        pagination
                                        pageSize={50}
                                        rowsPerPageOptions={[50]}
                                        componentsProps={{
                                            pagination: {
                                                labelRowsPerPage: formatForId(intl, "dataGrid.pagination.rowsPerPage"),
                                                labelDisplayedRows: ({ from, to, count }) =>
                                                    formatForId(intl, "dataGrid.pagination.displayedRows", {
                                                        from,
                                                        to,
                                                        count,
                                                    }),
                                            },
                                        }}
                                    />
                                </AlertGroup>
                            )}
                        </>
                    )}
                    {((_.isEmpty(data.active) && _.isEmpty(data.notActive)) || _.isEmpty(filterSeverityFormatted)) && <NoDataMessage />}
                </LoadingScope>
            </AlertHistoryDialogWrapperContent>
        </AlertHistoryDialogWrapper>
    );
}
