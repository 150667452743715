import React from 'react';

import {Collapse, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: props => theme.spacing(props.compact ? 0 : 4),
    },
}));

export function NestedMenuItem({item, currentPath, compact, onItemClick}) {
    const classes = useStyles({compact: compact});

    return <div>
        <ListItem button
                  selected={item.path === currentPath}
                  component={Link}
                  to={item.path}>
            <ListItemIcon>
                {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.name}/>
        </ListItem>
        <Collapse in={true} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {item.items.map((item) => <ListItem button
                                                    className={classes.nested}
                                                    selected={item.path === currentPath}
                                                    component={Link} to={item.path}
                                                    key={item.path}
                                                    onClick={onItemClick}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.name}/>
                    </ListItem>
                )}

            </List>
        </Collapse>
    </div>
}