import {Button} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    control: {
        width: 70,
        position: "sticky",
        top: theme.spacing(1),
        right: theme.spacing(1),
    }
}));

export default function ZoomControl({value, values, onValueChanged}) {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.control}>
            <Button disabled={value === 0} onClick={() => onValueChanged(value - 1)}>
                <FontAwesomeIcon icon={faPlus} size={"lg"}/>
            </Button>
            <Button disabled={value === values.length - 1}
                    onClick={() => onValueChanged(value + 1)}>
                <FontAwesomeIcon icon={faMinus} size={"lg"}/>
            </Button>
        </div>
    </div>
}