const EMPTY_ARRAY = [];

export function setSelectedItem(store, item) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { selectedItem: item },
    });
}

export function setCurrentOverlayImages(store, images) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { currentOverlay: images },
    });
}

export function setImageOverlayEnabled(store, enabled) {
    if (enabled) {
        window.history.replaceState(null, null, "?radarHistory=1");
    } else {
        setCurrentOverlayImages(store, EMPTY_ARRAY);
        setOverlayIndex(store, 0);
        window.history.replaceState(null, null, "?radarHistory=0");
    }

    store.dispatch({
        type: "METEO::MAP",
        payload: { imageOverlayEnabled: enabled },
    });
}

export function setOverlayIndex(store, index) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { overlayIndex: index },
    });
}

export function setImageOverlayPaused(store, paused) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { imageOverlayPaused: paused },
    });
}

export function setSelectedDataType(store, dataType) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { selectedDataType: dataType },
    });
}

export function setNiraDataPanelEnabled(store, enabled) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { niraDataPanelEnabled: enabled },
    });
}

export function setSelectedMapType(store, mapType) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { selectedMapType: mapType },
    });
}

export function setSelectedRoughnessDataTime(store, value) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { selectedRoughnessAlertTime: value },
    });
}

export function setNiraPlayerFrame(store, frame) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { niraPlayerFrame: frame },
    });
}

export function setSelectedPlayerTime(store, value) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { selectedPlayerTime: value },
    });
}

export function setDevicesHidden(store, hidden) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { devicesHidden: hidden },
    });
}

export function setInsufficientNiraZoom(store, insufficient) {
    console.log("Setting insufficient nira zoom with value " + insufficient);
    store.dispatch({
        type: "METEO::MAP",
        payload: { insufficientNiraZoom: insufficient },
    });
}

export function setNiraAlertsEnabled(store, enabled) {
    store.dispatch({
        type: "METEO::MAP",
        payload: { niraAlertsEnabled: enabled },
    });
}
