import { useGet } from "restful-react";
import _ from "underscore";
import React, { useEffect, useMemo, useState } from "react";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { formatFmtString, getLogSeverity, LogSeverity } from "../../Utils/StringUtils";
import { DataGrid } from "@material-ui/data-grid";
import { useIntl } from "react-intl";
import { OutlinedCard } from "../../Components/Forms/NoDataMessage";
import { formatTime } from "../../Utils/Data/Time";
import { Button, Grid } from "@material-ui/core";
import ColorOptionSelect from "../../Components/Forms/ColorOptionSelect";
import { useTheme } from "@material-ui/styles";
import DateRangePicker from "../../Components/Forms/DateRangePicker";
import { useHistoryFilter } from "../../Api";
import { RefreshButton } from "../../Components/Forms/RefreshButton";
import { useStore } from "react-redux";
import { openDialog } from "../../Utils/Data/actions/gui";
import { SimpleOutlinedDeviceQueryField } from "../../Components/Forms/GlobalDeviceQueryField";

const defaultSortModel = [{ field: "time", sort: "desc" }];
export default function EventsData({ userId, deviceId }) {
    const intl = useIntl();
    const theme = useTheme();
    const colors = useMemo(() => [theme.palette.primary.light, theme.palette.primary.main, theme.palette.warning.main, theme.palette.error.main], [theme]);

    const [sortModelSt, setSortModel] = useState(_.clone(defaultSortModel));
    const history = useHistoryFilter();

    const [filter, setFilter] = useState({
        _page: 0,
        device_id: deviceId,
        user_id: userId,
        limit: 100,
        order_by: "-time",
        filter_severity: "1,2,3",
        begin_time: history.value.begin_time,
        end_time: history.value.end_time,
    });
    const [rowCount, setRowCount] = useState(null);

    const { data, refetch, loading, error, response, cancel } = useGet({
        path: "event/list/",
        queryParams: filter,
        lazy: true,
    });
    const [filterSeverity, setFilterSeverity] = useState([1, 2, 3]);

    useEffect(() => {
        setFilter({
            ...filter,
            _page: 0,
            offset: 0,
            begin_time: history.value.begin_time,
            end_time: history.value.end_time,
        });
    }, [history.value.begin_time, history.value.end_time]);

    useEffect(() => {
        const lazyTimer = setTimeout(() => {
            if (filter && filter.hasOwnProperty("limit")) {
                if (filter.limit !== 0) {
                    refetch({ queryParams: filter });
                } else {
                    refetch({ queryParams: { ...filter, limit: 100 } });
                }
            }
        }, 250);
        return () => {
            clearTimeout(lazyTimer);
            cancel();
        };
    }, [filter]);

    useEffect(() => {
        if (response) {
            setRowCount(parseInt(response.headers.get("-x-row-count"), 10));
        }
    }, [response]);
    const store = useStore();
    const columns = useMemo(() => {
        let fields = [
            {
                sortable: false,
                type: "boolean",
                field: "severity",
                headerName: formatForId(intl, "pages.eventList.severity"),
                width: 100,
                renderCell: ({ value }) => {
                    return (
                        <div
                            style={{
                                display: "inline-block",
                                width: "1em",
                                height: "1em",
                                borderRadius: "50%",
                                backgroundColor: colors[value],
                            }}
                            title={formatForId(intl, `log.severity.${getLogSeverity(value)}`)}
                        >
                            &nbsp;
                        </div>
                    );
                },
            },
            {
                field: "time",
                headerName: formatForId(intl, "pages.eventList.time"),
                width: 200,
                type: "date",
                valueFormatter: (params) => {
                    return formatTime(params.value, true);
                },
                valueGetter: (params) => {
                    return new Date(params.value);
                },
            },
        ];

        const allMode = typeof userId === "undefined" && typeof deviceId === "undefined";
        if (allMode) {
            fields.push({
                field: "component_type",
                sortable: false,
                headerName: formatForId(intl, "pages.eventList.component"),
                width: 150,
            });
            fields.push({
                field: "user_login",
                sortable: false,
                headerName: formatForId(intl, "pages.eventList.user_login"),
                flex: 0.1,
                renderCell: ({ value, row }) => {
                    if (row.user_id) {
                        return (
                            <Button
                                onClick={() =>
                                    openDialog(store, {
                                        type: "userEventList",
                                        entity: { id: row.user_id, name: value },
                                    })
                                }
                            >
                                {value}
                            </Button>
                        );
                    }
                    return <div></div>;
                },
            });
            fields.push({
                field: "device_name",
                sortable: false,
                headerName: formatForId(intl, "pages.eventList.device_name"),
                flex: 0.1,
                renderCell: ({ value, row }) => {
                    if (row.device_id) {
                        return (
                            <Button
                                onClick={() =>
                                    openDialog(store, {
                                        type: "eventList",
                                        entity: { id: row.device_id, name: value },
                                    })
                                }
                            >
                                {value}
                            </Button>
                        );
                    }
                    return <div></div>;
                },
            });
        }

        fields.push({
            field: "event_type",
            sortable: false,
            headerName: formatForId(intl, "pages.eventList.event"),
            flex: 0.5,
            valueFormatter: ({ value, row }) => {
                if (value && value.indexOf(" ") === -1) {
                    const intlMsg = formatForId(intl, `log.messages.${value}`);
                    return formatFmtString(intlMsg, JSON.parse(row?.message_tokens_json));
                }
                return formatFmtString(value, JSON.parse(row?.message_tokens_json));
            },
        });

        return fields;
    }, [intl, deviceId, userId]);

    const severityFilterOptions = useMemo(
        () =>
            LogSeverity.map((severity, idx) => ({
                key: idx,
                name: formatForId(intl, `log.severity.${getLogSeverity(idx)}`),
                color: colors[idx],
            })),
        []
    );

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Grid container={true} spacing={1} direction={"row"} alignItems={"center"}>
                <Grid item={true} xs={12} md={5}>
                    <DateRangePicker dense={true} value={history.value} disabled={false} onChange={(value) => history.setValue(value)} />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                    <ColorOptionSelect
                        style={{ width: "100%" }}
                        variant={"outlined"}
                        maring={"normal"}
                        options={severityFilterOptions}
                        value={filterSeverity}
                        onChange={(evt) => {
                            evt.target.value.sort();
                            setFilterSeverity(evt.target.value);
                            setFilter({
                                ...filter,
                                _page: 0,
                                offset: 0,
                                filter_severity: evt.target.value.join(","),
                            });
                        }}
                    />
                </Grid>
                <Grid item={true} xs={1}>
                    <RefreshButton
                        onClick={() => {
                            history.refresh();
                            setFilter({ ...filter, _page: 0, offset: 0, _time: Date.now() });
                        }}
                    />
                </Grid>
            </Grid>
            <div style={{ height: theme.spacing(2) }}>&nbsp;</div>

            {rowCount === 0 ? (
                <OutlinedCard title={formatForId(intl, "noData.title")} message={formatForId(intl, "noData.message")} />
            ) : (
                <div style={{ flexGrow: 1, height: 0 }}>
                    <DataGrid
                        rowCount={rowCount}
                        rows={data || []}
                        columns={columns}
                        autoPageSize={true}
                        disableSelectionOnClick={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        disableMultipleColumnsSorting={true}
                        disableMultipleColumnsFiltering={true}
                        loading={loading}
                        paginationMode={"server"}
                        density={"compact"}
                        sortingMode={"server"}
                        sortModel={sortModelSt}
                        filterMode={"server"}
                        onPageSizeChange={(param) => {
                            console.log("pageSizeChange", param.pageSize);
                            const newFilter = {
                                ...filter,
                                _page: param.page,
                                limit: param.pageSize,
                                offset: param.pageSize * param.page,
                            };
                            setFilter(newFilter);
                        }}
                        onPageChange={(param) => {
                            console.log("onPageChange", param.pageSize);

                            const newFilter = {
                                ...filter,
                                _page: param.page,
                                limit: param.pageSize,
                                offset: param.pageSize * param.page,
                            };
                            setFilter(newFilter);
                        }}
                        page={filter._page}
                        onSortModelChange={(param) => {
                            let { sortModel } = param;
                            if (_.isEmpty(sortModel)) {
                                let sort = "desc";
                                if (sortModelSt[0].sort === "desc") {
                                    sort = "asc";
                                }

                                sortModel = [{ field: sortModelSt[0].field, sort }];
                            }

                            let sortArgs = [];
                            for (const col of sortModel) {
                                if (col.sort === "desc") {
                                    sortArgs.push("-" + col.field);
                                } else {
                                    sortArgs.push(col.field);
                                }
                            }
                            setFilter({ ...filter, _page: 0, limit: 0, order_by: sortArgs.join(",") });
                            setSortModel(_.clone(sortModel));
                        }}
                    />
                </div>
            )}
        </div>
    );
}
