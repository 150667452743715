import React, { useEffect, useMemo, useRef, useState } from "react";
import { MODE } from "../Device/ViewMode";
import DeviceBlock from "../Device/DeviceBlock";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareInfo, faTimes, faWindowMaximize, faWindowRestore } from "@fortawesome/pro-solid-svg-icons";
import { useIsSelected } from "./MapUtils";
import clsx from "clsx";
import { useStore } from "react-redux";
import { setSelectedItem } from "../../Utils/Data/actions/map";
import { DeviceStateTooltip } from "../DeviceStateIcon";
import { useDeviceState, useIsHistoryActive, useValueColor } from "../../Utils/Data/hooks/server";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import StateView, { VIEW_TYPE } from "../Device/StateView";
import { hasValue, useValueInfo } from "../../Utils/Data/ValueMapper";
import { Value } from "../Device/Value";
import { baseFontSize } from "../../index";
import { MAP_TOOLTIP_BACKGROUND } from "./TooltipView";
import "./beak.css";

const baseRoot = {
    borderRadius: 8,
};

const useStyles = makeStyles((theme) => ({
    rootMedium: {
        ...baseRoot,
        minWidth: 300,
    },
    rootLarge: {
        ...baseRoot,
        minWidth: 325,
    },
    rootSmall: {
        ...baseRoot,
        minWidth: 240,
    },
    unselected: {
        outline: "1.4px solid #5F656D",
        outlineOffset: -1,
    },
    selected: {
        outline: "3px solid  #3452eb",
        outlineOffset: -1,
    },
    control: {
        display: "flex",
    },
    iconStyles: {
        fontSize: "0.75em",
    },
}));

const useTooltipStyles = makeStyles((theme) => ({
    outerRoot: {
        display: "flex",
        flexDirection: (props) => (props.vertical ? "column" : "row"),
        alignItems: "center",
    },
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: "#ededed",
        backgroundColor: MAP_TOOLTIP_BACKGROUND,
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        minWidth: 250,
        fontSize: baseFontSize,
        height: 124,
        justifyContent: "center",
    },
    header: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginLeft: 2,
    },
    state: {
        paddingTop: theme.spacing(0.5),
        width: "100%",
    },
    values: {
        paddingTop: theme.spacing(0.5),
        width: "100%",
        display: "flex",
        marginLeft: 2,
    },
    valuesColumn: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    row: {
        width: 120,
        flex: 1,
        paddingBottom: 1,
        paddingTop: 1,
        display: "flex",
    },
    value: {
        display: "flex",
        justifyContent: "center",
        flex: 3,
        width: "100%",
    },
    valueRender: {
        width: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    label: {
        flex: 1,
    },
}));

export function TooltipDeviceView({ device, tooltipDirection }) {
    const vertical = useMemo(() => tooltipDirection === "top" || tooltipDirection === "bottom", [tooltipDirection]);

    const classes = useTooltipStyles({ vertical });
    const deviceState = useDeviceState(device.id);
    const historyActive = useIsHistoryActive();
    const meteo_v1 = deviceState?.data?.meteo_v1 || {};

    const tempFrozKey = useMemo(() => {
        if (hasValue(meteo_v1["temp_froz_mea"]?.value)) {
            return "temp_froz_mea";
        } else if (hasValue(meteo_v1["temp_froz_comp"]?.value)) {
            return "temp_froz_comp";
        } else if (hasValue(meteo_v1["precip"]?.value)) {
            return "precip";
        } else {
            return "dewpoint";
        }
    }, [device, deviceState]);

    const beakClass = useMemo(() => {
        switch (tooltipDirection) {
            case "top":
                return "beakDown";
            case "bottom":
                return "beakUp";
            case "left":
                return "beakRight";
            case "right":
                return "beakLeft";
        }
        return "beakBottom";
    }, [tooltipDirection, classes]);

    const ValueCustom = ({ value, valueKey, valueTextColor = "#2FBFF7" }) => {
        const valueInfo = useValueInfo(valueKey);
        const color = useValueColor(valueInfo, deviceState.active_warnings, value, historyActive);

        return (
            <div className={classes.row}>
                <div className={classes.label}>{valueInfo.shortcutFormatted + ":"}</div>

                <div className={classes.value}>
                    <Value
                        value={value?.value?.value}
                        valueInfo={valueInfo}
                        className={classes.valueRender}
                        backgroundColor={color.primary}
                        textColor={color.textColor ? color.textColor : valueTextColor}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={classes.outerRoot}>
            {(tooltipDirection === "bottom" || tooltipDirection === "right") && <div className={beakClass}></div>}

            <div className={classes.root}>
                <div className={classes.header}>
                    <Typography variant={"h6"}>{device.name}</Typography>
                </div>
                <div className={classes.state}>
                    <StateView device={device} deviceState={deviceState} type={VIEW_TYPE.one_row_warning_and_states} />
                </div>
                <div className={classes.values}>
                    <div className={classes.valuesColumn}>
                        <ValueCustom value={meteo_v1["temp"]} valueKey={"temp"}></ValueCustom>
                        <ValueCustom value={meteo_v1[tempFrozKey]} valueKey={tempFrozKey}></ValueCustom>
                    </div>
                    <div className={classes.valuesColumn}>
                        <ValueCustom value={meteo_v1["road_temp"]} valueKey={"road_temp"}></ValueCustom>
                        <ValueCustom value={meteo_v1["humidity"]} valueKey={"humidity"}></ValueCustom>
                    </div>
                </div>
            </div>
            {(tooltipDirection === "top" || tooltipDirection === "left") && <div className={beakClass}></div>}
        </div>
    );
}

function CustomTooltip({ title, children, deviceOpened }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!deviceOpened) {
            setOpen(false);
        }
    }, [deviceOpened]);

    return (
        <Tooltip title={title} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
            {children}
        </Tooltip>
    );
}

export const SingleDeviceView = React.forwardRef((props) => {
    const { device, setOpen, onDimensionsChange, open } = props;
    const classes = useStyles();
    const [mode, setMode] = useState(MODE.small);
    const store = useStore();
    const deviceState = useDeviceState(device.id);
    const intl = useIntl();

    const isSelected = useIsSelected(device.id);
    const ref = useRef();

    const getRootClass = (mode) => {
        switch (mode) {
            case MODE.small:
                return classes.rootSmall;
            case MODE.medium:
                return classes.rootMedium;
            case MODE.large:
                return classes.rootLarge;
        }
    };
    const rootClass = useMemo(() => (isSelected ? clsx(getRootClass(mode), classes.selected) : clsx(getRootClass(mode), classes.unselected)), [isSelected, mode]);

    useEffect(() => {
        if (ref.current) {
            onDimensionsChange({ height: ref.current.clientHeight, width: ref.current.clientWidth });
        }
    }, [mode, ref.current]);

    const changeMode = (e) => {
        if (mode === MODE.small) {
            setMode(MODE.medium);
        } else if (mode === MODE.medium) {
            setMode(MODE.large);
        } else if (mode === MODE.large) {
            setMode(MODE.small);
        }
        e.stopPropagation();
    };

    const close = (e) => {
        setOpen(false);
        e.stopPropagation();
    };

    return (
        <div className={rootClass} onClick={() => setSelectedItem(store, device)} ref={ref} {...props}>
            <DeviceBlock
                camera={true}
                device={device}
                mode={mode}
                square={false}
                headerStylesOverride={{ paddingBottom: 0 }}
                onRenderControl={() => (
                    <div className={classes.control}>
                        <DeviceStateTooltip deviceState={deviceState} device={device}>
                            <IconButton>
                                <FontAwesomeIcon icon={faSquareInfo} className={classes.iconStyles} />
                            </IconButton>
                        </DeviceStateTooltip>

                        <CustomTooltip deviceOpened={open} title={formatForId(intl, `pages.views.flag.control.tooltip.${mode === MODE.large ? "minimize" : "maximize"}`)}>
                            <IconButton onClick={changeMode}>
                                <FontAwesomeIcon icon={mode === MODE.large ? faWindowRestore : faWindowMaximize} className={classes.iconStyles} />
                            </IconButton>
                        </CustomTooltip>

                        <CustomTooltip deviceOpened={open} title={formatForId(intl, "pages.views.flag.control.tooltip.close")}>
                            <IconButton onClick={close}>
                                <FontAwesomeIcon icon={faTimes} className={classes.iconStyles} />
                            </IconButton>
                        </CustomTooltip>
                    </div>
                )}
                showDeviceStateTooltip={false}
            />
        </div>
    );
});
