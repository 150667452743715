import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Security from "./security";
import Login from "./Pages/Login";
import { Button, createTheme, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider, useIntl } from "react-intl";
import { getIntlCatalog } from "./Utils/Lang/LangLoader";
import { RestfulProvider } from "restful-react";
import ModelProvider from "./Utils/Data/ModelProvider";
import { ErrorBoundary } from "react-error-boundary";
import { formatForId } from "./Utils/Lang/IntlHelper";
import { LocalizationProvider } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import moment from "moment";
import "moment/locale/sk";
import { YupLocaleProvider } from "./Utils/Lang/YupLocaleProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReactNotifications } from "react-notifications-component";
import ValueInfoProvider from "./Utils/Data/ValueMapper";
import { useLocale } from "./Utils/Data/hooks/gui";
import { NIRA_ALERT_COLOR_ORANGE, NIRA_ALERT_COLOR_RED } from "./Components/Map/Nira/Gradient";

moment.locale("sk"); // it is required to select default locale manually

export const baseFontSize = "0.86rem";
export const baseButtonFontSize = "0.82rem";
export const ToolbarHeight = 56;

export const PALETTE = {
    type: "dark",
    primary: {
        main: "#1565c0",
    },
    secondary: {
        main: "#f50057",
    },
    background: {
        default: "#121212",
        paper: "#18202c",
        appBar: "#343B46",
    },
    warnings: {
        level_1: {
            primary: "#fffc04",
            secondary: "#fffc9c",
            textColor: "#121212",
        },
        level_2: {
            primary: "#FF7800",
            secondary: "#ffdc64",
            textColor: "#121212",
        },
        level_3: {
            primary: "#ff0404",
            secondary: "#ff5858",
            textColor: "#fff",
        },
        level_100: {
            primary: "grey",
            secondary: "grey",
            textColor: "#fff",
        },
        level_20: {
            primary: "white",
            secondary: "white",
            textColor: "#121212",
        },
        level_30: {
            primary: "#6F44B4",
            secondary: "#6F44B4",
            textColor: "#fff",
        },
        nira_level_1: {
            primary: NIRA_ALERT_COLOR_ORANGE,
            textColor: "#121212",
        },
        nira_level_2: {
            primary: NIRA_ALERT_COLOR_RED,
            textColor: "#fff",
        },
    },
    hover: "#4874b5",
    historyActiveColor: "#f0ca46",
    valueTextColor: "#2FBFF7",
};

export let theme = createTheme({
    palette: PALETTE,
    typography: {
        fontSize: 12,
        body1: {
            fontSize: baseFontSize,
        },
        body2: {
            fontSize: baseFontSize,
        },
        h5: {
            fontSize: "1.15rem",
        },
        h6: {
            fontSize: "0.96rem",
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            height: ToolbarHeight,
        },
    },
    custom: {
        baseClickableDiv: {
            borderRadius: 8,
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    backgroundColor: theme.palette.background.default,
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: theme.palette.background.paper,
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: theme.palette.background.paper,
            },
        },
        MuiButton: {
            root: {
                fontSize: baseButtonFontSize + " !important",
            },
            label: {
                textTransform: "none",
            },
            contained: {
                boxShadow: "none",
                "&:active": {
                    boxShadow: "none",
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                fontSize: baseButtonFontSize + " !important",
                textTransform: "none",
                margin: "0 16px",
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
                fontSize: baseFontSize,
                // fontWeight: "inherit"
            },
        },
        MuiDivider: {
            root: {
                // backgroundColor: '#404854',
            },
        },
        MuiListItem: {
            root: {
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: "inherit",
                marginRight: 0,
                "& svg": {
                    // fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
        MuiFormControl: {
            root: { padding: 5 },
        },
        MuiFormLabel: {
            root: {
                color: "inherit",
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#343B46",
            },
        },
    },
};

function ErrorFallback({ error, resetErrorBoundary }) {
    const intl = useIntl();
    return (
        <Dialog open={true}>
            <DialogTitle>{formatForId(intl, "dialog.error.title")}</DialogTitle>
            <DialogContent>
                {error && error.message}
                {!error && formatForId(intl, "dialog.error.unknown")}
            </DialogContent>
            <DialogActions>
                <Button onClick={resetErrorBoundary}> {formatForId(intl, "dialog.error.reset")}</Button>
            </DialogActions>
        </Dialog>
    );
}

function LocalizedApp({}) {
    const [locale] = useLocale();

    return (
        <IntlProvider locale={locale} messages={getIntlCatalog(locale)} onError={() => {}}>
            <YupLocaleProvider>
                <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={locale}>
                    {Security.isAuthenticated() ? (
                        <ModelProvider>
                            <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                onReset={() => {
                                    window.location.href = "/";
                                }}
                            >
                                <ValueInfoProvider>
                                    <App />
                                </ValueInfoProvider>
                            </ErrorBoundary>
                        </ModelProvider>
                    ) : (
                        <Login />
                    )}
                </LocalizationProvider>
            </YupLocaleProvider>
        </IntlProvider>
    );
}

ReactDOM.render(
    <React.StrictMode>
        {
            <DndProvider backend={HTML5Backend}>
                <ThemeProvider theme={theme}>
                    <ReactNotifications />
                    <RestfulProvider base="/api/">
                        <BrowserRouter>
                            <LocalizedApp />
                        </BrowserRouter>
                    </RestfulProvider>
                </ThemeProvider>
            </DndProvider>
        }
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
