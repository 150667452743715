import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import {useIntl} from "react-intl";
import EventsData from "./EventsData";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {makeStyles} from "@material-ui/core/styles";
import useWindowDimensions from "../../Utils/Data/hooks/dimensions";
import {CommonDialog} from "../../Components/CommonDialog";
import {DialogCloseButton} from "../../Components/MasterDetail/DialogSaveCancelButtons";

const useStyles = makeStyles((theme) => ({
    main: {
        margin: theme.spacing(3),
    },
}));

export function EventListDialog({userId, deviceId, onClose, open}) {
    const intl = useIntl();
    const classes = useStyles();
    const {height} = useWindowDimensions();

    return <CommonDialog fullWidth={true} maxWidth={"lg"} open={open} onClose={onClose} PaperProps={{style: {height: height -100}}} >
        <DialogContent className={classes.main} >

                <EventsData userId={userId} deviceId={deviceId} limit={100} />

        </DialogContent>
        <DialogActions>
          <DialogCloseButton onClose={onClose} />
        </DialogActions>
    </CommonDialog>
}
