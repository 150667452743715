import React, { useMemo } from "react";
import { Chip, FormControl, FormControlLabel, FormHelperText, FormLabel, MenuItem, Select } from "@material-ui/core";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import _ from "loadsh";
import { useDrag, useDrop } from "react-dnd";

import { MenuProps, useStyles } from "./DropDownMenuProps";

export function DraggableChip({ fieldId, label, className, value, onInsertBefore, icon, onClick }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: fieldId,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        item: { value },
    }));

    const [{ isOver }, drop] = useDrop(() => ({
        accept: fieldId,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
        drop: (element, monitor) => {
            onInsertBefore(element.value, value);
        },
    }));
    return (
        <div ref={drop} dragValue={value} onMouseDown={(evt) => evt.stopPropagation()} onClick={onClick}>
            <Chip ref={drag} dragValue={value} label={label} className={className} icon={icon} style={{ opacity: isDragging ? 0.5 : 1, backgroundColor: isOver ? "red" : "" }} />
        </div>
    );
}

export function DropdownField({ inputRef, fieldId, value, required, changeHandle, touched, errors, label, multiple, disabled, fieldDef }) {
    const intl = useIntl();
    const items = fieldDef.props.items || [];
    const valueNames = fieldDef.props.valueNames || [];
    const { allowChangeOrder } = fieldDef.props;
    const localizationContext = fieldDef.props.localizationContext;
    const classes = useStyles();
    const onInsertBeforeHandler = (subject, dest) => {
        if (subject === dest) return;

        const subjectIndex = _.indexOf(value, subject);

        const newItems = _.without(value, subject);
        const destIndex = _.indexOf(newItems, dest);

        if (subjectIndex <= destIndex) {
            newItems.splice(destIndex, 1, dest, subject);
        } else {
            newItems.splice(destIndex, 0, subject);
        }

        changeHandle({
            target: {
                name: fieldId,
                value: newItems,
            },
        });
    };

    const valueLabel = (value, idx) => {
        if (valueNames.length > 0) {
            return valueNames[idx];
        }
        return localizationContext ? formatForId(intl, localizationContext + "." + value) : value;
    };
    const placeholder = () => {
        const key = localizationContext + ".placeholder";
        let result = localizationContext ? formatForId(intl, key) : null;
        if (result === key) {
            result = null;
        }
        return result;
    };

    const placeHolderLabel = useMemo(() => placeholder(), []);

    const selectProps = !multiple
        ? {}
        : allowChangeOrder
        ? {
              renderValue(selected) {
                  // we need to ensure that we create new draggablechip instances everytime
                  if (_.isEmpty(selected)) {
                      return <em style={{ color: "lightgray" }}>{placeHolderLabel}</em>;
                  }

                  const id = selected ? selected.join("+") : "";
                  return (
                      <div className={classes.chips}>
                          {_.map(selected, (value, idx) => (
                              <DraggableChip key={value + ":" + id} fieldId={fieldId} label={valueLabel(value, idx)} className={classes.chip} value={value} onInsertBefore={onInsertBeforeHandler} />
                          ))}
                      </div>
                  );
              },
          }
        : {
              renderValue(selected) {
                  if (_.isEmpty(selected)) {
                      return <em style={{ color: "lightgray" }}>{placeHolderLabel}</em>;
                  }

                  return (
                      <div className={classes.chips}>
                          {_.map(selected, (value, idx) => (
                              <Chip key={value} label={valueLabel(value, idx)} className={classes.chip} />
                          ))}
                      </div>
                  );
              },
          };

    const usedChangeHandler = !placeHolderLabel
        ? changeHandle
        : (evt) => {
              if (_.indexOf(evt.target.value, "") < 0) {
                  changeHandle(evt);
              } else {
                  changeHandle({ target: { ...evt.target, value: [] } });
              }
          };
    selectProps.displayEmpty = !!placeHolderLabel;

    const menuProps = MenuProps; // {...MenuProps, anchorEl: ()=>document.getElementsByClassName("anchor-"+fieldId)[0]};

    if (multiple) {
        value = _.filter(value, (val) => items.indexOf(val) !== -1);
    }

    return (
        <FormControl fullWidth required={required}>
            <FormLabel component="legend" disabled={disabled}>
                {label}
            </FormLabel>
            <FormControlLabel
                style={{ padding: "10px 17px" }}
                label={""}
                control={
                    <Select
                        inputRef={inputRef}
                        MenuProps={menuProps}
                        placeholder={placeHolderLabel}
                        multiple={Boolean(multiple)}
                        name={fieldId}
                        value={value ? value : multiple ? [] : items?.[0]}
                        onChange={usedChangeHandler}
                        disabled={disabled}
                        error={touched && Boolean(errors)}
                        fullWidth
                        {...selectProps}
                    >
                        {!_.isEmpty(placeHolderLabel) && multiple ? (
                            <MenuItem
                                title={formatForId(intl, "actions.dblClickSelectAll")}
                                value=""
                                onDoubleClickCapture={() => {
                                    usedChangeHandler({
                                        target: {
                                            name: fieldId,
                                            value: _.clone(items),
                                        },
                                    });
                                }}
                            >
                                <em style={{ color: "lightgray" }}>{placeHolderLabel}</em>
                            </MenuItem>
                        ) : (
                            !_.isEmpty(placeHolderLabel) && (
                                <MenuItem value="">
                                    <em style={{ color: "lightgray" }}>{placeHolderLabel}</em>
                                </MenuItem>
                            )
                        )}

                        {items.map((item, idx) => (
                            <MenuItem key={item} value={item}>
                                {valueLabel(item, idx)}
                            </MenuItem>
                        ))}
                    </Select>
                }
            />
            {touched && Boolean(errors) && <FormHelperText error={true}>{errors}</FormHelperText>}
            <div className={"anchor-" + fieldId}></div>
        </FormControl>
    );
}
