import { hasValue, useValueInfo } from "../../Utils/Data/ValueMapper";
import { Tooltip } from "@material-ui/core";
import { Value } from "./Value";
import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MODE } from "./ViewMode";
import { useIsHistoryActive, useValueColor } from "../../Utils/Data/hooks/server";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 325,
        paddingLeft: 2,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
    },
    table: {
        flex: 1,
        display: "flex",
        overflow: "hidden",
    },
    flexRow: {
        flexDirection: "row",
    },
    flexColumn: {
        flexDirection: "column",
    },
    row: {
        width: 150,
        flex: 1,
        paddingBottom: 1,
        paddingTop: 1,
        display: "flex",
    },
    label: {
        flex: 1,
    },
    value: {
        display: "flex",
        justifyContent: "center",
        flex: 3,
    },
    valueRender: {
        width: "95%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

function Row({ valueKey, classes, value, warnings, historyActive, valueTextColor }) {
    const valueInfo = useValueInfo(valueKey);
    const color = useValueColor(valueInfo, warnings, value, historyActive);

    return (
        <div className={classes.row}>
            <Tooltip title={valueInfo.nameFormatted}>
                <div className={classes.label}>{valueInfo.shortcutFormatted + ":"}</div>
            </Tooltip>
            <div className={classes.value}>
                <Value
                    value={value?.value?.value}
                    valueInfo={valueInfo}
                    className={classes.valueRender}
                    backgroundColor={color.primary}
                    textColor={color.textColor ? color.textColor : valueTextColor}
                />
            </div>
        </div>
    );
}

function SubTable({ values, classes, horizontal }) {
    return <div className={clsx(classes.table, horizontal ? classes.flexRow : classes.flexColumn)}>{[...values]}</div>;
}

export function ValuesTable({ deviceState, mode = MODE.medium, horizontal = false, valueTextColor = "#2FBFF7", fieldsOrder }) {
    const historyActive = useIsHistoryActive();

    const classes = useStyles();

    const values = useMemo(() => {
        const meteo_v1 = deviceState?.data?.meteo_v1 || {};

        let ret = [];
        fieldsOrder.forEach((item) => {
            if (item !== "dateTime" && hasValue(meteo_v1[item]?.value)) {
                ret.push(
                    <Row
                        key={item}
                        valueKey={item}
                        deviceState={deviceState}
                        value={meteo_v1[item]}
                        classes={classes}
                        warnings={deviceState.active_warnings}
                        historyActive={historyActive}
                        valueTextColor={valueTextColor}
                    />
                );
            }
        });
        return ret;
    }, [deviceState]);

    const splitIdx = useMemo(() => Math.ceil(values.length / 2), [values]);

    return (
        <div className={clsx(classes.root, horizontal ? classes.flexColumn : classes.flexRow)}>
            <SubTable values={values.slice(0, splitIdx)} classes={classes} horizontal={horizontal} />
            <SubTable values={values.slice(splitIdx, values.length)} classes={classes} horizontal={horizontal} />
        </div>
    );
}
