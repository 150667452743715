import { makeStyles } from "@material-ui/core/styles";
import { Halign, Valign } from "../../../Components/Flex";
import { useEndDeviceData, useRnrTrafficState, useRnrViewContext } from "../../../Utils/Data/actions/RnrData";
import { FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { usePostMutate } from "../../../Api";
import { useNotification } from "../../../Notification";
import { formatTime } from "../../../Utils/Data/Time";
import { useHasAnyPermission } from "../../../Utils/Permissions/RequireAnyPermission";
import { RequirePermission } from "../../../Utils/Permissions/RequirePermission";
import { TrafficStatesControl } from "./TrafficStateControl";
import LoadingScope from "../../../Components/LoadingScope";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        height: "100%",
        paddingBottom: 16,
    },
    table: {
        minWidth: 800,
        overflow: "hidden",
    },
}));

export function TrafficStateSelector({ leftTrafficStates, rightTrafficStates, rnrTrafficStateData, rnrDriverId, reloadData, domainId }) {
    const intl = useIntl();
    const { postData } = usePostMutate("/rnr-project/traffic-state/");
    const notification = useNotification();
    const hasPermRnrSetState = useHasAnyPermission({ permission: "rnr__set_state", domainID: domainId });

    const onSubmit = async (values) => {
        const response = await notification.showApiMessage(
            postData({
                rnr_project_device_id: rnrDriverId,
                left_state: values.leftTrafficState,
                right_state: values.rightTrafficState,
            })
        );
        reloadData();
    };

    const formik = useFormik({
        initialValues: {
            leftTrafficState: rnrTrafficStateData?.left_lane?.active_traffic_state?.name || "default",
            rightTrafficState: rnrTrafficStateData?.right_lane?.active_traffic_state?.name || "default",
        },
        enableReinitialize: true,

        onSubmit: async (values) => {
            await onSubmit(values);
        },
    });

    return (
        <form style={{ width: "100%", padding: 16 }} onSubmit={formik.handleSubmit}>
            <Halign width={"100%"}>
                <FormControl fullWidth>
                    <InputLabel>{formatForId(intl, "forms.trafficStates.name")}</InputLabel>
                    <Select
                        id="leftTrafficState"
                        name="leftTrafficState"
                        value={formik.values.leftTrafficState}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={!hasPermRnrSetState}
                        error={formik.touched.leftTrafficState && Boolean(formik.errors.leftTrafficState)}
                    >
                        {leftTrafficStates?.data?.map((state) => (
                            <MenuItem key={state.name} value={state.name}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.leftTrafficState && formik.errors.leftTrafficState ? <div>{formik.errors.leftTrafficState}</div> : null}
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>{formatForId(intl, "forms.trafficStates.name")}</InputLabel>
                    <Select
                        id="rightTrafficState"
                        name="rightTrafficState"
                        value={formik.values.rightTrafficState}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={!hasPermRnrSetState}
                        error={formik.touched.rightTrafficState && Boolean(formik.errors.rightTrafficState)}
                    >
                        {rightTrafficStates?.data?.map((state) => (
                            <MenuItem key={state.name} value={state.name}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.rightTrafficState && formik.errors.rightTrafficState ? <div>{formik.errors.rightTrafficState}</div> : null}
                </FormControl>
                {hasPermRnrSetState && (
                    <Button color="primary" variant="contained" type="submit" style={{ marginLeft: 16, width: 120 }}>
                        {formatForId(intl, "form.submit")}
                    </Button>
                )}
            </Halign>
        </form>
    );
}

export function TrafficStateControlContainer({ bgRef, trafficStates, rnrTrafficStateData, reloadData, rnrDriverId, domainId, endDevices, slices }) {
    return (
        <Valign
            style={{
                height: 120,
                width: "100%",
                padding: 4,
            }}
        >
            <TrafficStatesControl
                trafficStates={trafficStates}
                rnrTrafficStateData={rnrTrafficStateData}
                reloadData={reloadData}
                rnrDriverId={rnrDriverId}
                domainId={domainId}
                endDevices={endDevices}
                slices={slices}
            />
        </Valign>
    );
}

function RnrTableRow({ device, rnrTrafficStateData }) {
    const { endDeviceState, rnrState } = useEndDeviceData(device, rnrTrafficStateData);

    return (
        <TableRow>
            <TableCell align={"center"} component="th" scope="row">
                {device.rnrId}
            </TableCell>
            <TableCell align="center">{device.sliceId}</TableCell>
            <TableCell align="center">{device.endDeviceId}</TableCell>
            <TableCell align="center">{endDeviceState?.current_display ? JSON.stringify(endDeviceState?.current_display?.symbols) : "NONE"}</TableCell>
            <TableCell align="center">{endDeviceState?.pending_display ? JSON.stringify(endDeviceState?.pending_display?.symbols) : "NONE"}</TableCell>
            <TableCell align="center">{endDeviceState?.infos?.last?.message}</TableCell>
            <TableCell align="center">{endDeviceState?.warnings?.last?.message}</TableCell>
            <TableCell align="center">{endDeviceState?.errors?.last?.message}</TableCell>
        </TableRow>
    );
}

function RnrStateTable({ rnrTrafficStateData, endDevices }) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper} style={{ marginRight: 16 }}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">RNR ID</TableCell>
                        <TableCell align="center">NR ID</TableCell>
                        <TableCell align="center">Device ID</TableCell>
                        <TableCell align="center">Current symbols</TableCell>
                        <TableCell align="center">Pending symbols</TableCell>
                        <TableCell align="center">Last info</TableCell>
                        <TableCell align="center">Last warning</TableCell>
                        <TableCell align="center">Last error</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {endDevices.map((device) => (
                        <RnrTableRow key={device.id} device={device} rnrTrafficStateData={rnrTrafficStateData} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function TrafficStateTable({ trafficState, lane_id }) {
    const classes = useStyles();
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>RNR ID</TableCell>
                        <TableCell align="center">NR ID</TableCell>
                        <TableCell align="center">Device ID</TableCell>
                        <TableCell align="center">Is Mandatory</TableCell>
                        <TableCell align="center">Delay</TableCell>
                        <TableCell align="center">Symbols</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {trafficState?.data
                        ?.sort((a, b) => a.rnr_id.localeCompare(b.rnr_id))
                        .map((row) => (
                            <TableRow key={lane_id + "_" + row.rnr_id + "_" + row.nr_id + "_" + row.device_id}>
                                <TableCell component="th" scope="row">
                                    {row.rnr_id}
                                </TableCell>
                                <TableCell align="center">{row.nr_id}</TableCell>
                                <TableCell align="center">{row.device_id}</TableCell>
                                <TableCell align="center">{row.is_mandatory.toString()}</TableCell>
                                <TableCell align="center">{row.delay}</TableCell>
                                <TableCell align="center">{row.symbols.join(", ")}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function TrafficStateInfo({ trafficState }) {
    return (
        <Halign style={{ paddingLeft: 16, paddingBottom: 16 }}>
            <Typography variant={"body2"}>{`Name : ${trafficState?.name}, User: ${trafficState?.user ? trafficState.user?.name : "NONE"}, Timestamp: ${formatTime(trafficState?.time)}`}</Typography>
        </Halign>
    );
}

function DebugRnrViewLocal({ trafficStates, rnrDriverId, projectDevices, endDevices, domainId, slices }) {
    const classes = useStyles();
    const bgRef = React.useRef(null);
    const { rnrTrafficStateData, reloadData, error, loading } = useRnrTrafficState(rnrDriverId);

    return (
        <RequirePermission permission={"rnr__debug_state"} domainID={domainId}>
            <RequirePermission permission={"rnr__view_state"} domainID={domainId}>
                <LoadingScope loading={loading} error={error} showLoading={false} dialog={false}>
                    <Valign className={classes.root} vertical={"flex-start"}>
                        <Valign style={{ width: "100%", padding: 16 }} vertical={"flex-start"} horizontal={"flex-start"}>
                            <TrafficStateControlContainer
                                rnrTrafficStateData={rnrTrafficStateData}
                                reloadData={reloadData}
                                trafficStates={trafficStates}
                                rnrDriverId={rnrDriverId}
                                bgRef={bgRef}
                                domainId={domainId}
                                endDevices={endDevices}
                                slices={slices}
                            />

                            <Typography variant={"h5"} style={{ padding: 16 }}>
                                Device states
                            </Typography>
                            <RnrStateTable rnrTrafficStateData={rnrTrafficStateData} endDevices={endDevices} />
                            <Typography variant={"h5"} style={{ padding: 16 }}>
                                Active traffic state left
                            </Typography>
                            <TrafficStateInfo trafficState={rnrTrafficStateData?.left_lane?.active_traffic_state} />
                            <TrafficStateTable trafficState={rnrTrafficStateData?.left_lane?.active_traffic_state_detail} lane_id={"left"} />

                            <Typography variant={"h5"} style={{ padding: 16 }}>
                                Active traffic state right
                            </Typography>
                            <TrafficStateInfo trafficState={rnrTrafficStateData?.right_lane?.active_traffic_state} />
                            <TrafficStateTable trafficState={rnrTrafficStateData?.right_lane?.active_traffic_state_detail} lane_id={"right"} />
                        </Valign>
                    </Valign>
                </LoadingScope>
            </RequirePermission>
        </RequirePermission>
    );
}

export default function DebugRnrView({}) {
    const { trafficStates, rnrDriverId, projectDevices, endDevices, domainId, slices } = useRnrViewContext();

    return <DebugRnrViewLocal projectDevices={projectDevices} endDevices={endDevices} rnrDriverId={rnrDriverId} domainId={domainId} trafficStates={trafficStates} slices={slices} />;
}
