import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToLine, faBackwardStep, faForwardStep, faPause, faPauseCircle, faPlay, faPlayCircle } from "@fortawesome/pro-solid-svg-icons";
import React, { useMemo, useState } from "react";
import { useIntervalWhen } from "rooks";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useIsHistoryActive } from "../../Utils/Data/hooks/server";
import { useWideScreen } from "../../Utils/Hooks/DesktopOnlyView";
import { ArrowIcon } from "../../Components/Icons/MapIcons";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: (props) => (props.vertical ? "column" : "row"),
    },
    button: {
        width: 38,
        paddingLeft: theme.spacing(0.25),
        paddingRight: theme.spacing(0.25),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    control: {
        height: "100%",
        display: "flex",
        border: "1px solid rgba(255, 255, 255, 0.23)",
        borderRadius: 8,
        paddingRight: theme.spacing(0.25),
        alignItems: "center",
        justifyContent: "center",
    },
    speedControl: {
        display: "flex",
        marginRight: (props) => (props.vertical ? 0 : theme.spacing(1)),
        marginBottom: (props) => (props.vertical ? theme.spacing(0.25) : 0),
        border: "1px solid rgba(255, 255, 255, 0.23)",
        borderRadius: 8,
        height: 38,
    },
    icon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

function CameraButton({ icon, onClick, rotation, disabled, renderIcon = false }) {
    const classes = useStyles();

    return (
        <div className={classes.button}>
            <IconButton onClick={onClick} disabled={disabled}>
                {renderIcon ? icon : <FontAwesomeIcon icon={icon} rotation={rotation} />}
            </IconButton>
        </div>
    );
}

export default function CameraPlayer({ control }) {
    const wideScreen = useWideScreen(600);
    const classes = useStyles({ vertical: !wideScreen });

    const [playingForward, setPlayingForward] = useState(false);
    const [playingBackward, setPlayingBackward] = useState(false);
    const [speed, setSpeed] = useState(1000);
    const historyActive = useIsHistoryActive();

    const futureAllowed = useMemo(() => historyActive || control.frame < 0, [control.frame, historyActive]);
    const setPlaying = (forward, backward) => {
        if (forward || backward) {
            control.setPaused(false);
        } else {
            control.setPaused(true);
        }
        setPlayingForward(forward);
        setPlayingBackward(backward);
    };

    useIntervalWhen(
        () => {
            if (playingForward && !historyActive && control.frame >= 0) {
                setPlaying(false, false);
                return;
            }

            if (control.frame !== control.lastPlayedFrame) {
                console.log("not moving because previous frame was not loaded yet", control.frame, control.lastPlayedFrame); //TODO remove logging
                return;
            }
            console.log("moving to next frame", control.frame, control.lastPlayedFrame);
            if (playingForward) {
                control.moveForwards();
            } else if (playingBackward) {
                control.moveBackwards();
            }
        },
        speed,
        !control.paused,
        true
    );

    return (
        <div className={classes.root}>
            <div className={classes.speedControl}>
                <ToggleButtonGroup value={speed} onChange={(evt, val) => setSpeed(val)} exclusive>
                    <ToggleButton value={1000}>1x</ToggleButton>
                    <ToggleButton value={600}>2x</ToggleButton>
                    <ToggleButton value={300}>5x</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div className={classes.control}>
                <div style={{ paddingLeft: 4 }}></div>
                <CameraButton onClick={() => control.moveBackwards()} icon={faBackwardStep} />
                <CameraButton
                    onClick={() => {
                        setPlaying(false, true);
                    }}
                    icon={playingBackward ? faPlayCircle : faPlay}
                    rotation={180}
                />
                <CameraButton
                    onClick={() => {
                        setPlaying(false, false);
                    }}
                    icon={control.paused && control.frame !== 0 ? faPauseCircle : faPause}
                />
                <CameraButton
                    onClick={() => {
                        setPlaying(true, false);
                    }}
                    icon={playingForward ? faPlayCircle : faPlay}
                    disabled={!futureAllowed}
                />
                <CameraButton onClick={() => control.moveForwards()} icon={faForwardStep} disabled={!futureAllowed} />
                {control.frame === 0 ? (
                    <CameraButton
                        onClick={() => {
                            control.setFrame(0);
                            setPlaying(false, false);
                        }}
                        icon={
                            <div className={classes.icon}>
                                <ArrowIcon />
                            </div>
                        }
                        renderIcon
                        disabled
                    />
                ) : (
                    <CameraButton
                        onClick={() => {
                            control.setFrame(0);
                            setPlaying(false, false);
                        }}
                        icon={faArrowRightToLine}
                    />
                )}
            </div>
        </div>
    );
}

export function useCameraPlayerControl() {
    const [frame, setFrame] = useState(0);
    const [paused, setPaused] = useState(true);
    const [lastPlayedFrame, setLastPlayedFrame] = useState(undefined);

    const moveBackwards = () => setFrame(frame - 1);
    const moveForwards = () => setFrame(frame + 1);

    return { frame, setFrame, paused, setPaused, moveForwards, moveBackwards, lastPlayedFrame, setLastPlayedFrame };
}
