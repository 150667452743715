import { AirPressure, CurrentRoughness, Friction, LongTermRoughness, None, RelativeHumidity, RoughnessChangesAlerts, Temperature, Wiperspeed } from "../../NiraApi";
import { makeStyles } from "@material-ui/core/styles";
import chroma from "chroma-js";
import { useMemo } from "react";
import { Typography } from "@material-ui/core";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import { NIRA_ALERT_NONE, NIRA_ALERT_RED, NIRA_ALERT_YELLOW } from "../../../Utils/Data/AlertFormatter";

export const RadarImageType = "radarImageType";
const useStyles = makeStyles((theme) => ({
    gradientPanelRoot: {
        height: "100%",
        width: "100%",
        zIndex: 401, //due to leaflet
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
    },
    gradientPanel: {
        width: "100%",
        height: 12,
    },
    legendPanel: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingTop: theme.spacing(0.25),
    },
    segmentedPanel: {
        height: 22,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(0.5),
    },
    segment: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
    },
    labelItem: {
        display: "flex",
        flex: 1,
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));

export function GradientPanel({ dataType, gradientOpacity = 1 }) {
    const classes = useStyles();
    const intl = useIntl();

    const { gradient, options } = useMemo(() => {
        const options = getGradientOptions(dataType);
        return { gradient: `linear-gradient(90deg, ${options.colors.join(",")})`, options };
    }, [dataType]);

    if (dataType === None) {
        return <></>;
    }

    return (
        <div className={classes.gradientPanelRoot}>
            <>
                {options.segmented ? (
                    <div className={classes.segmentedPanel}>
                        {options.colors.map((color, idx) => (
                            <div style={{ backgroundColor: color, color: options.labelKeys[idx].textColor }} className={classes.segment}>
                                {formatForId(intl, options.labelKeys[idx].key)}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={classes.gradientPanel} style={{ background: gradient, opacity: gradientOpacity }} />
                )}
            </>
            {!options.segmented && (
                <div className={classes.legendPanel}>
                    {options.prefixKey && <Typography variant={"subtitle2"}>{formatForId(intl, options.prefixKey)}</Typography>}
                    {options.domain.map((item) => (
                        <Typography variant={"subtitle2"}>{item + (options.unitKey ? " " + formatForId(intl, options.unitKey) : "")}</Typography>
                    ))}
                    {options.suffixKey && <Typography variant={"subtitle2"}>{formatForId(intl, options.suffixKey)}</Typography>}
                </div>
            )}
        </div>
    );
}

function getGradientOptions(dataType) {
    let colors;
    let segmented = false;
    let domain = [0, 1];
    let labelKeys, prefixKey, suffixKey, unitKey;
    switch (dataType) {
        case Friction:
            colors = ["#FF0404", "#FF7800", "#50ea05"];
            segmented = true;
            labelKeys = [
                {
                    key: "value.unit.friction.verySlippery",
                    textColor: "white",
                },
                { key: "value.unit.friction.slippery", textColor: "black" },
                {
                    key: "value.unit.friction.notSlippery",
                    textColor: "black",
                },
            ];
            break;
        case Wiperspeed:
            colors = ["#e95b5c", "#5080eb"];
            segmented = true;
            labelKeys = [
                {
                    key: "value.unit.wipesPerSecond.1",
                    textColor: "white",
                },
                {
                    key: "value.unit.wipesPerSecond.0",
                    textColor: "white",
                },
            ];
            break;
        case Temperature:
            colors = ["#302148", "#4066dd", "#26a8d4", "#75e859", "#c9cd33", "#e89924", "#951406"];
            domain = [-20, 0, 20];
            unitKey = "value.unit.celsius";
            break;
        case AirPressure:
            colors = ["#7ed4ea", "#5497d4", "#3d5bb3"];
            domain = [800, 1100];
            unitKey = "value.unit.hpa";
            break;
        case RelativeHumidity:
            colors = ["#FF0404", "#FF7800", "#50ea05"];
            domain = [0, 110];
            unitKey = "value.unit.percent";
            break;
        case LongTermRoughness:
        case CurrentRoughness:
            colors = ["#302148", "#4066dd", "#26a8d4", "#75e859", "#c9cd33", "#e89924", "#951406"];
            domain = [0.25, 3, 12];
            unitKey = "value.unit.mkm";
            break;
        case RoughnessChangesAlerts:
            colors = [];
            domain = [];
            break;
        case RadarImageType:
            colors = ["#c1d3ee", "#0249a5", "#00e50f", "#ecff00", "#f62600", "#a42860", "#f7a6f7"];
            domain = [0.02, 0.03, 1.3, 5.6, 12, 50, 200, 800];
            prefixKey = "pages.views.mapView.imageOverlay.precipLabel";
            suffixKey = "value.unit.mmPerHour";
            break;
        default:
            colors = ["#FF0404", "#FF7800", "#50ea05"];
            break;
    }
    return { colors, domain, segmented, labelKeys, prefixKey, suffixKey, unitKey };
}

export function useGradient(dataType) {
    const scaler = useMemo(() => {
        const { domain, colors } = getGradientOptions(dataType);
        return chroma.scale(colors).domain(domain);
    }, [dataType]);

    const gradientGetter = (value) => {
        if (dataType === Wiperspeed) {
            return wiperSpeed(value);
        } else if (dataType === Friction) {
            return friction(value);
        } else if (scaler) {
            return scaler(value);
        } else return "";
    };

    return { gradientGetter };
}

function wiperSpeed(value) {
    if (value > 0) {
        return "#e95b5c";
    } else return "#4e7ce6";
}

export const NIRA_ALERT_COLOR_RED = "#FF0404";
export const NIRA_ALERT_COLOR_ORANGE = "#FF7800";

export function getFrictionSeverity(value) {
    if (value <= 0.35) {
        return NIRA_ALERT_RED;
    } else if (value <= 0.65) {
        return NIRA_ALERT_YELLOW;
    } else {
        return NIRA_ALERT_NONE;
    }
}

function friction(value) {
    const severity = getFrictionSeverity(value);
    switch (severity) {
        case NIRA_ALERT_RED:
            return NIRA_ALERT_COLOR_RED;
        case NIRA_ALERT_YELLOW:
            return NIRA_ALERT_COLOR_ORANGE;
        default:
            return "#50ea05";
    }
}

export function getFrictionValueForLevel(level) {
    switch (level) {
        case NIRA_ALERT_RED:
            return 0.35;
        case NIRA_ALERT_YELLOW:
            return 0.65;
        default:
            return 1;
    }
}
