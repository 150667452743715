import {connect, send} from '@giantmachines/redux-websocket';

export function sendToWs(store, message) {
   store.dispatch(send(message));
}

export function connectToWs(store)
{
   store.dispatch(
      connect((window.location.protocol === "https:" ? "wss://" : "ws://") + window.location.hostname + ":" + window.location.port + "/ws/data/")
   );
}