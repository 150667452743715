import { LargeDialog, LargeDialogContent } from "../../../Components/Icons/LargeDialog";
import React, { useMemo } from "react";
import { DialogTitle, IconButton, useTheme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { MapContainer } from "react-leaflet";
import BaseTileLayer from "../../../Components/Map/BaseTileLayer";
import { NiraAlertLayer } from "../../../Components/Map/Nira/VectorGrid/NiraAlertLayer";
import { alertsToGeoJson } from "../../../Utils/Data/GeoJsonUtils";

export function AlertGroupMapDialog({ open, onClose, entity }) {
    const theme = useTheme();
    const position = [48.5, 19.5];

    const data = useMemo(() => alertsToGeoJson(entity.areas, theme.palette.success.main), [entity]);
    return (
        <LargeDialog open={open} onClose={onClose} fullWidth maxWidth={"xl"}>
            <IconButton
                color="inherit"
                onClick={onClose}
                style={{
                    position: "absolute",
                    right: theme.spacing(1),
                    top: theme.spacing(1),
                }}
            >
                <FontAwesomeIcon icon={faTimes} />
            </IconButton>
            <DialogTitle>{entity.name}</DialogTitle>
            <LargeDialogContent>
                <div style={{ height: "100%", width: "100%", display: "flex", flex: 1, paddingTop: 8 }}>
                    <MapContainer style={{ height: "80vh", width: "100%" }} minZoom={3} maxZoom={18} zoom={8} fadeAnimation={false} center={position}>
                        <BaseTileLayer selectedMapKey={"nira"} />
                        <NiraAlertLayer initialData={data} />
                    </MapContainer>
                </div>
            </LargeDialogContent>
        </LargeDialog>
    );
}
