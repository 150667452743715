import React, {useMemo} from "react";
import {makeStyles} from "@material-ui/styles";
import {Toolbar} from "@material-ui/core";
import ActionButtons from "./ActionButtons";
import SearchBox from "../Forms/SearchBox";
import {useStore} from "react-redux";
import {filterBy, reloadList, resetFilterBy} from "../../Utils/Data/actions/dataList";
import {useDataListFilter} from "../../Utils/Data/hooks/dataList";
import _ from "loadsh";
import {RefreshButton} from "../Forms/RefreshButton";
import store from "../../Utils/Data/store";

const useStyles = makeStyles(theme => ({
   actionToolbar: {
      paddingRight: 0,
      paddingLeft: 0,
      "& > *": {
         marginRight: theme.spacing(1)
      }
   },
   spacer: {
      flexGrow: 1
   }
}));

function ActionSearchBox({fieldName}) {
   const store = useStore();
   const onChange = (value) => {
      if (_.isEmpty(value))
      {
         resetFilterBy(store, {field: fieldName});
      }
      else {
         filterBy(store, {field: fieldName, value: value});
      }
   }

   const filters = useDataListFilter();
   const filter = _.find(filters, (filter) => filter.field === fieldName);

   let filterValue = filter?.value;
   if (!filterValue)
   {
      filterValue = "";
   }
   return <SearchBox onChange={onChange} value={filterValue}/>;
}

export default function ActionToolbar({localizationContext, actions, searchFieldName, detailToolbar, buttonProps}) {
   const classes = useStyles();
   const store = useStore();
   return <Toolbar className={classes.actionToolbar}>
      <ActionButtons actions={actions}
                     localizationContext={localizationContext} buttonProps={buttonProps}/>
      <div className={classes.spacer} />
      {!detailToolbar &&
      <RefreshButton onClick={()=>{ reloadList(store)}} /> }

      {searchFieldName && <ActionSearchBox fieldName={searchFieldName}/>}

   </Toolbar>;
}

