import {useIntl} from "react-intl";
import {useServerConnected} from "../Utils/Data/hooks/server";
import React, {useState} from "react";
import {formatTime} from "../Utils/Data/Time";
import {Dialog, DialogContent, Typography} from "@material-ui/core";
import {formatForId} from "../Utils/Lang/IntlHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlug} from "@fortawesome/pro-solid-svg-icons";

export function DisconnectedDialog() {
   const intl = useIntl();
   const serverConnected = useServerConnected();
   const [disconnectedTime, setDisconnectedTime] = useState();
   React.useEffect(() => {
      if (!serverConnected) {
         setDisconnectedTime(formatTime(Date.now(), true));
      }
   }, [serverConnected])

   return <Dialog open={!serverConnected}>
      <DialogContent>

         <Typography variant={"h5"}>{formatForId(intl, "messagess.offline")} <FontAwesomeIcon
            icon={faPlug}/></Typography>
         <Typography variant={"subtitle1"}>{formatForId(intl, "messagess.lastConntact")} {disconnectedTime}</Typography>


      </DialogContent>
   </Dialog>
}
