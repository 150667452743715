import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NestedMenuItem } from "./NestedMenuItem";
import { useStore } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, SvgIcon } from "@material-ui/core";
import { useBuildInfo } from "../Utils/Data/hooks/server";
import { useCompactDrawer } from "../Utils/Data/hooks/gui";
import { setCompactDrawer } from "../Utils/Data/actions/gui";
import { useSafePageExit } from "../Utils/Data/hooks/masterDetail";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { ReactComponent as Logo } from "../Icons/logo_dark_white_only.svg";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 24,
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: theme.palette.background.paper,
        textDecoration: "none",
        color: "inherit",
    },
    titleLarge: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
    },
    titleSmall: {
        paddingLeft: theme.spacing(2),
    },
    drawerFull: {
        width: (props) => props.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: (props) => props.drawerWidth,
        overflowX: "hidden",
    },
    collapseIcon: {
        padding: theme.spacing(2),
        marginRight: 0,
        position: "fixed",
        right: 18,
        top: 8,
        zIndex: 1,
    },
}));

function BuildInfo({ info, compact }) {
    const [time, none, buildNumber, hash, branch] = useMemo(() => {
        const parts = info.split(/[-]|git:|@|build number/);
        if (parts.length === 5) {
            parts[4] = parts[4].replace("refs/heads/", "");
        }
        return parts;
    }, [info]);

    return (
        <div
            style={{
                display: compact ? "none" : "flex",
                flexDirection: "column",
                flexGrow: 1,
                textAlign: "center",
                verticalAlign: "bottom",
            }}
        >
            <div style={{ flexGrow: 1 }}>&nbsp;</div>
            {!branch && <div style={{ padding: "1em", backgroundColor: "#2a4651", fontSize: "small" }}>{info}</div>}
            {branch && (
                <div title={info} style={{ padding: "1em", backgroundColor: "#2a4651", fontSize: "small" }}>
                    V:{buildNumber}
                    <br />
                    <span style={{ fontSize: "xx-small" }}>
                        {hash}@{branch}
                    </span>
                </div>
            )}
        </div>
    );
}

export function MainDrawer({ menu, currentPath }) {
    const store = useStore();
    const info = useBuildInfo();

    const compact = useCompactDrawer();

    const classes = useStyles({ drawerWidth: 450 });
    const safePageExit = useSafePageExit();

    const closeDrawer = () => {
        setCompactDrawer(store, true);
    };

    const onClick = (evt) => {
        if (!safePageExit()) {
            evt.preventDefault();
            evt.stopPropagation();
        }

        closeDrawer();
    };

    const onCollapseClick = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        closeDrawer();
    };

    return (
        <Drawer
            ModalProps={{ onBackdropClick: closeDrawer }}
            className={classes.drawerFull}
            variant={"temporary"}
            classes={{
                paper: classes.drawerPaper,
            }}
            open={!compact}
            anchor="right"
        >
            <Link to={"/views/"} className={classes.title} key={"title"} onClick={onClick}>
                <div className={classes.titleLarge}>
                    <SvgIcon style={{ fontSize: "2rem", marginRight: 30 }}>
                        <Logo />
                    </SvgIcon>
                    <IconButton className={classes.collapseIcon} size={"small"} onClick={onCollapseClick}>
                        <FontAwesomeIcon icon={faChevronRight} size={"1x"} />
                    </IconButton>
                </div>
                <Divider />
            </Link>

            <div>
                <List>
                    {menu.map((item, index) => {
                        return item.items ? (
                            <NestedMenuItem item={item} key={item.path} currentPath={currentPath} onItemClick={onClick} />
                        ) : (
                            <ListItem component={Link} to={item.path} button selected={item.path === currentPath} key={item.path} onClick={onClick}>
                                <ListItemIcon title={item.name}>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItem>
                        );
                    })}
                </List>
                <Divider />
                <BuildInfo info={info} compact={compact} />
            </div>
        </Drawer>
    );
}
