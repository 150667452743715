import React, { useMemo } from "react";
import { CustomForm } from "../../../Components/Forms/CustomForm";
import { useMasterDetailFormSave, useOpenedDetailEntity } from "../../../Utils/Data/hooks/masterDetail";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { useStore } from "react-redux";
import { openDialog } from "../../../Utils/Data/actions/gui";
import _ from "loadsh";
import { useNiraAlertConfig } from "../../../Api";
import { mapCustomFieldsConfig, meteoViewConfigFields, rnrViewConfigFields } from "./MeteoView";

const formConfig = [
    {
        id: "name",
        type: "TextField",
        props: {
            required: true,
        },
    },
    {
        id: "type",
        type: "DropdownField",
        props: {
            multiple: false,
            items: ["meteo", "rnr"],
            localizationContext: "forms.view.type.items",
            defaultValue: "meteo",
        },
        onValueChanged(config, formik, value) {
            return buildConfig(value, formik.values.data_query);
        },
    },

    {
        id: "is_active",
        type: "CheckboxField",
        props: {},
    },
];

function buildConfig(viewType, dataQuery) {
    let formConfigRet = formConfig;

    if (_.isEmpty(viewType) || viewType === "meteo") {
        formConfigRet = formConfig.concat(meteoViewConfigFields);
        if (_.isEmpty(dataQuery?.filter)) {
            formConfigRet = formConfigRet.concat(mapCustomFieldsConfig);
        }
    } else {
        formConfigRet = formConfigRet.concat(rnrViewConfigFields);
    }

    return formConfigRet;
}

export default function ViewDetail({ reloadCallback }) {
    const { makeSubmitCallback } = useMasterDetailFormSave("view/");
    const { entity, timeOpened } = useOpenedDetailEntity();
    const store = useStore();

    const { data } = useNiraAlertConfig(true);

    const formConfigWithCustomFields = useMemo(() => {
        let formConfigRet = buildConfig(entity?.type, entity?.data_query);

        if (data) {
            const geoAlertGroupsField = formConfigRet.find((item) => item.id === "geo_alert_groups");
            if (geoAlertGroupsField) {
                geoAlertGroupsField.props.items = data.map((areaGroup) => areaGroup.id);
                geoAlertGroupsField.props.valueNames = data.map((areaGroup) => areaGroup.name);
            }
        }
        return formConfigRet;
    }, [entity, data]);

    const openedEntityWithCustomProps = useMemo(() => {
        const { map_zoom, map_lat, map_lon, rnr_project, background_color, tooltip_color } = !_.isEmpty(entity.gui_custom_field)
            ? JSON.parse(entity.gui_custom_field)
            : {
                  map_zoom: undefined,
                  map_lat: undefined,
                  map_lon: undefined,
                  rnr_project: undefined,
                  background_color: undefined,
                  tooltip_color: undefined,
              };
        return { ...entity, map_zoom, map_lat, map_lon, rnr_project, background_color, tooltip_color };
    }, [entity]);

    const submitCallback = makeSubmitCallback({
        onBeforeSubmit: (values) => {
            let { map_zoom, map_lat, map_lon, data_query, rnr_project, background_color, tooltip_color } = values;

            if (!_.isEmpty(data_query?.filter)) {
                map_zoom = null;
                map_lat = null;
                map_lon = null;
            }

            values.gui_custom_field = JSON.stringify({
                map_zoom,
                map_lat,
                map_lon,
                rnr_project,
                background_color,
                tooltip_color,
            });
        },
        onSuccess: reloadCallback,
    });

    const actions = [
        {
            id: "users",
            onClick() {
                openDialog(store, { type: "viewEditUsers", entity: entity });
            },
        },
        {
            id: "groups",
            onClick() {
                openDialog(store, { type: "viewEditGroups", entity: entity });
            },
        },
        {
            id: "copy",
            onClick() {
                openDialog(store, { type: "viewCopy", entity: entity });
            },
        },
    ];

    return (
        <>
            {!entity.isNew && <ActionToolbar localizationContext="pages.view" actions={actions} detailToolbar={true} buttonProps={{ variant: "outlined" }} />}
            <CustomForm key={entity.id} timeOpened={timeOpened} formConfig={formConfigWithCustomFields} formData={openedEntityWithCustomProps} onSubmit={submitCallback} formId={"view"} />
        </>
    );
}
