import React, { useMemo } from "react";
import { CustomForm } from "../../../Components/Forms/CustomForm";
import _ from "loadsh";
import { useMasterDetailFormSave, useOpenedDetailEntity } from "../../../Utils/Data/hooks/masterDetail";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { mdiCalendarCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { useStore } from "react-redux";
import { openDialog } from "../../../Utils/Data/actions/gui";
import { DEFAULT_SOUND, SOUNDS } from "../../../Utils/Data/Sounds";
import { useGraphValueKeys } from "../../../Utils/Data/ValueMapper";

export const wind_units = ["ms", "kmh"];
export const available_locales = ["sk", "en"];
const formConfig = [
    {
        id: "email_address",
        type: "TextField",
        props: {
            required: true,
        },
    },

    {
        id: "first_name",
        type: "TextField",
        props: {},
    },
    {
        id: "last_name",
        type: "TextField",
        props: {},
    },
    {
        id: "tel_number",
        type: "TelephoneNumberField",
        props: {},
    },
    {
        id: "note",
        type: "TextField",
        props: {},
    },
    {
        id: "is_active",
        type: "CheckboxField",
        props: {},
    },
    {
        id: "default_login_path",
        type: "TextField",
        props: {},
    },
    {
        type: "Group",
        fields: [
            {
                id: "sound_alert_enabled",
                type: "DropdownField",
                props: {
                    multiple: false,
                    items: ["0", "1", "2", "3"],
                    localizationContext: "forms.user.sound_alert_types",
                },
                defaultValue: ["0"],
            },
            {
                id: "beaufort_scale",
                type: "CheckboxField",
                props: {},
            },
        ],
    },
    {
        type: "Group",
        fields: [
            {
                id: "wind_unit",
                type: "DropdownField",
                props: {
                    multiple: false,
                    items: wind_units,
                    localizationContext: "forms.user.wind_unit_types",
                },
                defaultValue: ["ms"],
            },
            {
                id: "locale",
                type: "DropdownField",
                props: {
                    multiple: false,
                    items: available_locales,
                    localizationContext: "forms.user.locale",
                },
                defaultValue: ["sk"],
            },
            {
                id: "disabled_aggregation",
                type: "CheckboxField",
                props: {},
            },
        ],
    },

    {
        id: "default_alert_filter",
        type: "AlertFilter",
    },

    {
        id: "sound_alert",
        type: "DropdownField",
        props: {
            multiple: false,
            items: SOUNDS,
            localizationContext: "forms.soundAlert.sound_alerts",
        },
        defaultValue: [DEFAULT_SOUND],
    },
    {
        id: "default_graph_fields",
        type: "DropdownField",
        props: {
            multiple: true,
            localizationContext: "value.meteo_v1.name",
        },
    },

    {
        id: "prolonged_alerts",
        type: "AlertSelectionField",
    },
    {
        id: "prolonged_alerts_timeout",
        type: "TimeoutField",
        defaultValue: 60 * 60,
    },
];
export default function UserDetail({ reloadCallback }) {
    const { makeSubmitCallback } = useMasterDetailFormSave("user/");
    const { entity, timeOpened } = useOpenedDetailEntity();
    const store = useStore();
    const graphValueFields = useGraphValueKeys();

    const openedEntityWithCustomProps = useMemo(() => {
        const {
            tel_number,
            note,
            sound_alert_enabled,
            wind_unit,
            beaufort_scale,
            locale,
            default_alert_filter,
            disabled_aggregation,
            sound_alert,
            prolonged_alerts,
            prolonged_alerts_timeout,
            default_graph_fields,
        } = !_.isEmpty(entity.custom_props)
            ? JSON.parse(entity.custom_props)
            : {
                  tel_number: "",
                  note: "",
                  sound_alert_enabled: "1",
                  wind_unit: "ms",
                  beaufort_scale: false,
                  locale: "sk",
                  disabled_aggregation: false,
                  default_alert_filter: {
                      filter: [],
                      levelFilter: [],
                  },
                  sound_alert: DEFAULT_SOUND,
                  prolonged_alerts: [],
                  prolonged_alerts_timeout: 60 * 60,
                  default_graph_fields: [],
              };

        const result = {
            ...entity,
            tel_number,
            note,
            sound_alert_enabled,
            wind_unit,
            beaufort_scale,
            locale,
            disabled_aggregation,
            default_alert_filter: default_alert_filter || { filter: [], levelFilter: [] },
            sound_alert,
            prolonged_alerts,
            prolonged_alerts_timeout,
            default_graph_fields,
        };

        if (!_.some(available_locales, (loc) => result.locale === loc)) {
            result.locale = "sk";
        }

        if (!_.some(wind_units, (wnd) => result.wind_unit === wnd)) {
            result.wind_unit = "ms";
        }

        if (result.disabled_aggregation === undefined) {
            result.disabled_aggregation = false;
        }

        if (result.beaufort_scale === undefined) {
            result.beaufort_scale = false;
        }

        return result;
    }, [entity]);

    const submitCallback = makeSubmitCallback({
        onBeforeSubmit: (values) => {
            const {
                tel_number,
                note,
                sound_alert_enabled,
                wind_unit,
                beaufort_scale,
                locale,
                disabled_aggregation,
                default_alert_filter,
                sound_alert,
                prolonged_alerts,
                prolonged_alerts_timeout,
                default_graph_fields,
            } = values;

            values.custom_props = JSON.stringify({
                tel_number,
                note,
                sound_alert_enabled,
                wind_unit,
                beaufort_scale,
                locale,
                disabled_aggregation,
                default_alert_filter,
                sound_alert,
                prolonged_alerts,
                prolonged_alerts_timeout,
                default_graph_fields,
            });

            if (!_.isEmpty(values.email_address)) {
                values.login = values.email_address;
            }
            values.name = values.login;
        },
        onExtendEntity: (values, response) => {
            values.record_version++;
        },
        onSuccess: reloadCallback,
    });

    const actions = [
        {
            id: "eventList",
            icon: <Icon path={mdiCalendarCheck} size={1} />,
            onClick() {
                openDialog(store, { type: "userEventList", entity: entity });
            },
        },
        {
            id: "editRoles",
            onClick() {
                openDialog(store, { type: "userEditRoles", entity: entity });
            },
        },
        {
            id: "changePassword",
            onClick() {
                openDialog(store, { type: "userChangePassword", entity: entity });
            },
        },
        {
            id: "groups",
            onClick() {
                openDialog(store, { type: "userGroups", entity: entity });
            },
        },
        {
            id: "copy",
            onClick() {
                openDialog(store, { type: "copyUser", entity: entity });
            },
        },
    ];

    const formConfigWithComputedFields = useMemo(
        () =>
            formConfig.map((field) => {
                if (field.id === "default_graph_fields") {
                    return {
                        ...field,
                        props: {
                            ...field.props,
                            items: graphValueFields,
                        },
                    };
                }
                return field;
            }),
        [formConfig, graphValueFields]
    );

    return (
        <>
            {!entity.isNew && <ActionToolbar localizationContext="pages.users" actions={actions} detailToolbar={true} buttonProps={{ variant: "outlined" }} />}
            <CustomForm key={entity.id} timeOpened={timeOpened} formConfig={formConfigWithComputedFields} formData={openedEntityWithCustomProps} onSubmit={submitCallback} formId={"user"} />
        </>
    );
}
