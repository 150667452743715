import React, { memo, useEffect, useMemo, useRef } from "react";

import "../../index.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import { useDeviceState, useIsHistoryActive, useValueColor } from "../../Utils/Data/hooks/server";
import { Button, ButtonGroup, Paper, Tooltip, useTheme } from "@material-ui/core";
import { useValueInfo } from "../../Utils/Data/ValueMapper";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { Value } from "../../Components/Device/Value";
import { useIntl } from "react-intl";
import SpecialButtonsView, { FORECAST_VIEW_TYPE } from "../../Components/Device/SpecialButtonsView";
import { DeviceNameWithState } from "../../Components/DeviceStateIcon";
import clsx from "clsx";
import { useStore } from "react-redux";
import memoize from "memoize-one";

import { setCompactTableView, setCompactTableViewLabel, setTableViewFirstVisibleItem, setTableViewHeight, setTableViewPinnedLine, setTableViewSelectedLine } from "../../Utils/Data/actions/gui";
import { useCompactTableView, useCompactTableViewLabel, useTableViewFirstVisibleItem, useTableViewHeighht, useTableViewPinnedLine, useTableViewSelectedLine } from "../../Utils/Data/hooks/gui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import StateView, { VIEW_TYPE } from "../../Components/Device/StateView";
import { useAppFieldsOrder, useDataFieldsOrder, useDeviceDataActiveViewDevices, useVisibleDataFields } from "../../Utils/Data/hooks/deviceDataView";
import { RequireAnyPermissionsForVisibleDevices } from "../../Utils/Permissions/RequireAnyPermission";
import { PermsAnyAlerts, PermsNumericForecastOrAlerts, PermsStatesErrors } from "../../Utils/Permissions/Permissions";
import {
    AlertHeight,
    CameraHeight,
    CompactAlertHeight,
    CompactCameraHeight,
    CompactForecastHeight,
    CompactItemWidth,
    CompactLabelWidth,
    CompactStateHeight,
    doubleHeightValueItems,
    DoubleItemHeight,
    ForecastHeight,
    ItemHeight,
    ItemWidth,
    LabelWidth,
    MaxItemHeight,
    StateHeight,
    TableViewItemPadding,
    TitleHeight,
    useStyles,
} from "./TableView.styles";
import { areEqual, VariableSizeList as List } from "../../Components/VirtualizedTable/react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { ALERT_APP_FIELD, CAMERA_APP_FIELD, SPECIAL_BUTTONS_APP_FIELD, STATE_APP_FIELD } from "../../Utils/Data/ViewsDefinitions";
import AlertView, { ALERT_VIEW_TYPE } from "../../Components/Device/AlertView";
import CameraView from "../../Components/Device/CameraView";

function chooseBackgroundColor(color, isEven) {
    let valueBackground = color.primary;

    if (isEven) {
        valueBackground = color.secondary;
    }
    return valueBackground;
}

function useItemHeight(valueKey, dataItemHeight, doubleDataItemHeight, compactView) {
    return useMemo(() => {
        if (compactView && doubleHeightValueItems.includes(valueKey)) {
            return doubleDataItemHeight;
        } else {
            return dataItemHeight;
        }
    }, [valueKey, dataItemHeight, doubleDataItemHeight, compactView]);
}

function SelectableItem({ index, classes, children, className, ...props }) {
    const store = useStore();
    const selectedLineIdx = useTableViewSelectedLine();
    const pinned = useTableViewPinnedLine();

    const isRowSelected = selectedLineIdx === index;
    const isEven = index % 2 === 1;

    const setSelectedIndex = () => {
        if (!pinned && selectedLineIdx !== index) {
            setTableViewSelectedLine(store, index);
        }
    };

    const onClick = () => setTableViewPinnedLine(store, !pinned);

    return (
        <div onClick={onClick} {...props} onMouseEnter={setSelectedIndex} className={clsx(className, isEven && classes.valueItemOdd, selectedLineIdx === index && classes.valueItemSelected)}>
            {children(isRowSelected, isEven)}
        </div>
    );
}

function SelectedLineResetableView({ children, ...props }) {
    const store = useStore();
    const pinned = useTableViewPinnedLine();

    return (
        <div {...props} onMouseLeave={() => !pinned && setTableViewSelectedLine(store, -1)}>
            {children}
        </div>
    );
}

function DataItem({ value, valueKey, index, classes, warnings, driverTypes, dataItemHeight, historyActive, doubleDataItemHeight }) {
    const valueInfo = useValueInfo(valueKey);
    const color = useValueColor(valueInfo, warnings, value, historyActive);
    const visible = useVisibleDataFields(valueInfo, driverTypes);
    const compact = useCompactTableView();
    const itemHeight = useItemHeight(valueKey, dataItemHeight, doubleDataItemHeight, compact);

    return (
        visible && (
            <SelectableItem index={index} classes={classes} style={{ height: itemHeight }} className={clsx(classes.valueItemBase, classes.valueItem)}>
                {(isRowSelected, isEven) => (
                    <Value
                        value={value}
                        valueInfo={valueInfo}
                        className={clsx(classes.valueItemBase, classes.valueItemData, classes.value, isRowSelected && "selected")}
                        backgroundColor={chooseBackgroundColor(color, isEven)}
                        textColor={color.textColor}
                        numberOfLines={2}
                    />
                )}
            </SelectableItem>
        )
    );
}

function Values({ deviceState, classes, driverTypes, dataItemHeight, historyActive, doubleDataItemHeight }) {
    const fieldsOrder = useDataFieldsOrder();

    return (
        <SelectedLineResetableView className={classes.values}>
            {fieldsOrder.map((valueKey, idx) => {
                const meteo_v1 = deviceState?.data?.meteo_v1 || {};
                const value = meteo_v1[valueKey];

                return (
                    <DataItem
                        key={valueKey}
                        classes={classes}
                        index={idx}
                        value={valueKey === "dateTime" ? deviceState.last_communication : value?.value?.value}
                        warnings={deviceState.active_warnings}
                        valueKey={valueKey}
                        driverTypes={driverTypes}
                        dataItemHeight={dataItemHeight}
                        historyActive={historyActive}
                        doubleDataItemHeight={doubleDataItemHeight}
                    />
                );
            })}
        </SelectedLineResetableView>
    );
}

function Section({ className, children, align = "center", classes, hidden, ...props }) {
    return (
        !hidden && (
            <div className={clsx(classes.section, className)} {...props}>
                {children}
            </div>
        )
    );
}

function HeaderColumns({ hasAnyCamera, classes, smallDevice, driverTypes, dataItemHeight, doubleDataItemHeight }) {
    const intl = useIntl();
    const compact = useCompactTableView();
    const compactLabel = useCompactTableViewLabel();
    const store = useStore();

    const row_order = useAppFieldsOrder();
    const fieldsOrder = useDataFieldsOrder();

    const HeaderItem = ({ intlId, className, hidden }) =>
        !hidden && (
            <Section className={clsx(className, classes.headerColumn)} classes={classes}>
                {intlId && formatForId(intl, intlId)}
            </Section>
        );

    const ChangeModeButton = ({ enabled, onChange }) => (
        <Button className={classes.collapseIcon} size={"medium"} onClick={onChange} style={{ height: "100%" }}>
            <FontAwesomeIcon icon={enabled ? faChevronRight : faChevronLeft} size={"lg"} className={classes.collapseIcon} />
        </Button>
    );

    const NameItem = () => (
        <Section className={clsx(classes.sectionTitle, classes.headerColumn)} classes={classes}>
            {formatForId(intl, "pages.views.tableView.table.deviceName")}
            <ButtonGroup orientation="vertical" variant="outlined">
                <ChangeModeButton enabled={compact} onChange={() => setCompactTableView(store, !compact)} />
                <ChangeModeButton enabled={compactLabel} onChange={() => setCompactTableViewLabel(store, !compactLabel)} />
            </ButtonGroup>
        </Section>
    );

    const HeaderValueItem = ({ valueKey, index }) => {
        const valueInfo = useValueInfo(valueKey);
        const visible = useVisibleDataFields(valueInfo, driverTypes);
        const itemHeight = useItemHeight(valueKey, dataItemHeight, doubleDataItemHeight, compact);

        return (
            visible && (
                <SelectableItem index={index} classes={classes} className={clsx(classes.sectionSingleValue, classes.headerColumn, classes.section)} style={{ height: itemHeight }}>
                    {() =>
                        compactLabel ? (
                            <Tooltip title={valueInfo.nameFormatted}>
                                <div>{valueInfo.shortcutFormatted}</div>
                            </Tooltip>
                        ) : (
                            valueInfo.nameFormatted
                        )
                    }
                </SelectableItem>
            )
        );
    };

    return (
        <div className={clsx(classes.itemGeneral, classes.labelsLeft)}>
            <NameItem />
            <div style={{ top: TitleHeight, position: "absolute", width: "100%" }}>
                {row_order.map((key) => {
                    switch (key) {
                        case STATE_APP_FIELD:
                            //XXX states and pictograms in one view?
                            return (
                                <RequireAnyPermissionsForVisibleDevices permissions={PermsStatesErrors} onErrorComponent={() => false}>
                                    <HeaderItem className={classes.sectionState} intlId={""} hidden={smallDevice} />
                                </RequireAnyPermissionsForVisibleDevices>
                            );
                        case ALERT_APP_FIELD:
                            return (
                                <RequireAnyPermissionsForVisibleDevices permissions={PermsAnyAlerts} onErrorComponent={() => false}>
                                    <HeaderItem className={classes.sectionAlert} intlId={""} />
                                </RequireAnyPermissionsForVisibleDevices>
                            );
                        case SPECIAL_BUTTONS_APP_FIELD:
                            return (
                                <RequireAnyPermissionsForVisibleDevices permissions={PermsNumericForecastOrAlerts} onErrorComponent={() => false}>
                                    <HeaderItem className={classes.sectionForecast} hidden={smallDevice} />
                                </RequireAnyPermissionsForVisibleDevices>
                            );
                        case CAMERA_APP_FIELD:
                            return (
                                <RequireAnyPermissionsForVisibleDevices permission={"dev__camera_image"} onErrorComponent={() => false}>
                                    <HeaderItem className={classes.sectionCamera} intlId={"pages.views.tableView.table.camera"} hidden={!hasAnyCamera || smallDevice} />
                                </RequireAnyPermissionsForVisibleDevices>
                            );
                    }
                })}
                <SelectedLineResetableView>
                    {fieldsOrder.map((valueKey, idx) => (
                        <HeaderValueItem key={valueKey} valueKey={valueKey} index={idx} />
                    ))}
                </SelectedLineResetableView>
            </div>
        </div>
    );
}

function DeviceColumn({ device, hasAnyCamera, classes, compact, smallDevice, driverTypes, dataItemHeight, doubleDataItemHeight }) {
    const deviceState = useDeviceState(device.id);
    const historyActive = useIsHistoryActive();
    const row_order = useAppFieldsOrder();

    const orderedComponents = useMemo(() => {
        return (deviceState, smallDevice, compact, hasAnyCamera) =>
            row_order.map((key) => {
                switch (key) {
                    case STATE_APP_FIELD:
                        return (
                            <RequireAnyPermissionsForVisibleDevices key={device.id + ":" + key} permissions={PermsStatesErrors} onErrorComponent={() => false}>
                                <Section className={classes.sectionState} align={"center"} classes={classes} hidden={smallDevice}>
                                    <StateView deviceState={deviceState} device={device} type={compact ? VIEW_TYPE.grid : VIEW_TYPE.large} />
                                </Section>
                            </RequireAnyPermissionsForVisibleDevices>
                        );
                    case ALERT_APP_FIELD:
                        return (
                            <RequireAnyPermissionsForVisibleDevices key={device.id + ":" + key} permissions={PermsAnyAlerts} onErrorComponent={() => false}>
                                <Section className={classes.sectionAlert} classes={classes}>
                                    <AlertView device={device} mode={compact ? ALERT_VIEW_TYPE.title_only : ALERT_VIEW_TYPE.large} deviceState={deviceState} lineCount={4} />
                                </Section>
                            </RequireAnyPermissionsForVisibleDevices>
                        );

                    case SPECIAL_BUTTONS_APP_FIELD:
                        return (
                            <RequireAnyPermissionsForVisibleDevices key={device.id + ":" + key} permissions={PermsNumericForecastOrAlerts} onErrorComponent={() => false}>
                                <Section className={classes.sectionForecast} classes={classes} hidden={smallDevice}>
                                    <SpecialButtonsView
                                        deviceState={deviceState}
                                        device={device}
                                        viewType={compact ? FORECAST_VIEW_TYPE["3x2"] : FORECAST_VIEW_TYPE["2x3"]}
                                        style={{ marginLeft: -2, marginRight: -2 }}
                                    />
                                </Section>
                            </RequireAnyPermissionsForVisibleDevices>
                        );
                    case CAMERA_APP_FIELD:
                        return (
                            <RequireAnyPermissionsForVisibleDevices key={device.id + ":" + key} permission={"dev__camera_image"} onErrorComponent={() => false}>
                                <Section className={classes.sectionCamera} classes={classes} hidden={!hasAnyCamera || smallDevice}>
                                    <CameraView device={device} compact={compact} />
                                </Section>
                            </RequireAnyPermissionsForVisibleDevices>
                        );
                }
            });
    }, [row_order, device]);

    return (
        <div className={clsx(classes.item, classes.itemGeneral)}>
            <Section className={clsx(classes.sectionTitle, classes.sectionTitleItem)} classes={classes}>
                <DeviceNameWithState device={device} deviceState={deviceState} noWrap={false} wrapAfter={2} />
            </Section>
            {orderedComponents(deviceState, smallDevice, compact, hasAnyCamera).map((component) => component)}
            <Section classes={classes}>
                <Values
                    deviceState={deviceState}
                    classes={classes}
                    driverTypes={driverTypes}
                    dataItemHeight={dataItemHeight}
                    historyActive={historyActive}
                    doubleDataItemHeight={doubleDataItemHeight}
                />
            </Section>
        </div>
    );
}

function useHeight(cameraActive) {
    const row_order = useAppFieldsOrder();
    const fieldsOrder = useDataFieldsOrder();
    const compact = useCompactTableView();
    const theme = useTheme();
    const tableHeight = useTableViewHeighht();

    const tableViewItemPadding = TableViewItemPadding.paddingBottom + TableViewItemPadding.paddingTop;
    return useMemo(() => {
        let height = TitleHeight + theme.spacing(0.25);
        row_order.forEach((key) => {
            switch (key) {
                case STATE_APP_FIELD:
                    height += compact ? CompactStateHeight : StateHeight;
                    height += tableViewItemPadding;
                    break;
                case ALERT_APP_FIELD:
                    height += compact ? CompactAlertHeight : AlertHeight;
                    height += tableViewItemPadding;
                    break;
                case SPECIAL_BUTTONS_APP_FIELD:
                    height += compact ? CompactForecastHeight : ForecastHeight;
                    break;
                case CAMERA_APP_FIELD:
                    if (cameraActive) {
                        height += theme.spacing(1);
                        height += compact ? CompactCameraHeight : CameraHeight;
                    }
                    break;
            }
        });

        const itemCnt = fieldsOrder.reduce((accumulator, valueKey) => (compact && doubleHeightValueItems.includes(valueKey) ? accumulator + 2 : accumulator + 1), 0);

        let dataItemHeight = (tableHeight - height - 12) / itemCnt; //12 is height of scrollbar
        const isOverflown = dataItemHeight < ItemHeight;

        dataItemHeight = isOverflown ? ItemHeight : dataItemHeight;
        dataItemHeight = dataItemHeight > MaxItemHeight ? MaxItemHeight : dataItemHeight;

        const doubleDataItemHeight = dataItemHeight < DoubleItemHeight ? DoubleItemHeight : dataItemHeight;

        height = fieldsOrder.reduce((accumulator, valueKey) => (compact && doubleHeightValueItems.includes(valueKey) ? accumulator + doubleDataItemHeight : accumulator + dataItemHeight), height);

        return [height, dataItemHeight, isOverflown, doubleDataItemHeight];
    }, [compact, cameraActive, tableHeight]);
}

function useFirstVisibleIndex(listRef) {
    const idx = useTableViewFirstVisibleItem();
    const compact = useCompactTableView();
    useEffect(() => listRef.current && listRef.current.scrollToItem(idx, "start"), [compact]);
}

const Column = memo(({ data, index, style }) => {
    const { devices, hasAnyCamera, compact, classes, driverTypes, dataItemHeight, height, compactLabels, doubleDataItemHeight } = data;

    const device = devices[index - 1];

    return (
        <div style={{ ...style, height: height }} className={clsx(classes.root, compact && "compactView", index === 0 && classes.labelsLeftRoot)}>
            {index > 0 ? (
                <DeviceColumn
                    key={device.id}
                    device={device}
                    hasAnyCamera={hasAnyCamera}
                    classes={classes}
                    compact={compact}
                    smallDevice={false}
                    driverTypes={driverTypes}
                    dataItemHeight={dataItemHeight}
                    doubleDataItemHeight={doubleDataItemHeight}
                />
            ) : (
                <div style={{ height: height }} className={clsx(classes.root, compactLabels && "compactViewLabel", compact && "compactView")}>
                    <HeaderColumns
                        hasAnyCamera={hasAnyCamera}
                        classes={classes}
                        smallDevice={false}
                        driverTypes={driverTypes}
                        dataItemHeight={dataItemHeight}
                        doubleDataItemHeight={doubleDataItemHeight}
                    />
                </div>
            )}
        </div>
    );
}, areEqual);

export function TableView({}) {
    const compact = useCompactTableView();
    const compactLabels = useCompactTableViewLabel();
    const store = useStore();
    const devices = useDeviceDataActiveViewDevices();
    const classes = useStyles(compact);
    const listRef = useRef();

    useEffect(() => {
        localStorage.setItem("tableCompactView", JSON.stringify(compact));
    }, [compact]);

    useEffect(() => {
        localStorage.setItem("compactTableViewLabel", JSON.stringify(compactLabels));
    }, [compactLabels]);

    const hasAnyCamera = useMemo(() => {
        return !!devices.find((dev) => dev.camera_devices && dev.camera_devices.length > 0);
    }, [devices]);

    const driverTypes = useMemo(() => {
        let ret = [];
        devices.forEach((device) => {
            if (!ret.includes(device.driver)) {
                ret.push(device.driver);
            }
        });
        return ret;
    }, [devices]);

    const [height, dataItemHeight, isOverflown, doubleDataItemHeight] = useHeight(hasAnyCamera);
    const listStyle = useMemo(() => (isOverflown ? {} : { overflowY: "hidden" }), [isOverflown]);

    const createItemData = memoize((devices, hasAnyCamera, compact, classes, driverTypes, dataItemHeight, height, compactLabels, doubleDataItemHeight) => ({
        devices,
        hasAnyCamera,
        compact,
        classes,
        driverTypes,
        dataItemHeight,
        height,
        compactLabels,
        doubleDataItemHeight,
    }));

    const getItemSize = (index) => {
        if (index === 0) {
            return compactLabels ? CompactLabelWidth : LabelWidth;
        } else {
            return compact ? CompactItemWidth : ItemWidth;
        }
    };

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [compactLabels, compact]);

    useFirstVisibleIndex(listRef);

    const itemData = createItemData(devices, hasAnyCamera, compact, classes, driverTypes, dataItemHeight, height, compactLabels, doubleDataItemHeight);

    useEffect(() => {
        return () => {
            setTableViewPinnedLine(store, false);
            setTableViewSelectedLine(store, -1);
        };
    }, []);

    return (
        <Paper style={{ flexGrow: 1, display: "flex", overflow: "hidden" }} square={true}>
            <div style={{ flex: 1 }}>
                <AutoSizer>
                    {({ height, width }) => {
                        setTableViewHeight(store, height);
                        return (
                            <List
                                style={listStyle}
                                itemCount={devices.length + 1}
                                layout="horizontal"
                                height={height}
                                width={width}
                                itemSize={getItemSize}
                                itemKey={(index) => (index === 0 ? "labels" : devices[index - 1].id)}
                                onItemsRendered={(data) => setTableViewFirstVisibleItem(store, data.visibleStartIndex)}
                                ref={listRef}
                                itemData={itemData}
                                persistedIndexes={[0]}
                            >
                                {Column}
                            </List>
                        );
                    }}
                </AutoSizer>
            </div>
        </Paper>
    );
}
