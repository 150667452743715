import React, { useMemo } from "react";

import MasterDetailView from "../Components/MasterDetail/MasterDetailView";
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";
import _ from "loadsh";
import { UserIcon } from "../Components/Icons/ToolbarIcons";
import { useOpenedDetailEntity, useSafeOpenDetail } from "../Utils/Data/hooks/masterDetail";
import MasterDetailContextMenu from "../Components/MasterDetail/MasterDetailContextMenu";
import ActionToolbar from "../Components/MasterDetail/ActionToolbar";
import { DetailContextMenu } from "../Components/MasterDetail";
import { useStore } from "react-redux";
import MasterDetailTable from "../Components/MasterDetail/MasterDetailTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import Badge from "@material-ui/core/Badge";
import { faLink, faUsersClass } from "@fortawesome/pro-light-svg-icons";
import { formatForId } from "../Utils/Lang/IntlHelper";
import List from "@material-ui/core/List";
import { ListItem, ListItemText } from "@material-ui/core";
import { useGetInMemoryDataList } from "../Utils/Data/hooks/dataList";
import { closeDetail } from "../Utils/Data/actions/masterDetail";
import { RequireAnyPermission } from "../Utils/Permissions/RequireAnyPermission";
import { DialogCloseButton } from "../Components/MasterDetail/DialogSaveCancelButtons";

function ActiveUserDetail({ reloadCallback }) {
    const intl = useIntl();
    const { entity, timeOpened } = useOpenedDetailEntity();
    const store = useStore();

    return (
        <List>
            {entity.sessions.map((url, idx) => (
                <ListItem>
                    <ListItemText
                        primary={
                            <span>
                                <Button href={url} disabled={_.isEmpty(url)}>
                                    <FontAwesomeIcon icon={faLink} /> &nbsp; {_.isEmpty(url) ? formatForId(intl, "pages.activeUsers.unknownUrl") : url}
                                </Button>
                            </span>
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
}

export default function ActiveUsers() {
    const store = useStore();

    const columns = useMemo(
        () => [
            { field: "id", width: 100, type: "number", hidden: true },
            {
                field: "name",
                sortable: true,
                headerName: "name",
                width: 400,
                renderCell: (params) => {
                    return (
                        <>
                            <Button>
                                <FontAwesomeIcon icon={faUser} />
                                &nbsp;{params.row.name}
                            </Button>

                            <Badge color={"secondary"} badgeContent={params.row.sessionCount}>
                                <FontAwesomeIcon icon={faUsersClass} />
                            </Badge>
                        </>
                    );
                },
            },
        ],
        []
    );

    const refreshInterval = 1000 * 60;
    const dataSource = useGetInMemoryDataList({
        url: "active-users/",
        defaultSort: { field: "name", isAscending: true },
        preprocessData: (apiUsers) =>
            _.map(apiUsers.users, (user) => {
                return { ...user.user, sessionCount: user.sessions.length, sessions: user.sessions };
            }),
        refreshInterval,
    });

    return (
        <RequireAnyPermission permission={"others__active_users"}>
            <MasterDetailView
                onRenderContextMenu={({ isDetailOpened, viewMode }) => (
                    <MasterDetailContextMenu
                        isDetailOpened={isDetailOpened}
                        viewMode={viewMode}
                        onRenderDetailMenu={() => <DetailContextMenu readOnly={true} onRenderBeforeLabel={() => <UserIcon />} />}
                        onRenderMasterMenu={() => <ActionToolbar actions={[]} localizationContext={"pages.users"} searchFieldName={"name"} />}
                    />
                )}
                onRenderDetail={() => <ActiveUserDetail />}
                onRenderDialogActions={() => (
                    <DialogCloseButton
                        onClose={() => {
                            closeDetail(store);
                        }}
                    />
                )}
            >
                <MasterDetailTable dataSource={dataSource} entityType={"user"} columns={columns} refresh={true} />
            </MasterDetailView>
        </RequireAnyPermission>
    );
}
