import { useMap } from "react-leaflet";
import L from "leaflet";
import { forwardRef, useEffect, useImperativeHandle } from "react";

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

export const DrawLayer = forwardRef(({ initialData }, ref) => {
    const map = useMap();

    useImperativeHandle(ref, () => ({
        getData: () => map.pm.getGeomanLayers(true).toGeoJSON(),
    }));

    useEffect(() => {
        map.pm.addControls({
            position: "topleft",
            drawCircle: false,
            drawCircleMarker: false,
            drawText: false,
            drawMarker: false,
            rotateMode: false,
            drawPolyline: false,
            cutPolygon: false,
        });

        const geoJsonInitial = L.geoJSON(initialData);
        map.addLayer(geoJsonInitial);

        const bounds = geoJsonInitial.getBounds();
        if (bounds.isValid()) {
            map.fitBounds(bounds);
        }
        return () => {
            map.pm.removeControls();
            map.removeLayer(geoJsonInitial);
        };
    }, [map]);

    return <></>;
});
