import { ReactComponent as WindIcon } from "../../Icons/pictograms/Wind.svg";
import { ReactComponent as CloudFogIcon } from "../../Icons/pictograms/CloudFog.svg";
import { ReactComponent as Rain1Icon } from "../../Icons/pictograms/Rain1.svg";
import { ReactComponent as Rain2Icon } from "../../Icons/pictograms/Rain2.svg";
import { ReactComponent as Rain3Icon } from "../../Icons/pictograms/Rain3.svg";
import { ReactComponent as RainSnowMixIcon } from "../../Icons/pictograms/RainSnowMix.svg";
import { ReactComponent as RoadSnow1Icon } from "../../Icons/pictograms/RoadSnow1.svg";
import { ReactComponent as RoadSnow2Icon } from "../../Icons/pictograms/RoadSnow2.svg";
import { ReactComponent as RoadSnow3Icon } from "../../Icons/pictograms/RoadSnow3.svg";
import { ReactComponent as RoadWet1Icon } from "../../Icons/pictograms/RoadWet1.svg";
import { ReactComponent as RoadWet2Icon } from "../../Icons/pictograms/RoadWet2.svg";
import { ReactComponent as RoadWet3Icon } from "../../Icons/pictograms/RoadWet3.svg";
import { ReactComponent as Snow1Icon } from "../../Icons/pictograms/Snow1.svg";
import { ReactComponent as Snow2Icon } from "../../Icons/pictograms/Snow2.svg";
import { ReactComponent as Snow3Icon } from "../../Icons/pictograms/Snow3.svg";
import { ReactComponent as SnowMap } from "../../Icons/map/Snow.svg";
import { ReactComponent as RainMap } from "../../Icons/map/Rain.svg";

import { SvgIcon } from "@material-ui/core";

export const svgOptions = {
    fill: "white",
    stroke: "white",
    style: {
        height: "100%",
        width: "100%",
    },
};

const style = {};

export function Rain3() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <Rain3Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function Rain2() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <Rain2Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function Rain1() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <Rain1Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function Snow3() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <Snow3Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function Snow2() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <Snow2Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function Snow1() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <Snow1Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function RainSnowMix() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <RainSnowMixIcon {...svgOptions} />
        </SvgIcon>
    );
}

export function CloudFog() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <CloudFogIcon {...svgOptions} />
        </SvgIcon>
    );
}

export function Wind() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <WindIcon {...svgOptions} />
        </SvgIcon>
    );
}

export function RoadWet3() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <RoadWet3Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function RoadWet2() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <RoadWet2Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function RoadWet1() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <RoadWet1Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function RoadSnow3() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <RoadSnow3Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function RoadSnow2() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <RoadSnow2Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function RoadSnow1() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <RoadSnow1Icon {...svgOptions} />
        </SvgIcon>
    );
}

export function MapSnow() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <SnowMap
                style={{
                    height: "100%",
                    width: "100%",
                }}
            />
        </SvgIcon>
    );
}

export function MapRain() {
    return (
        <SvgIcon style={style} fontSize={"inherit"}>
            <RainMap
                style={{
                    height: "100%",
                    width: "100%",
                }}
            />
        </SvgIcon>
    );
}
