import React, { useEffect, useMemo, useRef, useState } from "react";
import { CommonDialog } from "../../CommonDialog";
import { MeteogramControlCustom } from "./MeteogramControlCustom";
import { charts } from "./Charts";
import { useNumericForecast } from "../../../Api";
import LoadingScope from "../../LoadingScope";
import _ from "loadsh";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";

import CanvasJSReact from "../../Charts/canvasjs.stock.react";
import getOptions from "./CustomChart";
import { useTheme } from "@material-ui/core";
import moment from "moment";
import { NoDataMessage } from "../../Forms/NoDataMessage";
import { useValueInfos } from "../../../Utils/Data/ValueMapper";
import { makeStyles } from "@material-ui/styles";

const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(() => ({
    chart: {
        "& .canvasjs-stock-container": {
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            flexDirection: "column",
        },
        "& .canvasjs-chart-panel": {
            position: "static!important",
        },
        "& canvas": {
            cursor: "default!important",
        },
    },
}));

function getColumnNames() {
    const result = new Set();

    for (const chart of charts) {
        if (!_.isEmpty(chart.yAxis)) {
            result.add(chart.yAxis);
        }

        for (const subChart of chart.charts) {
            if (!_.isEmpty(subChart.yAxis)) {
                result.add(subChart.yAxis);
            }
        }
    }

    const resultArray = [];
    for (const key of result.keys()) {
        resultArray.push(key);
    }
    return resultArray;
}

function useChartDimensions() {
    const [chartHeight, setChartHeight] = useState(null);
    const [chartWidth, setChartWidth] = useState(null);
    const [refState, setRefState] = useState(0);

    const chartRef = useRef();

    useEffect(() => {
        const { current } = chartRef;
        if (current) {
            const rect = current.getBoundingClientRect();
            setChartHeight(Math.max(rect.height - 5, 1690));
            setChartWidth(Math.max(Math.min(rect.width, 1200), 950));

            let timer;
            const handler = () => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    const rect = current.getBoundingClientRect();

                    setChartHeight(Math.max(rect.height - 5, 1690));
                    setChartWidth(Math.max(Math.min(rect.width, 1200), 950));
                }, 250);
            };
            window.addEventListener("resize", handler);

            return () => {
                window.removeEventListener("resize", handler);
            };
        }
    }, [chartRef, chartRef.current, refState]);

    return [
        chartRef,
        chartWidth,
        chartHeight,
        (ref) => {
            chartRef.current = ref;
            setRefState(ref);
        },
    ];
}

export default function NumericForecastMeteogramDialog({ device, onClose, standalone = false }) {
    const [future, setFuture] = useState({ key: 0, hours: 72 });
    const classes = useStyles();

    const intl = useIntl();
    const theme = useTheme();

    const dateFilter = useMemo(
        () => ({
            value: {
                begin_time: moment().startOf("day").add(-1, "second").toDate().getTime(),
                end_time: moment().startOf("day").toDate().getTime() + future.hours * 60 * 60 * 1000,
            },
        }),
        [future]
    );

    const columns = useMemo(() => getColumnNames().join(","), []);

    const {
        forecastData: { loading, data, refetch },
        issueTime,
    } = useNumericForecast(dateFilter, true, device, columns, dateFilter.value.begin_time);

    const handleReset = () => {
        setFuture({ ...future, key: Date.now() });
    };
    const [valueInfos, findValueInfo] = useValueInfos(true);
    const [chartRef, chartWidth, chartHeight, setRef] = useChartDimensions();

    const options = useMemo(
        () =>
            !loading &&
            chartHeight &&
            !_.isEmpty(data) && {
                rangeSelector: { enabled: false },
                navigator: { enabled: false },

                backgroundColor: theme.palette.background.paper,
                height: chartHeight,
                charts: charts.map((chart) =>
                    getOptions({
                        intl: intl,
                        height: (chartHeight - 20 * charts.length) / charts.length,
                        dateFilter: dateFilter,
                        forecastData: data,
                        title: formatForId(intl, "numForecastMeteogram." + chart.title),
                        charts: chart.charts,
                        yAxis: chart.yAxis,
                        theme: theme,
                        findValueInfo,
                    })
                ),
            },
        [loading, theme, chartHeight, data, chartWidth]
    );

    return (
        <>
            <CommonDialog open={true} onClose={onClose} fullScreen>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                    }}
                >
                    <MeteogramControlCustom
                        handleClose={onClose}
                        device={device}
                        future={future}
                        onFutureChanged={(val) => {
                            setFuture({ ...val, key: Date.now() });
                        }}
                        onRefresh={handleReset}
                        issueTime={issueTime}
                        standalone={standalone}
                    />
                    <LoadingScope loading={loading}>
                        <div style={{ height: "1em" }}>&nbsp;</div>
                        <div
                            ref={(element) => setRef(element)}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                overflowY: "auto",
                                alignItems: "center",
                            }}
                            className={classes.chart}
                        >
                            {_.isEmpty(data) && <NoDataMessage />}

                            {!_.isEmpty(data) && options && (
                                <div style={{ width: chartWidth }}>
                                    <CanvasJSStockChart key={future.key} options={options} />
                                </div>
                            )}
                        </div>
                    </LoadingScope>
                </div>
            </CommonDialog>
        </>
    );
}
