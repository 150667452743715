import Button from "@material-ui/core/Button";
import {AddIcon} from "../Icons/ToolbarIcons";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import React from "react";
import {useIntl} from "react-intl";
import {useStore} from "react-redux";
import {dispatchEvent, ReduxEvent} from "react-redux-events";

function getLabelIntlKey( localizationContext, id)
{
   if (localizationContext)
   {
      return `${localizationContext}.${id}`;
   }
   return id;
}

function getClickHandler(store, action) {
  if (typeof action.onClick === "function")
  {
     return action.onClick;
  }
  else if (typeof action.onClickEventName === "string")
  {
     return ()=>{
        store.dispatch(dispatchEvent(new ReduxEvent(action.onClickEventName, {action: action.id})));
     }
  }
  else {
     return ()=>{
        store.dispatch(dispatchEvent(new ReduxEvent(action.id)));
     }
  }
}

export default function ActionButtons({localizationContext, actions, buttonProps}) {
   const intl = useIntl();
   const store = useStore();

   return actions.map(action=> <Button variant="contained" color="primary" onClick={getClickHandler(store, action)} {...buttonProps}{...action.props}>
      { action.icon && action.icon }
      {formatForId(intl,getLabelIntlKey(localizationContext, action.id) )}
   </Button>)
}

