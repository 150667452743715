import {useAppAccess} from "../Data/hooks/server";
import React, {useMemo} from "react";
import _ from "loadsh";
import {ShowError} from "../../Components/ShowError";
import {NoAccessMessage} from "../../Components/Forms/NoDataMessage";
import {DefaultErrorComponentCallback} from "./DefaultErrorComponentCallback";

function findInObject(object, value) {
    if (object === undefined) {
        return null;
    }
    if (object.permissions.length === 0) {
        return 0;
    }

    return _.find(object.permissions, (el) => {
        return el === value
    });
    ;
}

export function calcAccess(appAccess, domainID, groupID, permission) {
    //global
    let result = _.find(appAccess.permissions, (elem) => {
        return permission === elem
    });

    if (result) {
        return true;
    }

    if (appAccess.domains.length === 0) {
        return false;
    }

    //domain
    if (domainID) {
        const domain = _.find(appAccess.domains, {id: domainID});
        result = findInObject(domain, permission);

        if (result) {
            return true;
        }

        //group
        if (domain) {
            const group = _.find(domain.device_groups, {id: groupID});
            result = findInObject(group, permission);
        }

        if (result) {
            return true;
        }
    }

    return false;
}

export function useRequirePermissionCheck({
                                              permission,
                                              domainID,
                                              groupID
                                          }) {
    const appAccess = useAppAccess();
    const hasAccess = useMemo(() => calcAccess(appAccess, domainID, groupID, permission), [appAccess, domainID, groupID, permission]);

    return hasAccess;

}

export function RequirePermission({
                                      permission,
                                      domainID,
                                      groupID,
                                      children,
                                      onErrorComponent = DefaultErrorComponentCallback
                                  }) {
    const hasAccess = useRequirePermissionCheck({permission, domainID, groupID});

    return hasAccess ? <>{children}</> : (onErrorComponent ? onErrorComponent({permission}) : false);
}



