import React from "react";
import {alpha, makeStyles} from '@material-ui/core/styles';
import {IconButton, InputAdornment, TextField} from "@material-ui/core";
import {useIntl} from "react-intl";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faXmark} from "@fortawesome/pro-solid-svg-icons";
import _ from "loadsh";

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }
}));

export default function SearchBox({onChange, value}) {
    const classes = useStyles();
    const intl = useIntl();

    return <form className={classes.search} autoComplete={"off"}>
        <TextField
            size={"small"}
            variant={"outlined"}
            value={value}
            onChange={evt => {
                onChange && onChange(evt.target.value);
            }}
            InputProps={{
                endAdornment: !_.isEmpty(value) && <InputAdornment position="end">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <IconButton onClick={() => onChange(null)} onMouseDown={() => onChange(null)}>
                            <FontAwesomeIcon icon={faXmark} size={"xs"}/>
                        </IconButton>
                    </div>

                </InputAdornment>,
                startAdornment: <InputAdornment position="start">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <FontAwesomeIcon icon={faSearch} size={"sm"}/>
                    </div>

                </InputAdornment>,
            }}
            placeholder={formatForId(intl, "forms.search")}
        />
    </form>
}