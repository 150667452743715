import {Redirect, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import React from "react";
import StandaloneCameraDialog from "../Meteogram/StandaloneCameraDialog";
import {PermsCamera} from "../../Utils/Permissions/Permissions";
import {RequirePermission} from "../../Utils/Permissions/RequirePermission";

export function DeviceCameraView() {
    const {path, url} = useRouteMatch();
    let {id, group} = useParams();

    const domainId = parseInt(id);

    const groupId = parseInt(group);


    return (<RequirePermission domainID={domainId} groupID={groupId} permission={PermsCamera[0]}>
            <Switch>
                <Route path={path + "camera/:cameraId/"}>
                    <StandaloneCameraDialog/>
                </Route>
                <Route path={path + "camera/"}>
                    <StandaloneCameraDialog/>
                </Route>
            </Switch>
        </RequirePermission>
    );
}