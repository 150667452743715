import React, { useEffect, useMemo, useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { useCurrentOverlayImages, useImageOverlayEnabled, useNiraAlertsEnabled, useNiraDataPanelEnabled, useOverlayIndex, useSelectedDataType, useSelectedMapType } from "../../Utils/Data/hooks/map";
import { IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faDrawSquare, faRadar, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useStore } from "react-redux";
import { setImageOverlayEnabled, setNiraAlertsEnabled, setNiraDataPanelEnabled, setOverlayIndex, setSelectedDataType } from "../../Utils/Data/actions/map";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import moment from "moment";
import _ from "loadsh";
import { ToolbarHeight } from "../../index";
import { useHasOneOfPermission, useHasPermission } from "../../Utils/Permissions/RequireAnyPermission";
import { InsufficientZoom, NiraDataLoading, NiraDataPanel, NiraPerms } from "./NiraDataPanel";
import { PlayerSlider } from "../DataPlayer/PlayerSlider";
import { GradientPanel, RadarImageType } from "./Nira/Gradient";
import { useActiveView, useViewDefaultNiraField } from "../../Utils/Data/hooks/deviceDataView";
import { Friction, None } from "../NiraApi";
import { formatTimeOnly } from "../../Utils/Data/Time";
import { useIsMobilePortrait } from "../../Utils/Hooks/DesktopOnlyView";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        zIndex: 401, //due to leaflet
        top: ToolbarHeight + theme.spacing(1),
        right: theme.spacing(1),
        left: (props) => (props.isMobile ? theme.spacing(1) : "unset"),
        pointerEvents: "none",
    },
    imageOverlayRoot: {
        backgroundColor: alpha(theme.palette.background.appBar, 0.8),
        border: "1px solid rgba(255, 255, 255, 0.23)",
        borderRadius: 8,
        width: (props) => (props.isMobile ? "100%" : 367),
        pointerEvents: "auto",
    },
    infoControl: {
        marginLeft: "auto",
        paddingRight: theme.spacing(1),
        alignItems: "center",
        justifyContent: "flex-end",
        display: "flex",
    },
    overlayControl: {
        display: "flex",
        flexDirection: "column",
        pointerEvents: "auto",
    },
    slider: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
    },
    legend: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.5),
    },
    buttonWrapper: {
        backgroundColor: (props) => alpha(theme.palette.background.appBar, 0.8),
        border: "1px solid rgba(255, 255, 255, 0.23)",
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    valueLabel: {
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
        flexBasis: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

function ImageInfo({}) {
    const isMobile = useIsMobilePortrait();
    const classes = useStyles({ isMobile });
    const store = useStore();

    const images = useCurrentOverlayImages();
    const currentIndex = useOverlayIndex();
    const overlayEnabled = useImageOverlayEnabled();
    const [label, setLabel] = useState("");

    const values = useMemo(() => {
        return images.map((image) => {
            return { value: image.time, ...image };
        });
    }, [images]);

    const currentValue = useMemo(() => {
        if (_.isEmpty(images)) {
            return 0;
        }

        const image = images[currentIndex];
        if (_.isEmpty(image)) {
            return 0;
        }
        return image.time;
    }, [currentIndex]);

    return (
        <div className={classes.imageOverlayRoot}>
            <div className={classes.slider}>
                <PlayerSlider
                    value={currentValue}
                    onChange={(val) => {
                        const idx = images && images.findIndex((image) => image.time === val);
                        if (idx !== undefined && idx !== null) {
                            setOverlayIndex(store, idx);
                        }
                    }}
                    values={values}
                    labelFormatter={(image, idx) => formatTimeOnly(moment(image.time), false)}
                    labelFilterFactor={5}
                    onDisplayValue={setLabel}
                />
                <div className={classes.valueLabel}>{label}</div>
                <div className={classes.infoControl}>
                    <IconButton>
                        <FontAwesomeIcon icon={faTimes} size={"1x"} onClick={() => setImageOverlayEnabled(store, !overlayEnabled)} />
                    </IconButton>
                </div>
            </div>
            <div className={classes.legend}>
                <GradientPanel dataType={RadarImageType} gradientOpacity={0.7} />
            </div>
        </div>
    );
}

export function ButtonWrapper({ children, ...props }) {
    const isMobile = useIsMobilePortrait();
    const classes = useStyles({ isMobile });
    return (
        <div {...props} className={classes.buttonWrapper}>
            {children}
        </div>
    );
}

export function RadarForecastView({ niraData }) {
    const store = useStore();
    const intl = useIntl();
    const overlayEnabled = useImageOverlayEnabled();
    const niraDataPanelEnabled = useNiraDataPanelEnabled();
    const hasNiraPermission = useHasOneOfPermission({ permissions: NiraPerms });
    const hasForecastPermission = useHasPermission({ permission: "forecast__radar" });
    const hasGeoAlertPermission = useHasPermission({ permission: "geo_alerts__view" });
    const defaultNiraField = useViewDefaultNiraField();
    const dataType = useSelectedDataType();
    const niraAlertsEnabled = useNiraAlertsEnabled();
    const activeView = useActiveView();
    const showNiraAlerts = useMemo(() => !!hasGeoAlertPermission && activeView?.geo_alert_groups && activeView?.geo_alert_groups.length > 0, [hasGeoAlertPermission, activeView]);

    const top = useMemo(() => {
        if (niraDataPanelEnabled) {
            return 140;
        }
        if (overlayEnabled) {
            return 110;
        }
        return 8;
    }, [niraDataPanelEnabled, overlayEnabled]);

    const isMobile = useIsMobilePortrait();
    const classes = useStyles({ top, isMobile });

    const openNira = () => {
        setNiraDataPanelEnabled(store, true);
        setSelectedDataType(store, defaultNiraField === None ? Friction : defaultNiraField);
    };

    const changeNiraAlerts = () => {
        if (!niraAlertsEnabled) {
            setNiraDataPanelEnabled(store, true);
            setSelectedDataType(store, Friction);
        }

        setNiraAlertsEnabled(store, !niraAlertsEnabled);
    };
    const openRadarForecast = () => {
        setImageOverlayEnabled(store, true);
    };

    useEffect(() => {
        if (dataType === None) {
            setNiraDataPanelEnabled(store, false);
        }
    }, [dataType]);

    return hasForecastPermission || hasNiraPermission ? (
        <div className={classes.root}>
            {overlayEnabled && <ImageInfo />}
            {overlayEnabled && niraDataPanelEnabled && <div style={{ height: 8 }} />}
            {niraDataPanelEnabled && <NiraDataPanel niraData={niraData} />}

            <div className={classes.overlayControl}>
                {!!hasForecastPermission && !overlayEnabled && (
                    <ButtonWrapper style={{ marginTop: niraDataPanelEnabled ? 8 : 0 }}>
                        <Tooltip title={formatForId(intl, "pages.views.mapView.imageOverlay.overlayTooltip")}>
                            <IconButton onClick={openRadarForecast}>
                                <FontAwesomeIcon icon={faRadar} style={{ fontSize: "1.4em" }} color={"white"} />
                            </IconButton>
                        </Tooltip>
                    </ButtonWrapper>
                )}
                {!!hasNiraPermission && !niraDataPanelEnabled && (
                    <ButtonWrapper style={{ marginTop: 8 }}>
                        <Tooltip title={formatForId(intl, "pages.views.mapView.imageOverlay.niraDataPanelEnabledTooltip")}>
                            <IconButton onClick={openNira}>
                                <FontAwesomeIcon icon={faCar} style={{ fontSize: "1.4em" }} color={"white"} />
                            </IconButton>
                        </Tooltip>
                    </ButtonWrapper>
                )}
                {!!showNiraAlerts && (
                    <ButtonWrapper style={{ marginTop: 8 }}>
                        <Tooltip
                            title={formatForId(intl, niraAlertsEnabled ? "pages.views.mapView.imageOverlay.niraAlertsDisableTooltip" : "pages.views.mapView.imageOverlay.niraAlertsEnableTooltip")}
                        >
                            <IconButton onClick={changeNiraAlerts}>
                                <FontAwesomeIcon icon={faDrawSquare} style={{ fontSize: "1.4em" }} color={niraAlertsEnabled ? "gray" : "white"} />
                            </IconButton>
                        </Tooltip>
                    </ButtonWrapper>
                )}
            </div>
            {niraDataPanelEnabled && <InsufficientZoom />}
            {niraDataPanelEnabled && <NiraDataLoading />}
        </div>
    ) : (
        <></>
    );
}
