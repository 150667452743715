import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { buildFormValidation } from "../../../Components/Forms/CustomForm";
import * as Yup from "yup";

import { CommonDialog } from "../../../Components/CommonDialog";
import { usePostMutate } from "../../../Api";
import { useNotification } from "../../../Notification";
import { DialogSaveCancelButtons } from "../../../Components/MasterDetail/DialogSaveCancelButtons";
import DialogForm from "../../../Components/Forms/DialogForm";
import { useStore } from "react-redux";
import { reloadList } from "../../../Utils/Data/actions/dataList";

const formConfig = [
    {
        id: "pwd",
        type: "PwdField",
        props: {
            required: true,
        },
    },
    {
        id: "pwdRepeat",
        type: "PwdField",
        props: {
            required: true,
            yup(yupField, intl) {
                return yupField.oneOf([Yup.ref("pwd"), null], formatForId(intl, "form.passwordMustMatch"));
            },
        },
    },
];

export function ChangePasswordDialog({ user, onClose, CancelButton }) {
    const { postData } = usePostMutate("user/reset_password");
    const notification = useNotification();
    const submitEventId = React.useMemo(() => user.id + ":" + formId, [user.id]);
    const store = useStore();
    const formId = "changePassword";

    return (
        <CommonDialog onClose={onClose}>
            <DialogTitle>{user.email_address}</DialogTitle>
            <DialogContent>
                <DialogForm
                    key={submitEventId}
                    submitEventId={submitEventId}
                    formData={user}
                    formId={formId}
                    onClose={onClose}
                    formConfig={formConfig}
                    onSubmit={async (values) => {
                        await notification.showApiMessage(postData({ id: user.id, password: values.pwd }));
                        onClose();
                        reloadList(store);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <DialogSaveCancelButtons onClose={onClose} submitEventId={submitEventId} />
            </DialogActions>
        </CommonDialog>
    );
}
