import React, { useEffect } from "react";
import { useOpenedAppDialog, useOpenedLoadingDialog } from "./Utils/Data/hooks/gui";
import { DeviceStatusDialog } from "./Pages/Settings/Domain/Device/DeviceStatusDialog";
import { DeviceValuesDialog } from "./Pages/Settings/Domain/Device/DeviceValuesDialog";
import { EventListDialog } from "./Pages/Events/EventListDialog";
import { useDeviceState } from "./Utils/Data/hooks/server";
import { useStore } from "react-redux";
import { closeDialog, closeLoadingDialog } from "./Utils/Data/actions/gui";
import { ChangePasswordDialog } from "./Pages/Settings/User/ChangePasswordDialog";
import { RoleDialog } from "./Pages/Settings/User/RoleDialog";
import MeteogramDialog from "./Components/Device/Meteogram/MeteogramDialog";
import UserGroupMembersDialog from "./Pages/Settings/UserGroup/UserGroupMembersDialog";
import UserGroupsDialog from "./Pages/Settings/User/UserGroupsDialog";
import { AlertHistoryDialog } from "./Components/Device/AlertHistoryDialog";
import { TextForecastDialog } from "./Components/TextForecast/TextForecastDialog";
import { LoadingDialog } from "./Components/LoadingDialog";
import ViewUsersDialog from "./Pages/Settings/Views/ViewUsersDialog";
import ViewUserGroupsDialog from "./Pages/Settings/Views/ViewUserGroupsDialog";
import NumericForecastMeteogram from "./Components/Device/NumericForecastMeteogram";
import queryString from "querystring";
import { useHistory } from "react-router-dom";
import AudioErrorDialog from "./Components/AudioErrorDialog";
import ViewCopyDialog from "./Pages/Settings/Views/ViewCopyDialog";
import { NiraStatusDialog } from "./Pages/Settings/NiraConfig/NiraStatusDialog";
import { DeletionDialog } from "./Pages/Settings/DeletionDialog";
import CameraDialog from "./Components/Device/CameraDialog";
import { DeleteForeverDialog, RestoreDialog } from "./Pages/DeletionLog";
import { CopyUserDialog } from "./Pages/Settings/User/CopyDialog";
import { NiraAlertHistoryDialog } from "./Components/Nira/Alerts/NiraAlertHistoryDialog";
import { AlertGroupMapDialog } from "./Pages/Settings/NiraAlerts/AlertGroupMapDialog";

function DeviceStateProvider({ entity, children }) {
    const deviceState = useDeviceState(entity.id);
    const store = useStore();

    return React.cloneElement(children, {
        entity: entity,
        deviceId: entity.id,
        device: deviceState,
        open: true,
        onClose: () => {
            closeDialog(store);
        },
    });
}

function UserDialogWrapper({ entity, children }) {
    const store = useStore();

    return React.cloneElement(children, {
        userId: entity.id,
        user: entity,
        entity: entity,
        entityType: "user",
        open: true,
        onClose: () => closeDialog(store),
    });
}

function UserGroupDialogWrapper({ entity, children }) {
    const store = useStore();

    return React.cloneElement(children, {
        entity: entity,
        entityType: "group",
        open: true,
        onClose: () => closeDialog(store),
    });
}

function ViewDialogWrapper({ entity, children }) {
    const store = useStore();

    return React.cloneElement(children, {
        entity: entity,
        entityType: "view",
        open: true,
        onClose: () => closeDialog(store),
    });
}

function MeteoragmDialogWrapper({ entity, children }) {
    const store = useStore();

    return React.cloneElement(children, {
        device: entity,
        open: true,
        standalone: false,
        onClose: () => {
            closeDialog(store);
        },
    });
}

function AlertHistoryDialogWrapper({ entity, children }) {
    const store = useStore();

    return React.cloneElement(children, {
        open: true,
        singleDevice: entity?.singleDevice,
        defaultSeverity: entity?.defaultSeverity,
        confirmationEnabled: entity?.confirmationEnabled,
        onClose: () => {
            closeDialog(store);
        },
    });
}

function NiraAlertDialogWrapper({ entity, children }) {
    const store = useStore();

    return React.cloneElement(children, {
        open: true,
        singleArea: entity?.singleArea,
        onClose: () => {
            closeDialog(store);
        },
    });
}

function BasicDialogWrapper({ entity, children }) {
    const store = useStore();

    return React.cloneElement(children, {
        open: true,
        onClose: () => {
            closeDialog(store);
        },
    });
}

function BasicEnityDialogWrapper({ entity, children }) {
    const store = useStore();

    return React.cloneElement(children, {
        entity: entity,
        open: true,
        onClose: () => {
            closeDialog(store);
        },
    });
}

function LoadingDialogWrapper({ entity, children }) {
    const store = useStore();

    return React.cloneElement(children, {
        open: true,
        error: entity?.error,
        onClose: () => {
            closeDialog(store);
            closeLoadingDialog(store);
        },
    });
}

const dialogs = {
    deviceStatus: (
        <DeviceStateProvider>
            <DeviceStatusDialog />
        </DeviceStateProvider>
    ),
    deviceValues: (
        <DeviceStateProvider>
            <DeviceValuesDialog />
        </DeviceStateProvider>
    ),
    eventList: (
        <DeviceStateProvider>
            <EventListDialog />
        </DeviceStateProvider>
    ),
    userEventList: (
        <UserDialogWrapper>
            <EventListDialog />
        </UserDialogWrapper>
    ),
    userChangePassword: (
        <UserDialogWrapper>
            <ChangePasswordDialog />
        </UserDialogWrapper>
    ),
    userEditRoles: (
        <UserDialogWrapper>
            <RoleDialog />
        </UserDialogWrapper>
    ),
    userGroupEditRoles: (
        <UserGroupDialogWrapper>
            <RoleDialog />
        </UserGroupDialogWrapper>
    ),
    userGroupMembers: (
        <UserGroupDialogWrapper>
            <UserGroupMembersDialog />
        </UserGroupDialogWrapper>
    ),
    userGroups: (
        <UserDialogWrapper>
            <UserGroupsDialog />
        </UserDialogWrapper>
    ),
    viewEditUsers: (
        <ViewDialogWrapper>
            <ViewUsersDialog />
        </ViewDialogWrapper>
    ),
    viewEditGroups: (
        <ViewDialogWrapper>
            <ViewUserGroupsDialog />
        </ViewDialogWrapper>
    ),
    viewCopy: (
        <ViewDialogWrapper>
            <ViewCopyDialog />
        </ViewDialogWrapper>
    ),

    forecastCharts: (
        <MeteoragmDialogWrapper>
            <NumericForecastMeteogram />
        </MeteoragmDialogWrapper>
    ),
    meteogram: (
        <MeteoragmDialogWrapper>
            <MeteogramDialog />
        </MeteoragmDialogWrapper>
    ),
    alertHistory: (
        <AlertHistoryDialogWrapper>
            <AlertHistoryDialog />
        </AlertHistoryDialogWrapper>
    ),
    textForecast: (
        <BasicDialogWrapper>
            <TextForecastDialog />
        </BasicDialogWrapper>
    ),
    loading: (
        <LoadingDialogWrapper>
            <LoadingDialog />
        </LoadingDialogWrapper>
    ),
    cameraView: (
        <DeviceStateProvider>
            <CameraDialog />
        </DeviceStateProvider>
    ),
    errorAudio: (
        <BasicDialogWrapper>
            <AudioErrorDialog />
        </BasicDialogWrapper>
    ),
    niraStatus: (
        <DeviceStateProvider>
            <NiraStatusDialog />
        </DeviceStateProvider>
    ),
    deletion: (
        <BasicEnityDialogWrapper>
            <DeletionDialog />
        </BasicEnityDialogWrapper>
    ),
    recycleRestore: (
        <BasicEnityDialogWrapper>
            <RestoreDialog />
        </BasicEnityDialogWrapper>
    ),
    recycleDeleteForever: (
        <BasicEnityDialogWrapper>
            <DeleteForeverDialog />
        </BasicEnityDialogWrapper>
    ),
    copyUser: (
        <UserDialogWrapper>
            <CopyUserDialog />
        </UserDialogWrapper>
    ),
    niraAlertHistory: (
        <NiraAlertDialogWrapper>
            <NiraAlertHistoryDialog />
        </NiraAlertDialogWrapper>
    ),
    alertGroupMap: (
        <BasicEnityDialogWrapper>
            <AlertGroupMapDialog />
        </BasicEnityDialogWrapper>
    ),
};

export default function AppDialogs() {
    const store = useStore();
    const dialog = useOpenedAppDialog();
    const loadingDialog = useOpenedLoadingDialog();
    const history = useHistory();

    useEffect(() => {
        history.listen((location, action) => {
            const params = queryString.parse(location.search.slice(1));
            if (!params.openedDialog) {
                closeDialog(store);
            }
        });
    }, []);

    if (dialog) {
        return React.cloneElement(dialogs[dialog.type], { entity: dialog.entity });
    } else if (loadingDialog) {
        return React.cloneElement(dialogs[loadingDialog.type], { entity: loadingDialog.entity });
    } else {
        return false;
    }
}
