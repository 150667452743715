import React, {useMemo} from "react";
import {EntitySelectionDialog} from "../../../Components/EntitySelectionDialog";
import {useIntl} from "react-intl";
import {formatForId} from "../../../Utils/Lang/IntlHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/pro-solid-svg-icons";
import {useGet} from "restful-react";
import _ from "loadsh";
import {usePostMutate} from "../../../Api";
import {useNotification} from "../../../Notification";

export default function UserGroupMembersDialog({entity, onClose, open}) {
    const intl = useIntl();

    const {data, loading} = useGet(`/group/${entity.id}/members/list/`);
    const {data: users, loading: usersLoading} = useGet("/users/");

    const selection = useMemo(() => _.map(data, row => row.id.toString()), [data]);
    const choices = useMemo(() => _.map(users, row => ({id: row.id, name: row.login})), [users]);

    const {postData} = usePostMutate(`/group/${entity.id}/members/`);
    const notification = useNotification();

    return <EntitySelectionDialog
        title={<><FontAwesomeIcon icon={faUsers}/>&nbsp;{entity.name}</>}
        localizationContext={"userGroupMembers"}
        open={open}
        onClose={onClose}
        choices={choices}
        selection={selection}
        onSelect={async (selection) => {
            await notification.showApiMessage(postData(selection));
            onClose();
        }
        }

    />
}