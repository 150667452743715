import {LinearProgress, Typography} from "@material-ui/core";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {useGet, useMutate} from "restful-react";
import {CustomForm} from "../../../Components/Forms/CustomForm";
import LoadingScope from "../../../Components/LoadingScope";
import {useMasterDetailFormSave, useOpenedDetailEntity} from "../../../Utils/Data/hooks/masterDetail";


export default function RoleDetail({reloadCallback}) {
    const {entity, detailEditable} = useOpenedDetailEntity();
    const {loading, makeSubmitCallback} = useMasterDetailFormSave("role/");

    const {data: permissions, refetch: reloadPermissions, loading: loadingPermissions, error} = useGet({
        path: "role/allowed-permissions/"
    });

    const formConfig = useMemo(() => {
        return [{
            id: "name",
            type: "TextField",
            props: {
                required: true,
                min: 3,
                immutable: false
            }
        },
            {
                id: "permissions",
                localizationContext: "permissions",
                type: "CheckForm",
                props: {
                    multiple: true,
                    required: true,
                    items: permissions
                },
                defaultValue: []
            }
        ];
    }, [entity, permissions])

    return (<LoadingScope loading={loading} error={error}>
            {<CustomForm key={entity.id} formConfig={formConfig} formData={entity}
                         onSubmit={makeSubmitCallback({onSuccess: reloadCallback})}
                         formId={"role"}/>}
        </LoadingScope>

    );
}