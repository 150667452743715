import React, {useMemo} from "react";
import {EntitySelectionDialog} from "../../../Components/EntitySelectionDialog";
import {useIntl} from "react-intl";
import {formatForId} from "../../../Utils/Lang/IntlHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faUsers} from "@fortawesome/pro-solid-svg-icons";
import {useGet} from "restful-react";
import _ from "loadsh";
import {usePostMutate} from "../../../Api";
import {useNotification} from "../../../Notification";
import {faEye} from "@fortawesome/pro-light-svg-icons";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {CommonDialog} from "../../../Components/CommonDialog";
import {CustomForm} from "../../../Components/Forms/CustomForm";
import {useMasterDetailFormSave} from "../../../Utils/Data/hooks/masterDetail";
import DialogForm from "../../../Components/Forms/DialogForm";
import ActionButtons from "../../../Components/MasterDetail/ActionButtons";

const formConfig =
    [
        {
            id: "name",
            type: "TextField",
            props: {
                required: true,
            }
        }
    ];

export default function ViewCopyDialog({entity, onClose, open}) {
    const intl = useIntl();
    const {makeSubmitCallback} = useMasterDetailFormSave("view/");
    const timeOpened = React.useMemo(() => Date.now());
    const intialData = React.useMemo(() => ({name: formatForId(intl, "viewCopy.name", entity)}), [entity?.name]);

    return <CommonDialog fullWidth={false} maxWidth={"lg"} open={open} onClose={onClose}>
        <DialogTitle>
            {formatForId(intl, "pages.view.copy")}
        </DialogTitle>
        <DialogContent>
            <DialogForm key={entity.id}
                        timeOpened={timeOpened}
                        formConfig={formConfig}
                        formData={intialData}
                        onSubmit={makeSubmitCallback({
                            onBeforeSubmit: (values) => {
                                _.each(entity, (value, key) => {
                                    if (key !== "name" && key !== "id") {
                                        values[key] = value;
                                    }
                                });
                            }
                        })}
                        formId={"view"}
                        submitEventId={"copy"}
            />


        </DialogContent>
        <DialogActions>
            <ActionButtons actions={[{id: "copy"}]} localizationContext={"pages.view"}/>
        </DialogActions>
    </CommonDialog>
}