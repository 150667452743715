
import defaultState from "./masterDetail.default";

export default function masterDetailReducer(state = defaultState, action)  {

   switch (action.type) {
      case "METEO::MASTER_DETAIL::OPEN": {
         const {entityType, entity} = action.payload;
         return  {detailOpened: true,detailEditable: !!entity.isNew, entityType, entity, timeOpened: Date.now()};
      }
      case "METEO::MASTER_DETAIL::EDIT": {
         return {...state, ...action.payload, userChanged: false};
      }
      case  "METEO::MASTER_DETAIL::USER_CHANGED":
      {
         return {...state, ...action.payload};
      }
      case "METEO::MASTER_DETAIL::CLOSE": {
         return defaultState;
      }
      default:
         return state;
   }
}