import DatePicker from 'react-date-picker';
import {TextField} from "@material-ui/core";
import React, {useMemo, useRef} from "react";
import moment from "moment";
import "./DatePicker.css"
import {useLocale} from "../../../Utils/Data/hooks/gui";

export const YearView = "decade";
export const YearMonthView = "year";
export const YearMonthDateView = "month";

function addMuiClasses(input) {

    if (input && input.wrapper) {
        const inputGroup = input.wrapper.querySelector(".react-date-picker__inputGroup")
        if (inputGroup && inputGroup.className.indexOf("MuiInputBase-input") === -1) {
            inputGroup.className += " MuiInputBase-input";
        }
    }
}

export function DatePickerWrapper({value, onChange, disabled, views, maxDate}) {
    const dateValue = useMemo(() => value.toDate(), [value]);
    const [locale] = useLocale();

    return <DatePicker
        ref={addMuiClasses}
        value={dateValue}
        onChange={(value) => {
            onChange(moment(value))
        }}
        className={"MuiInputBase-root MuiInput-root MuiInput-underline"}
        calendarClassName={"MuiPaper-root MuiPickersPopper-paper MuiPaper-elevation8 MuiPaper-rounded"}
        maxDetail={views}
        clearIcon={null}
        calendarIcon={null}
        locale={locale}
        showLeadingZeros={true}
    />

}