import _ from "loadsh";

const AppAccess = window.AppAccess;

function getMetoCtx() {
    if (window.MeteoCtx) {
        return window.MeteoCtx;
    } else {
        return {
            user: null,
            perms: []
        }
    }
}

export function getDomains() {
    return AppAccess?.domains;
}

export function getAuthenticatedUser() {
    return getMetoCtx().user;
}

export function getUserCustomFields() {
    let ret = !_.isEmpty(getAuthenticatedUser()?.custom_data) ? JSON.parse(getAuthenticatedUser().custom_data) : {};

    if (!ret.hasOwnProperty("sound_alert_enabled")) {
        ret.sound_alert_enabled = "0";
    }
    if (!ret.hasOwnProperty("wind_unit")) {
        ret.wind_unit = "ms";
    }

    return ret;
}
