import { CircularProgress, FormControl, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { CustomForm } from "../../../Components/Forms/CustomForm";
import _ from "underscore";
import { useStore } from "react-redux";
import { openDialog } from "../../../Utils/Data/actions/gui";
import { useDetailEditable, useMasterDetailForm, useMasterDetailFormSave, useOpenedDetailEntity } from "../../../Utils/Data/hooks/masterDetail";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { FormSubmitEvent } from "../../../Utils/Data/Events";
import { useDataSources, useDrivers, useDriverTemplates, usePostMutate } from "../../../Api";
import DeviceTestResultDialog from "./DeviceTestResultDialog";
import { faDatabase } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { advancedFormConfig, beginFormConfig, customIdField, DriverInterfaces, enabledField, immutableNameField, requestTimeConfig, rnrIdField } from "./DeviceDetail.form";
import { NoRunningDrivers } from "../../../Components/Forms/NoDataMessage";
import { SingleEntitySelectionDialog } from "../../../Components/SingleEntitySelectionDialog";
import { formatDriverName } from "../../../Components/DeviceStateIcon";
import { useNotification } from "../../../Notification";

function DriverForm({ data, timeOpened, driverInterfaces, onSubmitData, onSubmitTest, loadingTest, isMasterDriver, onSubmitRnrConfig, loadingRnrConfig, rnrConfigResponse }) {
    const intl = useIntl();
    const form = useMasterDetailForm();
    const { drivers, names, getFormFields, loading, allDrivers } = useDrivers(driverInterfaces);
    const detailEditable = useDetailEditable();
    const [openDataSourceDialog, setOpenDataSourceDialog] = useState(false);
    const [referencedDataSource, setReferencedDataSource] = useState(data.referencedDataSource);
    const [selectedDriverName, setSelectedDriverName] = useState(data.isNew ? null : data.driver);
    const [selectedTemplateId, setSelectedTemplateId] = useState(data.isNew ? null : data.configuration_template_id?.toString());
    const [rnrChangedValues, setRnrChangedValues] = useState({});

    const selectedDriver = useMemo(() => selectedDriverName && _.find(allDrivers, (drv) => drv.metadata.name === selectedDriverName), [selectedDriverName, drivers]);
    const selectedDriverInterface = useMemo(() => selectedDriver?.metadata?.interface, [selectedDriver]);

    const isRnrDevice = selectedDriverInterface === DriverInterfaces.MasterRnrDevice;

    const notification = useNotification();

    useEffect(() => {
        setSelectedDriverName(data.isNew ? null : data.driver);
        setReferencedDataSource(data.referencedDataSource);
        setSelectedTemplateId(data.isNew ? null : data.configuration_template_id?.toString());
    }, [data]);

    const formConfig = useMemo(() => {
        if (isMasterDriver) {
            return [...beginFormConfig, ...getFormFields(selectedDriverName)];
        } else if (referencedDataSource) {
            if (isRnrDevice) {
                return [immutableNameField, enabledField, rnrIdField, ...getFormFields(selectedDriverName)];
            } else {
                return [...beginFormConfig, customIdField, ...getFormFields(selectedDriverName), ...advancedFormConfig];
            }
        } else {
            return [...beginFormConfig, customIdField, ...getFormFields(selectedDriverName), ...requestTimeConfig, ...advancedFormConfig];
        }
    }, [selectedDriverName, referencedDataSource, data, selectedDriverInterface, isRnrDevice]);

    const { dataSources, refetch } = useDataSources(data.domain_id);
    const { templates, refetch: refetchTemplates } = useDriverTemplates(data.domain_id, selectedDriverName);

    const onDriverChange = async (e) => {
        if (e.target.value === "none") {
            setSelectedDriverName(null);
            setReferencedDataSource(null);
        } else if (e.target.value === "master") {
            await refetch();
            setOpenDataSourceDialog(true);
        } else {
            setSelectedDriverName(e.target.value);
            setReferencedDataSource(null);
        }
    };

    const onTemplateDriverChange = async (e) => {
        if (e.target.value === "none") {
            setSelectedTemplateId(null);
        } else {
            setSelectedTemplateId(e.target.value);
        }
    };

    const makeDriverConfiguration = (values) => {
        const driverSpecificForm = getFormFields(selectedDriverName);
        const configJson = {};
        for (const { id } of driverSpecificForm) {
            configJson[id] = values[id]?.toString();
        }

        return configJson;
    };

    const makeCameraConfiguration = (values) => {
        for (const camera of values.camera_devices || []) {
            if (!_.isEmpty(camera.http_url)) {
                camera.driver = "http_camera_device";
                camera.driver_configuration_json = JSON.stringify({ http_url: camera.http_url });
                camera.enabled = true;
            }
        }
    };
    const onFormSubmit = async (values, { setErrors }) => {
        values.driver = selectedDriverName;

        values.configuration_template_id = selectedTemplateId ? parseInt(selectedTemplateId, 10) : null;
        values.driver_configuration_json = JSON.stringify(makeDriverConfiguration(values));
        makeCameraConfiguration(values);

        if (isMasterDriver) {
            values.name = `${values.name}@$$`;
        } else if (referencedDataSource) {
            values.running_device_id = referencedDataSource.id;
            values.driver = referencedDataSource.driver;
        }

        if (values.submit_action === "testDevice") {
            await onSubmitTest(values);
        } else if (values.submit_action === "rnrConfig") {
            values.response_type = "request_config";
            await onSubmitRnrConfig(values);
        } else {
            return await onSubmitData(values, { setErrors });
        }
    };

    const intitialValues = useMemo(
        () => ({
            ...data,
            ...JSON.parse(data.driver_configuration_json || "{}"),
            camera_devices: data.camera_devices ? data.camera_devices.map((camera) => ({ ...camera, ...JSON.parse(camera.driver_configuration_json || "{}") })) : [],
        }),
        [data, timeOpened, rnrConfigResponse]
    );

    useEffect(() => {
        if (rnrConfigResponse && rnrConfigResponse.status === 200 && !_.isEmpty(rnrConfigResponse.body)) {
            const jsonData = rnrConfigResponse.body;

            // functionality is duplicated in device_manager.cpp:527 - where is used to update the device configuration
            // from the changed RNR configuration

            setRnrChangedValues({
                name: jsonData.rnr_id,
                rnr_id: jsonData.rnr_id,
                lat: jsonData.active_configuration.position.gps.lat,
                lon: jsonData.active_configuration.position.gps.lon,
                road: jsonData.active_configuration.position.road,
                road_km: jsonData.active_configuration.position.road_km,
                lane_description: jsonData.active_configuration.position.lane_description,
                active_configuration: JSON.stringify(jsonData.active_configuration, null, 2),
            });
        } else if (rnrConfigResponse) {
            notification.showError("Error getting RNR configuration");
            //TODO error handling
        }
    }, [rnrConfigResponse]);

    const hasTemplates = !_.isEmpty(selectedDriver?.metadata?.template_configuration_form);
    const hasTemplatesDefineds = !_.isEmpty(templates);

    return loading ? (
        <CircularProgress size={30} />
    ) : _.isEmpty(drivers) && isMasterDriver ? (
        <NoRunningDrivers />
    ) : (
        <div>
            <DeviceHeader device={data} disabled={!selectedDriverName} isRnrDevice={isRnrDevice} />
            {(loadingTest || loadingRnrConfig) && <CircularProgress size={30} />}
            <FormControl fullWidth>
                <TextField disabled={!data.isNew} id={"deviceSelect"} select name={"deviceSelect"} onChange={onDriverChange} value={selectedDriverName || "none"} margin="normal">
                    <MenuItem key={"none"} value="none">
                        {formatForId(intl, "pages.device.selectDriver")}
                    </MenuItem>
                    {!isMasterDriver && (
                        <MenuItem key={"master"} value="master">
                            <FontAwesomeIcon icon={faDatabase} /> &nbsp;
                            {formatForId(intl, "pages.device.dataSource")}
                        </MenuItem>
                    )}
                    {names.map((item) => (
                        <MenuItem key={item} value={item}>
                            {formatDriverName(intl, { driver: item })}
                        </MenuItem>
                    ))}
                    {referencedDataSource && (
                        <MenuItem key={selectedDriverName} value={selectedDriverName}>
                            {referencedDataSource.name} @ {referencedDataSource.driver}
                        </MenuItem>
                    )}
                </TextField>
            </FormControl>
            {hasTemplates && hasTemplatesDefineds && (
                <FormControl fullWidth>
                    <TextField id={"templateSelect"} select name={"templateSelect"} margin="normal" value={selectedTemplateId || "none"} onChange={onTemplateDriverChange} disabled={!detailEditable}>
                        {selectedTemplateId === null && (
                            <MenuItem key={"none"} value="none">
                                {formatForId(intl, "pages.device.selectTemplate")}
                            </MenuItem>
                        )}
                        {templates.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {formatForId(intl, "pages.device.linkedTemplate")}: {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )}

            {hasTemplates && !hasTemplatesDefineds && <div>{formatForId(intl, "pages.device.templatesRequired")}</div>}

            {selectedDriverName && selectedDriverName !== "master" && (!hasTemplates || selectedTemplateId) && (
                <CustomForm
                    key={intitialValues.id + selectedDriverName + timeOpened}
                    formConfig={formConfig}
                    formData={data.isNew ? {} : intitialValues}
                    onSubmit={onFormSubmit}
                    formId={"deviceDetail"}
                    changedValues={rnrChangedValues}
                />
            )}
            {openDataSourceDialog && (
                <SingleEntitySelectionDialog
                    choices={dataSources}
                    selection={referencedDataSource}
                    open={openDataSourceDialog}
                    onClose={() => {
                        setOpenDataSourceDialog(false);
                    }}
                    localizationContext={"dataSource"}
                    onSelect={(selection) => {
                        setSelectedDriverName(selection.driver);
                        setReferencedDataSource(selection);
                        setOpenDataSourceDialog(false);
                    }}
                />
            )}
        </div>
    );
}

function DeviceHeader({ device, disabled, isRnrDevice }) {
    const store = useStore();
    const testDeviceAction = {
        id: "testDevice",
        onClickEventName: FormSubmitEvent,
    };
    const rnrConfigAction = {
        id: "rnrConfig",
        onClickEventName: FormSubmitEvent,
    };

    return (
        <ActionToolbar
            detailToolbar={true}
            localizationContext={"pages.device"}
            actions={
                device.isNew
                    ? [isRnrDevice ? rnrConfigAction : testDeviceAction]
                    : [
                          {
                              id: "eventList",
                              onClick() {
                                  openDialog(store, { type: "eventList", entity: device });
                              },
                          },
                          isRnrDevice ? rnrConfigAction : testDeviceAction,
                      ]
            }
            buttonProps={{ variant: "outlined", disabled }}
        />
    );
}

export default function DeviceDetail({ onReload, driverInterfaces, isMasterDriver }) {
    const { entity: data, timeOpened } = useOpenedDetailEntity();

    const postPath = "domain/" + data.domain_id + "/device-group/" + data.group_id + "/device/";
    const { loading, makeSubmitCallback } = useMasterDetailFormSave(postPath);

    const submitForm = makeSubmitCallback({
        onSuccess: onReload,
        onExtendEntity: (values, response) => {
            values.domain_id = data.domain_id;
            values.group_id = data.group_id;

            const { custom_data } = response.body;
            for (let i = 0, len = custom_data.length; i < len; ++i) {
                if (i < values.camera_devices?.length) {
                    values.camera_devices[i].id = parseInt(custom_data[i].value, 10);
                }
            }

            if (isMasterDriver) {
                values.name = values.name.substring(0, values.name.length - 3);
            }
        },
    });
    const { postData: postTest, loading: loadingTest, response, setResponse } = usePostMutate(postPath + "test/");
    const { postData: postRnrConfig, loading: loadingRnrConfig, response: rnrConfigResponse, setResponse: setRnrConfigResponse } = usePostMutate(postPath + "test/");

    return (
        <div>
            <DriverForm
                data={data}
                timeOpened={timeOpened}
                driverInterfaces={driverInterfaces}
                onSubmitData={submitForm}
                onSubmitTest={postTest}
                loadingTest={loadingTest}
                isMasterDriver={isMasterDriver}
                onSubmitRnrConfig={postRnrConfig}
                loadingRnrConfig={loadingRnrConfig}
                rnrConfigResponse={rnrConfigResponse}
            />

            {response && <DeviceTestResultDialog data={response} onClose={() => setResponse(null)} />}
        </div>
    );
}
