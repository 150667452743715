import React from "react";
import {CustomForm} from "../../Components/Forms/CustomForm";
import {useMasterDetailFormSave, useOpenedDetailEntity} from "../../Utils/Data/hooks/masterDetail";

const formConfig =
    [{
        id: "name",
        type: "TextField",
        props: {
            required: true,
            min: 3,
        }
    },
        {
            id: "support_contact",
            type: "TextField",
            props: {}
        },
        {
            id: "is_active",
            type: "CheckboxField",
            props: {}
        }
    ];


export default function DomainDetail({reloadCallback}) {
    const {entity} = useOpenedDetailEntity();
    const {makeSubmitCallback} = useMasterDetailFormSave("domain/");

    return (<CustomForm
                         key={entity.id}
                         formConfig={formConfig}
                         formData={entity}
                         onSubmit={makeSubmitCallback({onSuccess: reloadCallback})}
                         formId={"domain"}/>);
}