import { useEffect, useMemo, useState } from "react";
import { useActiveAlertCounter, useActiveGeoAlertCounter, useAppAccess } from "../Utils/Data/hooks/server";
import { useAudioAlertEnabled } from "../Utils/Data/hooks/gui";
import { openDialog } from "../Utils/Data/actions/gui";
import { useStore } from "react-redux";
import { useIntervalWhen } from "rooks";
import { getUserCustomFields } from "../Utils/Permissions/PermissionHelper";
import { DEFAULT_SOUND } from "../Utils/Data/Sounds";
import _ from "underscore";

function useAudio() {
    const store = useStore();
    const { custom_props } = useAppAccess();

    const audio = useMemo(() => {
        let name = getUserCustomFields().sound_alert;

        for (const customProp of custom_props) {
            const customPropsJson = JSON.parse(customProp);
            if (customPropsJson.sound_alert) {
                name = customPropsJson.sound_alert;
                break;
            }
        }

        if (_.isEmpty(name)) {
            name = DEFAULT_SOUND;
        }
        return new Audio(`/sound-alert/${name}`);
    }, []);

    const playAudio = () => {
        const playPromise = audio.play();

        if (playPromise !== undefined) {
            playPromise
                .then((_) => {})
                .catch((error) => {
                    openDialog(store, { type: "errorAudio" });
                    console.log("playback prevented", error);
                });
        }
    };

    return { playAudio };
}

export default function useWarningSound() {
    const { notConfirmedConfirmableAlerts, confirmableMaxLevel } = useActiveAlertCounter();
    const [prevCounter, setPrevCounter] = useState(notConfirmedConfirmableAlerts);
    const [level] = useAudioAlertEnabled();
    const { playAudio } = useAudio();

    const shouldPlayAudio = () => {
        return level > 0 && confirmableMaxLevel >= level && notConfirmedConfirmableAlerts > 0;
    };

    useEffect(() => {
        if (shouldPlayAudio() && prevCounter < notConfirmedConfirmableAlerts) {
            playAudio();
        }
        setPrevCounter(notConfirmedConfirmableAlerts);
    }, [notConfirmedConfirmableAlerts]);

    useIntervalWhen(
        () => {
            playAudio();
        },
        1000 * 60,
        shouldPlayAudio(),
        false
    );
}

export function useGeoWarningSound() {
    const { notConfirmedActiveGeoAlerts, maxLevel } = useActiveGeoAlertCounter();
    const [prevCounter, setPrevCounter] = useState(notConfirmedActiveGeoAlerts);
    const [level] = useAudioAlertEnabled();
    const { playAudio } = useAudio();

    const shouldPlayAudio = () => {
        return level > 0 && maxLevel >= level && notConfirmedActiveGeoAlerts > 0;
    };

    useEffect(() => {
        if (shouldPlayAudio() && prevCounter < notConfirmedActiveGeoAlerts) {
            playAudio();
        }
        setPrevCounter(notConfirmedActiveGeoAlerts);
    }, [notConfirmedActiveGeoAlerts]);

    useIntervalWhen(
        () => {
            playAudio();
        },
        1000 * 60,
        shouldPlayAudio(),
        false
    );
}
