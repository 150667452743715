import {Tooltip, Typography, useTheme} from "@material-ui/core";
import React, {memo, useMemo} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import _ from "loadsh";
import {
    filterAlert,
    getAlertColor,
    getDeviceStateSeverity,
    useFormattedWarnings
} from "../../Utils/Data/AlertFormatter";
import {useStore} from "react-redux";
import {openDialog} from "../../Utils/Data/actions/gui";

export const ALERT_VIEW_TYPE = {"number": 1, "title_only": 2, "large": 3, "description_only": 4}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: '100%',
        width: '100%',
        cursor: "pointer",
        ...theme.custom.baseClickableDiv
    },
    alertTitle: {
        flexGrow: 1,
        justifyContent: "center",
        alignItems: props => props.centerTitle ? "center" : "flex-start",
        display: "flex",
        textAlign: "center"
    },
    mediumDescription: {
        textAlign: "center",
        overflow: "hidden",
        flexGrow: 1,
        alignSelf: "center",
        lineHeight: '1.4em',
        maxHeight: props => `${props.lineCount * 1.4}em`,
        width: "95%",
        display: props => props.centerTitle && "none"
    },
}));

function isConfirmed(deviceState) {
    const notConfirmedWarning = deviceState && deviceState.active_warnings && deviceState.active_warnings
        .filter(alert => filterAlert(alert, false))
        .find(wrn => _.isEmpty(wrn.confirmation));

    return _.isEmpty(notConfirmedWarning);
}

function AlertDescription({mode, formattedWarnings, classes}) {
    switch (mode) {
        case ALERT_VIEW_TYPE.title_only:
        case ALERT_VIEW_TYPE.number:
            return <div/>
        case ALERT_VIEW_TYPE.description_only:
        case ALERT_VIEW_TYPE.large:
            return <Typography className={classes.mediumDescription} variant={"body1"}>
                {formattedWarnings}
            </Typography>
    }

}

function AlerTitle({mode, deviceState, classes, intl, formattedWarnings}) {

    const alertTitle = useMemo(() => formatForId(intl, "pages.device.alert.title." + getDeviceStateSeverity(deviceState)),
        [deviceState, intl]);

    const Title = () => <div className={classes.alertTitle}>
        <Typography variant={"h6"}>
            {alertTitle}
        </Typography>
    </div>

    switch (mode) {
        case ALERT_VIEW_TYPE.description_only:
            return _.isEmpty(formattedWarnings) ? <Title/> : <div/>
        case ALERT_VIEW_TYPE.number: {
            const severity = getDeviceStateSeverity(deviceState, false)
            return <Tooltip title={severity > 0 ? "" : alertTitle}>
                <div className={classes.alertTitle}>
                    <Typography variant={"h6"}>
                        {severity > 0 ? severity : ""}
                    </Typography>
                </div>
            </Tooltip>
        }
        case ALERT_VIEW_TYPE.large:
        case ALERT_VIEW_TYPE.title_only:
            return <Title/>
    }

}

function AlertContainer({formattedWarnings, classes, mode, device, deviceState, children}) {
    const theme = useTheme();
    const store = useStore();

    const {color, hasBackground} = useMemo(() => {
        const level = getDeviceStateSeverity(deviceState);
        return {
            color: level === 0 ? {primary: theme.palette.success.main} :
                getAlertColor(level, theme),
            hasBackground: !isConfirmed(deviceState) || mode === ALERT_VIEW_TYPE.description_only
        }

    }, [deviceState, mode]);


    return <Tooltip title={formattedWarnings}>
        <div className={classes.root}
             style={{
                 border: "6px solid " + color.primary,
                 backgroundColor: hasBackground ? color.primary : "inherit",
                 color: hasBackground ? color.textColor : "inherit"
             }
             }
             onClick={(() => openDialog(store, {type: "alertHistory", entity: {singleDevice: device}}))}>
            {children}
        </div>
    </Tooltip>
}


function AlertView({device, deviceState, mode = ALERT_VIEW_TYPE.large, lineCount = 2}) {
    const intl = useIntl();

    const warnings = useMemo(() => deviceState?.active_warnings ? deviceState?.active_warnings : [], [deviceState])
    const formattedWarnings = useFormattedWarnings(warnings, deviceState);

    const styleProps = useMemo(() => {
        return {
            centerTitle: _.isEmpty(formattedWarnings) || mode === ALERT_VIEW_TYPE.title_only || mode === ALERT_VIEW_TYPE.number,
            lineCount: lineCount
        }
    }, [mode, formattedWarnings]);

    const classes = useStyles(styleProps);

    return <AlertContainer deviceState={deviceState} classes={classes} device={device}
                           formattedWarnings={formattedWarnings} mode={mode}>
        <AlerTitle mode={mode}
                   classes={classes}
                   deviceState={deviceState}
                   intl={intl}
                   formattedWarnings={formattedWarnings}/>
        <AlertDescription classes={classes}
                          mode={mode}
                          formattedWarnings={formattedWarnings}/>
    </AlertContainer>

}

export default memo(AlertView)