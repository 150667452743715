import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import React from "react";
import { CommonDialog } from "../../Components/CommonDialog";
import { useDeleteMutate, usePostMutate } from "../../Api";
import { useNotification } from "../../Notification";

import { DialogConfirmSelectionCancelButtonsOnSubmit, DialogDeleteConfirmSelectionCancelButtonsOnSubmit, DialogSaveCancelButtons } from "../../Components/MasterDetail/DialogSaveCancelButtons";
import { useIntl } from "react-intl";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useStore } from "react-redux";
import { reloadList } from "../../Utils/Data/actions/dataList";
import Button from "@material-ui/core/Button";
import { closeDialog, openDialog } from "../../Utils/Data/actions/gui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { useOpenedDetailEntity } from "../../Utils/Data/hooks/masterDetail";
import { closeDetail } from "../../Utils/Data/actions/masterDetail";

export function CreateDeleteButtonForList(intl, store, deletionUrlBase, deletionUrlCallback, disableCallback) {
    const getDeletionUrl = typeof deletionUrlCallback === "function" ? deletionUrlCallback : (row, deletionUrlBase) => `${deletionUrlBase}?id=${encodeURIComponent(row.id)}`;

    return {
        field: "delete",
        headerName: "Delete",
        width: 50,
        align: "center",
        renderCell: (params) => (
            <Button
                onClick={(evt) => {
                    openDialog(store, {
                        type: "deletion",
                        entity: { ...params.row, deletionUrl: getDeletionUrl(params.row, deletionUrlBase) },
                    });
                    evt.preventDefault();
                    evt.stopPropagation();
                }}
                title={formatForId(intl, "actions.delete.title", params.row)}
                disabled={disableCallback ? disableCallback(params.row) : false}
            >
                <FontAwesomeIcon icon={faTrashCan} size={"lg"} />
            </Button>
        ),
        renderIconButtonCell: (params) => (
            <IconButton
                onClick={(evt) => {
                    openDialog(store, {
                        type: "deletion",
                        entity: {
                            ...params.row,
                            deletionUrl: `${deletionUrlBase}?id=${encodeURIComponent(params.row.id)}`,
                        },
                    });
                    evt.preventDefault();
                    evt.stopPropagation();
                }}
                style={{ padding: 12 }}
                title={formatForId(intl, "actions.delete.title", params.row)}
                disabled={disableCallback ? disableCallback(params.row) : false}
            >
                <FontAwesomeIcon disabled icon={faTrashCan} size={"sm"} />
            </IconButton>
        ),
    };
}

export function DeletionDialog({ entity, onClose }) {
    const intl = useIntl();
    const { deleteData } = useDeleteMutate(entity.deletionUrl);
    const notification = useNotification();
    const store = useStore();

    const openedEntity = useOpenedDetailEntity();

    return (
        <CommonDialog onClose={onClose}>
            <DialogTitle>{entity.name}</DialogTitle>
            <DialogContent>{formatForId(intl, "actions.delete.question", { name: entity.name })}</DialogContent>
            <DialogActions>
                <DialogDeleteConfirmSelectionCancelButtonsOnSubmit
                    onClose={onClose}
                    onSubmit={async () => {
                        const result = await deleteData();
                        if (result.status === 200) {
                            notification.showSuccess("operation.success");
                        } else {
                            notification.showError(result.body.message);
                        }

                        reloadList(store);
                        if (openedEntity?.entity?.id === entity.id) {
                            closeDetail(store);
                        }
                        onClose();
                    }}
                />
            </DialogActions>
        </CommonDialog>
    );
}

export function DeletionDialogCustomAction({ open, onClose, onDelete, name }) {
    const intl = useIntl();

    return (
        <Dialog onClose={onClose} open={open} fullWidth>
            <DialogTitle>{name}</DialogTitle>
            <DialogContent>{formatForId(intl, "actions.delete.question", { name })}</DialogContent>
            <DialogActions>
                <DialogDeleteConfirmSelectionCancelButtonsOnSubmit onClose={onClose} onSubmit={onDelete} />
            </DialogActions>
        </Dialog>
    );
}
