import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import React from "react";
import { RequireAnyPermission } from "../Utils/Permissions/RequireAnyPermission";
import { GlobalDevicesView, Views } from "./View/Views";
import _ from "loadsh";
import { Card, CardActionArea, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { NoDataMessage } from "../Components/Forms/NoDataMessage";
import { useViewsList } from "../Api";
import LoadingScope from "../Components/LoadingScope";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    text: {
        marginTop: theme.spacing(0.25),
        marginBottom: theme.spacing(0.25),
    },
}));

function ViewLanding() {
    const { views, loading, error } = useViewsList();
    const classes = useStyles();

    const map = (list, func) => {
        if (_.isEmpty(list)) {
            return <NoDataMessage />;
        } else {
            return _.map(
                _.filter(list, (g) => g.id > 0),
                func
            );
        }
    };
    const history = useHistory();

    return (
        <LoadingScope loading={loading} error={error} dialog={true} dialogWithHiddenContent={true}>
            <div className={classes.root}>
                {map(views, (view) => (
                    <Card key={view.id} className={classes.card}>
                        <CardActionArea
                            onClick={() => {
                                history.push("./" + view.id + (_.isEmpty(view.initial_view) ? "/map/" : `\/${view.initial_view}\/`));
                            }}
                        >
                            <CardContent>
                                <Typography variant="h5" component="h2" className={classes.text}>
                                    <FontAwesomeIcon icon={faEye} size={"lg"} className={classes.icon} />
                                    &nbsp;{view.name}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </div>
        </LoadingScope>
    );
}

export default function GlobalViews() {
    const { path } = useRouteMatch();

    return (
        <RequireAnyPermission permission={"dev__data"}>
            <Switch>
                <Route path={"/views/"} exact={true}>
                    <ViewLanding />
                </Route>
                <Route path={`${path}`}>
                    <GlobalDevicesView />
                </Route>
            </Switch>
        </RequireAnyPermission>
    );
}
