import { PointSymbolizer } from "./Symbolizer.Point.js";
import { LineSymbolizer } from "./Symbolizer.Line.js";
import { FillSymbolizer } from "./Symbolizer.Fill.js";
import L from "leaflet";
import Pbf from "pbf";
import { VectorTile } from "@mapbox/vector-tile";
import { RoughnessChangesAlerts } from "../../../NiraApi";
import { globalRequestLimiter } from "./RequestLimiter";
import ZoomAbortManager from "./ZoomAbortManager";

/* 🍂class VectorGrid
 * 🍂inherits GridLayer
 *
 * A `VectorGrid` is a generic, abstract class for displaying tiled vector data.
 * it provides facilities for symbolizing and rendering the data in the vector
 * tiles, but lacks the functionality to fetch the vector tiles from wherever
 * they are.
 *
 * Extends Leaflet's `L.GridLayer`.
 */

L.VectorGrid = L.GridLayer.extend({
    options: {
        // 🍂option rendererFactory = L.svg.tile
        // A factory method which will be used to instantiate the per-tile renderers.
        rendererFactory: L.svg.tile,

        // 🍂option vectorTileLayerStyles: Object = {}
        // A data structure holding initial symbolizer definitions for the vector features.
        vectorTileLayerStyles: {},

        // 🍂option interactive: Boolean = false
        // Whether this `VectorGrid` fires `Interactive Layer` events.
        interactive: false,

        niraDataType: undefined,

        dataTimestamp: 0,

        // 🍂option getFeatureId: Function = undefined
        // A function that, given a vector feature, returns an unique identifier for it, e.g.
        // `function(feat) { return feat.properties.uniqueIdField; }`.
        // Must be defined for `setFeatureStyle` to work.

        // 🍂option filter: Function = undefined
        // A Function that will be used to decide whether to include a feature or not
        // depending on feature properties and zoom, e.g.
        // `function(properties, zoom) { return true; }`.
        // The default is to include all features. Similar to L.GeoJSON filter option.
    },

    initialize: function (options, test) {
        if (options.niraDataType === RoughnessChangesAlerts) {
            options.pane = "markerPane";
        }
        L.setOptions(this, options);
        L.GridLayer.prototype.initialize.apply(this, arguments);
        if (this.options.getFeatureId) {
            this._vectorTiles = {};
            this._overriddenStyles = {};
            this.on(
                "tileunload",
                function (e) {
                    var key = this._tileCoordsToKey(e.coords),
                        tile = this._vectorTiles[key];

                    if (tile && this._map) {
                        tile.removeFrom(this._map);
                    }
                    delete this._vectorTiles[key];
                },
                this
            );
        }
        this._dataLayerNames = {};
        this.skipped = 0;
        this.unskipped = 0;
        this.zoomAbortManager = new ZoomAbortManager();
    },

    getNiraDataPromise: function (coords, abortSignal) {
        const data = {
            x: coords.x,
            y: coords.y,
            z: coords.z,
        };
        const urlObj = new URL(this._url);
        urlObj.searchParams.set("time", this.options.dataTimestamp);
        urlObj.searchParams.set("x", coords.x);
        urlObj.searchParams.set("y", coords.y);
        urlObj.searchParams.set("z", coords.z);

        const dataUrl = urlObj.protocol + "//" + urlObj.host + "/measures/" + this.options.niraDataType + "/" + urlObj.search;

        return globalRequestLimiter.fetch(dataUrl, { signal: abortSignal }).then((response) => response.clone().json());
    },
    getNiraAlertChangesDataPromise: function (coords, abortSignal) {
        const data = {
            x: coords.x,
            y: coords.y,
            z: coords.z,
        };
        const urlObj = new URL(this._url);
        urlObj.searchParams.set("time", this.options.dataTimestamp);
        urlObj.searchParams.set("x", coords.x);
        urlObj.searchParams.set("y", coords.y);
        urlObj.searchParams.set("z", coords.z);

        const dataUrl = urlObj.protocol + "//" + urlObj.host + "/measures/" + this.options.niraDataType + "/" + urlObj.search;

        return globalRequestLimiter
            .fetch(dataUrl, { ...this.options.fetchOptions, signal: abortSignal })
            .then(
                function (response) {
                    if (!response.ok || !this._isCurrentTile(coords)) {
                        return { layers: [] };
                    }

                    return response
                        .clone()
                        .blob()
                        .then(function (blob) {
                            var reader = new FileReader();
                            return new Promise(function (resolve) {
                                reader.addEventListener("loadend", function () {
                                    // reader.result contains the contents of blob as a typed array
                                    // blob.type === 'application/x-protobuf'
                                    var pbf = new Pbf(reader.result);
                                    return resolve(new VectorTile(pbf));
                                });
                                reader.readAsArrayBuffer(blob);
                            });
                        });
                }.bind(this)
            )
            .then(function (json) {
                // Normalize feature getters into actual instanced features
                for (var layerName in json.layers) {
                    var feats = [];

                    for (var i = 0; i < json.layers[layerName].length; i++) {
                        var feat = json.layers[layerName].feature(i);
                        feat.geometry = feat.loadGeometry();
                        feats.push(feat);
                    }

                    json.layers[layerName].features = feats;
                }

                return json;
            });
    },

    valForDataset: function (segmentId, subsegmentId, data) {
        if (data) {
            const segment = data.segments[segmentId];
            if (segment) {
                const idx = segment.sub_segment_mapping.findIndex((subSegment) => {
                    if (subsegmentId >= subSegment[0] && subsegmentId <= subSegment[1]) {
                        return true;
                    }
                });

                if (idx !== -1) {
                    return {
                        value: segment.value[idx],
                        valueRel: segment.value_rel[idx],
                        numberOfMes: segment.number_of_measurements[idx],
                        subsegmentMapping: segment.sub_segment_mapping[idx],
                    };
                }
            }
        }
    },
    addFeatureLayer: function (featureLayer, renderer, layerStyle, layerName, coords) {
        let styleOptions = layerStyle;
        const id = this.options.getFeatureId(featureLayer);
        let styleOverride = this._overriddenStyles[id];
        if (styleOverride) {
            if (styleOverride[layerName]) {
                styleOptions = styleOverride[layerName];
            } else {
                styleOptions = styleOverride;
            }
        }

        if (styleOptions instanceof Function) {
            styleOptions = styleOptions(featureLayer.properties, coords.z);
        }

        if (!(styleOptions instanceof Array)) {
            styleOptions = [styleOptions];
        }

        for (let j = 0; j < styleOptions.length; j++) {
            let style = L.extend({}, L.Path.prototype.options, styleOptions[j]);
            featureLayer.render(renderer, style);
            renderer._addPath(featureLayer);
        }

        if (this.options.interactive) {
            featureLayer.makeInteractive();
        }

        // multiple features may share the same id, add them
        // to an array of features
        if (!renderer._features[id]) {
            renderer._features[id] = [];
        }

        renderer._features[id].push({
            layerName: layerName,
            feature: featureLayer,
        });
    },

    createTile: function (coords, done) {
        var tileSize = this.getTileSize();
        var renderer = this.options.rendererFactory(coords, tileSize, this.options);
        const abortSignal = this.zoomAbortManager.getAbortSignal(coords.z);

        if (this.options.niraDataType === RoughnessChangesAlerts) {
            const roughnessAlertPromise = this.getNiraAlertChangesDataPromise(coords, abortSignal);
            this._vectorTiles[this._tileCoordsToKey(coords)] = renderer;
            renderer._features = {};
            roughnessAlertPromise.then(
                function renderTile(vectorTile) {
                    for (var layerName in vectorTile.layers) {
                        this._dataLayerNames[layerName] = true;
                        var layer = vectorTile.layers[layerName];
                        var pxPerExtent = this.getTileSize().divideBy(layer.extent);

                        var layerStyle = this.options.vectorTileLayerStyles[layerName] || L.Path.prototype.options;

                        for (var i = 0; i < layer.features.length; i++) {
                            var feat = layer.features[i];

                            if (this.options.filter instanceof Function && !this.options.filter(feat.properties, coords.z)) {
                                continue;
                            }

                            let featureLayer = this._createLayer(feat, pxPerExtent);
                            this.addFeatureLayer(featureLayer, renderer, layerStyle, layerName, coords);
                        }
                    }

                    if (this._map != null) {
                        renderer.addTo(this._map);
                    }

                    L.Util.requestAnimFrame(done.bind(coords, null, null));
                }.bind(this)
            );
        } else {
            var vectorTilePromise = this._getVectorTilePromise(coords, null, abortSignal);

            this._vectorTiles[this._tileCoordsToKey(coords)] = renderer;
            renderer._features = {};

            vectorTilePromise.then(
                function renderTile(vectorTile) {
                    this.getNiraDataPromise(coords, abortSignal).then(
                        function (jsonNiraData) {
                            if (vectorTile.layers && vectorTile.layers.length !== 0) {
                                for (var layerName in vectorTile.layers) {
                                    this._dataLayerNames[layerName] = true;
                                    var layer = vectorTile.layers[layerName];

                                    var pxPerExtent = this.getTileSize().divideBy(layer.extent);

                                    var layerStyle = this.options.vectorTileLayerStyles[layerName] || L.Path.prototype.options;

                                    for (var i = 0; i < layer.features.length; i++) {
                                        var feat = layer.features[i];

                                        const niraData = this.valForDataset(feat.properties.segment_id, feat.properties.sub_segment_id, jsonNiraData);

                                        if (!niraData) {
                                            continue;
                                        }

                                        feat.properties.niraData = niraData;

                                        if (this.options.filter instanceof Function && !this.options.filter(feat.properties, coords.z)) {
                                            continue;
                                        }

                                        let featureLayer = this._createLayer(feat, pxPerExtent);

                                        for (const len = layer.features.length - 1; i !== len; ++i) {
                                            const nextFeature = this._createLayer(layer.features[i + 1], pxPerExtent);

                                            if (featureLayer.properties.segment_id !== nextFeature.properties.segment_id) {
                                                break;
                                            }

                                            const nextSubsegmentId = nextFeature.properties.sub_segment_id;
                                            if (nextSubsegmentId <= niraData.subsegmentMapping[0] || nextSubsegmentId > niraData.subsegmentMapping[1]) {
                                                break;
                                            }
                                            featureLayer._parts[0].push(...nextFeature._parts[0]);
                                        }

                                        featureLayer.properties.niraData = niraData;

                                        this.addFeatureLayer(featureLayer, renderer, layerStyle, layerName, coords);
                                    }
                                }
                            }

                            if (this._map != null) {
                                renderer.addTo(this._map);
                            }

                            L.Util.requestAnimFrame(done.bind(coords, null, null));
                        }.bind(this)
                    );
                }.bind(this)
            );
        }

        return renderer.getContainer();
    },

    // 🍂method setFeatureStyle(id: Number, layerStyle: L.Path Options): this
    // Given the unique ID for a vector features (as per the `getFeatureId` option),
    // re-symbolizes that feature across all tiles it appears in.
    setFeatureStyle: function (id, layerStyle) {
        this._overriddenStyles[id] = layerStyle;

        for (var tileKey in this._vectorTiles) {
            var tile = this._vectorTiles[tileKey];
            var features = tile._features[id];
            if (features) {
                for (var i = 0; i < features.length; i++) {
                    var feature = features[i];

                    var styleOptions = layerStyle;
                    if (layerStyle[feature.layerName]) {
                        styleOptions = layerStyle[feature.layerName];
                    }

                    this._updateStyles(feature.feature, tile, styleOptions);
                }
            }
        }
        return this;
    },

    // 🍂method setFeatureStyle(id: Number): this
    // Reverts the effects of a previous `setFeatureStyle` call.
    resetFeatureStyle: function (id) {
        delete this._overriddenStyles[id];

        for (var tileKey in this._vectorTiles) {
            var tile = this._vectorTiles[tileKey];
            var features = tile._features[id];
            if (features) {
                for (var i = 0; i < features.length; i++) {
                    var feature = features[i];

                    var styleOptions = this.options.vectorTileLayerStyles[feature.layerName] || L.Path.prototype.options;
                    this._updateStyles(feature.feature, tile, styleOptions);
                }
            }
        }
        return this;
    },

    // 🍂method setFilter(filterFn: Function): this
    // Sets filter function to filter displayed features.
    setFilter: function (filterFn) {
        this.options.filter = filterFn;
        this.redraw();
        return this;
    },

    // 🍂method getDataLayerNames(): Array
    // Returns an array of strings, with all the known names of data layers in
    // the vector tiles displayed. Useful for introspection.
    getDataLayerNames: function () {
        return Object.keys(this._dataLayerNames);
    },

    _updateStyles: function (feat, renderer, styleOptions) {
        styleOptions = styleOptions instanceof Function ? styleOptions(feat.properties, renderer.getCoord().z) : styleOptions;

        if (!(styleOptions instanceof Array)) {
            styleOptions = [styleOptions];
        }

        for (var j = 0; j < styleOptions.length; j++) {
            var style = L.extend({}, L.Path.prototype.options, styleOptions[j]);
            feat.updateStyle(renderer, style);
        }
    },

    _createLayer: function (feat, pxPerExtent, layerStyle) {
        var layer;
        switch (feat.type) {
            case 1:
                layer = new PointSymbolizer(feat, pxPerExtent);
                //prevent leaflet from treating these canvas points as real markers
                layer.getLatLng = null;
                break;
            case 2:
                layer = new LineSymbolizer(feat, pxPerExtent);
                break;
            case 3:
                layer = new FillSymbolizer(feat, pxPerExtent);
                break;
        }

        if (this.options.interactive) {
            layer.addEventParent(this);
        }

        return layer;
    },
});

/*
 * 🍂section Extension methods
 *
 * Classes inheriting from `VectorGrid` **must** define the `_getVectorTilePromise` private method.
 *
 * 🍂method getVectorTilePromise(coords: Object): Promise
 * Given a `coords` object in the form of `{x: Number, y: Number, z: Number}`,
 * this function must return a `Promise` for a vector tile.
 *
 */
L.vectorGrid = function (options) {
    return new L.VectorGrid(options);
};
