import { AppBar, FormControlLabel, IconButton, Switch, Tooltip, Typography } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faImage, faSync, faTimes } from "@fortawesome/pro-solid-svg-icons";
import React, { useEffect, useMemo } from "react";
import DateRangePicker from "../../Forms/DateRangePicker";
import { useIntl } from "react-intl";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { useStore } from "react-redux";
import { useForecastActive, useZoomActive } from "../../../Utils/Data/hooks/meteogram";
import { setForecastActive } from "../../../Utils/Data/actions/meteogram";
import { useHasPermission } from "../../../Utils/Permissions/RequireAnyPermission";
import html2canvas from "html2canvas";
import { formatTime } from "../../../Utils/Data/Time";
import moment from "moment";
import { HistoryCountdown } from "../../../Pages/View/ViewToolbar";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dateRange: {
        paddingRight: theme.spacing(2),
    },
    forecast: {
        paddingRight: theme.spacing(3),
    },
    countdown: {
        paddingRight: theme.spacing(1),
    },
}));

const downloadImage = async (element) => {
    const canvas = await html2canvas(element),
        link = document.createElement("a");

    link.href = canvas.toDataURL("image/png");
    link.download = `export_${formatTime(moment(), true)}.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

function ZoomCountdown({ handleReset }) {
    const zoomActive = useZoomActive();
    const classes = useStyles();
    const intl = useIntl();
    const endDate = useMemo(() => {
        if (zoomActive) {
            return moment().add(15, "minutes");
        } else return null;
    }, [zoomActive]);

    if (endDate) {
        return (
            <Tooltip title={formatForId(intl, "pages.device.meteogram.control.zoomCountdownTooltip")}>
                <div className={classes.countdown}>
                    <HistoryCountdown onExpiration={() => handleReset()} endDate={endDate} />
                </div>
            </Tooltip>
        );
    } else {
        return <></>;
    }
}

export function MeteogramControl({ handleClose, handleReset, device, history, standalone, rootRef }) {
    const classes = useStyles();
    const store = useStore();
    const intl = useIntl();

    const hasForecastPerm = useHasPermission({
        permission: "forecast__numeric",
        context: {
            domainID: device.domain_id,
            groupID: device.group_id,
        },
    });

    useEffect(() => setForecastActive(store, hasForecastPerm), []);

    const forecastActive = useForecastActive();

    const openInNewWindow = () => {
        const url = window.location.origin + `/meteogram/?device_id=${device.id}&domain_id=${device.domain_id}&group_id=${device.group_id}`;
        window.open(url, "_blank");
    };

    return (
        <AppBar className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    {device.name}
                </Typography>

                {hasForecastPerm && (
                    <div className={classes.forecast}>
                        <FormControlLabel
                            control={<Switch checked={forecastActive} onChange={(event) => setForecastActive(store, event.target.checked)} />}
                            label={formatForId(intl, "pages.device.meteogram.control.forecastLabel")}
                        />
                    </div>
                )}

                <div className={classes.dateRange}>
                    <DateRangePicker value={history.value} disabled={false} onChange={(value) => history.setValue(value)} dense={true} shiftEnabled history={history} />
                </div>

                <ZoomCountdown handleReset={handleReset} />
                <IconButton color="inherit" onClick={handleReset}>
                    <FontAwesomeIcon icon={faSync} size={"xs"} />
                </IconButton>

                <IconButton color="inherit" onClick={() => downloadImage(rootRef.current)}>
                    <FontAwesomeIcon icon={faImage} size={"xs"} />
                </IconButton>
                <IconButton color="inherit" onClick={openInNewWindow}>
                    <FontAwesomeIcon icon={faExternalLink} size={"xs"} />
                </IconButton>

                {!standalone && (
                    <IconButton color="inherit" onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimes} size={"xs"} />
                    </IconButton>
                )}
            </Toolbar>
        </AppBar>
    );
}
