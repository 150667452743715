import { useSelectedItem } from "../../Utils/Data/hooks/map";
import { useMemo, useState } from "react";
import { useLocalstorageState } from "rooks";
import { useDeviceDataActiveView } from "../../Utils/Data/hooks/deviceDataView";

export const RADAR_INTERVAL_HOURS = 1;
export const SELECTED_COLOR = "#3452eb";
export const BASE_Y_POPUP_OFFSET = -30;

export function useIsSelected(id) {
    const selectedItem = useSelectedItem();
    return useMemo(() => selectedItem?.id === id, [selectedItem, id]);
}

export function useDeviceSavedState(deviceId) {
    const activeView = useDeviceDataActiveView();

    const getInitValue = (mapState) => {
        if (activeView.context.type !== "view") {
            return undefined;
        }
        const views = mapState.views;
        if (views) {
            const devices = views[activeView.context.viewId]?.devices;
            if (devices) {
                return devices[deviceId];
            }
        }
    };
    const [mapState, setMapState] = useLocalstorageState("meteo::mapState", { views: {} });
    const [deviceState, setDeviceState] = useState(getInitValue(mapState));

    const onDeviceStateChanged = (deviceState) => {
        if (activeView.context.type !== "view") {
            return;
        }

        const viewId = activeView.context.viewId;

        setDeviceState(deviceState);
        setMapState((prevState) => {
            let newState = { ...prevState };
            if (!newState.views[viewId]) {
                newState.views[viewId] = { devices: {} };
            }
            newState.views[viewId].devices[deviceState.deviceId] = deviceState;
            return newState;
        });
    };

    return [deviceState, onDeviceStateChanged];
}
