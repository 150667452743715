import { useSelector } from "react-redux";

export function useSelectedItem() {
    return useSelector(({ map }) => {
        return map?.selectedItem;
    });
}

export function useCurrentOverlayImages() {
    return useSelector(({ map }) => {
        return map?.currentOverlay;
    });
}

export function useOverlayIndex() {
    return useSelector(({ map }) => {
        return map?.overlayIndex;
    });
}

export function useImageOverlayEnabled() {
    return useSelector(({ map }) => {
        return map?.imageOverlayEnabled;
    });
}

export function useImageOverlayPaused() {
    return useSelector(({ map }) => {
        return map?.imageOverlayPaused;
    });
}

export function useSelectedDataType() {
    return useSelector(({ map }) => {
        return map?.selectedDataType;
    });
}

export function useNiraDataPanelEnabled() {
    return useSelector(({ map }) => {
        return map?.niraDataPanelEnabled;
    });
}

export function useSelectedMapType() {
    return useSelector(({ map }) => {
        return map?.selectedMapType;
    });
}

export function useSelectedRoughnessDataTime() {
    return useSelector(({ map }) => {
        return map?.selectedRoughnessAlertTime;
    });
}

export function useNiraPlayerDataFrame() {
    return useSelector(({ map }) => {
        return map?.niraPlayerFrame;
    });
}

export function useSelectedPlayerTime() {
    return useSelector(({ map }) => {
        return map?.selectedPlayerTime;
    });
}

export function useDevicesHidden() {
    return useSelector(({ map }) => {
        return map?.devicesHidden;
    });
}

export function useInsufficientNiraZoom() {
    return useSelector(({ map }) => {
        return map?.insufficientNiraZoom;
    });
}

export function useNiraAlertsEnabled() {
    return useSelector(({ map }) => {
        return map?.niraAlertsEnabled;
    });
}
