import React, { useMemo, useState } from "react";

import "../../index.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import { makeStyles } from "@material-ui/core/styles";
import { ActiveCamera } from "../../Components/Device/CameraView";
import { Paper, Typography } from "@material-ui/core";
import { NoDataMessage } from "../../Components/Forms/NoDataMessage";
import { useDeviceDataActiveViewDevices } from "../../Utils/Data/hooks/deviceDataView";
import _ from "loadsh";
import ZoomControl from "../../Components/ZoomControl";

const BASE_HEIGHT = 92; //vh

const GRID_SIZES = [
    [1, 1],
    [2, 2],
    [2, 3],
    [2, 4],
    [3, 4],
    [4, 4],
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        paddingLeft: theme.spacing(1),
    },
    rootItems: {
        flexGrow: 1,
        display: "flex",
        flexWrap: "wrap",
    },
    itemContainer: {
        width: ({ xCount }) => 100 / xCount + "%",
    },
    item: {
        height: ({ yCount }) => BASE_HEIGHT / yCount + "vh",
        margin: theme.spacing(0.5),
        padding: theme.spacing(1),
    },
    cameraTitle: {
        alignSelf: "flex-start",
        width: "100%",
    },
    camera: {
        flex: 1,
        padding: theme.spacing(0.5),
        alignSelf: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: "91%",
        width: "100%",
    },
}));

function CameraItem({ device, classes, disableOpenDialog }) {
    return (
        <div className={classes.itemContainer}>
            <Paper className={classes.item} square>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div className={classes.cameraTitle}>
                        <Typography variant={"h6"} noWrap>
                            {device.device.name + (device.index ? ` #${device.index}` : "")}
                        </Typography>
                    </div>
                    <div className={classes.camera}>
                        <ActiveCamera device={device.device} id={device.cameraId} variant={"custom"} disableOpenDialog={disableOpenDialog} />
                    </div>
                </div>
            </Paper>
        </div>
    );
}

function getInitGridSize(devicesWithCamera) {
    if (devicesWithCamera.length === 1) {
        return 0;
    }

    for (let i = GRID_SIZES.length - 1; i >= 1; --i) {
        const grid = GRID_SIZES[i];
        if (devicesWithCamera.length >= grid[0] * grid[1]) {
            return i;
        }
    }
    return 1;
}

export function ScalableCameras({ devicesWithCameras, disableOpenDialog }) {
    const [gridSizeIdx, setGridSizeIdx] = useState(_.isEmpty(devicesWithCameras) ? 4 : getInitGridSize(devicesWithCameras));
    const classes = useStyles({
        yCount: GRID_SIZES[gridSizeIdx][0],
        xCount: GRID_SIZES[gridSizeIdx][1],
    });

    return (
        <div style={{ overflow: "auto", height: "100%" }}>
            {devicesWithCameras.length > 0 ? (
                <div className={classes.root}>
                    <div className={classes.rootItems}>
                        {devicesWithCameras.map((device) => {
                            return <CameraItem device={device} classes={classes} disableOpenDialog={disableOpenDialog} />;
                        })}
                    </div>
                    <ZoomControl value={gridSizeIdx} onValueChanged={(newVal) => setGridSizeIdx(newVal)} values={GRID_SIZES} />
                </div>
            ) : (
                <NoDataMessage />
            )}
        </div>
    );
}

export function CamerasView({ domainId, groupId, loading, error, ...props }) {
    const devices = useDeviceDataActiveViewDevices();

    const devicesWithCameras = useMemo(() => {
        let ret = [];
        devices.forEach((device) =>
            device.camera_devices?.forEach((camera, idx, arr) =>
                ret.push({
                    device: device,
                    cameraId: camera.id,
                    index: arr.length > 1 ? idx + 1 : 0,
                })
            )
        );
        return ret;
    }, [devices]);

    return <ScalableCameras devicesWithCameras={devicesWithCameras} />;
}
