import {useAppAccess} from "../Data/hooks/server";
import React, {useMemo} from "react";
import _ from "loadsh";
import {NoAccessMessage} from "../../Components/Forms/NoDataMessage";
import {useVisibleDomainAndGroups} from "../Data/hooks/deviceDataView";
import {DefaultErrorComponentCallback} from "./DefaultErrorComponentCallback";
import {calcAccess} from "./RequirePermission";

function checkDomainPerm(domain, permission) {
    if (!domain) {
        return false;
    } else {
        if (_.find(domain.permissions, perm => perm === permission)) {
            return true;
        } else {
            for (const dev_group of domain.device_groups) {
                if (_.find(dev_group.permissions, perm => perm === permission)) {
                    return true;
                }
            }
        }
    }
    return false;
}

function checkGlobalPerm(appAccess, permission) {
    return _.find(appAccess.permissions, (elem) => {
        return permission === elem
    });
}

export function calcAnyAccess(appAccess, domainId, permission) {
    if (checkGlobalPerm(appAccess, permission)) {
        return true;
    }
    if (domainId) {
        const domain = _.find(appAccess.domains, dom => dom.id === domainId);
        return checkDomainPerm(domain, permission);

    } else {

        for (const domain of appAccess.domains) {
            if (checkDomainPerm(domain, permission))
                return true;
        }

        return false;
    }
}

export function makeSecurityContext(device) {
    return device ? {domainID: device.domain_id, groupID: device.group_id} : null;
}

/**
 * This method check permission as calcAnyAccess when no context is provided
 * or calcAccess when the concrete context is provided
 * @param permission
 * @param context
 * @returns {boolean|boolean}
 */
export function useHasPermission({permission, context}) {
    const appAccess = useAppAccess();

    return useMemo(() => _.isEmpty(context) ?
            calcAnyAccess(appAccess, null, permission) :
            calcAccess(appAccess, context.domainID, context.groupID, permission),
        [appAccess, context, permission]);
}

export function useHasOneOfPermission({permissions, context}) {
    const appAccess = useAppAccess();

    return useMemo(() => {
            if (_.isEmpty(context)) {

                let result = false;
                for (const permission of permissions) {
                    result |= calcAnyAccess(appAccess, null, permission);
                }
                return result;

            }
            else {
                let result = false;
                for (const permission of permissions) {
                    result |= calcAccess(appAccess, context.domainID, context.groupID, permission);
                }
                return result;
            }
        },
        [appAccess, context, permissions]);
}

export function useHasAnyPermission({permission, domainID}) {
    const appAccess = useAppAccess();
    return useMemo(() => calcAnyAccess(appAccess, domainID, permission), [appAccess, domainID, permission]);
}

export function RequireAnyPermission({
                                         permission,
                                         domainID,
                                         children,
                                         onErrorComponent = DefaultErrorComponentCallback
                                     }) {
    const hasAccess = useHasAnyPermission({permission, domainID});
    return hasAccess ? <>{children}</> : (onErrorComponent ? onErrorComponent({permission}) : false);
}

function calcAccessVisibleDomainGroups(appAccess, visibleDomainGroups, permission) {
    if (checkGlobalPerm(appAccess, permission)) {
        return true;
    }

    for (const [domainId, groups] of visibleDomainGroups.entries()) {
        const domain = _.find(appAccess.domains, domain => domain.id === domainId);
        if (domain) {
            if (_.find(domain.permissions, perm => perm === permission)) {
                return true;
            } else {
                for (const dev_group of domain.device_groups) {
                    if (groups.has(dev_group.id)) {
                        if (_.find(dev_group.permissions, perm => perm === permission)) {
                            return true;
                        }
                    }
                }
            }
        }
    }

    return false;
}

export function RequireAnyPermissionsForVisibleDevices({
                                                           permission,
                                                           permissions,
                                                           children,
                                                           onErrorComponent = DefaultErrorComponentCallback
                                                       }) {
    const appAccess = useAppAccess();
    const visibleDomainGroups = useVisibleDomainAndGroups();
    const hasAccess = useMemo(() => {
            if (_.isString(permission)) {
                return calcAccessVisibleDomainGroups(appAccess, visibleDomainGroups, permission);
            } else {
                let result = false;
                for (const p of permissions) {
                    result = calcAccessVisibleDomainGroups(appAccess, visibleDomainGroups, p);
                    if (result) {
                        break;
                    }
                }

                return result;
            }
        },
        [appAccess, visibleDomainGroups, permissions, permission]);

    return hasAccess ? <>{children}</> : (onErrorComponent ? onErrorComponent({permissions}) : false);
}