
export function openDetail(store, {entityType, entity})
{
   if (entity.isNew)
   {
      entity.id = Date.now();
   }

   store.dispatch({
      type: "METEO::MASTER_DETAIL::OPEN",
      payload: { entityType, entity }
   });
}

export function setEditDetail(store, editable)
{
   store.dispatch({
      type: "METEO::MASTER_DETAIL::EDIT",
      payload: {detailEditable: editable}
   });
}

export function markUserChanged(store, userChanged)
{
   store.dispatch({
      type: "METEO::MASTER_DETAIL::USER_CHANGED",
      payload: {userChanged}
   });
}

export function backupForm(store, {form, focusedFieldId})
{
   store.dispatch({
      type: "METEO::MASTER_DETAIL::USER_CHANGED",
      payload: {form, focusedFieldId}
   });
}

export function closeDetail(store)
{
   store.dispatch({
      type: "METEO::MASTER_DETAIL::CLOSE"
   });
}

