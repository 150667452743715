import React from "react"
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useIntl} from "react-intl";
import {formatForId} from "../Utils/Lang/IntlHelper";

export default function AudioErrorDialog({onClose, open}) {
    const intl = useIntl();


    return <Dialog open={open} maxWidth={"xs"} fullWidth={false} scroll={"paper"}>
        <DialogTitle>
            {formatForId(intl, "audioErrorDialog.text")}
        </DialogTitle>

        <DialogActions>
            <Button onClick={onClose} open={open} color="primary" variant="outlined">
                {formatForId(intl, "alertHistory.action.confirm")}
            </Button>
        </DialogActions>
    </Dialog>
}