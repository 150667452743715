import React, {useMemo} from "react";
import {EntitySelectionDialog} from "../../../Components/EntitySelectionDialog";
import {useIntl} from "react-intl";
import {formatForId} from "../../../Utils/Lang/IntlHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faUsers} from "@fortawesome/pro-solid-svg-icons";
import {useGet} from "restful-react";
import _ from "loadsh";
import {usePostMutate} from "../../../Api";
import {useNotification} from "../../../Notification";

export default function UserGroupsDialog({entity, onClose, open})
{
   const {data, loading} = useGet(`/user/${entity.id}/role/groups/list/`);
   const {data: groups, loading: usersLoading} = useGet("/group/list/");

   const selection = useMemo(()=> _.map(data, row=>row.id),[data]);

   const {postData} = usePostMutate(`/user/${entity.id}/role/groups/`);
   const notification = useNotification();

   return <EntitySelectionDialog
      title={<><FontAwesomeIcon icon={faUser} />&nbsp;{entity.name}</>}
      localizationContext={"userGroups"}
      open={open}
      onClose={onClose}
      choices={groups}
      selection={selection}
      onSelect={async (selection)=>{
         await notification.showApiMessage(postData(selection));
         onClose();
      }
      }

   />
}