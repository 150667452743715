import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import React, { useMemo } from "react";
import { RequireAnyPermission } from "../Utils/Permissions/RequireAnyPermission";
import { DomainGroupDevicesView, Views } from "./View/Views";
import { useAppAccess } from "../Utils/Data/hooks/server";
import _ from "loadsh";
import { Card, CardActionArea, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { NoDataMessage } from "../Components/Forms/NoDataMessage";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    text: {
        marginTop: theme.spacing(0.25),
        marginBottom: theme.spacing(0.25),
    },
}));

function DomainLanding({ domainId }) {
    const appAccess = useAppAccess();
    const domain = useMemo(() => _.find(appAccess.domains, (domain) => domain.id === domainId), [appAccess, domainId]);
    const classes = useStyles();

    const map = (list, func) => {
        if (_.isEmpty(list)) {
            return <NoDataMessage />;
        } else {
            return _.map(
                _.filter(list, (g) => g.id > 0),
                func
            );
        }
    };
    const history = useHistory();

    return domain ? (
        <div className={classes.root}>
            {map(domain.device_groups, (group) => (
                <Card key={group.id} className={classes.card}>
                    <CardActionArea
                        onClick={() => {
                            history.push("./group/" + group.id + "/table/");
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" component="h2" className={classes.text}>
                                <FontAwesomeIcon icon={faEye} size={"lg"} className={classes.icon} />
                                &nbsp;{group.name}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}
        </div>
    ) : (
        <NoDataMessage />
    );
}

export default function DomainView() {
    const { id } = useParams();
    const domainId = parseInt(id, 10);
    const { path } = useRouteMatch();
    return (
        <RequireAnyPermission domainID={domainId} permission={"dev__data"}>
            <Switch>
                <Route path={path} exact={true}>
                    <DomainLanding domainId={domainId} />
                </Route>
                <Route path={`${path}/group/:group`}>
                    <DomainGroupDevicesView />
                </Route>
            </Switch>
        </RequireAnyPermission>
    );
}
