import React from "react";
import TextField from "@material-ui/core/TextField";
import { FormControl, FormLabel, TextareaAutosize, useTheme } from "@material-ui/core";

export function CustomTextField({ inputRef, fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete, formatter }) {
    return (
        <FormControl fullWidth required={!!required} {...fieldDef.props?.formControl}>
            <FormLabel component="legend" {...fieldDef.props?.formLabel} disabled={disabled}>
                {label}
            </FormLabel>
            <TextField
                editable={false}
                autoComplete={autoComplete || "off"}
                inputRef={inputRef}
                disabled={disabled}
                type={type}
                variant={"outlined"}
                name={fieldId}
                value={formatter ? formatter(value) : value}
                onChange={changeHandle}
                error={touched && Boolean(errors)}
                helperText={touched && errors}
                {...fieldDef.props?.inputField}
            />
        </FormControl>
    );
}

export function CustomTextAreaField({ inputRef, fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete, formatter }) {
    const theme = useTheme();
    return (
        <FormControl fullWidth required={!!required} {...fieldDef.props?.formControl} disabled={disabled}>
            <FormLabel component="legend" {...fieldDef.props?.formLabel}>
                {label}
            </FormLabel>
            <TextareaAutosize
                style={{ backgroundColor: "transparent", color: disabled ? "grey" : "white", borderRadius: 5, border: "1px solid gray", marginTop: 4 }}
                inputRef={inputRef}
                disabled={disabled}
                variant={"outlined"}
                minRows={20}
                name={fieldId}
                value={formatter ? formatter(value) : value}
                onChange={changeHandle}
                error={touched && Boolean(errors)}
                helperText={touched && errors}
                {...fieldDef.props?.inputField}
            />
        </FormControl>
    );
}
