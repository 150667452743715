import moment from 'moment';
import React, {useState} from 'react'
import {formatDayOfTheWeek, formatTime, formatHoursOnly, formatDayTimeShort} from "../../../Utils/Data/Time";
import {windDirFormatter, windFormatter} from "../../../Utils/Data/ValueMapper";
import _ from "loadsh";
import {formatForId} from "../../../Utils/Lang/IntlHelper";
import {getAlertColor} from "../../../Utils/Data/AlertFormatter";


function minMax(data, min) {
    let valMin = Infinity;
    let valMax = -Infinity;
    if (min) {
        data.forEach((a) => {
            valMin = Math.min(a.y, valMin);
        });
        return valMin;
    } else {
        data.forEach((a) => {
            valMax = Math.max(a.y, valMax);
        });
        return valMax;

    }
}

function getMaxMin(data, xKey) {
    if (_.isEmpty(data))
        return [];

    const result = [];
    const shouldCalcMinimum = (x) => {
        const hour = x.getHours();
        return hour >= 18 || hour < 6;
    }

    const makeBeginPoint = (dataPoint, isMinimum) => ({
        x: dataPoint.x,
        y: dataPoint.y,
        minimum: isMinimum,
        lineColor: isMinimum ? 'rgba(0,0,255,0.25)' : 'rgba(255,0,0,0.25)',
        record: dataPoint.record
    });

    let currentCalc = makeBeginPoint(data[0], shouldCalcMinimum(data[0].time));
    let beginIndex = 0;

    for (let i = 1, len = data.length; i !== len; i++) {
        const dataPoint = data[i];
        const nextCalc = shouldCalcMinimum(dataPoint.time);


        result.push(currentCalc)

        if (currentCalc.minimum !== nextCalc) {
            for (let idx = beginIndex; idx !== i; ++idx) {
                const insertionRecord = data[idx];
                const value = _.clone(currentCalc);
                value.x = insertionRecord.x;
                value.time = insertionRecord.time;
                value.record = insertionRecord.record;
            }

            const finalPoint = _.clone(currentCalc);
            finalPoint.x = dataPoint.x;
            finalPoint.time = dataPoint.time;
            finalPoint.record = dataPoint.record;

            result.push(finalPoint);

            const delimitter = _.clone(finalPoint);
            delimitter.y = null;
            result.push(delimitter);

            currentCalc = makeBeginPoint(dataPoint, nextCalc);
            beginIndex = i;
        } else if (nextCalc) {
            currentCalc.y = Math.min(currentCalc.y, dataPoint.y);
        } else {
            currentCalc.y = Math.max(currentCalc.y, dataPoint.y);
        }
    }

    return result;

}

function fixRange(options, yAxis) {

    let allMax = minMax(options.data[0].dataPoints, false);
    let allMin = minMax(options.data[0].dataPoints, true);

    let roadAllMax = minMax(options.data[options.data.length - 1].dataPoints, false);
    let roadAllMin = minMax(options.data[options.data.length - 1].dataPoints, true);

    const min = Math.min(allMin, roadAllMin);
    const max = Math.max(allMax, roadAllMax);

    const numberOfTicks = ((options.height - options.axisY.labelFontSize * 12) / options.axisY.labelFontSize) | 0;

    const tickSize = Math.max(((max - min) / numberOfTicks) | 0, 2);


    options.axisY2.interval = tickSize;
    options.axisY.interval = tickSize;

    options.axisY.maximum = max + tickSize * 2;
    options.axisY.viewportMaximum = max + tickSize * 2;

    options.axisY2.maximum = max + tickSize * 2;
    options.axisY2.viewportMaximum = max + tickSize * 2;

    if (yAxis === "wind_speed" || yAxis === "precip") {
        options.axisY.minimum = 0;
        options.axisY2.minimum = 0;
        options.axisY.minimum = 0;
        options.axisY2.minimum = 0;
    } else {
        options.axisY.minimum = min - tickSize;
        options.axisY2.minimum = min - tickSize;
        options.axisY.viewportMinimum = min - tickSize;
        options.axisY2.viewportMinimum = min - tickSize;
    }

    if (yAxis === "precip" && min === 0 && max === 0) {

        options.axisY.maximum = 10;
        options.axisY.viewportMaximum = 10;
        options.axisY2.maximum = 10;
        options.axisY2.viewportMaximum = 10;

        options.axisY.minimum = 0;
        options.axisY.viewportMinimum = 0;
        options.axisY2.minimum = 0;
        options.axisY2.viewportMinimum = 0;

    }
}

const WMO_STATE_MAPPING = (function () {
    const result = new Map();
    const MIST = 1;
    const LIQUID = 2;
    const MIXED = 3;
    const SOLID = 4;

    for (const [wx, state] of [
        [0, null],
        [10, MIST],
        [30, MIST],
        [41, LIQUID],
        [47, MIXED],
        [51, LIQUID],
        [52, LIQUID],
        [53, LIQUID],
        [61, LIQUID],
        [62, LIQUID],
        [63, LIQUID],
        [67, MIXED],
        [68, MIXED],
        [71, SOLID],
        [72, SOLID],
        [73, SOLID],
        [77, SOLID],
        [78, SOLID],
        [79, SOLID],
    ]) {
        result.set(wx, state);
    }

    return result;
}());

const WMO_ALERT_MAPPING = (function () {
    const result = new Map();

    for (const [wx, state] of [
        [0, 0],
        [10, 0],
        [30, 1],
        [41, 0],
        [47, 3],
        [51, 0],
        [52, 0],
        [53, 0],
        [61, 0],
        [62, 0],
        [63, 1],
        [67, 1],
        [68, 2],
        [71, 1],
        [72, 2],
        [73, 3],
        [77, 1],
        [78, 2],
        [79, 3],
    ]) {
        result.set(wx, state);
    }

    return result;
}());

const RC_STATE_MAPPING = (function () {
    const result = new Map();
    const LIQUID = 1;
    const MIXED = 2;
    const SOLID = 3;

    for (const [wx, state] of [
        [0, 0],
        [1, 0],
        [2, LIQUID],
        [3, SOLID],
        [4, MIXED],
        [5, LIQUID],
        [6, MIXED],
        [7, SOLID],
        [8, SOLID],
    ]) {
        result.set(wx, state);
    }

    return result;
}());

const RC_ALERT_MAPPING = (function () {
    const result = new Map();

    for (const [wx, state] of [
        [1, 0],
        [2, 0],
        [3, 2],
        [4, 2],
        [5, 1],
        [6, 1],
        [7, 3],
        [8, 3],
    ]) {
        result.set(wx, state);
    }

    return result;
}());


function getWXState(value) {
    if (value === 0) return null;
    let result = WMO_STATE_MAPPING.get(value);
    if (result === null || typeof result === "undefined") {
        result = 5;
    }
    return result;
}

function getWXAlertLevel(value) {
    if (value === 0) return null;
    let result = WMO_ALERT_MAPPING.get(value);
    if (result === null || typeof result === "undefined") {
        result = 0;
    }
    return result;
}


function getRCState(value) {
    if (!value) return 0;
    let result = RC_STATE_MAPPING.get(value);

    if (result === null || typeof result === "undefined") {
        result = 4;
    }
    return result;
}

function getRCAlertLevel(value) {
    if (value === 0) return null;
    let result = RC_ALERT_MAPPING.get(value);
    if (result === null || typeof result === "undefined") {
        result = 0;
    }
    return result;
}

function getWindsSpeedAlertLevel(value) {
    if (value === null || typeof value === "undefined") {
        return 0;
    }

    if (value > 20.7) {
        return 3;
    }

    if (value >= 17.2) {
        return 2;
    }

    if (value >= 10.7) {
        return 1;
    }


    return 0;
}

function formatRCType(intl, value) {
    return formatForId(intl, `value.forecast.road_condition_types.${value}`);
}


function getData(data, yAxis, metadata, info, theme) {
    if (!data) {
        return null;
    }
    let finalData = [];

    for (let i = 0, len = data.length; i !== len; ++i) {
        let yVal = data[i][yAxis];
        if (yAxis === "wind_dir") {
            yVal = yVal % 360;
        }

        if (yAxis === "wind_speed" && metadata.type === "scatter") {

            const level = getWindsSpeedAlertLevel(yVal);
            if (level === 0)
                continue;

            let alertColor = getAlertColor(level, theme)
            finalData.push({
                x: new Date(data[i].time),
                time: new Date(data[i].time),
                y: yVal,
                level: level,
                record: data[i],
                color: alertColor.primary,
                markerBorderColor: "red",
                markerBorderThickness: 1,
                markerSize: 10

            });
            continue;
        }
        if (yAxis === "precip_type") {

            let alertColor = getAlertColor(getWXAlertLevel(yVal), theme)
            finalData.push({
                x: new Date(data[i].time),
                time: new Date(data[i].time),
                y: getWXState(yVal),
                record: data[i],
                color: alertColor.primary

            });

            continue;
        }

        if (yAxis === "road_condition") {
            let alertColor = getAlertColor(getRCAlertLevel(yVal), theme)
            finalData.push({
                x: new Date(data[i].time),
                time: new Date(data[i].time),
                y: getRCState(yVal),
                record: data[i],
                color: _.isEmpty(alertColor) ? "grey" : alertColor.primary

            });

            continue;
        }


        finalData.push({
            x: new Date(data[i].time),
            time: new Date(data[i].time),
            y: yVal,
            record: data[i]
        });
    }
    if (metadata.type === "stepLine" && metadata.xKey === "x") {
        finalData = getMaxMin(finalData, metadata.xKey);
    }
    return finalData;
}

const axisDim = {
    labelFontSize: 14,
    gridThickness: 0.4,
    lineThickness: 0.4,
    tickThickness: 0.4,
};
let roadConditionSpec = null;
export default function getOptions({
                                       intl,
                                       dateFilter,
                                       title,
                                       height,
                                       charts,
                                       forecastData,
                                       yAxis,
                                       theme,
                                       findValueInfo
                                   }) {

    if (!roadConditionSpec) {
        roadConditionSpec = {
            color: null,
            nameFormatted: formatForId(intl, `value.forecast.road_condition`),
            formatter: (val) => formatRCType(intl, val)

        };
    }

    let info = findValueInfo(yAxis);
    if (!info && yAxis === "road_condition") {
        info = roadConditionSpec;
    }

    const options = {
        navigator: {
            enabled: false
        },
        height: height,
        title: {
            text: title,
            fontColor: "#fff",
            fontFamily: theme.typography.fontFamily,
            fontSize: 18,
        },
        animationEnabled: false,
        rangeSelector: {
            enabled: false,
        },
        toolTip: {
            enabled: true,
            shared: true,
            contentFormatter: function (e) {
                const record = e.entries[0].dataPoint.record;
                if (!record) return "";


                var content = "<table>";
                const processed = new Set();
                for (const chart of charts) {
                    const y = chart.yAxis || yAxis;
                    if (processed.has(y)) {
                        continue;
                    } else {
                        processed.add(y);
                    }

                    let myInfo = info;
                    if (chart.yAxis) {
                        myInfo = findValueInfo(chart.yAxis);
                    }

                    let hasName = true;

                    if (yAxis === "road_condition") {
                        if (!info) {
                            info = roadConditionSpec;
                        }
                        if (record[y] === 1 || record[y] === 0) {
                            hasName = false;
                        }

                    }

                    content += `<tr><th>${hasName ? `${myInfo.nameFormatted}:` : ""}</th> <td>${
                        myInfo.hasOwnProperty("graphFormatter") ? myInfo.graphFormatter(record[y]) : myInfo.formatter(record[y])
                    }</td></tr>`;
                }

                if (yAxis === "wind_speed") {
                    const level = getWindsSpeedAlertLevel(record[yAxis]);
                    if (level > 0) {
                        content += `<tr><th>${formatForId(intl, "pages.device.meteogram.alert")}</th> <td>${formatForId(intl, "alert.type.strong_wind." + level)}</td></tr>`
                    }

                }

                content += "</table>";

                content = "<span>" + formatTime(record.time) + "</span>" + content;
                return content;
            }
            },
        axisX: [{
                crosshair: {
                    enabled: true,
                    snapToDataPoint: true,
                    labelBackgroundColor: "white",
                    labelFontColor: "black",
                    color: "white",
                    labelFormatter: (evt) => {
                    return formatDayTimeShort(evt.value)
                }
                },
                lineColor: "#fff",
                labelFontColor: "#fff",
                tickColor: "#fff",
                labelFormatter: (ctx) => {
                    return formatHoursOnly(ctx.value);
                },
                ...axisDim,
                minimum: new Date(dateFilter.value.begin_time),
                maximum: new Date(dateFilter.value.end_time),
                viewportMinimum: new Date(dateFilter.value.begin_time),
                viewportMaximum: new Date(dateFilter.value.end_time),
                interval: 2,
                intervalType: "hour",

            },
            {
                labelFontColor: "#fff",
                lineColor: "transparent",
                tickColor: "transparent",
                labelFormatter: (ctx) => {
                    return formatDayOfTheWeek(ctx.value);
                },
                ...axisDim,
                viewportMinimum: new Date(dateFilter.value.begin_time),
                viewportMaximum: new Date(dateFilter.value.end_time),
                minimum: new Date(dateFilter.value.begin_time),
                maximum: new Date(dateFilter.value.end_time),
                interval: 1,
                intervalType: "day",
                gridColor: "white",

            }],
        axisY: {
            titleFontColor: "#4F81BC",
            lineColor: "#fff",
            labelFontColor: "#fff",
            tickColor: "#fff",
            margin: 24,
            maximum: null,
            ...axisDim,

        },
        axisY2: {
            titleFontColor: "#4F81BC",
            lineColor: "#fff",
            labelFontColor: "#fff",
            tickColor: "#fff",
            margin: 24,
            maximum: null,
            ...axisDim
        },
        legend: {
            fontColor: "white",
            fontSize: 14,
            fontWeight: "lighter"
        },
        data: charts.map((chart) => {
            let myInfo = info;
            if (chart.yAxis) {
                myInfo = findValueInfo(chart.yAxis);
            }

            let color = myInfo.color;
            if (chart.yAxis && chart.yAxis === "gust_speed") {
                    color = "#65aba8"
            }

            return {
                ...chart,
                color: color,
                legendMarkerType: "circle",
                legendMarkerColor: color,
                legendText: myInfo.nameFormatted,
                    dataPoints: getData(forecastData, chart.yAxis || yAxis, chart, info, theme)
                }
            }
        )
    };

    const setupFormatter = () => {
        if (info) {

            options.axisY.labelFormatter = ({value}) => {
                return info.graphFormatter(value);
            }
            options.axisY2.labelFormatter = ({value}) => {
                return info.graphFormatter(value);
        }
        }
    }

    if (yAxis === "temp" && forecastData
    ) {
        fixRange(options, yAxis);
        setupFormatter();
    } else if (yAxis === "wind_dir") {
        const exe = {intl};
        const formatter = (ctx) => {
            const result = windDirFormatter.call(exe, ctx.value, true);
            return result && result.substring(0, result.indexOf("("));
        }

        options.axisY.labelFormatter = formatter;
        options.axisY2.labelFormatter = formatter;
        options.axisY.interval = 45;
        options.axisY2.interval = 45;

    } else if (yAxis === "precip_type") {
        const formatter = (ctx) => {
            return formatForId(intl, "pages.device.meteogram.precip_type." + ctx.value);
        }

        options.axisY.labelFormatter = formatter;
        options.axisY2.labelFormatter = formatter;

        options.axisY.interval = 1;
        options.axisY2.interval = 1;

        options.axisY.minimum = 0;
        options.axisY.maximum = 5;

        options.axisY2.minimum = 0;
        options.axisY2.maximum = 5
    } else if (yAxis === "road_condition") {
        const formatter = (ctx) => {
            return formatForId(intl, "pages.device.meteogram.road_condition_state." + ctx.value);
        }

        options.axisY.labelFormatter = formatter;
        options.axisY2.labelFormatter = formatter;

        options.axisY.interval = 1;
        options.axisY2.interval = 1;

        options.axisY.minimum = 0;
        options.axisY.maximum = 3.5;

        options.axisY2.minimum = 0;
        options.axisY2.maximum = 3.5;
    } else {
        fixRange(options, yAxis);
        setupFormatter();
    }


    return options;
}

