import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppDialogs from "./AppDialogs";

import {AppRouting} from "./AppPages";
import {DisconnectedDialog} from "./Components/DisconnectedDialog";
import AppMenu from "./AppMenu";
import {useAppAccess} from "./Utils/Data/hooks/server";
import _ from "loadsh";
import ReactTooltip from "react-tooltip";
import {sendToWs} from "./Utils/Data/actions/server";
import {useStore} from "react-redux";
import {useInterval} from "rooks";


function useDefaultLoginPage() {
    const appAccess = useAppAccess();
    let loginPage = _.isEmpty(appAccess.default_login_page) ? "/views/" : appAccess.default_login_page;

    if (loginPage.charAt(0) != "/") {
        loginPage = "/" + loginPage;
    }

    return loginPage;
}

function useWindowLocationHref() {
    const [href, setHref] = useState(null);

    useInterval(()=>{
        setHref(window.location.href);
    }, 1000, true);

    return href;
}

function useUrlChange() {
    const store = useStore();
    const href = useWindowLocationHref();

    React.useEffect(() => {
        if (href) {
            sendToWs(store, ["url-changed", href]);
        }
    }, [store, href]);

    useInterval(()=>{
        sendToWs(store, ["url-changed", window.location.href]);
    }, 30000, true);

    return href;
}

export default function App() {
    const pageAfterLogin = useDefaultLoginPage();
    useUrlChange();

    return (
        <>
            <CssBaseline/>
            <AppMenu>
                <Switch>
                    <Redirect exact from='/' to={pageAfterLogin}/>
                    {
                        AppRouting.map(({handler, path}) => <Route key={path} path={path}>{handler}</Route>)
                    }
                </Switch>
            </AppMenu>
            <DisconnectedDialog/>
            <AppDialogs/>
        </>
    )
        ;
}
