import React, {useEffect, useMemo, useState} from "react";
import {DataGrid} from "@material-ui/data-grid";
import _ from "loadsh";
import {CommonDialog} from "./CommonDialog";
import {DialogActions, DialogContent, DialogTitle, IconButton, Button} from "@material-ui/core";
import {
    DialogCloseButton,
    DialogConfirmSelectionCancelButtonsOnSubmit,
    DialogSaveCancelButtonsOnSubmit
} from "./MasterDetail/DialogSaveCancelButtons";
import {makeStyles} from "@material-ui/core/styles";
import useWindowDimensions from "../Utils/Data/hooks/dimensions";
import {formatForId} from "../Utils/Lang/IntlHelper";
import {getLogSeverity} from "../Utils/StringUtils";
import {useIntl} from "react-intl";
import {NoDataMessage, NoDataMessageLocal, OutlinedCard} from "./Forms/NoDataMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDoubleRight, faTrashAlt} from "@fortawesome/pro-solid-svg-icons";
import SearchBox from "./Forms/SearchBox";


const useStyles = makeStyles((theme) => ({
    main: {
        margin: theme.spacing(3),
        display: "flex",
        flexDirection: "column", overflow: "hidden"
    },
    leftSide:
        {

            "& .MuiDataGrid-selectedRowCount":
                {
                    color: "transparent"
                }
        },
    leftSideMessage:
        {
            borderColor: "rgba(81, 81, 81, 1)",
            height: "100%",
        },

    panelContainer:
        {
            display: "grid",
            gridAutoFlow: "column",
            gridAutoColumns: "1fr",
            flexGrow: 1,

        },
    searchContainer:
        {
            display: "flex",
            position: "relative",
            bottom: 45,
            left: 7,
            zIndex: 10,
            maxWidth: "50%"
        },
    panel: {
        height: "100%"
    },
    selectedRow:
        {
            display: "flex",
            flexGrow: 1
        },
    selectedRowTitle:
        {
            flexGrow: 1
        },
    selected: {
        marginBottom: theme.spacing(2)
    }
}));

const defaultSortModel = [{
    field: 'name',
    sort: 'asc',
}];

export function SingleEntitySelectionDialog({title, localizationContext, choices, selection, onSelect, open, onClose}) {
    const classes = useStyles();
    const {height} = useWindowDimensions();
    const intl = useIntl();

    const [filteredChoices, setFilteredChoices] = useState(choices || []);
    const [localSelectionId, setLocalSelectionId] = useState(selection?.id);

    const localSelection = useMemo(() => _.find(choices, row => row.id === localSelectionId), [localSelectionId])

    const [sortModel, setSortModel] = React.useState(defaultSortModel);
    const [filter, setFilter] = React.useState(null);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (_.isEmpty(filter)) {
                setFilteredChoices(choices);
            } else {
                const lcFilter = filter.toLowerCase();
                setFilteredChoices(_.filter(choices, row => row.name.toLowerCase().indexOf(lcFilter) !== -1));
            }
        }, 250);
        return () => clearTimeout(timer);

    }, [filter, choices]);


    React.useEffect(() => {
        setFilter(null);
        setLocalSelectionId(selection?.id);
        setFilteredChoices(choices || []);

    }, [choices, selection]);

    const columns = [{
        sortable: true,
        type: "string",
        field: "name",
        headerName: formatForId(intl, `${localizationContext}.source.name`),
        flex: 1
    }];

    return <CommonDialog fullWidth={true} maxWidth={"lg"} open={open} onClose={onClose}
                         PaperProps={{style: {height: height - 100}}}>
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent className={classes.main}>
            <OutlinedCard
                className={classes.selected}
                title={formatForId(intl, `${localizationContext}.source.selected`)}
                message={localSelection ? localSelection.name : formatForId(intl, `${localizationContext}.source.notSelected`)}/>

            <div className={classes.panelContainer}>
                <div className={classes.panel}>
                    {
                        _.isEmpty(filteredChoices) ?
                            <NoDataMessageLocal className={classes.leftSideMessage} hasFilters={!_.isEmpty(filter)}
                                                onClearFilter={() => setFilter("")}/> :
                            <> <DataGrid
                                checkboxSelection={false}
                                className={classes.leftSide}
                                rows={filteredChoices}
                                columns={columns}
                                autoPageSize={true}
                                disableSelectionOnClick={false}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                disableMultipleColumnsSorting={true}
                                disableMultipleColumnsFiltering={true}
                                density={"compact"}
                                sortModel={sortModel}
                                onSortModelChange={(event) => {
                                    setSortModel(_.isEmpty(event.sortModel) ? defaultSortModel : event.sortModel);
                                }
                                }
                                selectionModel={[localSelectionId]}
                                onSelectionModelChange={(newSelectionModel) => {
                                    const selectionModel = _.filter(newSelectionModel.selectionModel, row=> typeof row !== "undefined");
                                    if (!_.isEmpty(selectionModel))
                                    {
                                        setLocalSelectionId(_.first(selectionModel));
                                    }
                                }}

                            /></>}
                    <div className={classes.searchContainer}>
                        <SearchBox value={filter} onChange={(value) => {
                            if (_.isEmpty(value)) {
                                setFilter("");
                            } else {
                                setFilter(value);
                            }
                        }
                        }/>
                    </div>
                </div>
            </div>

        </DialogContent>
        <DialogActions>
            <DialogConfirmSelectionCancelButtonsOnSubmit onClose={onClose} onSubmit={() => {
                onSelect(localSelection);
            }
            }/>
        </DialogActions>
    </CommonDialog>
}