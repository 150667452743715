export const charts = [
    {
        title: "temp",
        yAxis: "temp",
        charts: [
            {
                axisXIndex: 1,
                axisYType: "secondary",
                type: "line",
                xKey: "label",
                markerType: "none",
                showInLegend: true,

            },

            {
                type: "stepLine",
                xKey: "x",
                markerType: "none",
            }, {
                type: "line",
                yAxis: "road_temp",
                markerType: "none",
                showInLegend: true,
            },
        ]
    },
    {
        title: "precip_type",
        yAxis: "precip_type",
        charts: [{
            type: "scatter",
            markerType: "square",
        }, {
            axisXIndex: 1,
            axisYType: "secondary",
            markerType: "square",
            type: "scatter",
        }]
    },
    {
        title: "road_condition",
        yAxis: "road_condition",
        charts: [{
            type: "scatter",
            markerType: "square",
        }, {
            axisXIndex: 1,
            axisYType: "secondary",
            markerType: "square",
            type: "scatter",
        }]
    },
    {
        title: "precip",
        yAxis: "precip",
        charts: [
            {


                type: "column",
                markerType: "none",
                showInLegend: true,


            },
            {
                axisXIndex: 1,
                axisYType: "secondary",
                yAxis: "new_snow",
                showInLegend: true,
                type: "column",

            }
        ]
    },
    {
        title: "wind_speed",
        yAxis: "wind_speed",
        charts: [
            {
                axisXIndex: 1,
                axisYType: "secondary",
                yAxis: "gust_speed",
                type: "column",
                markerType: "none",
                showInLegend: true,

            },
            {
                type: "scatter",
                markerType: "square",

            },
            {
                type: "line",
                markerType: "none",
                showInLegend: true,
            },
        ]
    },
    {
        title: "wind_dir",
        yAxis: "wind_dir",
        charts: [
            {
                type: "scatter",
                axisXIndex: 1,
                axisYType: "secondary",
                markerType: "circle",
                markerSize: 10,
            },
            {
                type: "scatter",

                markerType: "circle",
                markerSize: 0,

            },

        ]
    },
    {
        title: "pressure",
        yAxis: "pressure",
        charts: [
            {
                type: "line",
                markerType: "none",
            },
            {
                axisXIndex: 1,
                markerType: "none",
                axisYType: "secondary",
                type: "line",


            }
        ]
    },
];

