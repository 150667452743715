
export const LogSeverity = ["debug", "info", "warning", "error"];
export function getLogSeverity(severity)
{
    return LogSeverity[severity];
}

export function formatFmtString(string, args) {
    let index = 0;
    return string.replaceAll("{}", () => {
        const ret = args[index];
        ++index;
        return ret;
    });
}