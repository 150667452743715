import React, { useEffect, useMemo, useState } from "react";
import { LargeDialog, LargeDialogContent } from "../../Icons/LargeDialog";
import { MapContainer } from "react-leaflet";
import BaseTileLayer from "../../Map/BaseTileLayer";
import { DialogTitle, IconButton, useTheme } from "@material-ui/core";
import { NiraAlertLayer } from "../../Map/Nira/VectorGrid/NiraAlertLayer";
import { Friction, useNiraData } from "../../NiraApi";
import VectorGrid from "../../../Pages/View/VectorGrid";
import { NiraDataPanel } from "../../Map/NiraDataPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { setNiraDataPanelEnabled, setSelectedDataType } from "../../../Utils/Data/actions/map";
import { useStore } from "react-redux";
import { useSelectedDataType } from "../../../Utils/Data/hooks/map";
import { pointsToGeoJson } from "../../../Utils/Data/GeoJsonUtils";
import moment from "moment";

function FrictionDataPanel({ niraData }) {
    const store = useStore();
    const [initialDataType, setInitialDataType] = useState(null);
    const selectedDataType = useSelectedDataType();
    useEffect(() => {
        if (!initialDataType) {
            setInitialDataType(selectedDataType);
        }

        setNiraDataPanelEnabled(store, true);
        setSelectedDataType(store, Friction);

        return () => {
            if (initialDataType) {
                setSelectedDataType(store, initialDataType);
            }
        };
    }, [selectedDataType]);

    return (
        <div
            style={{
                position: "absolute",
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
                zIndex: 401, //due to leaflet
                top: 70,
                right: 20,
                pointerEvents: "none",
            }}
        >
            <NiraDataPanel niraData={niraData} frictionOnly={true} />
        </div>
    );
}

function DialogDetail({ open, onClose, area }) {
    const historyDate = useMemo(() => {
        return moment(area.time_last_read);
    }, [area]);

    const niraData = useNiraData(historyDate, area.level);
    const position = [48.5, 19.5];
    const theme = useTheme();

    const geojsonData = useMemo(() => pointsToGeoJson(area, area.level), [area]);

    return (
        <LargeDialog open={open} onClose={onClose} fullWidth maxWidth={"xl"}>
            <IconButton
                color="inherit"
                onClick={onClose}
                style={{
                    position: "absolute",
                    right: theme.spacing(1),
                    top: theme.spacing(1),
                }}
            >
                <FontAwesomeIcon icon={faTimes} />
            </IconButton>

            <DialogTitle>{area.name}</DialogTitle>
            <LargeDialogContent>
                <div style={{ height: "100%", width: "100%", display: "flex", flex: 1 }}>
                    <FrictionDataPanel niraData={niraData} />

                    <MapContainer style={{ height: "80vh", width: "100%" }} minZoom={3} maxZoom={18} zoom={8} fadeAnimation={false} center={position}>
                        <BaseTileLayer selectedMapKey={"nira"} />
                        <NiraAlertLayer initialData={geojsonData} showInfo={false} />
                        <VectorGrid niraData={niraData} />
                    </MapContainer>
                </div>
            </LargeDialogContent>
        </LargeDialog>
    );
}

export function NiraAlertDetailDialog({ open, onClose, onSubmit, area }) {
    if (open) {
        return <DialogDetail open={open} onClose={onClose} area={area} />;
    } else return <> </>;
}
