import React, { useMemo } from "react";
import { useDeviceState } from "../../Utils/Data/hooks/server";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { DeviceNameWithState } from "../DeviceStateIcon";
import SpecialButtonsView, { FORECAST_VIEW_TYPE } from "./SpecialButtonsView";
import { ValuesTable } from "./ValuesTable";
import { MODE } from "./ViewMode";
import StateView, { StateRow, VIEW_TYPE } from "./StateView";
import AlertView, { ALERT_VIEW_TYPE } from "./AlertView";
import CameraView from "./CameraView";
import clsx from "clsx";
import { useDataFieldsOrder } from "../../Utils/Data/hooks/deviceDataView";

const useStyles = makeStyles((theme) => ({
    main: {
        paddingLeft: 8,
        paddingBottom: 6,
        paddingRight: 8,
        paddingTop: 2,
        display: "flex",
        flexDirection: "column",
    },
    header: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: theme.spacing(0.5),
    },
    alertViewWithCamera: {
        minHeight: 60,
        display: "flex",
    },
    alertView: {
        height: 80,
        display: "flex",
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
    divider: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
    },
    pictograms: {
        height: (props) => (props.mode === MODE.small ? 45 : 55),
        width: "100%",
    },
    state: {
        paddingLeft: theme.spacing(0.5),
    },
    alertWithCameraItem: {
        flexBasis: "50%",
    },
    cameraItem: {
        width: "100%",
        height: "100%",
        display: "flex",
    },
    itemPaddingRight: {
        paddingRight: 4,
    },
    itemPaddingLeft: {
        paddingLeft: 4,
    },
    nameWithState: {
        flex: 1,
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        minWidth: 0,
    },
}));

function Padding({ classes }) {
    return <div className={classes.divider} />;
}

function AlertWithCamera({ device, deviceState, camera, classes }) {
    const hasCamera = useMemo(() => device.camera_devices && device.camera_devices.length > 0, [device]);
    return (
        <>
            {camera && hasCamera ? (
                <div className={classes.alertViewWithCamera}>
                    <div className={clsx(classes.itemPaddingRight, classes.alertWithCameraItem)}>
                        <AlertView device={device} deviceState={deviceState} mode={ALERT_VIEW_TYPE.title_only} />
                    </div>
                    <div className={clsx(classes.itemPaddingLeft, classes.alertWithCameraItem)}>
                        <div className={classes.cameraItem}>
                            <CameraView device={device} compact={true} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classes.alertView}>
                    <AlertView device={device} deviceState={deviceState} mode={ALERT_VIEW_TYPE.large} />
                </div>
            )}
            <Padding classes={classes} />
        </>
    );
}

export default function DeviceBlock({ device, mode = MODE.medium, onRenderControl = () => <div />, showDeviceStateTooltip = true, camera = false, square = true, headerStylesOverride = {} }) {
    const styleProps = useMemo(() => {
        return { mode };
    }, [mode]);

    const classes = useStyles(styleProps);
    const deviceState = useDeviceState(device.id);
    const fieldsOrder = useDataFieldsOrder();

    return (
        <Paper square={square} className={classes.main}>
            <div className={classes.header} style={headerStylesOverride}>
                <div className={classes.nameWithState}>
                    <DeviceNameWithState deviceState={deviceState} device={device} showTooltip={showDeviceStateTooltip} size={mode} />
                    {mode === MODE.small && (
                        <div className={classes.state}>
                            <StateRow deviceState={deviceState} device={device} />
                        </div>
                    )}
                </div>
                {onRenderControl()}
            </div>
            <Padding classes={classes} />
            <div className={classes.pictograms}>
                <StateView device={device} deviceState={deviceState} type={mode === MODE.small ? VIEW_TYPE.one_row_warning : VIEW_TYPE.one_row} />
            </div>
            <Padding classes={classes} />
            {mode !== MODE.small && <AlertWithCamera camera={camera} device={device} deviceState={deviceState} classes={classes} />}

            <SpecialButtonsView deviceState={deviceState} device={device} viewType={mode === MODE.small ? FORECAST_VIEW_TYPE.type1x4 : FORECAST_VIEW_TYPE.type1x6} />

            {mode === MODE.large && (
                <div>
                    <Padding classes={classes} />
                    <ValuesTable deviceState={deviceState} mode={mode} fieldsOrder={fieldsOrder} />
                </div>
            )}
        </Paper>
    );
}
