import { FormControl, FormLabel, Typography } from "@material-ui/core";
import { Halign, Valign } from "../../Flex";
import React from "react";
import Button from "@material-ui/core/Button";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faUsers } from "@fortawesome/pro-solid-svg-icons";
import * as PapaParse from "papaparse";

function onFileAdd(onFileLoaded, accept) {
    const input = document.createElement("input");
    input.type = "file";
    if (accept) {
        input.accept = accept;
    }

    input.onchange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file); // Read file as Data URL

            reader.onload = (readerEvent) => {
                const base64Data = readerEvent.target.result.split(",")[1];
                onFileLoaded(file.name, base64Data);
            };
        }
    };
    input.click();
}

export default function FileSelectionField({ inputRef, fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete, formik, localizationContext }) {
    const intl = useIntl();

    const valueParsed = value ? JSON.parse(value) : null;

    return (
        <FormControl fullWidth required={!!required} {...fieldDef.props?.formControl}>
            <FormLabel component="legend" {...fieldDef.props?.formLabel} disabled={disabled}>
                {label}
            </FormLabel>
            <Halign style={{ marginTop: 8, marginBottom: 8 }} horizontal={"flex-start"}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={disabled}
                    onClick={() =>
                        onFileAdd((name, data) => {
                            changeHandle({ target: { name: fieldId, value: JSON.stringify({ name, data }) } });
                        }, fieldDef.props.accept)
                    }
                >
                    <FontAwesomeIcon icon={faFileUpload} /> &nbsp;
                    {formatForId(intl, "FileSelectionField.upload")}
                </Button>
                <FormLabel disabled={disabled} style={{ paddingLeft: 8 }}>
                    {valueParsed?.name ? valueParsed.name : formatForId(intl, "FileSelectionField.noFile")}
                </FormLabel>
            </Halign>
        </FormControl>
    );
}
