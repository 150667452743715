import React, { useMemo } from "react";
import { CustomForm } from "../../../Components/Forms/CustomForm";
import { useMasterDetailFormSave, useOpenedDetailEntity } from "../../../Utils/Data/hooks/masterDetail";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { useStore } from "react-redux";
import { openDialog } from "../../../Utils/Data/actions/gui";
import _ from "loadsh";
import { DEFAULT_SOUND, SOUNDS } from "../../../Utils/Data/Sounds";
import { useGraphValueKeys } from "../../../Utils/Data/ValueMapper";

const formConfig = [
    {
        id: "name",
        type: "TextField",
        props: {
            required: true,
        },
    },
    {
        id: "note",
        type: "TextField",
        props: {},
    },
    {
        id: "is_active",
        type: "CheckboxField",
        props: {},
    },
    {
        id: "default_login_path",
        type: "TextField",
        props: {},
    },
    {
        id: "default_alert_filter",
        type: "AlertFilter",
    },
    {
        id: "sound_alert",
        type: "DropdownField",
        props: {
            multiple: false,
            items: SOUNDS,
            localizationContext: "forms.soundAlert.sound_alerts",
        },
        defaultValue: [DEFAULT_SOUND],
    },
    {
        id: "default_graph_fields",
        type: "DropdownField",
        props: {
            multiple: true,
            localizationContext: "value.meteo_v1.name",
        },
    },
];
export default function UserGroupDetail({ reloadCallback }) {
    const { makeSubmitCallback } = useMasterDetailFormSave("group/");
    const { entity, timeOpened } = useOpenedDetailEntity();
    const store = useStore();
    const graphValueFields = useGraphValueKeys();

    const actions = [
        {
            id: "editRoles",
            onClick() {
                openDialog(store, { type: "userGroupEditRoles", entity: entity });
            },
        },
    ];

    const openedEntityWithCustomProps = useMemo(() => {
        const { default_alert_filter, sound_alert, default_graph_fields } = !_.isEmpty(entity.custom_props)
            ? JSON.parse(entity.custom_props)
            : {
                  default_alert_filter: {
                      filter: [],
                      levelFilter: [],
                  },
                  sound_alert: DEFAULT_SOUND,
                  default_graph_fields: [],
              };

        return {
            ...entity,
            default_alert_filter: default_alert_filter || { filter: [], levelFilter: [] },
            sound_alert,
            default_graph_fields,
        };
    }, [entity]);

    const submitCallback = makeSubmitCallback({
        onBeforeSubmit: (values) => {
            const { default_alert_filter, sound_alert, default_graph_fields } = values;

            values.custom_props = JSON.stringify({
                default_alert_filter,
                sound_alert,
                default_graph_fields,
            });
        },
    });

    const formConfigWithComputedFields = useMemo(
        () =>
            formConfig.map((field) => {
                if (field.id === "default_graph_fields") {
                    return {
                        ...field,
                        props: {
                            ...field.props,
                            items: graphValueFields,
                        },
                    };
                }
                return field;
            }),
        [formConfig, graphValueFields]
    );

    return (
        <>
            {!entity.isNew && <ActionToolbar localizationContext="pages.userGroup" actions={actions} detailToolbar={true} buttonProps={{ variant: "outlined" }} />}
            <CustomForm key={entity.id} timeOpened={timeOpened} formConfig={formConfigWithComputedFields} formData={openedEntityWithCustomProps} onSubmit={submitCallback} formId={"userGroup"} />
        </>
    );
}
