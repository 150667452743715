import { NIRA_ALERT_COLOR_ORANGE, NIRA_ALERT_COLOR_RED } from "../../Components/Map/Nira/Gradient";

export function geoJsonToPoints(geojsonObject) {
    const polygons = [];

    for (const feature of geojsonObject.features) {
        const points = [];

        feature.geometry.coordinates.forEach((coordinate) => {
            coordinate.forEach((point) => {
                points.push({
                    lat: point[1],
                    lon: point[0],
                });
            });
        });
        polygons.push({ points });
    }
    return polygons;
}

export function pointsToGeoJson(area, level = 0) {
    const features = [];

    area.polygons.forEach((polygon) => {
        const coordinates = [];
        polygon.points.forEach((point) => {
            coordinates.push([point.lon, point.lat]);
        });
        features.push({
            type: "Feature",
            properties: {
                name: area.name,
                color: getAlertColor(level),
            },
            geometry: {
                type: "Polygon",
                coordinates: [coordinates],
            },
        });
    });

    return {
        type: "FeatureCollection",
        features: features,
    };
}

function getAlertColor(level, defaultColor = "whitesmoke") {
    switch (level) {
        case 1:
            return NIRA_ALERT_COLOR_ORANGE;
        case 2:
            return NIRA_ALERT_COLOR_RED;
        default:
            return defaultColor;
    }
}

export function alertsToGeoJson(alerts, defaultColor) {
    const features = [];

    alerts.forEach((alert) => {
        alert.polygons.forEach((polygon) => {
            const coordinates = [];
            polygon.points.forEach((point) => {
                coordinates.push([point.lon, point.lat]);
            });
            features.push({
                type: "Feature",
                properties: {
                    name: alert.name,
                    color: getAlertColor(alert.level, defaultColor),
                    alert,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [coordinates],
                },
            });
        });
    });

    return {
        type: "FeatureCollection",
        features: features,
    };
}
