import reduxWebsocket from "@giantmachines/redux-websocket";
import { applyMiddleware, compose, createStore } from "@reduxjs/toolkit";
import reducer from "./reducers";

const reduxWebsocketMiddleware = reduxWebsocket({
    serializer: (payload) => {
        const msg = JSON.stringify(payload);
        console.log("sending msg to ws: ", msg);
        return msg;
    },
    reconnectInterval: 1000,
    reconnectOnClose: true,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(reducer, composeEnhancers(applyMiddleware(reduxWebsocketMiddleware)));
