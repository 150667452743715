import { Tooltip, Typography, useTheme } from "@material-ui/core";
import React, { memo, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import { useStore } from "react-redux";
import { openDialog } from "../../Utils/Data/actions/gui";
import { ALERT_FORECAST_1, ALERT_FORECAST_2, ALERT_FORECAST_3, CONFIRMABLE_ALERTS, formatForecastWarnings, getAlertColor } from "../../Utils/Data/AlertFormatter";
import _ from "loadsh";
import moment from "moment";
import { makeSecurityContext, useHasPermission } from "../../Utils/Permissions/RequireAnyPermission";
import { confirmAlert } from "../../Api";
import { useNotification } from "../../Notification";
import { useHistoryGetData, useIsHistoryActive } from "../../Utils/Data/hooks/server";
import { useSpecialButtonsList } from "../../Utils/Data/hooks/deviceDataView";
import { ALERTS_SPECIAL_BUTTON, CONFIRM_SPECIAL_BUTTON, GRAPH_SPECIAL_BUTTON, METEOGRAM_SPECIAL_BUTTON } from "../../Utils/Data/ViewsDefinitions";
import { faCamera, faChartColumn, faChartLine, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FORECAST_VIEW_TYPE = { "3x2": 1, "2x3": 2, type1x6: 3, type1x4: 4, forecastOnly: 5 };

const ITEM_STYLE = {
    flex: 1,
    flexBasis: "50%",
    margin: 2,
    whiteSpace: "nowrap",
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    wrapGroup: {
        alignItems: "center",
        flex: 1,
        display: "flex",
        flexBasis: "50%",
    },
    emptyItem: {
        ...ITEM_STYLE,
    },
    forecastItem: {
        ...theme.custom.baseClickableDiv,
        ...ITEM_STYLE,
        cursor: "pointer",
        minWidth: (props) => (props.minItemWidth ? props.minItemWidth : "unset"),
    },
    itemIcon: {
        fontSize: "1.4em",
    },
}));

function useActiveForecastAlert(deviceState, level) {
    return useMemo(() => {
        const filtered = deviceState?.active_forecast_warnings?.filter((wrn) => wrn.level === level);
        let newest = {};

        filtered?.forEach((wrn) => {
            if (!newest.begin_time) {
                newest = wrn;
            } else if (wrn.begin_time < newest.begin_time) {
                newest = wrn;
            }
        });

        if (_.isEmpty(newest)) {
            return { forecasts: [] };
        }

        return {
            forecasts: filtered.filter((wrn) => wrn.begin_time === newest.begin_time),
            begin_time: newest.begin_time,
        };
    }, [deviceState?.active_forecast_warnings]);
}

function ItemButton({ onClick, text, tooltip = "", visible = true, icon, iconColor, colors = {}, classes, theme, height }) {
    return visible ? (
        <Tooltip title={tooltip}>
            <div
                className={classes.forecastItem}
                style={{
                    backgroundColor: colors.color ? colors.color : theme.palette.divider,
                    color: colors.textColor ? colors.textColor : "inherit",
                    height: height ? height : ITEM_STYLE.height,
                }}
                onClick={onClick}
            >
                {icon ? (
                    <FontAwesomeIcon icon={icon} className={classes.itemIcon} color={iconColor} />
                ) : (
                    <Typography align={"center"} variant={"h6"}>
                        {text}
                    </Typography>
                )}
            </div>
        </Tooltip>
    ) : (
        <div className={classes.emptyItem}></div>
    );
}

function ForecastItem({ device, deviceState, forecastLevel, viewType, visible, classes, theme, forceRender, itemHeight }) {
    const activeForecast = useActiveForecastAlert(deviceState, forecastLevel);
    const historyDate = useHistoryGetData();
    const time = useMemo(() => {
        if (activeForecast?.begin_time) {
            const ret = Math.ceil(moment.duration(moment(activeForecast?.begin_time).diff(historyDate ? historyDate : moment())).asHours());
            return ret < 1 ? 1 : ret;
        } else return 0;
    }, [activeForecast, historyDate]);
    const store = useStore();
    const intl = useIntl();

    const openAlertDialog = () =>
        openDialog(store, {
            type: "alertHistory",
            entity: { singleDevice: device, defaultSeverity: [forecastLevel] },
        });

    const colors = useMemo(() => {
        if (time <= 0) {
            return {};
        } else {
            const color = getAlertColor(forecastLevel, theme);
            return { color: color.primary, textColor: color.textColor };
        }
    }, [time]);

    const tooltip = useMemo(() => formatForecastWarnings(activeForecast?.forecasts, intl, forecastLevel), [activeForecast]);
    const text = useMemo(() => {
        if (time > 0) {
            if (time === 1) {
                return `< 1h`;
            } else return `${time}h`;
        } else return "";
    }, [time]);

    if (time === 0 && !forceRender) {
        return <></>;
    }

    return <ItemButton onClick={openAlertDialog} text={text} tooltip={tooltip} visible={visible} colors={colors} classes={classes} theme={theme} height={itemHeight} />;
}

function ConfirmButton({ deviceState, intl, device, visible, renderWhenNotConfirmable, historyActive, classes, theme, height }) {
    const notifications = useNotification();
    const context = useMemo(() => makeSecurityContext(device), [device]);

    const hasPermAlertConfirm = useHasPermission({ permission: "dev__alert_confirm", context });

    const confirmableIds = useMemo(
        () =>
            deviceState.active_warnings
                ? deviceState.active_warnings.filter((wrn) => CONFIRMABLE_ALERTS.includes(wrn.level) && wrn.confirmable && _.isEmpty(wrn.confirmation)).map((wrn) => wrn.id)
                : [],
        [deviceState.active_warnings]
    );

    const onConfirm = async () => {
        await notifications.showApiMessage(confirmAlert(confirmableIds));
    };

    const confirmable = visible && hasPermAlertConfirm && !_.isEmpty(confirmableIds) && !historyActive;
    if (!confirmable && renderWhenNotConfirmable) {
        return renderWhenNotConfirmable;
    }

    return (
        <ItemButton
            onClick={onConfirm}
            icon={faCheck}
            text={formatForId(intl, "pages.device.actionsWiew.confirm.label")}
            tooltip={formatForId(intl, "pages.device.actionsWiew.confirm.tooltip")}
            visible={confirmable}
            classes={classes}
            theme={theme}
            height={height}
        />
    );
}

function SpecialButtonsView({ deviceState, device, viewType, minItemWidth, itemHeight, ...props }) {
    const classes = useStyles({ minItemWidth });
    const intl = useIntl();
    const store = useStore();
    const historyActive = useIsHistoryActive();
    const theme = useTheme();

    const buttonPerms = {
        confirm: useHasPermission({
            permission: "dev__alert_confirm",
            context: {
                domainID: device.domain_id,
                groupID: device.group_id,
            },
        }),
        alerts:
            useHasPermission({
                permission: "dev__alert_view",
                context: {
                    domainID: device.domain_id,
                    groupID: device.group_id,
                },
            }) &
            /*binary is correct here*/ useHasPermission({
                permission: "forecast__numeric",
                context: {
                    domainID: device.domain_id,
                    groupID: device.group_id,
                },
            }),
        graph: useHasPermission({
            permission: "dev__data_graph",
            context: {
                domainID: device.domain_id,
                groupID: device.group_id,
            },
        }),
        meteogram: useHasPermission({
            permission: "forecast__numeric",
            context: {
                domainID: device.domain_id,
                groupID: device.group_id,
            },
        }),
    };

    const specialButtons = useSpecialButtonsList();
    const isButtonVisible = (buttonId) => {
        return buttonPerms[buttonId] && specialButtons.indexOf(buttonId) !== -1;
    };

    const MeteogramButton = () => (
        <ItemButton
            onClick={() => openDialog(store, { type: "forecastCharts", entity: device })}
            text={formatForId(intl, "pages.device.actionsWiew.meteogram.label")}
            tooltip={formatForId(intl, "pages.device.actionsWiew.meteogram.tooltip")}
            visible={isButtonVisible(METEOGRAM_SPECIAL_BUTTON)}
            icon={faChartColumn}
            theme={theme}
            classes={classes}
            height={itemHeight}
        />
    );

    const AlertForecast2 = ({ forceRender = true }) => (
        <ForecastItem
            deviceState={deviceState}
            forecastLevel={ALERT_FORECAST_2}
            device={device}
            visible={isButtonVisible(ALERTS_SPECIAL_BUTTON)}
            classes={classes}
            theme={theme}
            forceRender={forceRender}
            itemHeight={itemHeight}
        />
    );
    const AlertForecast3 = ({ forceRender = true }) => (
        <ForecastItem
            deviceState={deviceState}
            forecastLevel={ALERT_FORECAST_3}
            device={device}
            visible={isButtonVisible(ALERTS_SPECIAL_BUTTON)}
            classes={classes}
            theme={theme}
            forceRender={forceRender}
            itemHeight={itemHeight}
        />
    );
    const AlertForecast1 = ({ forceRender = true }) => (
        <ForecastItem
            deviceState={deviceState}
            forecastLevel={ALERT_FORECAST_1}
            device={device}
            visible={isButtonVisible(ALERTS_SPECIAL_BUTTON)}
            classes={classes}
            theme={theme}
            forceRender={forceRender}
            itemHeight={itemHeight}
        />
    );

    const ConfirmBtn = ({ renderWhenNotConfirmable }) => (
        <ConfirmButton
            deviceState={deviceState}
            intl={intl}
            device={device}
            visible={isButtonVisible(CONFIRM_SPECIAL_BUTTON)}
            renderWhenNotConfirmable={renderWhenNotConfirmable}
            historyActive={historyActive}
            classes={classes}
            theme={theme}
            height={itemHeight}
        />
    );

    const ChartsButton = () => (
        <ItemButton
            onClick={() => openDialog(store, { type: "meteogram", entity: device })}
            text={formatForId(intl, "pages.device.actionsWiew.graph.label")}
            tooltip={formatForId(intl, "pages.device.actionsWiew.graph.tooltip")}
            visible={isButtonVisible(GRAPH_SPECIAL_BUTTON)}
            icon={faChartLine}
            iconColor={historyActive ? theme.palette.historyActiveColor : ""}
            theme={theme}
            classes={classes}
            height={itemHeight}
        />
    );

    const CameraButton = () => (
        <ItemButton
            onClick={() =>
                openDialog(store, {
                    type: "cameraView",
                    entity: device,
                })
            }
            text={formatForId(intl, "pages.device.actionsWiew.camera.label")}
            tooltip={formatForId(intl, "pages.device.actionsWiew.camera.tooltip")}
            visible={device?.camera_devices && device.camera_devices.length > 0}
            icon={faCamera}
            theme={theme}
            classes={classes}
            height={itemHeight}
        />
    );

    const Root = ({ children }) => (
        <div className={classes.root} {...props}>
            {children}
        </div>
    );

    const Type3x2 = () => (
        <Root>
            <div className={classes.wrapGroup}>
                <ConfirmBtn />
                <ChartsButton />
            </div>
            <div className={classes.wrapGroup}>
                <MeteogramButton />
                <AlertForecast1 />
            </div>
            <div className={classes.wrapGroup}>
                <AlertForecast2 />
                <AlertForecast3 />
            </div>
        </Root>
    );

    const Type2x3 = () => (
        <Root>
            <div className={classes.wrapGroup}>
                <ConfirmBtn />
                <ChartsButton />
                <MeteogramButton />
            </div>
            <div className={classes.wrapGroup}>
                <AlertForecast1 />
                <AlertForecast2 />
                <AlertForecast3 />
            </div>
        </Root>
    );

    const Type1x6 = () => (
        <Root>
            <div className={classes.wrapGroup}>
                <ConfirmBtn />
                <ChartsButton />
                <MeteogramButton />
                <AlertForecast1 />
                <AlertForecast2 />
                <AlertForecast3 />
            </div>
        </Root>
    );

    const Type1x4 = () => (
        <Root>
            <div className={classes.wrapGroup}>
                <ConfirmBtn renderWhenNotConfirmable={<ChartsButton />} />
                <AlertForecast1 />
                <AlertForecast2 />
                <AlertForecast3 />
            </div>
        </Root>
    );

    const TypeForecastOnly = () => (
        <Root>
            <div className={classes.wrapGroup}>
                <ConfirmBtn renderWhenNotConfirmable={<></>} />
                <ChartsButton />
                <MeteogramButton />
                <CameraButton />
                <AlertForecast1 forceRender={false} />
                <AlertForecast2 forceRender={false} />
                <AlertForecast3 forceRender={false} />
            </div>
        </Root>
    );

    switch (viewType) {
        case FORECAST_VIEW_TYPE["2x3"]:
            return <Type2x3 />;
        case FORECAST_VIEW_TYPE["3x2"]:
            return <Type3x2 />;
        case FORECAST_VIEW_TYPE.type1x6:
            return <Type1x6 />;
        case FORECAST_VIEW_TYPE.type1x4:
            return <Type1x4 />;
        case FORECAST_VIEW_TYPE.forecastOnly:
            return <TypeForecastOnly />;
        default:
            return <div />;
    }
}

export default memo(SpecialButtonsView);
