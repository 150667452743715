import { useStore } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useRadarForecast } from "../../Api";
import { useCurrentOverlayImages, useImageOverlayEnabled, useImageOverlayPaused, useOverlayIndex } from "../../Utils/Data/hooks/map";
import { setCurrentOverlayImages, setOverlayIndex } from "../../Utils/Data/actions/map";
import L from "leaflet";
import _ from "loadsh";
import { ImageOverlay } from "react-leaflet";
import { RADAR_INTERVAL_HOURS } from "./MapUtils";
import { useIntervalWhen } from "rooks";
import { useHasPermission } from "../../Utils/Permissions/RequireAnyPermission";

const IMAGE_REFRESH_TIMEOUT = 600000; //10 minutes

function getLoadedId(url) {
    const params = new URLSearchParams(url.split("?")[1]);
    if (params.has("id")) {
        return parseInt(params.get("id"));
    } else return 0;
}

export default function RadarImageOverlay() {
    const store = useStore();

    const enabled = useImageOverlayEnabled();
    const images = useCurrentOverlayImages();
    const lastIdx = useOverlayIndex();
    const paused = useImageOverlayPaused();
    const [booleanState, setBooleanState] = useState(true);
    const [lastDownloadTime, setLastDownloadTime] = useState(moment().toDate().getTime());
    const [lastImgPausedCnt, setLastImgPausedCnt] = useState(0);
    const [lastPlayedId, setLastPlayedId] = useState(0);

    const [startTime, endTime] = useMemo(() => {
        return [moment().add(-RADAR_INTERVAL_HOURS, "hour").toDate().getTime(), moment().add(RADAR_INTERVAL_HOURS, "hour").toDate().getTime()];
    }, [lastDownloadTime]);

    const { data: radarData } = useRadarForecast({
        shouldLoad: enabled,
        startTime: startTime,
        endTime: endTime,
    });

    useEffect(() => {
        if (radarData) {
            const images = radarData.radar_image_links;
            setCurrentOverlayImages(store, images);
        } else {
            setCurrentOverlayImages(store, []);
        }
    }, [radarData]);

    const setIndex = () => {
        if (moment().toDate().getTime() - lastDownloadTime > IMAGE_REFRESH_TIMEOUT) {
            console.log("refetching radar data");
            // noinspection JSIgnoredPromiseFromCall
            setLastDownloadTime(moment().toDate().getTime());
        }

        if (paused) {
            return;
        }

        if (images.length > 0) {
            if (lastIdx >= images.length - 1) {
                setOverlayIndex(store, 0);
            } else {
                const activeImage = images ? images[lastIdx] : {};
                if (lastIdx !== 0 && activeImage.id !== lastPlayedId) {
                    console.log("radar image was not loaded yet, waiting for it to load", activeImage, lastPlayedId, lastIdx);
                    return;
                }
                setOverlayIndex(store, lastIdx + 1);
            }
        }
    };

    useEffect(() => {
        setIndex();
    }, [images, paused]);

    useIntervalWhen(
        () => {
            if (lastIdx === images.length - 1 && lastImgPausedCnt <= 7) {
                setLastImgPausedCnt(lastImgPausedCnt + 1);
            } else {
                setLastImgPausedCnt(0);
                setIndex();
            }
        },
        300,
        booleanState,
        true
    );

    const bounds = useMemo(() => {
        if (radarData?.location) {
            const apiBounds = radarData.location.bounds;
            return new L.LatLngBounds([apiBounds.position_1.lat, apiBounds.position_1.lon], [apiBounds.position_2.lat, apiBounds.position_2.lon]);
        } else return new L.LatLngBounds([50.7, 23.79], [46.05, 13.6]);
    }, [radarData]);

    const imageOverlayRef = useRef();

    useEffect(() => {
        const activeImage = images ? images[lastIdx] : {};
        if (imageOverlayRef.current && !_.isEmpty(activeImage)) {
            imageOverlayRef.current.setUrl(`${window.location.origin}/api/radar/image/?id=${activeImage.id}`);
        }
    }, [imageOverlayRef, lastIdx]);

    const eventHandlers = useMemo(() => {
        return {
            load(e) {
                setLastPlayedId(getLoadedId(e.target._url));
            },
        };
    }, []);

    const hasPermission = useHasPermission({ permission: "forecast__radar" });

    return enabled && hasPermission ? <ImageOverlay bounds={bounds} url={""} opacity={0.5} ref={imageOverlayRef} eventHandlers={eventHandlers} /> : <></>;
}
