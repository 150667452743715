import { DialogActions, FormControl, FormLabel, InputAdornment, TextField, useTheme } from "@material-ui/core";
import React, { useRef } from "react";
import { DialogConfirmSelectionCancelButtonsOnSubmit } from "../MasterDetail/DialogSaveCancelButtons";
import { LargeDialog, LargeDialogContent, LargeDialogTitle } from "../Icons/LargeDialog";
import BaseTileLayer from "../Map/BaseTileLayer";
import { MapContainer } from "react-leaflet";
import { DrawLayer } from "../Map/Draw/DrawLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";

const geojsonArea = require("@mapbox/geojson-area");

function calculateArea(data) {
    const ret = data?.features?.reduce((acc, feature) => {
        return acc + geojsonArea.geometry(feature.geometry);
    }, 0);
    return ret ? `${(ret / 1000000).toFixed(2)} km2` : "0 km2";
}

export function GeoLocationDialogForm({ inputRef, fieldId, value, changeHandle, disabled, label, fieldDef, required, errors, touched }) {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const theme = useTheme();

    return (
        <FormControl fullWidth {...fieldDef.props?.formControl} required={!!required}>
            <FormLabel component="legend" {...fieldDef.props?.formLabel}>
                {label}
            </FormLabel>

            <TextField
                editable={false}
                inputRef={inputRef}
                disabled={disabled}
                variant={"outlined"}
                name={fieldId}
                error={touched && Boolean(errors)}
                helperText={touched && errors}
                {...fieldDef.props?.inputField}
                onMouseDown={() => {
                    setDialogOpen(true);
                }}
                InputProps={{
                    readOnly: true,
                    endAdornment: disabled ? (
                        <></>
                    ) : (
                        <InputAdornment position={"end"}>
                            <FontAwesomeIcon icon={faEdit} style={{ cursor: "pointer" }} />
                        </InputAdornment>
                    ),
                }}
                value={calculateArea(value)}
            />

            <GeoLocationDialog
                onClose={() => setDialogOpen(false)}
                open={dialogOpen}
                onSubmit={(data) => {
                    if (data && data.features && data.features.length > 0) {
                        changeHandle({
                            target: {
                                name: fieldId,
                                value: data,
                            },
                        });
                    }
                    setDialogOpen(false);
                }}
                data={value}
                name={label}
            />
        </FormControl>
    );
}

function GeoLocationDialog({ open, onClose, onSubmit, name, data }) {
    const position = [48.5, 19.5];
    const drawRef = useRef();

    return (
        <LargeDialog open={open} onClose={onClose} fullScreen>
            <LargeDialogTitle>{name}</LargeDialogTitle>
            <LargeDialogContent>
                <div style={{ height: "100%", width: "100%" }}>
                    <MapContainer style={{ height: "100%", width: "100%" }} minZoom={3} maxZoom={18} zoom={8} fadeAnimation={false} center={position}>
                        <BaseTileLayer selectedMapKey={"bright"} />
                        <DrawLayer ref={drawRef} initialData={data} />
                    </MapContainer>
                </div>
            </LargeDialogContent>
            <DialogActions>
                <DialogConfirmSelectionCancelButtonsOnSubmit onClose={onClose} onSubmit={() => onSubmit(drawRef.current.getData())} />
            </DialogActions>
        </LargeDialog>
    );
}
