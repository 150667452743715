import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDevicesHidden } from "../../Utils/Data/hooks/map";
import { IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { useStore } from "react-redux";
import { useIntl } from "react-intl";
import { setDevicesHidden } from "../../Utils/Data/actions/map";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useViewMapConfig } from "../../Utils/Data/hooks/deviceDataView";
import _ from "loadsh";
import { ButtonWrapper } from "./RadarForecastPanel";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        left: 10,
        zIndex: 401, //due to leaflet
        display: "flex",
        flexDirection: "column",
        width: 48,
        height: 48,
        bottom: 64,
    },
}));

export function HideDevicesPanel({}) {
    const store = useStore();
    const intl = useIntl();

    const devicesHidden = useDevicesHidden();

    const classes = useStyles();
    const viewMapConfig = useViewMapConfig();

    if (_.isEmpty(viewMapConfig)) {
        return (
            <div className={classes.root}>
                <ButtonWrapper>
                    <Tooltip title={devicesHidden ? formatForId(intl, "pages.views.mapView.hideDevices.tooltip.show") : formatForId(intl, "pages.views.mapView.hideDevices.tooltip.hide")}>
                        <IconButton onClick={() => setDevicesHidden(store, !devicesHidden)}>
                            <FontAwesomeIcon icon={devicesHidden ? faEye : faEyeSlash} style={{ fontSize: "1.4em" }} color={"white"} />
                        </IconButton>
                    </Tooltip>
                </ButtonWrapper>
            </div>
        );
    } else {
        return <></>;
    }
}
