import { useIntervalWhen } from "rooks";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useHistoryData, useIsHistoryActive } from "./hooks/server";

export function useHistoryDate() {
    const [historyActive, setHistoryActive] = useState(false);
    const [date, setDate] = useState(moment.now());
    const [refreshCurrentTime, setRefreshCurrentTime] = useState(false);
    const isHistoryActiveGlobal = useIsHistoryActive();

    useHistoryData(historyActive ? date : null);

    useIntervalWhen(
        () => {
            if (refreshCurrentTime && !historyActive && moment(date).minute() !== moment().minute()) {
                setDate(moment.now());
            }
        },
        10000,
        true,
        true
    );

    const handleDateChange = (date) => {
        const now = moment();
        if (moment(date).isAfter(now)) {
            resetHistory();
        } else {
            setDate(date);
            setHistoryActive(true);
        }
    };

    const resetHistory = () => {
        setDate(moment.now());
        setHistoryActive(false);
    };

    const onHistoryExpiration = () => {
        resetHistory();
    };

    useEffect(() => {
        if (!isHistoryActiveGlobal) {
            resetHistory();
        }
    }, [isHistoryActiveGlobal]);

    return {
        historyActive,
        date,
        onHistoryExpiration,
        handleDateChange,
        resetHistory,
        setRefreshCurrentTime,
    };
}
