import React, {useMemo, useState} from "react";

import DeviceBlock from "../../Components/Device/DeviceBlock";
import {makeStyles} from "@material-ui/core/styles";
import {
    useAppFieldsOrder,
    useDataFieldsOrder,
    useDeviceDataActiveViewDevices
} from "../../Utils/Data/hooks/deviceDataView";
import {FixedSizeGrid as Grid} from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import {useTheme} from "@material-ui/styles";
import {MODE} from "../../Components/Device/ViewMode";

const ITEM_WIDTH = 350;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    item: {
        margin: theme.spacing(1),
    }
}));

function Device({device}) {
    const classes = useStyles();
    return <div className={classes.item}>
        <DeviceBlock device={device} mode={MODE.large}/>
    </div>
}

export function ColumnView({}) {
    const devices = useDeviceDataActiveViewDevices();
    const theme = useTheme();

    const [width, setWidth] = useState(0);

    const columnCount = useMemo(() => Math.max(1, Math.floor(width / ITEM_WIDTH * 1.05)), [width]);
    const rowCount = useMemo(() => Math.ceil(devices.length / columnCount), [columnCount]);

    const row_order = useAppFieldsOrder(); //TODO compute height of device
    const fieldsOrder = useDataFieldsOrder();

    const itemHeight = useMemo(() => {
        const valsHeight = (fieldsOrder.length / 2 + 1) * 22.125; //XXX magicNumber
        return valsHeight + 210;
    }, fieldsOrder);

    const Column = (data) => {
        const index = data.columnIndex + data.rowIndex * columnCount;
        if (index > devices.length - 1) {
            return <></>
        }
        const device = devices[index];
        return <div style={{
            ...data.style,
            top: `${parseFloat(data.style.top) + theme.spacing(1)}px`,
            left: `${parseFloat(data.style.left) + theme.spacing(1)}px`
        }}>
            <Device key={device.id} device={device}/>
        </div>
    }
    return <div style={{overflow: "hidden", flexGrow: 1}}>
        <AutoSizer onResize={(data) => setWidth(data.width)}>
            {({height, width}) => <Grid
                columnCount={columnCount}
                columnWidth={ITEM_WIDTH}
                height={height}
                rowCount={rowCount}
                rowHeight={itemHeight}
                width={width}>
                {Column}
            </Grid>
            }
        </AutoSizer>
    </div>
}
