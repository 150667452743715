import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { buildFormValidation } from "../../../Components/Forms/CustomForm";
import * as Yup from "yup";

import { CommonDialog } from "../../../Components/CommonDialog";
import { usePostMutate } from "../../../Api";
import { useNotification } from "../../../Notification";
import { DialogSaveCancelButtons } from "../../../Components/MasterDetail/DialogSaveCancelButtons";
import DialogForm from "../../../Components/Forms/DialogForm";
import { useStore } from "react-redux";
import { reloadList } from "../../../Utils/Data/actions/dataList";
import _ from "loadsh";
import { useSafeOpenDetail } from "../../../Utils/Data/hooks/masterDetail";
import { useIntl } from "react-intl";

const formConfig = [
    {
        id: "email",
        type: "TextField",
        props: {
            required: true,
        },
    },
];

export function CopyUserDialog({ user, onClose, CancelButton }) {
    const { postData } = usePostMutate("/copy-user/");
    const notification = useNotification();
    const submitEventId = React.useMemo(() => user.id + ":" + formId, [user.id]);
    const store = useStore();
    const formId = "copyUser";
    const safeOpenDetail = useSafeOpenDetail();
    const intl = useIntl();

    return (
        <CommonDialog onClose={onClose}>
            <DialogTitle>{formatForId(intl, "forms.copyUser.title")}</DialogTitle>
            <DialogContent>
                <DialogForm
                    key={submitEventId}
                    submitEventId={submitEventId}
                    formData={user}
                    formId={formId}
                    onClose={onClose}
                    formConfig={formConfig}
                    onSubmit={async (values, context) => {
                        const props = JSON.parse(user.custom_props);
                        props.tel_number = "";

                        const response = await notification.showApiMessage(
                            postData({
                                user_id: user.id,
                                email: values.email,
                                custom_props: JSON.stringify(props),
                            })
                        );
                        if (response.status === 200) {
                            onClose();
                            safeOpenDetail("user", {
                                ...user,
                                id: response?.body?.id?.value,
                                name: values.email,
                                login: values.email,
                                email_address: values.email,
                                custom_props: JSON.stringify(props),
                                first_name: "",
                                last_name: "",
                            });
                            reloadList(store);
                        } else if (!_.isEmpty(response?.body?.validation_errors)) {
                            context.setErrors(response?.body?.validation_errors);
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <DialogSaveCancelButtons onClose={onClose} submitEventId={submitEventId} />
            </DialogActions>
        </CommonDialog>
    );
}
