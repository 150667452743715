import { Button, Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import _ from "loadsh";
import { NoDataMessage } from "./NoDataMessage";
import { faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { theme } from "../../index";

function Value({ type, value }) {
    switch (type) {
        case "boolean":
            return <Checkbox disabled={true} checked={value} />;
        case "dateTime":
            return value.toLocaleString();
        default:
            return value;
    }
}

const useStyles = makeStyles((theme) => ({
    clickable: {
        cursor: "pointer",
    },
    cell: {
        padding: theme.spacing(1),
    },
}));

function Row({ row, columnsDef, selected, onSelect }) {
    const classes = useStyles();
    return (
        <TableRow className={classes.clickable} onClick={onSelect} selected={selected}>
            {columnsDef.map((column) => {
                if (!column.hidden) {
                    return (
                        <TableCell style={{ width: column.width || "auto" }} key={column.field} className={classes.cell} key={column.field} align={column.align}>
                            {column.hasOwnProperty("renderCell") ? column.renderCell({ row: row }) : <Value value={row[column.field]} type={column.type} />}
                        </TableCell>
                    );
                }
            })}
        </TableRow>
    );
}

function SortIcon({ isAscending }) {
    return <span style={{ marginLeft: "1em" }}>{isAscending ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />}</span>;
}

export default function MyTable({ columnsDef, rows, selected, setSelected, sortColumn, setSortColumn }) {
    return _.isEmpty(rows) ? (
        <NoDataMessage />
    ) : (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columnsDef.map((column) => {
                            if (!column.hidden) {
                                return (
                                    <TableCell
                                        key={column.field}
                                        onClick={() => {
                                            if (column.sortable && setSortColumn) {
                                                let isAscending = true;
                                                if (sortColumn?.field === column.field) {
                                                    isAscending = !sortColumn.isAscending;
                                                }
                                                setSortColumn({ field: column.field, isAscending: isAscending });
                                            }
                                        }}
                                        align={column.align}
                                    >
                                        {column.sortable ? (
                                            <Button>
                                                <>
                                                    {column.headerName} {sortColumn && sortColumn.field === column.field && <SortIcon isAscending={sortColumn.isAscending} />}
                                                </>
                                            </Button>
                                        ) : (
                                            column.headerName
                                        )}
                                    </TableCell>
                                );
                            }
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows &&
                        rows.map((row) => (
                            <Row
                                key={row.id}
                                row={row}
                                columnsDef={columnsDef}
                                onSelect={() => {
                                    setSelected(row);
                                }}
                                selected={selected?.id === row.id}
                            />
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
