import _ from "underscore";

export function formatForId(intl, id, ...params) {
    return intl.formatMessage({id: id}, ...params);
}

export function formatForServerId(intl, id, ...params) {
    if (/^T:.*/.test(id)) {
        id = id.substring(2);
    }

    return formatForId(intl, id, ...params);
}

export function translateColumns(intl, columns, entity) {
    const result = [];
    for (let col of columns) {
        const clone = _.clone(col);
        const id = "forms." + entity + "." + col.field;

        clone.headerName = intl.formatMessage({id});

        result.push(clone);
    }

    return result;

}