import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { DialogCloseButton } from "../../MasterDetail/DialogSaveCancelButtons";
import React from "react";
import { CommonDialog } from "../../CommonDialog";
import ReactJson from "react-json-view";

export function TrafficStateInfoDialog({ open, onClose, data }) {
    if (!data) {
        return null;
    }

    return (
        <CommonDialog onClose={onClose} open={open}>
            <DialogTitle>{data.name}</DialogTitle>
            <DialogContent>
                <ReactJson
                    src={data}
                    theme={"pop"}
                    style={{
                        padding: 16,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "inherit",
                        overflow: "auto",
                    }}
                />
            </DialogContent>
            <DialogActions>
                <DialogCloseButton onClose={onClose} />
            </DialogActions>
        </CommonDialog>
    );
}
