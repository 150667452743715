import React, { useMemo } from "react";
import MasterDetailView from "../../../Components/MasterDetail/MasterDetailView";
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";
import { useSafeOpenDetail } from "../../../Utils/Data/hooks/masterDetail";
import MasterDetailContextMenu from "../../../Components/MasterDetail/MasterDetailContextMenu";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { DetailContextMenu } from "../../../Components/MasterDetail";
import { useStore } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MasterDetailTable from "../../../Components/MasterDetail/MasterDetailTable";
import { faEye, faSensorAlert } from "@fortawesome/pro-solid-svg-icons";
import { useGetInMemoryDataList } from "../../../Utils/Data/hooks/dataList";
import NiraAlertDetail from "./NiraAlertDetail";

export default function NiraAlerts() {
    const intl = useIntl();
    const store = useStore();

    const columns = useMemo(
        () => [
            {
                field: "name",
                sortable: true,
                width: 200,
                renderCell: (params) => {
                    return (
                        <Button>
                            <FontAwesomeIcon icon={faSensorAlert} />
                            &nbsp;{params.row.name}
                        </Button>
                    );
                },
            },
        ],
        []
    );

    const safeOpenDetail = useSafeOpenDetail();

    const dataSource = useGetInMemoryDataList({
        url: "alert-area/list/",
        defaultSort: { field: "name", isAscending: true },
    });

    return (
        <MasterDetailView
            onRenderContextMenu={({ isDetailOpened, viewMode }) => (
                <MasterDetailContextMenu
                    isDetailOpened={isDetailOpened}
                    viewMode={viewMode}
                    onRenderDetailMenu={() => <DetailContextMenu onRenderBeforeLabel={() => <FontAwesomeIcon icon={faEye} />} />}
                    onRenderMasterMenu={() => (
                        <ActionToolbar
                            localizationContext={"pages.niraAlerts"}
                            actions={[
                                {
                                    id: "create",
                                    onClick() {
                                        safeOpenDetail("niraAlert", {
                                            isNew: true,
                                            is_active: true,
                                        });
                                    },
                                },
                            ]}
                            searchFieldName={"name"}
                        />
                    )}
                />
            )}
            onRenderDetail={() => <NiraAlertDetail />}
        >
            <MasterDetailTable dataSource={dataSource} entityType={"niraAlert"} columns={columns} />
        </MasterDetailView>
    );
}
