import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import {useIntl} from "react-intl";
import {formatForId} from "../../../../Utils/Lang/IntlHelper";
import {CommonDialog} from "../../../../Components/CommonDialog";


export function DeviceValuesDialog({device, onClose, open}) {
    const intl = useIntl();

    return <CommonDialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>{device?.name}</DialogTitle>
        <DialogContent>
            {JSON.stringify(device)}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary" variant="outlined">
                {formatForId(intl, "actions.cancel")}
            </Button>
        </DialogActions>
    </CommonDialog>
}
