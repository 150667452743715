import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Card, CardActions, CardContent, CardHeader, Fab, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { mdiMagnify, mdiPencil, mdiPlus } from "@mdi/js";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { CustomForm } from "../../../Components/Forms/CustomForm";

import { makeStyles } from "@material-ui/core/styles";
import DeviceDetail from "./DeviceDetail";
import _ from "loadsh";
import Icon from "@mdi/react";
import { useDeviceState, useDeviceSubscription, useDomainDeviceGroups } from "../../../Utils/Data/hooks/server";
import { DeviceStateIcon, formatDriverName } from "../../../Components/DeviceStateIcon";
import Button from "@material-ui/core/Button";
import MasterDetailView from "../../../Components/MasterDetail/MasterDetailView";
import { AddIcon, GroupsIcon } from "../../../Components/Icons/ToolbarIcons";
import { useMasterDetailFormSave, useOpenedDetailEntity, useSafeOpenDetail, useUserDataSafeMasterDetailChange } from "../../../Utils/Data/hooks/masterDetail";
import { openDetail } from "../../../Utils/Data/actions/masterDetail";
import { useStore } from "react-redux";
import { DetailContextMenu } from "../../../Components/MasterDetail";
import { openDialog } from "../../../Utils/Data/actions/gui";
import MasterDetailContextMenu from "../../../Components/MasterDetail/MasterDetailContextMenu";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { useDataListFilter, useDataListReloadTime } from "../../../Utils/Data/hooks/dataList";
import { useParams } from "react-router-dom";
import { NoDataMessage } from "../../../Components/Forms/NoDataMessage";
import { DriverInterfaces } from "./DeviceDetail.form";
import { CreateDeleteButtonForList } from "../DeletionDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";

//TODO THIS NEEDS HUGE CLEANUP - MOST FUNCTIONALITY MOVED TO MASTER_DETAIL_LIST
const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    device: {
        backgroundColor: "#18203c",
        marginBottom: "25px",
    },
    cont: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(3),
    },
    addNewGroup: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    table: {
        marginBottom: theme.spacing(3),
        width: "100%",
    },
    addDeviceIcon: {
        marginLeft: "auto",
    },
}));

const deviceGroupFormConfig = [
    {
        id: "name",
        type: "TextField",
        props: {
            required: true,
            min: {
                type: "uint32",
                value: 5,
            },
        },
    },
    {
        id: "note",
        type: "TextField",
        props: {},
    },
];

function GroupDetail({ timeOpened, data, onReload }) {
    const { makeSubmitCallback } = useMasterDetailFormSave("domain/" + data.domain_id + "/device-group/");

    return <CustomForm key={data.id} formConfig={deviceGroupFormConfig} formData={data} timeOpened={timeOpened} onSubmit={makeSubmitCallback({ onSuccess: onReload })} formId={"deviceGroup"} />;
}

function ItemDetail({ onReload }) {
    const { entityType, entity } = useOpenedDetailEntity();
    const driverInterfaces = [DriverInterfaces.MeteoDevice];
    return entityType === "deviceGroup" ? (
        <GroupDetail data={entity} onReload={onReload} />
    ) : (
        <DeviceDetail data={entity} onReload={onReload} driverInterfaces={driverInterfaces} isMasterDriver={false} />
    );
}

function DeviceDetailContextMenu({ entity }) {
    const deviceState = useDeviceState(entity.id);

    const store = useStore();

    return (
        <DetailContextMenu
            onRenderBeforeLabel={() => (
                <Button onClick={() => openDialog(store, { type: "deviceStatus", entity })}>
                    <DeviceStateIcon deviceState={deviceState} device={entity} />
                </Button>
            )}
        />
    );
}

export function DeviceGroupsContextMenu({ domainId, isDetailOpened, viewMode }) {
    const intl = useIntl();
    const store = useStore();
    const { entity, detailEditable, entityType } = useOpenedDetailEntity();
    const { userChanged, confirmWhenNeeded } = useUserDataSafeMasterDetailChange();

    const newGroupDetail = () => {
        confirmWhenNeeded(() =>
            openDetail(store, {
                entityType: "deviceGroup",
                entity: { isNew: true, domain_id: domainId },
            })
        );
    };
    return (
        <MasterDetailContextMenu
            isDetailOpened={isDetailOpened}
            viewMode={viewMode}
            onRenderMasterMenu={() => (
                <ActionToolbar
                    localizationContext={"pages.deviceGroups"}
                    actions={[
                        {
                            id: "addGroup",
                            onClick: newGroupDetail,
                            icon: <AddIcon />,
                        },
                    ]}
                    searchFieldName={"name"}
                />
            )}
            onRenderDetailMenu={() => (entityType === "device" ? <DeviceDetailContextMenu entity={entity} /> : <DetailContextMenu onRenderBeforeLabel={() => <GroupsIcon />} />)}
        />
    );
}

export function DeviceGroups() {
    const intl = useIntl();

    const { id } = useParams();
    const domainId = parseInt(id, 10);

    const [filters] = useDataListFilter();
    const { deviceGroups, refetch: reload, loading, error } = useDomainDeviceGroups(domainId);

    const allDevices = useMemo(() => {
        const devices = [];
        if (deviceGroups) {
            for (const group of deviceGroups) {
                devices.push(...group.devices);
                group._searchName = group.name.toLowerCase();
            }
        }

        for (const dev of devices) {
            dev._searchName = dev.name.toLowerCase() + dev.custom_id;
        }
        return devices;
    }, [deviceGroups]);
    useDeviceSubscription(allDevices);

    const store = useStore();
    const deletionButton = CreateDeleteButtonForList(intl, store, "domain/" + domainId + "/device-group/");

    const searchPredicate = useMemo(() => {
        if (filters) {
            const searchString = filters.value.toLowerCase();
            return (obj) => obj.id > 0 && obj._searchName.indexOf(searchString) >= 0;
        } else {
            return (obj) => obj.id > 0;
        }
    }, [filters]);

    const orderedGroups = useMemo(() => _.filter(_.orderBy(deviceGroups, ["name"]), searchPredicate), [deviceGroups, searchPredicate]);
    const orderedAllDevices = useMemo(() => _.filter(_.orderBy(allDevices, ["name"]), searchPredicate), [allDevices, searchPredicate]);

    const safeOpenDetail = useSafeOpenDetail();
    const reloadTime = useDataListReloadTime();
    React.useEffect(() => {
        if (!loading) {
            reload();
        }
    }, [reloadTime]);

    const openGroupDetail = (row) => {
        safeOpenDetail("deviceGroup", row);
    };

    const { entity, timeOpened, entityType } = useOpenedDetailEntity();

    return (
        <MasterDetailView
            localizationContext="pages.deviceGroups"
            loading={loading}
            loadingError={error}
            icon={<GroupsIcon />}
            onRenderDetail={({ viewMode }) => <ItemDetail viewMode={viewMode} onReload={reload} />}
            onRenderContextMenu={(props) => <DeviceGroupsContextMenu domainId={domainId} {...props} />}
        >
            {filters && !_.isEmpty(orderedAllDevices) && (
                <div style={{ width: "100%" }}>
                    <Device orderedAllDevices={orderedAllDevices} deviceGroups={deviceGroups} onGroupClick={() => openGroupDetail("row")} selected={entityType === "deviceGroup"} />
                </div>
            )}
            <div style={{ width: "100%" }}>
                {_.map(orderedGroups, (row) => (
                    <Group key={row.id} row={row} onGroupClick={() => openGroupDetail(row)} selected={entityType === "deviceGroup" && entity?.id === row.id} deletionButtonSpec={deletionButton} />
                ))}
            </div>

            {_.isEmpty(orderedAllDevices) && _.isEmpty(orderedGroups) && (
                <div style={{ width: "100%" }}>
                    <NoDataMessage />
                </div>
            )}
        </MasterDetailView>
    );
}

function DeviceRow({ device, selected, group, deleteButtonSpec }) {
    const safeOpenDetail = useSafeOpenDetail();
    const deviceState = useDeviceState(device.id);
    const intl = useIntl();
    const openDeviceDetail = (row) => {
        safeOpenDetail("device", row);
    };

    const tableCellProps = { align: "start" };

    return (
        <TableRow onClick={() => openDeviceDetail(device)} selected={selected}>
            <TableCell width={"10%"} {...tableCellProps}>
                <DeviceStateIcon deviceState={deviceState} device={device} />
            </TableCell>
            <TableCell width={"25%"} {...tableCellProps}>
                {_.isEmpty(device.disabled_alerts) ? (
                    device.name
                ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {device.name}
                        <Tooltip title={formatForId(intl, "forms.deviceGroups.disabledAlerts")}>
                            <FontAwesomeIcon style={{ paddingLeft: 8 }} icon={faCircleExclamation} size={"lg"} />
                        </Tooltip>
                    </div>
                )}
            </TableCell>
            {group ? (
                <TableCell width={"25%"} {...tableCellProps}>
                    {group.name}
                </TableCell>
            ) : (
                " "
            )}
            <TableCell width={"25%"} {...tableCellProps}>
                {device.referencedDataSource ? (
                    <span>
                        {" "}
                        {device.referencedDataSource.name} @ {formatDriverName(intl, device.referencedDataSource)}
                    </span>
                ) : (
                    <span>{formatDriverName(intl, device)}</span>
                )}
            </TableCell>
            <TableCell width={"10%"} {...tableCellProps}>
                {deleteButtonSpec.renderCell({ row: device })}
            </TableCell>
        </TableRow>
    );
}

function Device({ orderedAllDevices, deviceGroups, selected, onGroupClick }) {
    const classes = useStyles();
    const intl = useIntl();
    const store = useStore();
    const deleteButtonForList = CreateDeleteButtonForList(intl, store, null, (row) => {
        return "domain/" + row.domain_id + "/device-group/" + row.group_id + "/device/?id=" + row.id;
    });

    return (
        <Card className={classes.device} key={Math.random()} selected={selected}>
            <div className={classes.cont}>
                <Icon path={mdiMagnify} size={1} />
                <CardHeader title={formatForId(intl, "pages.deviceGroups.foundDevices")} />
            </div>
            <CardContent>
                {orderedAllDevices && (
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{formatForId(intl, "forms.deviceGroups.online")}</TableCell>
                                <TableCell>{formatForId(intl, "forms.deviceGroups.name")}</TableCell>
                                <TableCell>{formatForId(intl, "forms.userGroup.name")}</TableCell>
                                <TableCell>{formatForId(intl, "forms.deviceGroups.driver")}</TableCell>
                                <TableCell>{formatForId(intl, "forms.deviceGroups.delete")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderedAllDevices.map((device) => (
                                <DeviceRow key={device.id} device={device} group={deviceGroups.find((d) => d.id === device.group_id)} deleteButtonSpec={deleteButtonForList} />
                            ))}
                        </TableBody>
                    </Table>
                )}
                {!orderedAllDevices && <i>{formatForId(intl, "forms.deviceGroups.noDevice")}</i>}
            </CardContent>
        </Card>
    );
}

function Group({ row, selected, onGroupClick, onDeviceClick, deletionButtonSpec }) {
    const classes = useStyles();
    const intl = useIntl();
    const hasDevices = row.devices.length > 0;
    const { entity, entityType } = useOpenedDetailEntity();
    const { userChanged, confirmWhenNeeded } = useUserDataSafeMasterDetailChange();

    const store = useStore();
    const onAddDevice = () => {
        confirmWhenNeeded(() =>
            openDetail(store, {
                entityType: "device",
                entity: { isNew: true, domain_id: row.domain_id, group_id: row.id },
            })
        );
    };

    const deleteButtonForList = CreateDeleteButtonForList(intl, store, "domain/" + row.domain_id + "/device-group/" + row.id + "/device/");

    return (
        <Card className={classes.root} key={row.name} selected={selected}>
            <CardHeader
                action={
                    <>
                        <IconButton onClick={onGroupClick} title={formatForId(intl, "forms.deviceGroups.editGroup")}>
                            <Icon path={mdiPencil} size={1} />
                        </IconButton>
                        {deletionButtonSpec.renderIconButtonCell({ row })}
                    </>
                }
                title={row.name}
            />
            <CardContent>
                {hasDevices && (
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{formatForId(intl, "forms.deviceGroups.online")}</TableCell>
                                <TableCell>{formatForId(intl, "forms.deviceGroups.name")}</TableCell>
                                <TableCell>{formatForId(intl, "forms.deviceGroups.driver")}</TableCell>
                                <TableCell>{formatForId(intl, "forms.deviceGroups.delete")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {row.devices.map((device) => (
                                <DeviceRow
                                    key={device.id}
                                    device={device}
                                    onDeviceClick={onDeviceClick}
                                    selected={entityType === "device" && entity.id === device.id}
                                    deleteButtonSpec={deleteButtonForList}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}
                {!hasDevices && <i>{formatForId(intl, "forms.deviceGroups.noDevice")}</i>}

                <CardActions>
                    <Fab
                        size={"medium"}
                        color="primary"
                        title={formatForId(intl, "forms.deviceGroups.addDevice")}
                        className={classes.addDeviceIcon}
                        onClick={() => {
                            onAddDevice(row.id);
                        }}
                    >
                        <Icon path={mdiPlus} size={1} />
                    </Fab>
                </CardActions>
            </CardContent>
        </Card>
    );
}
