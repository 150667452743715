import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelectedItem } from "../../Utils/Data/hooks/map";
import _ from "loadsh";
import { useDeviceState } from "../../Utils/Data/hooks/server";
import { ValuesTable } from "../Device/ValuesTable";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronSquareDown } from "@fortawesome/pro-solid-svg-icons";
import { useStore } from "react-redux";
import { setSelectedItem } from "../../Utils/Data/actions/map";
import { DeviceNameWithState } from "../DeviceStateIcon";
import AlertView, { ALERT_VIEW_TYPE } from "../Device/AlertView";
import { StateRow } from "../Device/StateView";
import SpecialButtonsView, { FORECAST_VIEW_TYPE } from "../Device/SpecialButtonsView";
import { useAppFieldsOrder, useDataFieldsOrder } from "../../Utils/Data/hooks/deviceDataView";
import { ALERT_APP_FIELD, SPECIAL_BUTTONS_APP_FIELD, STATE_APP_FIELD } from "../../Utils/Data/ViewsDefinitions";
import useHasScrollbar from "../../Utils/Hooks/UseIsOverflow";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        overflowX: "auto",
        overflowY: "hidden",
    },
    data: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    name: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        left: 0,
        position: "sticky",
        background: theme.palette.background.paper,
        height: "100%",
    },
    beakWrapper: {
        position: "relative",
    },
    closeBeak: {
        position: "absolute",
        right: "calc(50% - 10px)",
        zIndex: 401, //due to leaflet
        bottom: -theme.spacing(1),
    },
    info: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    alert: {
        height: 36,
        width: 42,
        marginRight: 6,
    },
    state: {
        height: 36,
    },
    forecast: {
        marginLeft: 4,
        marginRight: theme.spacing(1),
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // minWidth: 150,
    },
}));

function Data({ device }) {
    const classes = useStyles();
    const deviceState = useDeviceState(device.id);
    const row_order = useAppFieldsOrder();
    const fieldsOrder = useDataFieldsOrder();

    const hasAppField = (key) => true;

    return (
        <div className={classes.data}>
            <div className={classes.name}>
                <DeviceNameWithState deviceState={deviceState} device={device} noWrap={false} textVariant={"body1"} />
            </div>
            <div className={classes.info}>
                {hasAppField(ALERT_APP_FIELD) && (
                    <div className={classes.alert}>
                        <AlertView device={device} deviceState={deviceState} mode={ALERT_VIEW_TYPE.number} />
                    </div>
                )}

                {hasAppField(STATE_APP_FIELD) && (
                    <div className={classes.state}>
                        <StateRow deviceState={deviceState} device={device} itemHeight={15} vertical itemStyles={{ flex: 1 }} />
                    </div>
                )}
                {hasAppField(SPECIAL_BUTTONS_APP_FIELD) && (
                    <div className={classes.forecast}>
                        <SpecialButtonsView device={device} deviceState={deviceState} viewType={FORECAST_VIEW_TYPE.forecastOnly} minItemWidth={42} itemHeight={36} />
                    </div>
                )}
            </div>
            <ValuesTable deviceState={deviceState} horizontal={true} fieldsOrder={fieldsOrder} />
        </div>
    );
}

export function BottomDetailView({}) {
    const classes = useStyles();
    const selectedItem = useSelectedItem();
    const store = useStore();
    const rootRef = useRef();
    const { hasHorizontalScrollbar } = useHasScrollbar(rootRef, [selectedItem]);

    if (_.isEmpty(selectedItem)) {
        return <></>;
    } else {
        return (
            <>
                <div className={classes.beakWrapper}>
                    <div className={classes.closeBeak}>
                        <IconButton onClick={() => setSelectedItem(store, {})}>
                            <FontAwesomeIcon icon={faChevronSquareDown} size={"1x"} color={"black"} />
                        </IconButton>
                    </div>
                </div>

                <div className={classes.root} ref={rootRef} style={{ height: hasHorizontalScrollbar ? 62 : 50 }}>
                    <Data device={selectedItem} />
                </div>
            </>
        );
    }
}
