import React, { useEffect, useMemo, useState } from "react";
import { Chip, Grid, useTheme, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import { confirmAlert, useAlertHistory, useHistoryFilter } from "../../Api";
import { DataGrid, GridCheckIcon } from "@material-ui/data-grid";
import { formatTime } from "../../Utils/Data/Time";
import { makeStyles } from "@material-ui/styles";
import { useNotification } from "../../Notification";
import _ from "loadsh";
import { FastTooltip as Tooltip, useFastTooltipRescan } from "../FastTooltip";
import ColorOptionSelect from "../Forms/ColorOptionSelect";
import {
    ALERT_1,
    ALERT_2,
    ALERT_3,
    ALERT_DEVICE_OFFLINE,
    ALERT_FAILURE,
    ALERT_FORECAST_1,
    ALERT_FORECAST_2,
    ALERT_FORECAST_3,
    ALERT_STATE,
    CONFIRMABLE_ALERTS,
    formatForecastWarnings,
    getAlertColor,
    WarningSeverity,
} from "../../Utils/Data/AlertFormatter";
import LoadingScope from "../LoadingScope";
import { NoDataMessage } from "../Forms/NoDataMessage";
import { makeSecurityContext, useHasPermission } from "../../Utils/Permissions/RequireAnyPermission";
import DateRangePicker from "../Forms/DateRangePicker";
import { Last24HoursType } from "../Forms/DateRangeField/hooks";
import { useAppAccess, useGlobalStateRefresh, useHistoryGetData } from "../../Utils/Data/hooks/server";
import ReactTooltip from "react-tooltip";
import { SimpleOutlinedDeviceQueryField } from "../Forms/GlobalDeviceQueryField";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import {
    AlertGroup,
    AlertHistoryDialogTitle,
    AlertHistoryDialogWrapper,
    AlertHistoryDialogWrapperContent,
    DataGridProps,
    TooltipedColumn,
    translateCols,
    useAlertHistoryStyles,
    useAlertRefresh,
    useLocaleComparator,
} from "../Charts/AlertHistoryDialogWrapper";

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        minWidth: 450,
    },
}))(Tooltip);

const useStylesMetadata = makeStyles((props) => ({
    colHead: {
        textAlign: "left",
        paddingRight: "10px",
    },
    line: {
        width: "230%",
    },
}));

const metadataFields = [
    {
        key: "forecast_type",
        formatter: (value) => `${value}h`,
    },
    {
        key: "creation_time",
        formatter: (value) => formatTime(value, true),
    },
    {
        key: "creation_source",
        formatter: (value) => value,
    },
    {
        key: "end_filled_time",
        formatter: (value) => formatTime(value, true),
    },
    {
        key: "end_filled_source",
        formatter: (value) => value,
    },
];

function ChipMetadataRow({ classes, field, value }) {
    const intl = useIntl();
    return (
        <tr>
            <th className={classes.colHead}>{formatForId(intl, `alert.metadata.${field}`)}</th>
            <td>{value}</td>
        </tr>
    );
}

function ChipMetadata({ children, classes, metadata, severity }) {
    const intl = useIntl();
    if (_.isEmpty(metadata)) {
        return <Tooltip title={formatForId(intl, `pages.device.alert.title.${severity}`)}>{children}</Tooltip>;
    } else {
        return (
            <CustomTooltip
                title={
                    <table>
                        <tbody>
                            {_.map(metadata, (metadataRow, idx) => (
                                <>
                                    {" "}
                                    {_.map(metadataFields, (field) =>
                                        metadataRow[field.key] === null || typeof metadataRow[field.key] === "undefined" ? (
                                            false
                                        ) : (
                                            <ChipMetadataRow key={field.key + ":" + idx} field={field.key} value={field.formatter(metadataRow[field.key])} classes={classes} />
                                        )
                                    )}
                                    {idx + 1 !== metadata.length && <hr key={"d:" + idx} className={classes.line}></hr>}
                                </>
                            ))}
                        </tbody>
                    </table>
                }
            >
                {children}
            </CustomTooltip>
        );
    }
}

const CustomLabelMap = (() => {
    const result = new Map();
    result.set(100, "Off");
    result.set(20, "S");
    result.set(30, "P");
    result.set(ALERT_FORECAST_1, `P${ALERT_FORECAST_1 - 10}`);
    result.set(ALERT_FORECAST_2, `P${ALERT_FORECAST_2 - 10}`);
    result.set(ALERT_FORECAST_3, `P${ALERT_FORECAST_3 - 10}`);
    return result;
})();

function MyChip({ label, color, metadataClasses, metadata, severity }) {
    return (
        <ChipMetadata metadata={metadata} classes={metadataClasses} severity={severity}>
            <Chip label={CustomLabelMap.get(label) || label} style={{ backgroundColor: color.primary, color: color.textColor }} />
        </ChipMetadata>
    );
}

function getAlertsFormatted(intl, alerts, level) {
    if (level === ALERT_FORECAST_1 || level === ALERT_FORECAST_2 || level === ALERT_FORECAST_3) {
        return formatForecastWarnings(
            alerts.map((alert) => ({ type: alert, level: level })),
            intl
        );
    } else {
        return alerts.map((alert) => formatForId(intl, `alert.type.${alert}.name`)).join(", ");
    }
}

function getTechnicalDescFormatted(intl, alerts) {
    return alerts
        .map((alert) => {
            const formatted = formatForId(intl, `alert.type.${alert}.description`);
            return formatted.includes(".description") ? alert : formatted;
        })
        .join(", "); //https://github.com/formatjs/formatjs/pull/3201
}

function ConfirmationColumn({ params, intl, refetch, context }) {
    const confirm = formatForId(intl, "alertHistory.action.confirm");
    const notConfirmed = formatForId(intl, "alertHistory.notConfirmed");

    const notifications = useNotification();

    const hasPermAlertConfirm = useHasPermission({ permission: "dev__alert_confirm", context });

    const onConfirm = async (ids) => {
        await notifications.showApiMessage(confirmAlert(ids));
        refetch();
    };

    return params.value ? (
        <span>
            {`${params.value.user.name}${params.value.auto_confirm ? " *" : ""}`}
            <i style={{ color: "lightgray" }}> {formatTime(params.value.time)}</i>{" "}
        </span>
    ) : CONFIRMABLE_ALERTS.includes(params.row.level) ? (
        hasPermAlertConfirm && params.row.confirmable ? (
            <Button title={confirm} onClick={(event) => onConfirm(params.row.ids)}>
                <GridCheckIcon style={{ marginRight: 4 }} /> {confirm}
            </Button>
        ) : (
            <i style={{ color: "lightgray" }}>{notConfirmed}</i>
        )
    ) : (
        <></>
    );
}

function transformInputFilter(filter, hasPermErrors, hasPermStates, hasPermAlertView, predefinedFilter) {
    if (!filter) {
        if (!_.isEmpty(predefinedFilter)) {
            return _.filter(
                predefinedFilter,
                (filterValue) =>
                    (hasPermAlertView &&
                        (filterValue === ALERT_1 ||
                            filterValue === ALERT_2 ||
                            filterValue === ALERT_3 ||
                            filterValue === ALERT_FORECAST_1 ||
                            filterValue === ALERT_FORECAST_2 ||
                            filterValue === ALERT_FORECAST_3)) ||
                    (hasPermStates && (filterValue === ALERT_STATE || filterValue === ALERT_DEVICE_OFFLINE)) ||
                    (hasPermErrors && filterValue === ALERT_FAILURE)
            );
        } else if (hasPermAlertView) {
            return [ALERT_1, ALERT_2, ALERT_3];
        } else if (hasPermStates) {
            return [ALERT_STATE];
        } else if (hasPermErrors) {
            return [ALERT_FAILURE];
        } else {
            return [];
        }
    }

    return filter;
}

function useDefaultFilter() {
    const { custom_props } = useAppAccess();

    const defaultFilter = useMemo(() => {
        const availableFilters = _.filter(
            _.map(custom_props, (props_json) => JSON.parse(props_json).default_alert_filter),
            (filter) => !_.isEmpty(filter) && (!_.isEmpty(filter.filter) || !_.isEmpty(filter.levelFilter))
        );

        const result = { filter: [], levelFilter: [], key: null };
        for (const filter of availableFilters) {
            if (_.isEmpty(result.filter)) {
                result.filter = filter.filter;
            }
            if (_.isEmpty(result.levelFilter)) {
                result.levelFilter = filter.levelFilter;
            }

            if (!_.isEmpty(result.filter) && !_.isEmpty(result.levelFilter)) {
                break;
            }
        }
        result.key = JSON.stringify(result);
        return result;
    }, [custom_props]);

    return defaultFilter;
}

export function AlertHistoryDialog({ open, onClose, singleDevice, defaultSeverity, confirmationEnabled = true }) {
    const intl = useIntl();
    const historyDate = useHistoryGetData();
    const history = useHistoryFilter(Last24HoursType, historyDate);

    function closeWindow(e) {
        if (e.keyCode === 27) {
            onClose();
        }
    }

    useEffect(() => {
        document.addEventListener("keyup", (e) => closeWindow(e));
        return () => {
            window.removeEventListener("keydown", (e) => closeWindow(e));
        };
    }, []);

    const context = useMemo(() => makeSecurityContext(singleDevice), [singleDevice]);
    const appAccess = useAppAccess();
    const hasPermAlertView = useHasPermission({ permission: "dev__alert_view", context });
    const hasPermStates = useHasPermission({ permission: "dev__states", context });
    const hasPermErrors = useHasPermission({ permission: "dev__errors", context });
    const hasPermTechnicalDesc = useHasPermission({ permission: "dev__alert_history_desc", context });

    const defaultFilter = useDefaultFilter();

    const [filterSeverity, setFilterSeverity] = useState(transformInputFilter(defaultSeverity, hasPermErrors, hasPermStates, hasPermAlertView, defaultFilter.levelFilter));
    const [deviceFilter, setDeviceFilter] = useState([]);
    const filterSeverityFormatted = useMemo(() => filterSeverity.join(","), [filterSeverity]);

    useEffect(() => {
        if (singleDevice) {
            const [domain] = _.filter(appAccess.domains, (domain) => domain.id === singleDevice.domain_id);
            const [group] = _.filter(domain?.device_groups, (group) => group.id === singleDevice.group_id);
            if (domain && group) {
                setDeviceFilter([domain, group, [singleDevice.id]]);
            }
        } else {
            setDeviceFilter(defaultFilter.filter);
        }
    }, [singleDevice, defaultFilter.key]);

    const { data, loading, refetch } = useAlertHistory(open, deviceFilter, filterSeverityFormatted, history, false);

    const globalStateRefresh = useGlobalStateRefresh();

    const alertRefresh = useAlertRefresh(refetch, globalStateRefresh, data, [deviceFilter, history, filterSeverityFormatted]);

    useFastTooltipRescan(data);

    const classes = useAlertHistoryStyles();
    const theme = useTheme();

    const severityFilterOptions = useMemo(
        () =>
            WarningSeverity.filter(
                (severity) =>
                    ((severity === ALERT_1 || severity === ALERT_2 || severity === ALERT_3 || severity === ALERT_FORECAST_1 || severity === ALERT_FORECAST_2 || severity === ALERT_FORECAST_3) &&
                        hasPermAlertView) ||
                    (severity === ALERT_FAILURE && hasPermErrors) ||
                    (severity === ALERT_STATE && hasPermStates) ||
                    (severity === ALERT_DEVICE_OFFLINE && hasPermStates)
            ).map((severity, idx) => ({
                key: severity,
                name: formatForId(intl, `pages.device.alert.title.${severity}`),
                color: getAlertColor(severity, theme)?.primary,
            })),
        [hasPermAlertView, hasPermStates, hasPermErrors]
    );

    const metadataClasses = useStylesMetadata();
    const localeColumComparator = useLocaleComparator();

    const Columns = useMemo(
        () =>
            translateCols(intl, "alertHistory.cols.", [
                {
                    field: "level",
                    renderCell: (params) => {
                        return <MyChip metadataClasses={metadataClasses} metadata={params.row.metadata} label={params.value} color={getAlertColor(params.value, theme)} severity={params.value} />;
                    },
                    type: "boolean",
                    width: 110,
                },
                {
                    field: "device",
                    valueGetter: (row) => row.value.name,
                    width: 300,
                    sortable: true,
                },
                {
                    field: "begin_time",
                    valueFormatter: (row) => {
                        return formatTime(row.value);
                    },
                    width: 210,
                    sortable: true,
                    type: "dateTime",
                },
                {
                    field: "end_time",
                    valueFormatter: (row) => formatTime(row.value),
                    width: 210,
                    sortable: true,
                    type: "dateTime",
                },
                {
                    field: "types",
                    flex: 1,
                    valueGetter: (row) => getAlertsFormatted(intl, row.value, row.row.level),
                    renderCell: (row) => <TooltipedColumn row={row} />,
                    sortComparator: localeColumComparator,
                },
                {
                    field: "technical_desc",
                    flex: 1,
                    valueGetter: (row) => getTechnicalDescFormatted(intl, row.row.types),
                    renderCell: (row) => <TooltipedColumn row={row} />,
                    sortComparator: localeColumComparator,
                    width: 300,
                    sortable: true,
                },
                {
                    field: "confirmation",
                    renderCell: (params) => <ConfirmationColumn refetch={refetch} intl={intl} params={params} context={context} />,
                    width: 270,
                    sortable: true,
                    type: "dateTime",
                },
            ]),
        []
    );

    const VisibleColumns = useMemo(() => {
        let filtered = singleDevice ? _.filter(Columns, (col) => col.field !== "device") : Columns;
        return hasPermTechnicalDesc ? filtered : _.filter(filtered, (col) => col.field !== "technical_desc");
    }, [singleDevice, Columns]);

    return (
        <AlertHistoryDialogWrapper open={open} onClose={onClose} fullWidth={true} scroll={"paper"} fullScreen={true}>
            <AlertHistoryDialogTitle alertRefresh={alertRefresh} icon={faCircleExclamation} intlId={"pages.appBar.tooltip.alerts"} />
            <AlertHistoryDialogWrapperContent onClose={onClose}>
                <ReactTooltip place="bottom" effect={"solid"} />
                <Grid container={true} spacing={1} direction={"row"} alignItems={"center"}>
                    <Grid item={true} xs={12} md={6}>
                        <SimpleOutlinedDeviceQueryField outline={true} dense={true} disableDeviceOrdering={true} value={deviceFilter} onChange={setDeviceFilter} allowUnselectLast={true} />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <DateRangePicker dense={true} value={history.value} disabled={false} onChange={(value) => history.setValue(value)} />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <ColorOptionSelect
                            style={{ width: "100%" }}
                            variant={"outlined"}
                            maring={"normal"}
                            options={severityFilterOptions}
                            value={filterSeverity}
                            onChange={(evt) => {
                                evt.target.value.sort();
                                setFilterSeverity(evt.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <div style={{ height: theme.spacing(2) }}>&nbsp;</div>
                <LoadingScope loading={alertRefresh.showLoading && loading}>
                    {!_.isEmpty(filterSeverityFormatted) && (
                        <>
                            {data.active.length > 0 && history.value.end_time === 0 && (
                                <AlertGroup title={formatForId(intl, "alertHistory.active")} rowCount={data.active.length}>
                                    {data.active.length > 0 && <DataGrid {...DataGridProps} classes={classes} columns={VisibleColumns} rows={data.active} pagination={false} hideFooter />}
                                </AlertGroup>
                            )}
                            {data.notActive.length > 0 && (
                                <AlertGroup title={formatForId(intl, "alertHistory.notActive")} style={{ paddingTop: theme.spacing(1) }}>
                                    <DataGrid
                                        {...DataGridProps}
                                        classes={classes}
                                        columns={VisibleColumns}
                                        rows={data.notActive}
                                        pagination
                                        pageSize={50}
                                        rowsPerPageOptions={[50]}
                                        componentsProps={{
                                            pagination: {
                                                labelRowsPerPage: formatForId(intl, "dataGrid.pagination.rowsPerPage"),
                                                labelDisplayedRows: ({ from, to, count }) =>
                                                    formatForId(intl, "dataGrid.pagination.displayedRows", {
                                                        from,
                                                        to,
                                                        count,
                                                    }),
                                            },
                                        }}
                                    />
                                </AlertGroup>
                            )}
                        </>
                    )}
                    {((_.isEmpty(data.active) && _.isEmpty(data.notActive)) || _.isEmpty(filterSeverityFormatted)) && <NoDataMessage />}
                </LoadingScope>
            </AlertHistoryDialogWrapperContent>
        </AlertHistoryDialogWrapper>
    );
}
