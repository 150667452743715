import {ListItemText, Menu, MenuItem, Tooltip, Typography, useTheme, withStyles} from "@material-ui/core";
import React, {useMemo} from "react";
import {useViewsMenu} from "../../AppPages";
import {Link, useLocation} from "react-router-dom";
import {useIntl} from "react-intl";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import Icon from "@mdi/react";
import {mdiEye} from "@mdi/js";
import _ from "loadsh";
import DesktopOnlyView from "../../Utils/Hooks/DesktopOnlyView";

const StyledMenu = withStyles({
    paper: {
        border: "2px solid rgb(81, 81, 81)",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        minWidth: 200
    },
}))(MenuItem);


const StyledToggleButton = withStyles((theme) => ({
    root: {
        textTransform: "none"
    },
}))(ToggleButton);


export default function ViewsButton({}) {
    const intl = useIntl();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const location = useLocation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const viewItems = useViewsMenu();

    const getViewId = path => {
        if (path && path.startsWith("/views/")) {
            return path.replace(/\D/g, '')
        }
        return path;
    }

    const selectedViewName = useMemo(() => viewItems.find(item => getViewId(item.path) === getViewId(location.pathname))?.name, [location.pathname, viewItems]);
    const isSomeViewSelected = useMemo(() => !_.isEmpty(selectedViewName), [selectedViewName]);

    return <>
        <Tooltip title={formatForId(intl, "pages.main.menu.views")}>
            <ToggleButtonGroup
                style={{marginRight: theme.spacing(2), marginLeft: -theme.spacing(1), height: 38}}>
                <StyledToggleButton
                    selected={isSomeViewSelected}
                    onClick={handleClick}>
                    <Icon path={mdiEye} size={1}/>
                    {isSomeViewSelected &&
                        <DesktopOnlyView>
                            <div style={{paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1)}}>
                                <Typography variant={"body1"} noWrap>{selectedViewName}</Typography>
                            </div>
                        </DesktopOnlyView>
                    }
                </StyledToggleButton>
            </ToggleButtonGroup>
        </Tooltip>
        <StyledMenu
            elevation={0}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {viewItems.map(item => <StyledMenuItem component={Link}
                                                   to={item.path}
                                                   onClick={handleClose}
                                                   key={item.path}
                                                   selected={getViewId(item.path) === getViewId(location.pathname)}>
                <ListItemText key={item.path}>
                    <div style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <Typography variant={"body1"} style={{fontWeight: 500}}>{item.name}</Typography>
                        <Typography variant={"body2"}
                                    style={{marginLeft: theme.spacing(1), marginRight: -theme.spacing(1)}}
                                    color={"textSecondary"}>{item.deviceCount}</Typography>
                    </div>
                </ListItemText>
            </StyledMenuItem>)}

        </StyledMenu>
    </>
}