import React, { useEffect, useMemo, useRef, useState } from "react";

import "../../index.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";

import CustomPopup from "../../Components/Map/EditablePopup";

import { MapContainer, Marker, Tooltip, useMap, useMapEvents, ZoomControl } from "react-leaflet";

import L from "leaflet";
import * as ReactDOMServer from "react-dom/server";
import { useDeviceState } from "../../Utils/Data/hooks/server";
import MarkerClusterGroup from "../../Components/Map/MarkerCluster";
import { useDeviceDataActiveViewDevices, useViewConfig, useViewDefaultNiraField } from "../../Utils/Data/hooks/deviceDataView";
import clsx from "clsx";
import { ALERT_DEVICE_OFFLINE, ALERT_FAILURE, getDeviceStateSeverity } from "../../Utils/Data/AlertFormatter";
import { SingleDeviceView } from "../../Components/Map/SingleDeviceView";
import { ClusterTooltipView, TooltipView } from "../../Components/Map/TooltipView";
import { useStore } from "react-redux";
import { setImageOverlayEnabled, setInsufficientNiraZoom, setNiraDataPanelEnabled, setSelectedDataType, setSelectedItem } from "../../Utils/Data/actions/map";
import { BottomDetailView } from "../../Components/Map/BottomDetailView";
import { BASE_Y_POPUP_OFFSET, SELECTED_COLOR, useIsSelected } from "../../Components/Map/MapUtils";
import { RadarForecastView } from "../../Components/Map/RadarForecastPanel";
import RadarImageOverlay from "../../Components/Map/RadarImageOverlay";
import _ from "loadsh";
import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import queryString from "querystring";
import BaseTileLayer, { DarkMap } from "../../Components/Map/BaseTileLayer";
import VectorGrid from "./VectorGrid";
import { None, useNiraData } from "../../Components/NiraApi";
import { useDevicesHidden, useSelectedMapType } from "../../Utils/Data/hooks/map";
import { HideDevicesPanel } from "../../Components/Map/HideDevicesPanel";
import { usePictograms } from "../../Utils/Data/PictogramAlgorithms";
import { useDisabledAggregation } from "../../Utils/Data/hooks/gui";
import { MapRain, MapSnow } from "../../Components/Icons/PictogramIcons";
import { NiraAlertsLayer } from "../../Components/Map/NiraAlertsLayer";
import { useHasOneOfPermission } from "../../Utils/Permissions/RequireAnyPermission";
import { NiraPerms } from "../../Components/Map/NiraDataPanel";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
    },
    rootColumn: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    map: {
        flex: 1,
    },
    icon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        color: "#ededed",
    },
    clusterDisabledIcon: {
        width: 20,
        height: 20,
    },
    smallIcon: {
        width: 30,
        height: 30,
    },
    largeIcon: {
        width: 40,
        height: 40,
    },
    clusterDisabledIconUnselected: {
        border: "1px solid black",
    },
    iconUnselected: {
        border: "2px solid black",
    },
    iconSelected: {
        border: "3px solid " + SELECTED_COLOR,
    },
    icon_0: {
        background: theme.palette.success.main,
    },
    icon_1: {
        background: theme.palette.warnings.level_1.primary,
        color: theme.palette.warnings.level_1.textColor,
    },
    icon_2: {
        background: theme.palette.warnings.level_2.primary,
        color: theme.palette.warnings.level_2.textColor,
    },
    icon_3: {
        background: theme.palette.warnings.level_3.primary,
        color: theme.palette.warnings.level_3.textColor,
    },
    icon_100: {
        background: theme.palette.warnings.level_100.primary,
        color: theme.palette.warnings.level_100.textColor,
    },
    icon_30: {
        background: theme.palette.warnings.level_30.primary,
        color: theme.palette.warnings.level_30.textColor,
    },
    tooltip: {
        padding: theme.spacing(1),
        margin: -theme.spacing(1),
    },
    pictogramIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.8rem",
    },
}));

const defaultTop = { direction: "top", offset: [0, -25], popupOffset: [-120, BASE_Y_POPUP_OFFSET] };
const left = { direction: "left", offset: [-25, 0], popupOffset: [-250, BASE_Y_POPUP_OFFSET] };
const right = { direction: "right", offset: [25, 0], popupOffset: [30, BASE_Y_POPUP_OFFSET] };
const bottom = { direction: "bottom", offset: [0, 25], popupOffset: [-120, 250] };

function computeTooltipDirection(map, position, offset = 250) {
    const isMarkerInView = map.getBounds().contains(position);

    if (isMarkerInView) {
        const west = map.latLngToLayerPoint(map.getBounds().getSouthWest()).x;
        const east = map.latLngToLayerPoint(map.getBounds().getSouthEast()).x;
        const north = map.latLngToLayerPoint(map.getBounds().getNorthEast()).y;
        const item = map.latLngToLayerPoint(position).x;
        const itemY = map.latLngToLayerPoint(position).y;

        const isLeft = Math.abs(west - item) > offset && Math.abs(east - item) < offset;
        const isRight = Math.abs(west - item) < offset && Math.abs(east - item) > offset;
        const isBottom = Math.abs(north - itemY) < offset;

        if (isLeft) {
            return left;
        } else if (isRight) {
            return right;
        } else if (isBottom) {
            return bottom;
        }
    }
    return defaultTop;
}

function CustomClusterIcon({ cluster, classes, severity, isSelected }) {
    const iconClass = cluster.getChildCount() < 100 ? classes.smallIcon : classes.largeIcon;
    //XXX do not use material here, it breaks styles!!

    return (
        <div className={clsx(classes.icon, iconClass, classes[`icon_${severity}`], isSelected ? classes.iconSelected : classes.iconUnselected)}>
            <div style={{ fontSize: "0.925rem", fontWeight: 500 }}>{cluster.getChildCount()}</div>
        </div>
    );
}

function getDeviceStateSeverityCustom(deviceState) {
    const severity = getDeviceStateSeverity(deviceState);

    if (severity === 0) {
        //MIR-83 show failure color in map
        const hasFailure = deviceState?.active_warnings?.find((alert) => alert.level === ALERT_FAILURE);
        if (hasFailure) {
            return ALERT_FAILURE;
        }
    }

    return severity;
}

const createClusterCustomIcon = (cluster, classes) => {
    let worstSeverity = 0;
    let isSelected = false;
    let items = [];
    const handleCluster = (cluster) => {
        if (!_.isEmpty(cluster._markers)) {
            cluster._markers.forEach((item) => {
                const severity = item.options.icon.options.severity;
                const device = item.options.device;

                items.push({ name: device.name, severity });
                isSelected |= item.options.icon.options.isSelected;

                if (severity > 0) {
                    if (severity === ALERT_DEVICE_OFFLINE) {
                        if (worstSeverity === 0) {
                            worstSeverity = severity;
                        }
                    } else if (severity === ALERT_FAILURE) {
                        if (worstSeverity === 0 || worstSeverity === ALERT_DEVICE_OFFLINE) {
                            worstSeverity = severity;
                        }
                    } else if (severity > worstSeverity || worstSeverity === ALERT_DEVICE_OFFLINE || worstSeverity === ALERT_FAILURE) {
                        worstSeverity = severity;
                    }
                }
            });
        }
        if (!_.isEmpty(cluster._childClusters)) {
            cluster._childClusters.forEach((item) => handleCluster(item));
        }
    };

    handleCluster(cluster);

    const tooltipPosition = computeTooltipDirection(cluster._map, cluster.getBounds().getCenter());

    cluster.unbindTooltip();

    items.sort((a, b) => {
        const transformSeverity = (item) => {
            switch (item.severity) {
                case ALERT_DEVICE_OFFLINE:
                    return 0.5;
                case ALERT_FAILURE:
                    return 0.75;
                default:
                    return item.severity;
            }
        };
        return transformSeverity(a) < transformSeverity(b) ? 1 : -1;
    });

    cluster.bindTooltip(ReactDOMServer.renderToString(<ClusterTooltipView devices={items} />), {
        offset: tooltipPosition.offset,
        direction: tooltipPosition.direction,
    });

    return L.divIcon({
        html: ReactDOMServer.renderToString(<CustomClusterIcon cluster={cluster} classes={classes} severity={worstSeverity} isSelected={isSelected} />),
    });
};

function MarkerStateIcon({ classes, isSelected, clusterDisabled, pictograms, severity }) {
    const PrecipState = () => {
        if (!clusterDisabled) {
            return <></>;
        }
        if (pictograms.isRain) {
            return (
                <div className={classes.pictogramIcon}>
                    <MapRain />
                </div>
            );
        } else if (pictograms.isSnow) {
            return (
                <div className={classes.pictogramIcon}>
                    <MapSnow />
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <div
            className={clsx(
                classes.icon,
                clusterDisabled ? classes.clusterDisabledIcon : classes.smallIcon,
                classes[`icon_${severity}`],
                isSelected ? classes.iconSelected : clusterDisabled ? classes.clusterDisabledIconUnselected : classes.iconUnselected
            )}
        >
            <PrecipState />
        </div>
    );
}

function MarkerState({ device, markerRef, classes, clusterGroupRef, tooltipPosition, clusterDisabled }) {
    const deviceState = useDeviceState(device.id);
    const isSelected = useIsSelected(device.id);
    const pictograms = usePictograms(device, deviceState);
    const severity = getDeviceStateSeverityCustom(deviceState);

    const iconSize = clusterDisabled ? 20 : 30;
    useEffect(() => {
        const icon = L.divIcon({
            html: ReactDOMServer.renderToString(<MarkerStateIcon severity={severity} classes={classes} isSelected={isSelected} clusterDisabled={clusterDisabled} pictograms={pictograms} />),
            iconSize: L.point(iconSize, iconSize, true),
            severity: severity,
            isSelected: isSelected,
        });
        markerRef.current.setIcon(icon);

        if (clusterGroupRef.current && markerRef.current) {
            clusterGroupRef.current.refreshClusters(markerRef.current);
        }
    }, [device, deviceState, isSelected, tooltipPosition]);

    return <></>;
}

function Device({ device, clusterGroupRef, clusterDisabled }) {
    const classes = useStyles();

    const initialPosition = useMemo(() => [device.lat, device.lon], [device]);

    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState(initialPosition);
    const [dragging, setDragging] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState(defaultTop);
    const [popupOffset, setPopupOffset] = useState(defaultTop.popupOffset);
    const [positionRefresh, setPositionRefresh] = useState(true);
    const markerRef = useRef();
    const [deviceDimensions, setDeviceDimensions] = useState({ width: 150, height: 150 });

    useMapEvents({
        moveend: (evt) => {
            const map = evt.target;
            setTooltipPosition(computeTooltipDirection(map, initialPosition, clusterDisabled ? 180 : 250));
        },
    });

    useEffect(() => {
        markerRef.current?.off("click");
        markerRef.current?.on("dblclick", () => (open ? setOpen(false) : markerRef.current?.openPopup()));
        return () => markerRef.current?.off("dblclick");
    }, [markerRef.current, open]);

    useEffect(() => {
        if (markerRef.current) {
            const marker = markerRef.current;

            if (!open && marker?._tooltip?.options) {
                if (clusterDisabled) {
                    marker._tooltip.options.offset = [tooltipPosition.offset[0] / 5, tooltipPosition.offset[1] / 5];
                } else {
                    marker._tooltip.options.offset = tooltipPosition.offset;
                }
                marker._tooltip.options.direction = tooltipPosition.direction;
                setPopupOffset(tooltipPosition.popupOffset);
            }
        }
    }, [tooltipPosition, open, device]);

    return (
        <Marker
            position={initialPosition}
            eventHandlers={{
                popupopen: (e) => {
                    setOpen(true);
                },
                popupclose: (e) => {
                    setOpen(false);
                },
            }}
            device={device}
            ref={markerRef}
        >
            {!clusterDisabled && (
                <CustomPopup
                    open={open}
                    removable={true}
                    editable={false}
                    closeOnClick={false}
                    autoClose={false}
                    autoPan={false}
                    autoPanPadding={[20, 20]}
                    popupPos={position}
                    initialPosition={initialPosition}
                    markerRef={markerRef}
                    onPositionChange={setPosition}
                    onDragStart={() => setDragging(true)}
                    popupDimensions={deviceDimensions}
                    offset={popupOffset}
                    positionRefresh={positionRefresh}
                >
                    <SingleDeviceView
                        device={device}
                        setOpen={setOpen}
                        onDimensionsChange={(dimensions) => setDeviceDimensions(dimensions)}
                        onClickCapture={(e) => {
                            if (dragging) {
                                e.stopPropagation();
                                setDragging(false);
                            }
                        }}
                        open={open}
                    />
                </CustomPopup>
            )}
            <Tooltip>
                <TooltipView device={device} tooltipDirection={tooltipPosition.direction} />
            </Tooltip>
            <MarkerState device={device} markerRef={markerRef} classes={classes} clusterGroupRef={clusterGroupRef} tooltipPosition={tooltipPosition} clusterDisabled={clusterDisabled} />
        </Marker>
    );
}

function MapDevices({ devices, clusterGroupRef, markersInitialized, clusterDisabled }) {
    const map = useMap();
    const devicesHidden = useDevicesHidden();

    useEffect(() => {
        if (devices && devices.length > 0) {
            map.fitBounds([devices.filter((device) => device.lat && device.lon).map((device) => [device.lat, device.lon])], { padding: [15, 15] });
        }
    }, [devices, clusterDisabled]);

    if (devicesHidden) {
        return [];
    } else {
        return devices.map(
            (device) => device.lat && <Device key={"device:" + device.id} device={device} clusterGroupRef={clusterGroupRef} markersInitialized={markersInitialized} clusterDisabled={clusterDisabled} />
        );
    }
}

function ZoomEventListener() {
    const map = useMap();
    const store = useStore();

    const onZoomChanged = (zoom) => {
        console.log("Zoom changed to " + zoom);
        setInsufficientNiraZoom(store, zoom < 9);
    };

    useMapEvents({
        zoomend: (evt) => {
            onZoomChanged(evt.target._zoom);
        },
    });

    return <></>;
}

function MapViewConfigProvider({}) {
    const viewConfig = useViewConfig();
    const map = useMap();

    useEffect(() => {
        if (_.isEmpty(viewConfig) || !_.isEmpty(viewConfig?.data_query?.filter)) {
            return;
        }

        const viewCustomFields = JSON.parse(viewConfig.gui_custom_field ? viewConfig.gui_custom_field : "{}");

        const zoom = viewCustomFields.map_zoom ? viewCustomFields.map_zoom : 9;
        const lat = viewCustomFields.map_lat ? viewCustomFields.map_lat : 48.148598;
        const lon = viewCustomFields.map_lon ? viewCustomFields.map_lon : 17.107748; // bratislava is default
        map.setView(new L.LatLng(lat, lon), zoom);
    }, [viewConfig, map]);

    return <></>;
}

export function MapView({}) {
    const classes = useStyles();
    const devices = useDeviceDataActiveViewDevices();
    const store = useStore();
    const markerRef = useRef();
    const mapContainerRef = useRef();
    const theme = useTheme();
    const location = useLocation();
    const [markersInitialized, setMarkersInitialized] = useState(false);
    const hasNiraPermission = useHasOneOfPermission({ permissions: NiraPerms });

    const niraData = useNiraData();
    const defaultNiraField = useViewDefaultNiraField();
    const mapType = useSelectedMapType();
    const [disabledAggregation] = useDisabledAggregation();

    useEffect(() => {
        if (mapContainerRef.current) {
            mapContainerRef.current._container.style.background = mapType === DarkMap ? "#1C2930" : "#ededed";
        }
    }, [mapContainerRef, mapType]);

    useEffect(() => {
        const params = queryString.parse(location.search.slice(1));

        const enabled = params.radarHistory && parseInt(params.radarHistory, 10) === 1;
        setImageOverlayEnabled(store, enabled);

        return () => {
            setSelectedItem(store, {});
        };
    }, []);

    useEffect(() => {
        setSelectedDataType(store, defaultNiraField);
        if (hasNiraPermission && defaultNiraField !== None) {
            setNiraDataPanelEnabled(store, true);
        }
    }, [defaultNiraField]);

    return (
        <div className={classes.root}>
            <div className={classes.rootColumn}>
                <RadarForecastView niraData={niraData} />
                <MapContainer minZoom={3} maxZoom={18} className={classes.map} whenCreated={() => setMarkersInitialized(true)} fadeAnimation={false} ref={mapContainerRef} zoomControl={false}>
                    <RadarImageOverlay />
                    {hasNiraPermission && <NiraAlertsLayer />}
                    <ZoomControl position={isMobile ? "bottomright" : "topleft"} />
                    <BaseTileLayer />
                    {hasNiraPermission && <VectorGrid niraData={niraData} />}
                    <MarkerClusterGroup
                        animate={false}
                        spiderfyDistanceMultiplier={4}
                        showCoverageOnHover={true}
                        spiderfyOnMaxZoom={true}
                        iconCreateFunction={(cluster) => createClusterCustomIcon(cluster, classes)}
                        onMarkerClick={(marker) => setSelectedItem(store, marker.layer.options.device)}
                        ref={markerRef}
                        maxClusterRadius={disabledAggregation ? 0 : 40}
                        spiderLegPolylineOptions={{ weight: 3, color: theme.palette.background.default, opacity: 1 }}
                    >
                        <MapDevices devices={devices} clusterGroupRef={markerRef} markersInitialized={markersInitialized} clusterDisabled={disabledAggregation} />
                    </MarkerClusterGroup>
                    <ZoomEventListener />
                    <MapViewConfigProvider />
                    <HideDevicesPanel />
                </MapContainer>
                <BottomDetailView />
            </div>
        </div>
    );
}
