import React, { useMemo } from "react";
import LoadingScope from "../../Components/LoadingScope";
import { useLocation, withRouter } from "react-router-dom";
import * as queryString from "querystring";
import MeteogramDialog from "../../Components/Device/Meteogram/MeteogramDialog";
import { useDeviceSubscription, useDomainDeviceGroups } from "../../Utils/Data/hooks/server";
import { setCompactDrawer, setCustomAppBar } from "../../Utils/Data/actions/gui";
import { useStore } from "react-redux";

function StandaloneMeteogram() {
    const location = useLocation();
    const store = useStore();
    const params = queryString.parse(location.search.slice(1));
    const [domainId, groupId, deviceId] = [parseInt(params.domain_id, 10), parseInt(params.group_id, 10), parseInt(params.device_id, 10)];

    const { deviceGroups, refetch: reload, loading, error } = useDomainDeviceGroups(domainId);

    React.useEffect(() => {
        reload();
        setCustomAppBar(store, true);
        setCompactDrawer(store, true);
        return () => setCustomAppBar(store, false);
    }, []);

    const device = useMemo(() => {
        const group = deviceGroups && deviceGroups.find((group) => group.id === groupId);
        if (group && group.devices) {
            return group.devices.find((item) => item.id === deviceId);
        }
    }, [deviceGroups]);

    const devices = useMemo(() => [device], [device]);

    useDeviceSubscription(devices);

    return (
        <LoadingScope loading={loading} error={error}>
            {device ? <MeteogramDialog device={device} onClose={() => {}} open={true} standalone={true} /> : <div />}
        </LoadingScope>
    );
}

export default withRouter(StandaloneMeteogram);
