import { Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { formatForId } from "../../../Utils/Lang/IntlHelper";

import { useNiraSyncStatus } from "../../../Components/NiraApi";
import { ShowError } from "../../../Components/ShowError";
import _ from "lodash";
import { formatDayTimeShort } from "../../../Utils/Data/Time";

function formatNiraSyncStatus(syncStatus) {
    switch (syncStatus) {
        case 0:
            return "waiting";
        case 1:
            return "downloading";
        case 2:
            return "dbWrite";
        default:
            return "unknown";
    }
}
function NiraStatusLine({ value, bundle }) {
    const intl = useIntl();
    const timeFields = _.filter(_.keys(value), (v) => v.endsWith("_time") && v !== "sync_status_time");

    return (
        <Card>
            <CardContent>
                <Typography component="h5" variant="h5">
                    {bundle.replaceAll("_", "-")}
                </Typography>
                {value !== null && (
                    <table>
                        <tr>
                            <th style={{ textAlign: "left" }}>{formatForId(intl, "NiraStatusDialog.field.status")}:&nbsp;</th>
                            <td style={{ textAlign: "right" }}>{formatForId(intl, "NiraStatusDialog.field.statusType." + formatNiraSyncStatus(value.sync_status))}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left" }}>{formatForId(intl, "NiraStatusDialog.field.sync_status_time")}:&nbsp;</th>
                            <td style={{ textAlign: "right" }}>{value.sync_status_time ? formatDayTimeShort(value.sync_status_time) : "--"}</td>
                        </tr>

                        <tr>
                            <th style={{ textAlign: "left" }}>{formatForId(intl, "NiraStatusDialog.field.remaining_tile_request_count")}:&nbsp;</th>
                            <td style={{ textAlign: "right" }}>{value.remaining_tile_request_count > 0 ? value.remaining_tile_request_count : "--"}</td>
                        </tr>

                        {timeFields.map((key) => (
                            <tr>
                                <th style={{ textAlign: "left" }}>{formatForId(intl, "NiraStatusDialog.field." + key)}:&nbsp;</th>
                                <td style={{ textAlign: "right" }}>{value[key] ? formatDayTimeShort(value[key]) : "--"}</td>
                            </tr>
                        ))}
                        <tr>
                            <th style={{ textAlign: "left" }}>{formatForId(intl, "NiraStatusDialog.field.histogram")}:&nbsp;</th>
                            <td>&nbsp;</td>
                        </tr>

                        {_.map(value.response_histogram, (value, key) => (
                            <tr key={key}>
                                <td style={{ textAlign: "left" }}>{key}</td>
                                <td style={{ textAlign: "right" }}>{value}</td>
                            </tr>
                        ))}
                    </table>
                )}
                {value === null && formatForId(intl, "NiraStatusDialog.driverIsStarting")}
            </CardContent>
        </Card>
    );
}

function NiraStatusData({ data }) {
    return _.map(data, (value, key) => <NiraStatusLine key={key} bundle={key} value={value} />);
}

export function NiraStatusDialog({ device, onClose, open }) {
    const intl = useIntl();
    const status = useNiraSyncStatus();
    const [lastError, setLastError] = React.useState(null);

    React.useEffect(() => {
        if (status.error) {
            setLastError(status.error);
        }
    }, [status.error]);

    React.useEffect(() => {
        if (!status.error && !status.loading && status.data) {
            setLastError(null);
        }
    }, [status.loading]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogContent>
                {status.data && <NiraStatusData data={status.data} />}
                {!status.data && status.loading && formatForId(intl, "NiraStatusDialog.loading")}
                {!status.data && lastError && <p>{lastError.message}</p>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" variant="outlined">
                    {formatForId(intl, "actions.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
