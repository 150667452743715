import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import _ from "loadsh";

export const YearType = "year";
export const YearMonthType = "year_month";
export const DateIntervalType = "date_interval";
export const Last8HoursType = "last8";
export const Last24HoursType = "last24";
export const Last36HoursType = "last36";
export const Last7DaysType = "last7";

export function getBeginTime(value, begin) {
    switch (value.type) {
        case YearType:
            return {
                type: value.type,
                begin: begin.clone().startOf("year"),
                end: begin.clone().startOf("year").add(1, "y"),
            };
        case YearMonthType:
            return {
                type: value.type,
                begin: begin.clone().startOf("month"),
                end: begin.clone().startOf("month").add(1, "months"),
            };
        case DateIntervalType:
            const newVal = {
                type: value.type,
                begin: begin.clone().startOf("d"),
                end: value.end,
            };

            if (newVal.begin.isAfter(newVal.end)) {
                newVal.end = newVal.begin.clone().add(1, "days");
            }

            return newVal;
        case Last8HoursType:
            return { type: value.type, begin: value.baseTime.clone().add(-8, "hours").startOf("hours"), end: null };
        case Last24HoursType:
            return { type: value.type, begin: value.baseTime.clone().add(-24, "hours").startOf("hours"), end: null };
        case Last36HoursType:
            return { type: value.type, begin: value.baseTime.clone().add(-36, "hours").startOf("hours"), end: null };
        case Last7DaysType:
            return { type: value.type, begin: value.baseTime.clone().add(-7, "days").startOf("days"), end: null };
    }
}

export function useMaxDate() {
    const getMaxDate = () => moment().add(1, "days").startOf("days");
    const [maxDate, setMaxDate] = useState(() => getMaxDate());
    useEffect(() => {
        const intervalHandle = setInterval(() => {
            setMaxDate(getMaxDate());
        }, 10000);
        return () => clearInterval(intervalHandle);
    }, []);

    return maxDate;
}

export function useDefaultDateRangePickerValue(type, end) {
    return useMemo(() => {
        const endCalc = _.isEmpty(end) ? moment() : end;
        let begin = end;

        switch (type) {
            case Last8HoursType: {
                begin = endCalc.clone().add(-8, "hours").startOf("hours");
                break;
            }
            case Last24HoursType: {
                begin = endCalc.clone().add(-24, "hours").startOf("hours");
                break;
            }
            case Last36HoursType: {
                begin = endCalc.clone().add(-36, "hours").startOf("hours");
                break;
            }
            case Last7DaysType: {
                begin = endCalc.clone().add(-7, "days").startOf("days");
                break;
            }
        }
        return {
            type,
            begin,
            baseTime: endCalc,
            end: end,
        };
    }, [end]);
}

export function useDateRangePickerLogic({ value, onChange, maxDate }) {
    const handleIntervalChange = (interval) => {
        if (interval.begin.isAfter(interval.end)) {
            const temp = interval.begin;
            interval.begin = interval.end;
            interval.end = temp;
        }

        if (interval.end.isAfter(maxDate)) {
            interval.end = maxDate;
        }
    };

    return {
        onBeginChange(begin) {
            onChange(getBeginTime(value, begin));
        },
        onEndChange(end) {
            const newVal = {
                type: value.type,
                begin: value.begin,
                end: end.clone().startOf("d"),
                baseTime: value.baseTime,
            };

            if (newVal.begin.isAfter(newVal.end)) {
                newVal.begin = newVal.end.clone().add(-1, "days");
            }

            onChange(newVal);
        },
        onModeSelectionChanged(evt) {
            const newValue = _.clone(value);
            newValue.type = evt.target.value;
            if (newValue.type === "date_interval") {
                if (!newValue.end) {
                    newValue.end = maxDate;
                }
                handleIntervalChange(newValue);
            }

            onChange(getBeginTime(newValue, newValue.begin));
        },
    };
}
