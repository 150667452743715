import {Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@material-ui/core";
import LoadingScope from "../../../Components/LoadingScope";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import React, {useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import ActionButtons from "../../../Components/MasterDetail/ActionButtons";
import {useIntl} from "react-intl";
import {formatForId, translateColumns} from "../../../Utils/Lang/IntlHelper";
import { OutlinedCard} from "../../../Components/Forms/NoDataMessage";
import {formatFmtString, getLogSeverity} from "../../../Utils/StringUtils";
import MyTable from "../../../Components/Forms/MyTable";
import {RoleIcon} from "../../../Components/Icons/ToolbarIcons";


function NoDriverData()
{
   const intl = useIntl();

   return <OutlinedCard title={formatForId(intl, "forms.deviceDetail.test.noData")}
                 message={formatForId(intl, "forms.deviceDetail.test.noDataMessage")} />
}



export default function DeviceTestResultDialog({data, onClose})
{

   const intl = useIntl();
   const columns = useMemo(()=> translateColumns(intl, [
      {
         field: 'severity', width: 100, sortable: false,
         renderCell: ({row}) => <span>{formatForId(intl, `log.severity.${getLogSeverity(row.severity)}`)}</span>
      },
      {
         field: "message",
         width: 400,
         renderCell: ({row}) => <span>{formatFmtString(row.message, row.tokens)}</span>
      }], "deviceDetail.test"), []);

   const explanation= ()=>{
      const key = `forms.deviceDetail.test.status${data.status}`;
      const message = formatForId(intl, key);
      if (key !== message)
      {
         return `[${message}]`;
      }
      else {
         return "";
      }
   }

   return <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>{formatForId(intl, "forms.deviceDetail.test.title")}</DialogTitle>
      <DialogContent>
         <pre>{formatForId(intl, "forms.deviceDetail.test.statusCode")}: {data.status} {explanation()}</pre>
         {data.body && data.body.length > 0 ? <MyTable rows={data.body} columnsDef={columns} />
            :  <NoDriverData />}
      </DialogContent>
      <DialogActions>
         <ActionButtons localizationContext={"form"} actions={[{
            id: "close",
            onClick: onClose,
            icon: <><FontAwesomeIcon icon={faTimes}/>&nbsp;</>,
            props: {color: "secondary"}
         } ]} />
      </DialogActions>
   </Dialog>
}