import React, {useMemo, useState} from "react";
import {DataGrid} from "@material-ui/data-grid";
import _ from "loadsh";
import {CommonDialog} from "./CommonDialog";
import {DialogActions, DialogContent, DialogTitle, IconButton, Button} from "@material-ui/core";
import {DialogCloseButton, DialogSaveCancelButtonsOnSubmit} from "./MasterDetail/DialogSaveCancelButtons";
import {makeStyles} from "@material-ui/core/styles";
import useWindowDimensions from "../Utils/Data/hooks/dimensions";
import {formatForId} from "../Utils/Lang/IntlHelper";
import {getLogSeverity} from "../Utils/StringUtils";
import {useIntl} from "react-intl";
import {NoDataMessage, NoDataMessageLocal, OutlinedCard} from "./Forms/NoDataMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDoubleRight, faTrashAlt} from "@fortawesome/pro-solid-svg-icons";
import SearchBox from "./Forms/SearchBox";


const useStyles = makeStyles((theme) => ({
    main: {
        margin: theme.spacing(3),
        display: "flex",
        flexDirection: "column", overflow: "hidden"
    },
    leftSide:
        {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            borderRight: "none",
            "& .MuiDataGrid-selectedRowCount":
                {
                    color: "transparent"
                }
        },
    leftSideMessage:
        {
            borderColor: "rgba(81, 81, 81, 1)",
            height: "100%",
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            borderRight: "none"
        },
    rightSide:
        {
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
        },
    rightSideMessage:
        {
            borderColor: "rgba(81, 81, 81, 1)",
            height: "100%",
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
        },
    panelContainer:
        {
            display: "grid",
            gridAutoFlow: "column",
            gridAutoColumns: "1fr",
            flexGrow: 1,

        },
    searchContainer:
        {
            display: "flex",
            position: "relative",
            bottom: 45,
            left: 7,
            zIndex: 10,
            maxWidth: "50%"
        },
    panel: {
        height: "100%"
    },
    selectedRow:
        {
            display: "flex",
            flexGrow: 1
        },
    selectedRowTitle:
        {
            flexGrow: 1
        }
}));

const defaultSortModel = [{
    field: 'name',
    sort: 'asc',
}];

function useParamSettableState(defaultValue) {
    const state = useState(defaultValue);
    React.useEffect(() => {
        state[1](defaultValue);
    }, [defaultValue]);

    return state;
}

function useDelayedMemo(fun, deps, time)
{
    const [state, setState] = useState(null);
    React.useEffect(()=>{
        const timer = setTimeout(()=>{
            setState(fun());
        }, time);

        return ()=> clearTimeout(timer);
    }, deps);
    return state;
}

const EMTPY_ARRAY = [];

function useFilterModel()
{
    const [filter, setFilter] = React.useState(null);
    const filterModel = useDelayedMemo(()=> _.isEmpty(filter)? {items:[]}:{items:[{columField: "name", operatorValue: "contains", value: filter}]},
        [filter], 100);

     return [filter, setFilter, filterModel];
}

export function EntitySelectionDialog({title, localizationContext, choices, selection, onSelect, open, onClose}) {
    const classes = useStyles();
    const {height} = useWindowDimensions();
    const intl = useIntl();

    const index = useMemo(() => {
        if (!choices)
        {
            return null;
        }
        const r = new Map();
        for (const entity of choices) {
            r.set(entity.id, entity);
            }
        return r;
        }, [choices]);

    const [localSelection, setLocalSelection] = useParamSettableState(selection || EMTPY_ARRAY);
    const selectedRows = useMemo(() => index === null? []: _.filter(_.map(localSelection, id => index.get(id)), val => typeof val !== "undefined") , [localSelection, index]);

    const [sortModel, setSortModel] = React.useState(defaultSortModel);
    const [filter, setFilter, filterModel] = useFilterModel();
    const [rightFilter, setRightFilter, rightFilterModel] = useFilterModel();


    React.useEffect(() => {
        setFilter(null);
        setRightFilter(null);
    }, [choices, selection]);

    const unselectRow = (row) => {
        setLocalSelection(_.without(localSelection, row.id));
    };

    const columns = [{
        sortable: true,

        type: "string",
        field: "name",
        headerName: formatForId(intl, `${localizationContext}.source.name`),
        flex: 1
    }];

    const selectedPanelColumns = [{
        sortable: true,

        type: "string",
        field: "name",
        headerName: formatForId(intl, `${localizationContext}.selected.name`),
        flex: 1,
        renderCell: ({value, row}) => {
            return <div className={classes.selectedRow} onDoubleClick={() => unselectRow(row)}>
                <div>{value}</div>
                <div className={classes.selectedRowTitle}>&nbsp;</div>
                <div><Button onClick={() => {
                    unselectRow(row);
                }}> <FontAwesomeIcon icon={faTrashAlt} size={"sm"}/></Button></div>
            </div>;
        }
    }];

    return <CommonDialog fullWidth={true} maxWidth={"lg"} open={open} onClose={onClose}
                         PaperProps={{style: {height: height - 100}}}>
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent className={classes.main}>

            <div className={classes.panelContainer}>
                <div className={classes.panel}>
                    {
                        _.isEmpty(choices) ?
                            <NoDataMessageLocal className={classes.leftSideMessage} hasFilters={!_.isEmpty(filter)}
                                                onClearFilter={() => setFilter("")}/> :
                            <> <DataGrid
                                localeText={{noResultsOverlayLabel: formatForId(intl, "noData.messageWithFilter") }}
                                filterModel={filterModel}
                                checkboxSelection={true}
                                className={classes.leftSide}
                                rows={choices}
                                columns={columns}
                                autoPageSize={true}
                                disableSelectionOnClick={false}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                disableMultipleColumnsSorting={true}
                                disableMultipleColumnsFiltering={true}
                                density={"compact"}
                                sortModel={sortModel}
                                onSortModelChange={(event) => {
                                    setSortModel(_.isEmpty(event.sortModel) ? defaultSortModel : event.sortModel);
                                }
                                }
                                selectionModel={localSelection}
                                onSelectionModelChange={(newSelectionModel) => {
                                    const selectionModel = _.filter(newSelectionModel.selectionModel, row=> typeof row !== "undefined");

                                    if (!_.isEmpty(filter)) {
                                        const selection = _.clone(selectionModel);
                                        setLocalSelection(selection);
                                    } else {
                                        setLocalSelection(selectionModel);
                                    }
                                }}

                            /></>}
                    <div className={classes.searchContainer}>
                        <SearchBox value={filter} onChange={(value) => {
                            if (_.isEmpty(value)) {
                                setFilter("");
                            } else {
                                setFilter(value);
                            }
                        }
                        }/>
                    </div>
                </div>
                <div className={classes.panel}>
                    {
                        _.isEmpty(localSelection) ? <OutlinedCard className={classes.rightSideMessage}
                                                                  title={formatForId(intl, `${localizationContext}.empty.title`)}
                                                                  message={formatForId(intl, `${localizationContext}.empty.message`)}/> :
                            <DataGrid
                                filterModel={rightFilterModel}
                                localeText={{noResultsOverlayLabel: formatForId(intl, "noData.messageWithFilter") }}
                                className={classes.rightSide}
                                rows={selectedRows}
                                columns={selectedPanelColumns}
                                autoPageSize={true}
                                disableSelectionOnClick={false}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                disableMultipleColumnsSorting={true}
                                disableMultipleColumnsFiltering={true}
                                density={"compact"}
                                sortModel={sortModel}
                                onSortModelChange={(event) => {
                                    setSortModel(_.isEmpty(event.sortModel) ? defaultSortModel : event.sortModel);
                                }
                                }
                            />}
                    <div className={classes.searchContainer}>
                        <SearchBox value={rightFilter} onChange={(value) => {
                            if (_.isEmpty(value)) {
                                setRightFilter("");
                            } else {
                                setRightFilter(value);
                            }
                        }
                        }/>
                    </div>
                </div>
            </div>


        </DialogContent>
        <DialogActions>
            <DialogSaveCancelButtonsOnSubmit onClose={onClose} onSubmit={() => {
                onSelect(localSelection);
            }
            }/>
        </DialogActions>
    </CommonDialog>
}