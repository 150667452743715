import { useSelector, useStore } from "react-redux";
import { useGet } from "restful-react";
import { useEffect, useMemo } from "react";
import _ from "loadsh";
import { reloadList, sortBy } from "../actions/dataList";
import { useLocale } from "./gui";
import { useIntervalWhen } from "rooks";

export function useDataListFilter() {
    return useSelector(({ dataList }) => {
        return dataList.filter;
    });
}

export function useDataListSort() {
    return useSelector(({ dataList }) => {
        return dataList.sortBy;
    });
}

export function useDataListReloadTime() {
    return useSelector(({ dataList }) => {
        return dataList.reloadTime;
    });
}

const delimiters = ["@", ";", ",", ".", "_", "-", "/", " "];

function getFilterPredicate({ field, value }, filters) {
    let predicate = filters && filters[field];
    if (_.isFunction(predicate)) {
        return filters[field];
    } else if (_.isString(value) && !_.isEmpty(value)) {
        return (row, value, field) => {
            let fieldValue = row[field];
            if (_.isString(fieldValue)) {
                const lcFieldValue = fieldValue.toLowerCase();
                const lcValue = value.toLowerCase();

                if (!lcFieldValue.startsWith(lcValue)) {
                    for (const delimiter of delimiters) {
                        const searchValue = lcValue.startsWith(delimiter) ? lcValue.substring(1) : lcValue;
                        let lastPos = 0;

                        while (lastPos >= 0 && lastPos < lcFieldValue.length) {
                            const delimiterPos = lcFieldValue.indexOf(delimiter, lastPos);

                            if (delimiterPos !== -1) {
                                const result = lcFieldValue.startsWith(searchValue, delimiterPos + 1);
                                if (result) {
                                    return true;
                                }
                                lastPos = delimiterPos + 1;
                            } else {
                                break;
                            }
                        }
                    }
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        };
    } else if (_.isBoolean(value)) {
        return (row, value, field) => {
            let fieldValue = row[field];
            if (value === true && fieldValue) {
                return true;
            } else if (value === false && !fieldValue) {
                return true;
            } else {
                return false;
            }
        };
    } else {
        return () => true;
    }
}

export function useGetInMemoryDataList({ url, preprocessData, filters, defaultSort, refreshInterval = 0 }) {
    const store = useStore();
    const filter = useDataListFilter();
    const sort = useDataListSort();
    const reloadTime = useDataListReloadTime();
    const [locale] = useLocale();

    const sortInternalBy = (field) => {
        sortBy(store, field);
    };
    const {
        data,
        refetch: reload,
        loading,
        error,
    } = useGet({
        path: url,
    });

    useEffect(() => {
        if (!loading) {
            reload();
        }
    }, [reloadTime]);

    useEffect(() => {
        if (defaultSort && (!sort || (sort && defaultSort.field !== sort.field))) {
            sortInternalBy(defaultSort);
        }
    }, [defaultSort]);

    const filteredData = useMemo(() => {
        if (_.isEmpty(data)) {
            return data;
        }

        let processedData = data;
        if (_.isFunction(preprocessData)) {
            processedData = preprocessData(data);
        }

        if (!_.isEmpty(filter)) {
            processedData = _.filter(processedData, (row) => {
                let result = true;
                for (const { field, value } of filter) {
                    const filterPredicate = getFilterPredicate({ field, value }, filters);
                    if (!filterPredicate(row, value, field)) {
                        result = false;
                        break;
                    }
                }

                return result;
            });
        }

        return processedData;
    }, [preprocessData, data, filter]);

    const filteredSortedData = useMemo(() => {
        if (sort && filteredData) {
            return filteredData.sort((a, b) => {
                const aValue = sort.isAscending ? a[sort.field] : b[sort.field];
                const bValue = sort.isAscending ? b[sort.field] : a[sort.field];

                if (aValue === null || bValue === null) {
                    return 0;
                }

                if (typeof aValue === "string") {
                    return aValue.localeCompare(bValue, locale);
                } else {
                    return aValue - bValue;
                }
            });

            return _.orderBy(filteredData, [sort.field], [sort.isAscending ? "asc" : "desc"]);
        } else {
            return filteredData;
        }
    }, [sort, filteredData]);

    const hasRefresh = useMemo(() => refreshInterval > 0, [refreshInterval]);

    useIntervalWhen(
        () => {
            reloadList(store);
        },
        refreshInterval,
        hasRefresh,
        false
    );

    return {
        loading,
        error,
        data: filteredSortedData,
        filter,
        reload,
        sortColumn: sort,
        sortBy: sortInternalBy,
        store,
    };
}
