import React, { useMemo } from "react";
import { CustomForm } from "../../../Components/Forms/CustomForm";
import { useMasterDetailFormSave, useOpenedDetailEntity, useUserDataSafeMasterDetailChange } from "../../../Utils/Data/hooks/masterDetail";
import { geoJsonToPoints, pointsToGeoJson } from "../../../Utils/Data/GeoJsonUtils";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { openDialog } from "../../../Utils/Data/actions/gui";
import { useStore } from "react-redux";

const formConfig = [
    {
        id: "name",
        type: "TextField",
        props: {
            required: true,
        },
    },
    {
        id: "is_active",
        type: "CheckboxField",
        props: {},
    },
    {
        id: "description",
        type: "TextField",
    },
    {
        id: "timeout",
        type: "TimeoutField",
        props: {
            required: true,
        },
        defaultValue: 120,
    },
    {
        id: "mapping_parsed",
        type: "DomainGroupQueryField",
        props: {
            disableDeviceOrdering: true,
            disableDevices: true,
        },
        defaultValue: {
            filter: [],
            order: null,
        },
    },
    {
        id: "areas",
        type: "FormList",
        defaultValue: [],
        props: {
            formId: "areaGroup",
            formConfig: [
                {
                    id: "areaName",
                    type: "TextField",
                    props: {
                        required: true,
                    },
                },
                {
                    id: "area",
                    type: "GeoLocationField",
                    props: {
                        required: true,
                    },
                },
            ],
        },
    },
];
export default function NiraAlertDetail({ reloadCallback }) {
    const { makeSubmitCallback } = useMasterDetailFormSave("alert-area/");
    const { entity, timeOpened } = useOpenedDetailEntity();
    const store = useStore();
    const { userChanged } = useUserDataSafeMasterDetailChange();

    const submitCallback = makeSubmitCallback({
        onBeforeSubmit: (values) => {
            values.areas = values.areas?.map((area) => {
                return {
                    name: area.areaName,
                    polygons: geoJsonToPoints(area.area),
                };
            });
            values.mapping = values.mapping_parsed.filter.map((item) => {
                let ret = { domain: item[0] };
                if (item.length === 2) {
                    ret.device_group = item[1];
                }
                return ret;
            });
        },
        onSuccess: reloadCallback,
    });

    const parsedEntity = useMemo(() => {
        return {
            ...entity,
            mapping_parsed: entity.mapping
                ? {
                      filter: entity.mapping?.map((item) => {
                          if (item.device_group) {
                              return [item.domain, item.device_group];
                          }
                          return [item.domain];
                      }),
                  }
                : undefined,
            areas: entity.areas?.map((area) => {
                return {
                    areaName: area.name,
                    area: pointsToGeoJson(area),
                };
            }),
        };
    }, [entity]);

    const actions = [
        {
            id: "viewAllGroup",
            onClick() {
                openDialog(store, { type: "alertGroupMap", entity: entity });
            },
        },
    ];
    return (
        <>
            <ActionToolbar localizationContext="forms.niraAlert" actions={actions} detailToolbar={true} buttonProps={{ variant: "outlined", disabled: userChanged }} />
            <CustomForm key={entity.id} timeOpened={timeOpened} formConfig={formConfig} formData={parsedEntity} onSubmit={submitCallback} formId={"niraAlert"} />
        </>
    );
}
