import { useMediaQuery } from "@material-ui/core";
import { isMobile, useMobileOrientation } from "react-device-detect";

export function useWideScreen(minWidth = 1000) {
    return useMediaQuery(`(min-width:${minWidth}px)`);
}

export default function DesktopOnlyView({ minWidth, ...props }) {
    const { children } = props;
    const wideScreen = useWideScreen();

    return wideScreen ? children : <> </>;
}

export function useIsMobilePortrait() {
    const { isPortrait } = useMobileOrientation();
    return isMobile && isPortrait;
}
