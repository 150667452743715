import * as ReactDOMServer from "react-dom/server";
import ColorLegend, { PRECIP_TYPE, WATER_THICK_TYPE } from "../../ColorLegend";
import { baseFontSize } from "../../../../../index";

function TooltipContainer({ children }) {
    //DO NOT USE MATERIAL HERE - IT IS RENDERED OUTSIDE THE MAIN SCOPE

    return (
        <div
            style={{
                color: "#ededed",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
                fontSize: baseFontSize,
                fontWeight: 400,
                lineHeight: "1.43em",
                fontFamily: ["Roboto", "Helvetica", "Arial"],
                paddingLeft: 4,
                paddingRight: 4,
            }}
        >
            {children}
        </div>
    );
}

export function precipTooltipFormatter(tooltipEvent, intl) {
    // if (tooltipEvent?.entries[0]?.dataPoint?.valueKey !== "precip" && tooltipEvent?.entries[0]?.dataPoint?.valueKey !== "precip_intens") {
    //     return null;
    // }
    return ReactDOMServer.renderToString(
        <TooltipContainer>
            <ColorLegend type={PRECIP_TYPE} intl={intl} selectedColor={tooltipEvent.entries[0].dataPoint.color} />
        </TooltipContainer>
    );
}

export function waterThickTooltipFormatter(tooltipEvent, intl) {
    return ReactDOMServer.renderToString(
        <TooltipContainer>
            <ColorLegend type={WATER_THICK_TYPE} intl={intl} selectedColor={tooltipEvent.entries[0].dataPoint.color} />
        </TooltipContainer>
    );
}
