import { baseFontSize } from "../../../../index";
import React, { useMemo } from "react";
import { formatDayMonthOnly, formatDayTimeShort, formatTimeOnly } from "../../../../Utils/Data/Time";
import { formatForId } from "../../../../Utils/Lang/IntlHelper";
import { useNiraValueInfo } from "../../../Nira/NiraValues";
import { Friction, isRoughness } from "../../../NiraApi";
import moment from "moment";
import { MAP_TOOLTIP_BACKGROUND } from "../../TooltipView";
import { alpha } from "@material-ui/core/styles";

function DataRow({ data, label }) {
    return (
        <div
            style={{
                width: "100%",
                height: 23,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <div>{label}</div>
            <div>{data}</div>
        </div>
    );
}

const TenMinutesDuration = moment.duration(10, "minutes");

export function formatNiraDataTs(dataType, dataTs, timeOnly = false) {
    if (isRoughness(dataType)) {
        return formatDayMonthOnly(dataTs);
    } else if (timeOnly) {
        return formatTimeOnly(moment(dataTs).subtract(TenMinutesDuration)) + " - " + formatTimeOnly(dataTs);
    } else {
        return formatDayTimeShort(moment(dataTs).subtract(TenMinutesDuration)) + " - " + formatTimeOnly(dataTs);
    }
}

export default function SegmentPopup({ niraData, selectedNiraSegment, intl, selectedData, dataType }) {
    const { dataTs } = niraData;

    const niraValueInfo = useNiraValueInfo(selectedData);
    const { data } = selectedNiraSegment;

    const relativeDataKey = useMemo(() => {
        switch (dataType) {
            case Friction:
                return "pages.views.mapView.nira.dataType.frictionMin";
            default:
                return undefined;
        }
    }, [dataType]);

    const dataTsFormatted = useMemo(() => formatNiraDataTs(dataType, dataTs), [dataTs, dataType]);
    return (
        <div
            style={{
                backgroundColor: alpha(MAP_TOOLTIP_BACKGROUND, 0.9),
                color: "#ededed",
                display: "flex",
                flexDirection: "column",
                padding: 8,
                alignItems: "flex-start",
                justifyContent: "center",
                fontSize: baseFontSize,
                fontWeight: 500,
                lineHeight: "1.2em",
                fontFamily: ["Roboto", "Helvetica", "Arial"],
                borderRadius: 4,
                width: 300,
            }}
        >
            <DataRow data={dataTsFormatted} label={formatForId(intl, "pages.views.mapView.nira.data.label.dataTimestamp")} />
            <DataRow data={niraValueInfo.formatter(data?.value, intl)} label={formatForId(intl, "pages.views.mapView.nira.dataType." + selectedData)} />
            {data?.numberOfMes !== undefined && <DataRow data={data?.numberOfMes} label={formatForId(intl, "pages.views.mapView.nira.data.label.numberOfMes")} />}
            {relativeDataKey && <DataRow data={niraValueInfo.formatter(data?.valueRel, intl)} label={formatForId(intl, relativeDataKey)} />}
        </div>
    );
}

export function AlertPopup({ alertData, intl }) {
    return (
        <div
            style={{
                backgroundColor: "rgba(97, 97, 97, 0.9)",
                color: "#ededed",
                display: "flex",
                flexDirection: "column",
                padding: 8,
                alignItems: "flex-start",
                justifyContent: "center",
                fontSize: baseFontSize,
                fontWeight: 500,
                lineHeight: "1.2em",
                fontFamily: ["Roboto", "Helvetica", "Arial"],
                borderRadius: 4,
                width: 300,
            }}
        >
            <DataRow data={formatDayMonthOnly(alertData.time_start)} label={formatForId(intl, "pages.views.mapView.nira.data.label.alertCreationDate")} />
            <DataRow data={alertData.alert_roughness.toFixed(1)} label={formatForId(intl, "pages.views.mapView.nira.data.label.alertRoughness")} />
            <DataRow data={alertData.baseline_roughness.toFixed(1)} label={formatForId(intl, "pages.views.mapView.nira.data.label.baselineRoughness")} />
        </div>
    );
}
