import defaultState, {ViewMode} from "./gui.default"

function getViewMode({view, compactDrawer}) {

   if ((view.width < 1500) || (view.width < 1200)) {
      return ViewMode.Dialog;
   }
   else {
      return ViewMode.MasterDetail;
   }
}

export default function (state= defaultState, action)
{
   switch (action.type) {
      case "METEO::GUI": {
         const newState = {...state, ...action.payload};

         newState.viewMode = getViewMode(newState);


         return newState;
      }

      case "METEO::MENU": {
         const newState = {...state, ...action.payload};

         return newState;
      }
      case "METEO::SOUND": {
         const newState = {...state, ...action.payload};

         return newState;
      }
      default:
         return state;
   }
}

