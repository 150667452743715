import { FormControl, FormControlLabel, FormLabel, IconButton, Menu, MenuItem, Radio, RadioGroup, Tooltip, Typography, withStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { getAuthenticatedUser } from "../../Utils/Permissions/PermissionHelper";
import { faExternalLink, faRightFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useBeaufort, useDisabledAggregation, useLocale, useWindUnit } from "../../Utils/Data/hooks/gui";
import Divider from "@material-ui/core/Divider";
import DrawerButton from "./DrawerButton";
import DesktopOnlyView from "../../Utils/Hooks/DesktopOnlyView";

const useStyles = makeStyles((theme) => ({
    iconButtonAvatar: {
        padding: 4,
    },
    formLabelFocused: {
        color: "inherit !important",
    },
}));

const StyledMenu = withStyles({
    paper: {
        border: "2px solid rgb(81, 81, 81)",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        cursor: "default",
        minWidth: 200,
        "&:hover": {
            backgroundColor: "inherit !important",
        },
        "&:focus": {
            backgroundColor: "inherit !important",
        },
        paddingLeft: theme.spacing(1) + "px !important",
        paddingRight: theme.spacing(1) + "px !important",
    },
}))(MenuItem);

function UserSettingItem({ items, value, onChange, intl, localizationContext, classes }) {
    return (
        <StyledMenuItem onClick={() => {}} disabledGutters disableTouchRipple>
            <FormControl component="fieldset">
                <FormLabel
                    component="legend"
                    classes={{
                        focused: classes.formLabelFocused,
                    }}
                >
                    {formatForId(intl, `${localizationContext}.title`)}
                </FormLabel>
                <RadioGroup row name="gender1" value={value} onChange={(e) => onChange(e.target.value)}>
                    {items.map((item) => (
                        <FormControlLabel key={item} value={item} control={<Radio />} label={formatForId(intl, `${localizationContext}.choices.${item}`)} />
                    ))}
                </RadioGroup>
            </FormControl>
        </StyledMenuItem>
    );
}

function UserIcon({}) {
    const intl = useIntl();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [windUnit, setWindUnit] = useWindUnit();
    const [beaufort, setBeaufort] = useBeaufort();
    const [disabledAggregation, setDisabledAggregation] = useDisabledAggregation();
    const [locale, setLocale] = useLocale();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const windUnitItems = ["ms", "kmh"];
    const beaufortItems = [true, false];
    const localeItems = ["sk", "en"];

    return (
        <>
            <Tooltip title={formatForId(intl, "pages.userActions.tooltip")}>
                <IconButton color="inherit" className={classes.iconButtonAvatar} onClick={handleClick}>
                    <Avatar src="src" alt={getAuthenticatedUser()?.login} />
                </IconButton>
            </Tooltip>
            <StyledMenu elevation={0} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <StyledMenuItem disabledGutters disableTouchRipple>
                    <Typography variant={"h6"}>{`${getAuthenticatedUser()?.first_name} ${getAuthenticatedUser()?.last_name}`}</Typography>
                </StyledMenuItem>

                <Divider style={{ marginBottom: 4, height: 2 }} />

                <UserSettingItem items={windUnitItems} classes={classes} intl={intl} value={windUnit} localizationContext={"pages.userActions.windUnit"} onChange={(val) => setWindUnit(val)} />
                <Divider style={{ marginBottom: 4, height: 2 }} />

                <UserSettingItem
                    items={beaufortItems}
                    classes={classes}
                    intl={intl}
                    value={beaufort}
                    localizationContext={"pages.userActions.beaufort"}
                    onChange={(val) => {
                        setBeaufort(val === "true");
                    }}
                />
                <Divider style={{ marginBottom: 4, height: 2 }} />

                <UserSettingItem
                    items={localeItems}
                    classes={classes}
                    intl={intl}
                    value={locale}
                    localizationContext={"pages.userActions.locale"}
                    onChange={(val) => {
                        setLocale(val);
                        handleClose();
                        window.location.reload();
                    }}
                />
                <Divider style={{ marginBottom: 4, height: 2 }} />
                <UserSettingItem
                    items={beaufortItems}
                    classes={classes}
                    intl={intl}
                    value={!disabledAggregation}
                    localizationContext={"pages.userActions.disabledAggregation"}
                    onChange={(val) => {
                        setDisabledAggregation(!disabledAggregation);
                        handleClose();
                        window.location.reload();
                    }}
                />
            </StyledMenu>
        </>
    );
}

export default function UserActions({}) {
    const intl = useIntl();
    const classes = useStyles();

    const openInNewWindow = () => {
        window.open(window.location.href, "_blank");
    };

    const logout = () => {
        fetch("/login/logout/").then((r) => {
            window.location.reload();
        });
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexWrap: "nowrap",
                width: "100%",
            }}
        >
            <DesktopOnlyView minWidth={1200}>
                <Tooltip title={formatForId(intl, "actions.openInNewTab")}>
                    <IconButton color="inherit" onClick={openInNewWindow}>
                        <FontAwesomeIcon className={classes.newTab} icon={faExternalLink} size={"xs"} />
                    </IconButton>
                </Tooltip>
            </DesktopOnlyView>
            <Tooltip title={intl.formatMessage({ id: "actions.logout" })}>
                <IconButton color="inherit" onClick={logout}>
                    <FontAwesomeIcon className={classes.newTab} icon={faRightFromBracket} size={"xs"} />
                </IconButton>
            </Tooltip>
            <DesktopOnlyView minWidth={1200}>
                <UserIcon />
            </DesktopOnlyView>
            <DrawerButton style={{ marginRight: -16 }} />
        </div>
    );
}
