import React from "react";
import { CustomForm } from "../../../Components/Forms/CustomForm";
import { useMasterDetailFormSave, useOpenedDetailEntity } from "../../../Utils/Data/hooks/masterDetail";

const formConfig = [
    {
        id: "username",
        type: "TextField",
        props: {
            required: true,
        },
    },
    {
        id: "password",
        type: "TextField",
        props: {
            required: true,
        },
    },
    {
        id: "regions",
        type: "FormList",
        defaultValue: [],
        props: {
            formId: "niraConfig",
            formConfig: [
                {
                    id: "north_west_lat",
                    type: "NumberField",
                    props: {
                        required: true,
                    },
                },
                {
                    id: "north_west_lon",
                    type: "NumberField",
                    props: {
                        required: true,
                    },
                },
                {
                    id: "south_east_lat",
                    type: "NumberField",
                    props: {
                        required: true,
                    },
                },
                {
                    id: "south_east_lon",
                    type: "NumberField",
                    props: {
                        required: true,
                    },
                },
            ],
        },
    },
];
export default function NiraConfigDetail({ reloadCallback }) {
    const { makeSubmitCallback } = useMasterDetailFormSave("nira/");
    const { entity, timeOpened } = useOpenedDetailEntity();

    return (
        <>
            <CustomForm key={entity.id} timeOpened={timeOpened} formConfig={formConfig} formData={entity} onSubmit={makeSubmitCallback()} formId={"niraConfig"} />
        </>
    );
}
