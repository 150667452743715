import React from 'react';
import {useIntl} from "react-intl";
import {formatForId, formatForServerId} from "../Utils/Lang/IntlHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLowVision} from "@fortawesome/pro-solid-svg-icons";

import {Paper} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles";
import _ from "loadsh";

const useStyles = makeStyles((theme) => ({
    mainText: {
        fontSize: "5vw",
        textAlign: "center"
    },
    description: {
        margin: "-3vw",
        fontSize: "2vw",
        color: "grey"
    },
    containerSmall: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    }
    ,
    containerBig: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "45vh",
        alignItems: "center"
    },
    icon: {
        fontSize: "5vw",
        margin: "15px"
    }

}));


export function ShowError({header, description}) {
    const classes = useStyles();
    const message = _.isArray(description) ? description[0] : description;
    const tokens = _.isArray(description) ? description[1] : null;

    const intl = useIntl();
    return (
        <Paper style={{margin: "10%"}}>
            <div className={classes.containerBig}>
                <div className={classes.containerSmall}>
                    <FontAwesomeIcon className={classes.icon} icon={faLowVision} size={"lg"}/>
                    <h1 className={classes.mainText}> {formatForServerId(intl, header)}</h1>
                </div>
                <div>
                    <p className={classes.description}>{formatForServerId(intl, message, tokens)}</p>
                </div>
            </div>
        </Paper>
    )
}