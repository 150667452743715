import ActionButtons from "./ActionButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes, faTrash } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

const submitButtonTmp = {
    id: "submit",
    icon: (
        <>
            <FontAwesomeIcon icon={faSave} />
            &nbsp;
        </>
    ),
    props: { color: "primary" },
};

const confirmSelection = {
    id: "confirmSelection",
    icon: (
        <>
            <FontAwesomeIcon icon={faSave} />
            &nbsp;
        </>
    ),
    props: { color: "primary" },
};

const cancelButtonTmp = {
    id: "cancel",
    icon: (
        <>
            <FontAwesomeIcon icon={faTimes} />
            &nbsp;
        </>
    ),
    props: { color: "secondary" },
};

const closeButtonTmp = {
    id: "close",
    icon: (
        <>
            <FontAwesomeIcon icon={faTimes} />
            &nbsp;
        </>
    ),
    props: { color: "secondary" },
};

export function DialogSaveCancelButtons({ onClose, submitEventId }) {
    return (
        <ActionButtons
            localizationContext={"form"}
            actions={[
                { ...cancelButtonTmp, onClick: onClose },
                { ...submitButtonTmp, onClickEventName: submitEventId },
            ]}
        />
    );
}

export function DialogSaveCancelButtonsOnSubmit({ onClose, onSubmit }) {
    return (
        <ActionButtons
            localizationContext={"form"}
            actions={[
                { ...cancelButtonTmp, onClick: onClose },
                { ...submitButtonTmp, onClick: onSubmit },
            ]}
        />
    );
}

export function DialogConfirmSelectionCancelButtonsOnSubmit({ onClose, onSubmit, confirmDisabled = false }) {
    return (
        <ActionButtons
            localizationContext={"form"}
            actions={[
                { ...cancelButtonTmp, onClick: onClose },
                {
                    ...confirmSelection,
                    onClick: onSubmit,
                    props: { ...confirmSelection.props, disabled: confirmDisabled },
                },
            ]}
        />
    );
}

export function DialogDeleteConfirmSelectionCancelButtonsOnSubmit({ onClose, onSubmit }) {
    return (
        <ActionButtons
            localizationContext={"form"}
            actions={[
                {
                    id: "cancel",
                    icon: (
                        <>
                            <FontAwesomeIcon icon={faTimes} />
                            &nbsp;
                        </>
                    ),
                    props: { color: "primary" },
                    onClick: onClose,
                },
                {
                    id: "confirmDeletion",
                    icon: (
                        <>
                            <FontAwesomeIcon icon={faTrash} />
                            &nbsp;
                        </>
                    ),
                    props: { color: "secondary" },
                    onClick: onSubmit,
                },
            ]}
        />
    );
}

export function DialogCancelButton({ onClose }) {
    return <ActionButtons localizationContext={"form"} actions={[{ ...cancelButtonTmp, onClick: onClose }]} />;
}

export function DialogCloseButton({ onClose }) {
    return <ActionButtons localizationContext={"form"} actions={[{ ...closeButtonTmp, onClick: onClose }]} />;
}
