import {getMeteoV1} from "./ValueMapper";


/**
 * forecast - value is treated as SPECIAL_BUTTONS_APP_FIELD row
 */
export const SPECIAL_BUTTONS_APP_FIELD = "forecast";
export const NAME_APP_FIELD = "name";
export const STATE_APP_FIELD = "state";
export const ALERT_APP_FIELD = "alert";
export const CAMERA_APP_FIELD = "camera";


export const DEFAULT_APP_FIELDS_ORDER = [NAME_APP_FIELD, STATE_APP_FIELD, ALERT_APP_FIELD, SPECIAL_BUTTONS_APP_FIELD, CAMERA_APP_FIELD]
export const DEFAULT_DATA_FIELDS_ORDER = Object.entries(getMeteoV1()).filter(item => item[1].guiVisible).map(item => item[0]);

export const CONFIRM_SPECIAL_BUTTON = "confirm";
export const GRAPH_SPECIAL_BUTTON = "graph";
export const METEOGRAM_SPECIAL_BUTTON = "meteogram";
export const ALERTS_SPECIAL_BUTTON = "alerts";

export const SPECIAL_BUTTONS_LIST = [CONFIRM_SPECIAL_BUTTON, GRAPH_SPECIAL_BUTTON, METEOGRAM_SPECIAL_BUTTON, ALERTS_SPECIAL_BUTTON];