import defaultState from "./deviceDataView.default"

export default function (state = defaultState, action) {

    switch (action.type) {
        case "METEO::DEVICEVIEW:SET":
            state.activeView = action.payload;
            break;
        case "METEO::DEVICEVIEW:SET_LOADING":
            state.activeViewLoadingState = action.payload;
            break;
        case "METEO::DEVICEVIEW:CLEAR":
            state.activeView = null;
            break;
        case "METEO::DEVICEVIEW:SET_SEARCH":
            state.searchKey = action.payload.value;
            break;
    }


    return state;
}