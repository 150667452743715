import Datetime from "./Library/DateTime";
import React, { useEffect, useRef, useState, useMemo } from "react";
import "./datetime.css";
import { useLocale } from "../../Utils/Data/hooks/gui";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowRightToLine } from "@fortawesome/pro-solid-svg-icons";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import { getDefaultLocale } from "../../Utils/Lang/LangLoader";

const useStyles = makeStyles((theme) => ({
    customViewRoot: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    topControls: {
        display: "flex",
        justifyContent: "flex-end",
    },
    calendar: {
        flex: 1,
    },
    controls: {
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "flex-end",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
}));

function renderCustomView(intl, mode, renderDefault, classes, onExit, onConfirmed, onBack, touched, onReset) {
    return (
        <div className={classes.customViewRoot}>
            <div className={classes.topControls}>
                <Tooltip title={formatForId(intl, "dateTime.resetTooltip")}>
                    <IconButton onClick={onReset} color="inherit" disabled={!touched}>
                        <FontAwesomeIcon icon={faArrowRightToLine} size={"xs"} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={formatForId(intl, "dateTime.closeTooltip")}>
                    <IconButton onClick={onExit} color="inherit">
                        <FontAwesomeIcon icon={faTimes} size={"xs"} />
                    </IconButton>
                </Tooltip>
            </div>
            <div className={classes.calendar}>{renderDefault()}</div>
            <Divider />
            <div className={classes.controls}>
                {mode !== "days" && (
                    <Button onClick={onBack} variant="contained" className={classes.backButton}>
                        {formatForId(intl, "dateTime.back")}
                    </Button>
                )}
                <Button onClick={onConfirmed} disabled={!touched} variant="contained" color="secondary">
                    {formatForId(intl, "dateTime.confirm")}
                </Button>
            </div>
        </div>
    );
}

export default function ViewDateTime({ onValueChanged, renderInput, value, disableFuture = false, enableEdit = false, onOpen, ...props }) {
    const locale = getDefaultLocale();
    const classes = useStyles();
    const intl = useIntl();
    const ref = useRef();
    const [valueLocal, setValueLocal] = useState(value);
    const [open, setOpen] = useState(false);

    const resetNavigation = () => {
        if (ref && ref.current) {
            ref.current.navigate("days");
        }
    };

    useEffect(() => onOpen(open), [open]);

    const touched = useMemo(() => value !== valueLocal, [valueLocal]);

    const onExit = () => {
        setOpen(false);
        setValueLocal(value);
        resetNavigation();
    };

    const onConfirm = () => {
        if (touched) {
            onValueChanged(valueLocal);
        }
        onExit();
    };

    const onBack = () => {
        resetNavigation();
    };

    const onReset = () => {
        setValueLocal(value);
    };

    useEffect(() => setValueLocal(value), [open, value]);

    return (
        <Datetime
            {...props}
            ref={ref}
            value={valueLocal}
            onChange={(val) => setValueLocal(val)}
            locale={locale}
            renderInput={renderInput}
            isValidDate={(current, selected) => {
                if (disableFuture) {
                    const now = moment();
                    return current.isBefore(now);
                }
                return true;
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            closeOnClickOutside={false}
            inlineDays
            renderView={(mode, renderDefault) => renderCustomView(intl, mode, renderDefault, classes, onExit, onConfirm, onBack, touched, onReset)}
        />
    );
}
