import React from "react";
import * as yup from "yup";
import { useIntl } from "react-intl";
import { formatForId } from "./IntlHelper";

function ipv4(intl) {
    const message = formatForId(intl, "formField.invalidIP");
    return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
        message,
        excludeEmptyString: true,
    }).test("ip", message, (value) => {
        return value === undefined || value.trim() === "" ? true : value.split(".").find((i) => parseInt(i, 10) > 255) === undefined;
    });
}

yup.addMethod(yup.string, "ipv4", ipv4);

function urlWithDebug(intl) {
    const message = formatForId(intl, "formField.validUrl");
    return this.matches(/^(http|https|debug):\/\/.*$/, {
        message,
        excludeEmptyString: true,
    });
}

yup.addMethod(yup.string, "urlWithDebug", urlWithDebug);

function phone(intl) {
    const message = formatForId(intl, "formField.invalidPhoneNumber");
    return this.matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, {
        message,
        excludeEmptyString: true,
    });
}

yup.addMethod(yup.string, "phone", phone);

function customId(intl) {
    const message = formatForId(intl, "formField.customId");
    return this.matches(/^\d{12}$/im, {
        message,
        excludeEmptyString: true,
    });
}

yup.addMethod(yup.string, "customId", customId);

export function YupLocaleProvider({ children }) {
    const intl = useIntl();

    React.useEffect(() => {
        yup.setLocale({
            mixed: {
                default: formatForId(intl, "formField.validationInvalid"),
                required: formatForId(intl, "formField.required"),
            },
            number: {
                min: ({ min }) => formatForId(intl, "formField.minValue", { value: min }),
                max: ({ max }) => formatForId(intl, "formField.maxValue", { value: max }),
            },
            string: {
                min: ({ min }) => formatForId(intl, "formField.minLength", { value: min }),
                max: ({ max }) => formatForId(intl, "formField.maxLength", { value: max }),
                url: formatForId(intl, "formField.validUrl"),
            },
            array: {
                min: ({ min }) => formatForId(intl, "formField.pickOneChoice", { value: min }),
            },
        });
    }, [intl.locale]);

    return children;
}

function hexColor(intl) {
    const message = formatForId(intl, "formField.invalidHexColor");
    return this.matches(/^#[0-9A-F]{6}[0-9a-f]{0,2}$/i, {
        message,
        excludeEmptyString: true,
    });
}

yup.addMethod(yup.string, "hexColor", hexColor);
