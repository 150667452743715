import React, { useMemo } from "react";
import { FormControl } from "@material-ui/core";
import { useDomains } from "../../../Utils/Data/hooks/server";
import { DropdownField } from "../DropdownField";
import { Halign } from "../../Flex";
import { useDataSources } from "../../../Api";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";

export function DataSourceField({ inputRef, fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete, formik, localizationContext }) {
    const domains = useDomains();
    const intl = useIntl();

    const domainsParsed = useMemo(() => {
        return {
            ids: [0, ...domains.map((domain) => domain.id)],
            names: [formatForId(intl, `${localizationContext}.noDomain`), ...domains.map((domain) => domain.name)],
        };
    }, [domains]);

    const { dataSources } = useDataSources(value?.domain);
    const dataSourcesParsed = useMemo(() => {
        return {
            ids: ["", ...dataSources.filter((dataSource) => dataSource.driver === fieldDef.props.driver).map((dataSource) => dataSource.id)],
            names: [formatForId(intl, `${localizationContext}.noDataSource`), ...dataSources.filter((dataSource) => dataSource.driver === fieldDef.props.driver).map((dataSource) => dataSource.name)],
        };
    }, [dataSources]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControl fullWidth required={!!required} {...fieldDef.props?.formControl}>
                <Halign>
                    <DropdownField
                        fieldDef={{
                            props: {
                                items: domainsParsed.ids,
                                valueNames: domainsParsed.names,
                                multiple: false,
                            },
                            defaultValue: 0,
                        }}
                        inputRef={inputRef}
                        fieldId={`${fieldId}.domain`}
                        value={value?.domain}
                        required={required}
                        changeHandle={(evt) => {
                            changeHandle({ target: { name: fieldId, value: { ...value, domain: evt.target.value } } });
                        }}
                        touched={touched}
                        errors={errors}
                        label={formatForId(intl, `${localizationContext}.domain`)}
                        multiple={false}
                        disabled={disabled}
                    />
                    <DropdownField
                        fieldDef={{
                            props: {
                                items: dataSourcesParsed.ids,
                                valueNames: dataSourcesParsed.names,
                                multiple: false,
                            },
                        }}
                        inputRef={inputRef}
                        fieldId={`${fieldId}.dataSource`}
                        value={value?.dataSource}
                        required={required}
                        changeHandle={(evt) => {
                            changeHandle({
                                target: {
                                    name: fieldId,
                                    value: { ...value, dataSource: evt.target.value },
                                },
                            });
                        }}
                        touched={touched}
                        errors={errors}
                        label={formatForId(intl, `${localizationContext}.dataSource`)}
                        multiple={false}
                        disabled={disabled || !value?.domain}
                    />
                </Halign>
            </FormControl>
        </div>
    );
}
