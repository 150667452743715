import { useParams, withRouter } from "react-router-dom";
import { setCompactDrawer, setCustomAppBar } from "../../Utils/Data/actions/gui";
import { useStore } from "react-redux";
import React from "react";
import _ from "loadsh";
import { CameraView } from "../../Components/Device/CameraView";
import { useGet } from "restful-react";
import "../../index.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import { Typography } from "@material-ui/core";
import LoadingScope from "../../Components/LoadingScope";
import { MeteoAppBarBase } from "../../Components/MeteoAppBarBase";
import { useDeviceSubscription } from "../../Utils/Data/hooks/server";
import { ScalableCameras } from "../View/CamerasView";

function useDeviceWithCameraIds(domainId, groupId, deviceId, cameraId) {
    const { data, refetch, loading, error } = useGet({
        lazy: true,
        path: "domain/" + domainId + "/device-group/list/",
    });

    React.useEffect(() => {
        refetch();
    }, [domainId, deviceId, groupId]);

    const findDevice = () => {
        const group = _.find(data, (group) => group.id === groupId);
        console.log(group);

        return _.find(group?.devices, (device) => device.id === deviceId);
    };

    const device = findDevice();
    if (device) {
        if (cameraId) {
            const camera = _.find(device.camera_devices, (camera) => camera.id === cameraId);
            return { devicesWithCameras: camera ? [{ device, cameraId: camera.id }] : [], loading };
        } else {
            return {
                devicesWithCameras: _.map(device.camera_devices, (camera, index) => ({
                    device: device,
                    cameraId: camera.id,
                    index: index + 1,
                })),
                loading,
            };
        }
    }
    return { devicesWithCameras: [], loading };
}

function StandaloneCameraDialog() {
    const store = useStore();
    React.useEffect(() => {
        setCustomAppBar(store, true);
        setCompactDrawer(store, true);
        return () => setCustomAppBar(store, false);
    }, []);

    let { id, deviceId, group, cameraId } = useParams();

    const domainId = parseInt(id);
    deviceId = parseInt(deviceId);
    const groupId = parseInt(group);
    cameraId = parseInt(cameraId);
    const { devicesWithCameras, loading } = useDeviceWithCameraIds(domainId, groupId, deviceId, cameraId);

    useDeviceSubscription(_.isEmpty(devicesWithCameras) ? [] : [devicesWithCameras[0].device]);

    const device = _.isEmpty(devicesWithCameras) ? null : devicesWithCameras[0].device;

    return (
        <>
            <MeteoAppBarBase>
                <Typography>
                    <Typography variant="h6" noWrap>
                        {device && device.name}
                    </Typography>
                </Typography>
            </MeteoAppBarBase>
            <LoadingScope loading={loading}>
                <ScalableCameras devicesWithCameras={devicesWithCameras} disableOpenDialog={true} />
            </LoadingScope>
        </>
    );
}

export default withRouter(StandaloneCameraDialog);
