import defaultState from "./dataList.default"
import _ from "loadsh";

export default function (state= defaultState, action)
{
   switch (action.type) {
      case "METEO::DATA_LIST::FILTER":
      {
         return {...state, filter: [action.payload]};
      }
      case "METEO::DATA_LIST::RESET_BY":
      {
         return {...state, filter: _.filter(state.filter, (filter)=>filter.field !== action.payload.field)};
      }
      case "METEO::DATA_LIST::SORT":
      {
         return {...state, sortBy: action.payload};
      }
      case "METEO::DATA_LIST::RELOAD":
      {
         return {...state, reloadTime: Date.now()};
      }
      case "METEO::DATA_LIST::RESET":
      {
         return defaultState;
      }
      default:
         return state;
   }
}
