import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Checkbox,
    ClickAwayListener,
    Dialog, DialogActions,
    DialogContent, DialogTitle,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import LoadingScope from "../LoadingScope";

import {useUpdatableViewMode} from "../../Utils/Data/hooks/gui";
import {ViewMode} from "../../Utils/Data/reducers/gui.default";
import {useDetailOpen, useSafeDetailClose} from "../../Utils/Data/hooks/masterDetail";
import {useStore} from "react-redux";
import {closeDetail} from "../../Utils/Data/actions/masterDetail";
import {FormSubmitEvent} from "../../Utils/Data/Events";
import ActionButtons from "./ActionButtons";
import {DialogSaveCancelButtons} from "./DialogSaveCancelButtons";
import {CommonDialog} from "../CommonDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,

    },
    container: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        alignItems: "stretch",
        height: 0
    },
    master: {
        flexGrow: 1,
        overflowY: "auto",
        padding: 10,
        maxWidth: props => props.viewMode === ViewMode.MasterDetail && props.isDetailOpened ? "50%" : "100%"
    },
    detail: {
        flexGrow: 1,
        overflowY: "auto",
        padding: 10,
        maxWidth: "50%"
    }

}));


function Value({type, value}) {
    switch (type) {
        case "boolean":
            return <Checkbox
                disabled={true}
                checked={value}
            />
        case "dateTime":
            return value.toLocaleString();
        default:
            return value;
    }
}

function Detail({onRenderDetail, viewMode, classes, onRenderDialogTitle, onRenderDialogActions}) {
    const store = useStore();
    const safeDetailClose = useSafeDetailClose();
    return viewMode === ViewMode.MasterDetail ?
        <div className={classes.detail} onKeyDown={(evt) => evt.key === "Escape" && safeDetailClose()}>
            {onRenderDetail({viewMode})}
        </div>
        : //dialog mode
        <CommonDialog open={true} onClose={() => safeDetailClose()} fullWidth>
            <DialogTitle>
                {onRenderDialogTitle && onRenderDialogTitle()}
            </DialogTitle>
            <DialogContent>
                {onRenderDetail({viewMode})}
            </DialogContent>
            <DialogActions>
                {onRenderDialogActions && onRenderDialogActions()}
            </DialogActions>
        </CommonDialog>
}


export default function MasterDetailView({
                                             children,
                                             onRenderDetail,
                                             onRenderContextMenu,
                                             onRenderDialogTitle,
                                             onRenderDialogActions,
                                             loading,
                                             loadingError,

                                         }) {
    const viewMode = useUpdatableViewMode();
    const isDetailOpened = useDetailOpen();
    const store = useStore();

    const classes = useStyles({viewMode, isDetailOpened});

    let dialogActions = onRenderDialogActions || (() => <DialogSaveCancelButtons onClose={() => closeDetail(store)}
                                                                                 submitEventId={FormSubmitEvent}/>);

    return (
        <div className={classes.root}>
            {!loadingError && onRenderContextMenu && onRenderContextMenu({viewMode, isDetailOpened})}
            <LoadingScope loading={loading} error={loadingError}>
                <div className={classes.container}>
                    <div className={classes.master}>
                        {children}
                    </div>
                    {isDetailOpened &&
                        <Detail classes={classes} onRenderDetail={onRenderDetail}
                                onRenderDialogTitle={onRenderDialogTitle}
                                onRenderDialogActions={dialogActions} viewMode={viewMode}/>}
                </div>
            </LoadingScope>
        </div>

    );
}