import React, { useMemo } from "react";
import { PALETTE } from "../../index";
import TooltipContainer from "./Nira/TooltipContainer";
import { useDisabledAggregation } from "../../Utils/Data/hooks/gui";
import { TooltipDeviceView } from "./SingleDeviceView";
import { alpha } from "@material-ui/core/styles";
///Cluster tooltip - written ugly because of impossibility of use of Maeterial

export const MAP_TOOLTIP_BACKGROUND = "rgba(97, 97, 97)";

const TooltipViewStyles = {
    height: 50,
    color: "#ededed",
    backgroundColor: alpha(MAP_TOOLTIP_BACKGROUND, 0.9),
    borderRadius: 4,
    fontSize: "1.5rem",
    fontWeight: 500,
    fontFamily: ["Roboto", "Helvetica", "Arial"],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
};

export function TooltipView({ device, tooltipDirection }) {
    const [disabledAggregation] = useDisabledAggregation();

    if (disabledAggregation) {
        return <TooltipDeviceView device={device} tooltipDirection={tooltipDirection} />;
    } else {
        return (
            <div style={{ ...TooltipViewStyles }}>
                <h6>{device.name}</h6>
            </div>
        );
    }
}

const IconStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    color: "#ededed",
    lineHeight: 2,
    width: 15,
    height: 15,
    marginRight: 8,
    flexBasis: 15,
};

function ClusterTooltipColumn({ children, ...props }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: 175,
            }}
            {...props}
        >
            {children}
        </div>
    );
}

function ClusterTooltipRow({ device, icons, ...props }) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                minHeight: 21,
            }}
            {...props}
        >
            <div style={{ ...IconStyles, ...icons[`icon_${device.severity}`] }}></div>
            <div
                style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    flex: 1,
                }}
            >
                {device.name}
            </div>
        </div>
    );
}

export function ClusterTooltipView({ devices }) {
    //DO NOT USE MATERIAL HERE - IT IS RENDERED OUTSIDE THE MAIN SCOPE
    const sliceIndex = 9;

    const Icons = useMemo(
        () => ({
            icon_0: {
                background: "rgb(76, 175, 80)",
            },
            icon_1: {
                background: PALETTE.warnings.level_1.primary,
                color: PALETTE.warnings.level_1.textColor,
            },
            icon_2: {
                background: PALETTE.warnings.level_2.primary,
                color: PALETTE.warnings.level_2.textColor,
            },
            icon_3: {
                background: PALETTE.warnings.level_3.primary,
                color: PALETTE.warnings.level_3.textColor,
            },
            icon_100: {
                background: PALETTE.warnings.level_100.primary,
                color: PALETTE.warnings.level_100.textColor,
            },
            icon_30: {
                background: PALETTE.warnings.level_30.primary,
                color: PALETTE.warnings.level_30.textColor,
            },
        }),
        []
    );

    const hasTwoColumns = devices.length > sliceIndex + 1;
    const secondColumnOverflow = devices.length > sliceIndex * 2 + 2;
    return (
        <TooltipContainer>
            <ClusterTooltipColumn>
                {devices.slice(0, sliceIndex).map((device) => (
                    <ClusterTooltipRow key={device.name} device={device} icons={Icons} />
                ))}
            </ClusterTooltipColumn>
            {hasTwoColumns && (
                <ClusterTooltipColumn>
                    {devices.slice(sliceIndex, secondColumnOverflow ? sliceIndex * 2 : sliceIndex * 2 + 1).map((device) => (
                        <ClusterTooltipRow key={device.name} device={device} icons={Icons} />
                    ))}
                    {secondColumnOverflow && "..."}
                </ClusterTooltipColumn>
            )}
        </TooltipContainer>
    );
}
