import { CircularProgress, FormControl, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { CustomForm } from "../../../Components/Forms/CustomForm";
import _ from "underscore";
import { useMasterDetailFormSave, useOpenedDetailEntity } from "../../../Utils/Data/hooks/masterDetail";
import { useDrivers, usePostMutate } from "../../../Api";
import { NoRunningDrivers } from "../../../Components/Forms/NoDataMessage";
import { formatDriverName } from "../../../Components/DeviceStateIcon";

function DriverForm({ data, timeOpened, onSubmitData, onSubmitTest, loadingTest }) {
    const intl = useIntl();

    const { drivers, names, getFormFields, loading } = useDrivers(null, (driver) => !!driver.metadata.template_configuration_form);
    const [selectedDriverName, setSelectedDriverName] = useState(data.isNew ? null : data.driver);
    useEffect(() => {
        setSelectedDriverName(data.isNew ? null : data.driver);
    }, [data]);

    const formConfig = useMemo(
        () => [
            {
                id: "name",
                type: "TextField",
                props: {
                    required: true,
                    min: 2,
                },
            },
            ...getFormFields(selectedDriverName),
        ],
        [selectedDriverName, drivers]
    );

    const onDriverChange = async (e) => {
        if (e.target.value === "none") {
            setSelectedDriverName(null);
        } else {
            setSelectedDriverName(e.target.value);
        }
    };

    const makeDriverConfiguration = (values) => {
        const driverSpecificForm = getFormFields(selectedDriverName);

        const configJson = {};
        for (const { id } of driverSpecificForm) {
            configJson[id] = values[id].toString();
        }

        return configJson;
    };

    const onFormSubmit = async (values, { setErrors }) => {
        values.driver = selectedDriverName;
        values.driver_configuration_json = JSON.stringify(makeDriverConfiguration(values));

        return await onSubmitData(values, { setErrors });
    };

    const intitialValues = useMemo(
        () => ({
            ...data,
            ...JSON.parse(data.driver_configuration_json || "{}"),
        }),
        [data, timeOpened]
    );

    return loading ? (
        <CircularProgress size={30} />
    ) : _.isEmpty(drivers) ? (
        <NoRunningDrivers />
    ) : (
        <>
            <FormControl fullWidth>
                <TextField disabled={!data.isNew} id={"deviceSelect"} select name={"deviceSelect"} onChange={onDriverChange} value={selectedDriverName || "none"} margin="normal">
                    <MenuItem key={"none"} value="none">
                        {formatForId(intl, "pages.device.selectDriver")}
                    </MenuItem>

                    {names.map((item) => (
                        <MenuItem key={item} value={item}>
                            {formatDriverName(intl, { driver: item })}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
            {selectedDriverName && selectedDriverName !== "master" && (
                <CustomForm
                    key={intitialValues.id + selectedDriverName + timeOpened}
                    formConfig={formConfig}
                    formData={data.isNew ? {} : intitialValues}
                    onSubmit={onFormSubmit}
                    create={data.isNew}
                    formId={"deviceDetail"}
                />
            )}
        </>
    );
}

export default function ConfigurationTemplateDetail({ onReload }) {
    const { entity: data, timeOpened } = useOpenedDetailEntity();

    const postPath = "domain/" + data.domain_id + "/configuration-template/";
    const { loading, makeSubmitCallback } = useMasterDetailFormSave(postPath);

    const submitForm = makeSubmitCallback({
        onSuccess: onReload,
        onBeforeSubmit(values) {
            values.domain_id = data.domain_id;
        },
        onExtendEntity: (values, response) => {
            values.domain_id = data.domain_id;
        },
    });
    const { postData: postTest, loading: loadingTest, response, setResponse } = usePostMutate(postPath + "test/");

    return (
        <div>
            <DriverForm data={data} timeOpened={timeOpened} onSubmitData={submitForm} onSubmitTest={postTest} loadingTest={loadingTest} />
        </div>
    );
}
