/**
 * RequestLimiter is a class that allows to limit the number of concurrent requests
 * and reuse promises for the same url as long as they are not resolved.
 */
class RequestLimiter {
    constructor(concurrency, eventName) {
        this.concurrency = concurrency;
        this._queue = [];
        this._activeRequests = 0;
        this._urlToPromiseMap = new Map();
        this._id = 0;

        this._lastTriggeredDownloading = false;
        this.eventName = eventName;
    }

    isDownloading() {
        return this._activeRequests > 0 || this._queue.length > 0;
    }

    fetch(url, options) {
        const promise = this._urlToPromiseMap.get(url);
        if (promise) {
            return promise;
        }

        const newPromise = this._fetchWithQueue(url, options);
        this._urlToPromiseMap.set(url, newPromise);
        return newPromise;
    }

    _fetchWithQueue(url, options) {
        if (this._activeRequests >= this.concurrency) {
            return new Promise((resolve, reject) => {
                this._queue.push({ url, options, resolve, reject });
            });
        }

        return new Promise((resolve, reject) => {
            this._fetch({ url, options, resolve, reject });
        });
    }
    _dequeue() {
        if (this._queue.length > 0) {
            let fetched = false;
            while (!fetched) {
                const request = this._queue.shift();
                if (!request) break;
                if (request.options?.signal?.aborted) {
                    continue;
                }
                this._fetch(request);
                fetched = true;
            }
            return fetched;
        }
    }

    _fetch({ id, url, options, resolve, reject, retryCnt = 0 }) {
        ++this._activeRequests;
        this._triggerDownloading();

        fetch(url, options)
            .then(resolve)
            .catch(() => {
                console.log("retrying fetch for: " + url + ", retryCount: " + retryCnt);
                if (retryCnt < 3) {
                    this._fetch({ id, url, options, resolve, reject, retryCnt: retryCnt + 1 });
                } else {
                    reject();
                }
            })
            .finally(() => {
                --this._activeRequests;
                this._triggerDownloading();

                this._urlToPromiseMap.delete(url);
                this._dequeue();
            });
    }

    _triggerDownloading() {
        const isDownloading = this.isDownloading();
        if (this._lastTriggeredDownloading !== isDownloading) {
            const evt = new Event(this.eventName);
            evt.data = { isDownloading };
            document.dispatchEvent(evt);

            this._lastTriggeredDownloading = isDownloading;
        }
    }
}

export const globalRequestLimiterEventName = "globalRequestLimiter_isDownloadingChanged";
export const globalRequestLimiter = new RequestLimiter(8, globalRequestLimiterEventName);
