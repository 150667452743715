function getMetoCtx() {
    if (window.MeteoCtx) {
        return window.MeteoCtx;
    } else {
        return {
            user: null,
            perms: []
        }
    }
}

export default {
    isAuthenticated() {
        return !!getMetoCtx().user;
    },
    hasPermission(scope, perm) {
        //TODO implement this
        return true;
    },
    getAuthenticatedUser() {
        return getMetoCtx().user;
    }
}