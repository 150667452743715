import { FormControl } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import _ from "underscore";
import { makeStyles } from "@material-ui/core/styles";
import { useDetailEditable, useMasterDetailForm } from "../../Utils/Data/hooks/masterDetail";
import { backupForm, markUserChanged } from "../../Utils/Data/actions/masterDetail";
import { connect, useStore } from "react-redux";
import { addEventListener, removeAllListenersForEvent } from "react-redux-events";

import { FormSubmitEvent } from "../../Utils/Data/Events";
import { buildFormEntityValues, findFirstFocusableFieldId, RenderField, useFormCommon } from "./FormCommon";

const useStyles = makeStyles((theme) => ({
    mainForm: {
        // overflowY: "auto",
        // height: "60vh"
    },
    formGroup: {
        display: "flex",
        flexDirection: "row",
    },
}));

const mapProps = (state) => ({});
const mapDispatch = (dispatch) => ({
    addEventListener: (event, context, handler, priority) => dispatch(addEventListener(event, context, handler, priority)),
    removeAllListenersForEvent: (event) => dispatch(removeAllListenersForEvent(event)),
});
const connectedForm = connect(
    mapProps,
    mapDispatch
)(function CustomForm({ formId, formConfig, formData, timeOpened, onSubmit, addEventListener, removeAllListenersForEvent, changedValues }) {
    const intl = useIntl();
    const classes = useStyles();
    const store = useStore();
    const editable = useDetailEditable();
    const { form: backedUpForm, focusedFieldId } = useMasterDetailForm();

    const focusFieldId = React.useMemo(() => {
        if (focusedFieldId) {
            return focusedFieldId;
        }
        return findFirstFocusableFieldId(formConfig, formData.isNew);
    }, [formConfig, formData]);

    const initialValues = React.useMemo(() => {
        if (backedUpForm) {
            return { ...buildFormEntityValues(formConfig, formData), ...backedUpForm };
        }
        return buildFormEntityValues(formConfig, formData);
    }, [formConfig, formData, timeOpened, editable]);

    const ref = React.useRef([]);

    const formik = useFormCommon(formConfig, initialValues, onSubmit);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            const element = document.querySelector(":focus");
            let focusedFieldId = null;
            if (element && _.find(formConfig, (field) => field.id === element.name)) {
                focusedFieldId = element.name;
            }

            backupForm(store, { form: formik.values, focusedFieldId });
        }, 500);
        return () => clearTimeout(timeout);
    }, [formik.values]);

    React.useEffect(() => {
        if (!editable && formik.dirty) {
            const resetData = buildFormEntityValues(formConfig, formData);
            formik.resetForm({ values: resetData });
        } else if (ref.current && ref.current.focus) {
            ref.current.focus();
        }
    }, [editable]);

    React.useEffect(() => {
        addEventListener(FormSubmitEvent, formik, (evt) => {
            if (evt.payload.action) {
                formik.setFieldValue("submit_action", evt.payload.action);
            }
            formik.submitForm();
        });

        return () => {
            removeAllListenersForEvent(FormSubmitEvent);
        };
    }, []);

    const [dynamicConfig, setDynamicConfig] = useState(formConfig);
    useEffect(() => {
        let currentConfig = formConfig;
        for (let i = 0, len = currentConfig.length; i !== len; ++i) {
            const item = currentConfig[i];

            if (typeof item.onValueChanged === "function") {
                const resultConfig = item.onValueChanged(dynamicConfig, formik, formik.values[item.id]);
                if (resultConfig) {
                    setDynamicConfig(resultConfig);
                }
            }
        }
        setDynamicConfig(currentConfig);
    }, [formConfig]);

    useEffect(() => {
        if (!_.isEmpty(changedValues)) {
            for (const [key, value] of Object.entries(changedValues)) {
                formik.setFieldValue(key, value);
            }
        }
    }, [changedValues]);

    return (
        <form autoComplete={"off"}>
            <FormControl fullWidth classes={{ root: classes.mainForm }}>
                {dynamicConfig.map((item, idx) =>
                    RenderField({
                        intl,
                        changeHandle: (evt) => {
                            formik.handleChange(evt);
                            if (typeof item.onValueChanged === "function") {
                                const resultConfig = item.onValueChanged(dynamicConfig, formik, evt.target.value);
                                if (resultConfig) {
                                    setDynamicConfig(resultConfig);
                                }
                            }

                            markUserChanged(store, true);
                        },
                        item,
                        formik,
                        formId,
                        focusFieldId,
                        ref,
                        editable,
                        isNew: formData.isNew,
                        classes,
                    })
                )}
            </FormControl>
        </form>
    );
});

export { connectedForm as CustomForm };
