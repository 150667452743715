import defaultState from "./map.default";

export default function (state = defaultState, action) {
    switch (action.type) {
        case "METEO::MAP": {
            return {...state, ...action.payload};
        }
        default:
            return state;
    }
}

