import { useGet } from "restful-react";
import { useEffect, useMemo, useState } from "react";
import { useNiraPlayerDataFrame, useSelectedDataType, useSelectedPlayerTime, useSelectedRoughnessDataTime } from "../Utils/Data/hooks/map";
import { useHistoryGetData } from "../Utils/Data/hooks/server";
import _ from "loadsh";
import { useInterval } from "rooks";
import moment from "moment";
import { NIRA_ALERT_NONE } from "../Utils/Data/AlertFormatter";
import { getFrictionValueForLevel } from "./Map/Nira/Gradient";

export const NiraUrl = window.NiraDriverUrl;

export const Friction = "friction";
export const Temperature = "temperature";
export const Wiperspeed = "wiperspeed";
export const AirPressure = "air_pressure";
export const RelativeHumidity = "relative_humidity";
export const CurrentRoughness = "current_roughness";
export const LongTermRoughness = "long_term_roughness";
export const RoughnessChangesAlerts = "roughness_changes_alerts";
export const None = "none";

export const NiraDataTypes = [None, Friction, Temperature, Wiperspeed, AirPressure, RelativeHumidity, CurrentRoughness, LongTermRoughness, RoughnessChangesAlerts];

export const SelectableNiraDataTypes = [Friction, Temperature, Wiperspeed, AirPressure, RelativeHumidity, CurrentRoughness, LongTermRoughness, RoughnessChangesAlerts];

export function useNiraSyncStatus() {
    const result = useGet({ base: NiraUrl, path: "/status/" });

    useInterval(
        () => {
            if (!result.loading) result.refetch();
        },
        1000,
        true
    );

    return result;
}

const InitialState = {
    data_sync_time: 0,
    geometry_model_id: 0,
    loadedDataType: None,
    dataSyncLoading: true,
    timestamp: null,
    dataSyncError: null,
};

export function isRoughness(dataType) {
    return dataType === LongTermRoughness || dataType === CurrentRoughness;
}

export function useIsRoughness(dataType) {
    return useMemo(() => isRoughness(dataType), [dataType]);
}

function useNiraDataSyncTime(dataType, historyDate, frictionClassFilter) {
    const timestamp = _.isEmpty(historyDate) ? 0 : historyDate.valueOf();

    const frictionMaxValue = useMemo(() => {
        if (dataType === Friction && frictionClassFilter !== NIRA_ALERT_NONE) {
            return getFrictionValueForLevel(frictionClassFilter);
        }
        return 0;
    }, [frictionClassFilter, dataType]);

    const { data, loading, refetch, error } = useGet({
        base: NiraUrl,
        path: "/measures/data/",
        queryParams: {
            time: timestamp,
            friction_max_value: frictionMaxValue,
        },
        lazy: true,
    });

    const [syncTimeData, setSyncTimeData] = useState(null);
    useEffect(() => {
        setSyncTimeData(data);
    }, [data]);

    const [state, setState] = useState(InitialState);
    const [requestState, setRequestState] = useState(null);

    useEffect(() => {
        refetch({ requestOptions: { cache: "no-cache" } });
        setRequestState(timestamp);
    }, [dataType, timestamp, frictionMaxValue]);

    useEffect(() => {
        if (timestamp === 0 && dataType !== None) {
            const interval = setInterval(async () => {
                try {
                    const resp = await fetch(NiraUrl + "/measures/data/", {
                        cache: "no-cache",
                        method: "GET",
                    }).then((resp) => resp.json());

                    if (!syncTimeData || resp.data_sync[dataType] !== syncTimeData.data_sync[dataType]) {
                        setSyncTimeData(resp);
                        setRequestState(timestamp);
                    }
                } catch (e) {
                    console.error(e);
                }
            }, 30000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [timestamp, dataType, state, frictionMaxValue]);

    useEffect(() => {
        if (syncTimeData) {
            if (requestState !== timestamp) {
                setState(InitialState);
            } else {
                setState({
                    data_sync_time: syncTimeData.data_sync[dataType],
                    geometry_model_id: syncTimeData.geometry_model[dataType],
                    loadedDataType: dataType,
                    dataSyncLoading: loading,
                    timestamp: timestamp,
                    roughnessChangesAlertsLastDataTime: syncTimeData.roughness_changes_alerts_most_recent_data_time,
                });
            }
        } else {
            if (error) {
                setState({ ...InitialState, dataSyncLoading: false });
            } else {
                setState(InitialState);
            }
        }
    }, [timestamp, dataType, syncTimeData, requestState, error, frictionMaxValue]);

    return state;
}

export function useNiraData(historyDateOverride, frictionClassFilter = NIRA_ALERT_NONE) {
    const dataType = useSelectedDataType();
    const historyDate = useHistoryGetData();
    const frame = useNiraPlayerDataFrame();

    const historyDateComputed = useMemo(() => (historyDateOverride ? historyDateOverride : historyDate), [historyDateOverride, historyDate]);

    const { data_sync_time, geometry_model_id, loadedDataType, dataSyncLoading, dataSyncError, roughnessChangesAlertsLastDataTime } = useNiraDataSyncTime(
        dataType,
        historyDateComputed,
        frictionClassFilter
    );

    return {
        dataType,
        dataTs: frame === null ? data_sync_time : frame.time,
        loading: dataSyncLoading,
        geometry_model_id: frame === null ? geometry_model_id : frame.geometry_model_id,
        error: dataSyncError,
        roughnessChangesAlertsLastDataTime,
        frictionClassFilter,
    };
}

export function useNiraPlayerData(dataSyncTime) {
    const historyDate = useHistoryGetData();
    const dataType = useSelectedDataType();
    const selectedPlayerTime = useSelectedPlayerTime();

    const [start_time, end_time] = useMemo(() => {
        const now = _.isEmpty(historyDate) ? moment().valueOf() : historyDate.valueOf();
        return [isRoughness(dataType) ? moment(now).subtract(selectedPlayerTime, "days").valueOf() : moment(now).subtract(selectedPlayerTime, "hours").valueOf(), now.valueOf()];
    }, [dataType, historyDate, selectedPlayerTime]);

    const { data, loading, refetch, error } = useGet({
        base: NiraUrl,
        path: "/measures/player_data/",
        queryParams: {
            end_time,
            start_time,
            data: dataType,
        },
        lazy: true,
        resolve: (data) => {
            return data;
        },
    });

    useEffect(() => {
        if (dataType !== None && selectedPlayerTime !== 0) {
            refetch();
        }
    }, [dataType, historyDate, dataSyncTime, start_time, end_time]);

    return { data, loading };
}

export function useRoughnessAlertsInterval(niraData) {
    const { roughnessChangesAlertsLastDataTime } = niraData;
    const selectedDays = useSelectedRoughnessDataTime();

    return useMemo(() => {
        const endDate = moment(roughnessChangesAlertsLastDataTime).subtract(1, "days").endOf("day");
        const startDate = moment(roughnessChangesAlertsLastDataTime).subtract(selectedDays, "days").startOf("day");
        return { startDate, endDate };
    }, [roughnessChangesAlertsLastDataTime, selectedDays]);
}
