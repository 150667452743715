import { formatForId } from "../../../../../Utils/Lang/IntlHelper";
import { ALERT_1, ALERT_2, ALERT_3, ALERT_DEVICE_OFFLINE, ALERT_FAILURE, ALERT_FORECAST_1, ALERT_FORECAST_2, ALERT_FORECAST_3, ALERT_STATE } from "../../../../../Utils/Data/AlertFormatter";
import _ from "loadsh";

function createLevelData(level, chartStartPoint) {
    return [{ x: chartStartPoint.x, y: level }, chartStartPoint];
}

export const AlertPositionKeys = [ALERT_1, ALERT_2, ALERT_3, ALERT_STATE, ALERT_FAILURE, ALERT_DEVICE_OFFLINE];

function prepareChartDataPoints(alertData, chartStartPoint, chartEndPoint, history, alertsViewSettings, historyBeginTime) {
    const separatedAlertData = new Map();
    const lastIntervals = new Map();

    for (let i = 0, len = AlertPositionKeys.length; i !== len; ++i) {
        separatedAlertData.set(AlertPositionKeys[i], createLevelData(i, chartStartPoint));
    }

    if (!alertsViewSettings.hasPermAlertView) {
        separatedAlertData.delete(ALERT_1);
        separatedAlertData.delete(ALERT_2);
        separatedAlertData.delete(ALERT_3);
    }

    if (!alertsViewSettings.hasPermErrors) {
        separatedAlertData.delete(ALERT_FAILURE);
    }

    if (!alertsViewSettings.hasPermStates) {
        separatedAlertData.delete(ALERT_STATE);
        separatedAlertData.delete(ALERT_DEVICE_OFFLINE);
    }

    const endInterval = (level, lastInterval) => {
        if (!lastInterval) return;

        const items = separatedAlertData.get(level);
        if (!items) return;

        const range = [
            { x: new Date(lastInterval.beginTime), y: null },
            { x: new Date(lastInterval.endTime), y: null },
            { x: new Date(lastInterval.endTime), y: null },
        ];

        const y = items[0].y;
        let lastPoint = null;

        for (const point of range) {
            point.y = y;
            items.push(point);
            lastPoint = point;
        }

        if (lastPoint !== null) lastPoint.y = null;
    };

    const pushRange = (level, beginTime, endTime) => {
        const lastInterval = lastIntervals.get(level);

        let isEnd = lastInterval && lastInterval.endTime < endTime && beginTime > lastInterval.endTime;
        if (isEnd) {
            endInterval(level, lastInterval);
            lastIntervals.set(level, { beginTime, endTime });
        } else {
            lastIntervals.set(level, {
                beginTime: Math.min(beginTime, lastInterval ? lastInterval.beginTime : beginTime),
                endTime: Math.max(endTime, lastInterval ? lastInterval.endTime : endTime),
            });
        }
    };

    const processAlertItem = (item) => {
        let level = item.level;
        if (level === ALERT_FORECAST_1 || level === ALERT_FORECAST_2 || level === ALERT_FORECAST_3) {
            level -= 10;

            let beginTime = item.begin_time;
            const futureEnd = !item.end_time || item.end_time > Date.now();
            if (!futureEnd) {
                return;
            }

            if (item.begin_time < Date.now() && futureEnd) {
                beginTime = Date.now();
            }

            let endTime = item.end_time;
            if (!endTime) {
                endTime = chartEndPoint.time;
            }

            pushRange(level, beginTime, endTime, item);
        } else {
            const historyEndTime = history.value.end_time ? history.value.end_time : Date.now();
            const itemEndTime = item.end_time ? item.end_time : Date.now();

            if (itemEndTime < historyBeginTime || item.begin_time > historyEndTime) {
                return [];
            }

            const endTime = itemEndTime > historyEndTime ? historyEndTime : itemEndTime;
            const beginTime = item.begin_time > historyBeginTime ? item.begin_time : historyBeginTime;

            pushRange(level, beginTime, endTime, item);
        }
    };

    _.orderBy(alertData.active.concat(alertData.notActive), ["begin_time"], ["asc"]).forEach((item) => processAlertItem(item));

    const result = [];
    for (const key of AlertPositionKeys) {
        const data = separatedAlertData.get(key);
        const lastInterval = lastIntervals.get(key);
        endInterval(key, lastInterval);

        data.sort((a, b) => a.x.getTime() - b.x.getTime());

        if (data) {
            result.push(data);
        } else {
            result.push(null);
        }
    }

    return result;
}

export function getDeviceChartData(alertData, history, theme, intl, chartStartPoint, chartEndPoint, alertsViewSettings, beginTime, rangeChanging) {
    const dataPoints = prepareChartDataPoints(alertData, chartStartPoint, chartEndPoint, history, alertsViewSettings, beginTime);

    const chartOptions = {
        markerType: "none",
        type: "stepLine",
        lineThickness: 10,
        showInLegend: false,
    };

    const data = [];
    let minimumVisibleKey = 0;
    let maximumVisibleKey = 0;

    for (let i = 0, len = dataPoints.length; i !== len; ++i) {
        const key = AlertPositionKeys[i];
        const levelData = dataPoints[i];
        if (levelData === null) {
            continue;
        }

        minimumVisibleKey = Math.min(i, minimumVisibleKey);
        maximumVisibleKey = Math.max(i, maximumVisibleKey);

        data.push({
            ...chartOptions,
            color: theme.palette.warnings["level_" + key]?.primary || "white",
            dataPoints: levelData,
        });
    }

    return {
        zoomEnabled: true,
        animationEnabled: false,
        exportEnabled: false,
        toolTip: {
            enabled: false,
        },
        title: {},
        axisX: {
            minimum: chartStartPoint.x,
            maximum: chartEndPoint.x,

            lineThickness: 1,
            tickLength: 0,
            labelFormatter: () => "",
            gridThickness: 0.4,
            lineColor: "transparent",
        },
        axisY: {
            minimum: minimumVisibleKey - 1,
            viewportMinimum: minimumVisibleKey - 0.5,
            maximum: maximumVisibleKey + 1,
            viewportMaximum: maximumVisibleKey + 0.5,
            gridThickness: 0.4,
            margin: 0,
            interval: 1,
            lineThickness: 0.4,
            tickThickness: 0.4,
            labelFormatter: function (e) {
                switch (AlertPositionKeys[e.value]) {
                    case ALERT_1:
                        return formatForId(intl, "pages.device.meteogram.statusGraph.alertLabelOne");
                    case ALERT_2:
                        return formatForId(intl, "pages.device.meteogram.statusGraph.alertLabelTwo");
                    case ALERT_3:
                        return formatForId(intl, "pages.device.meteogram.statusGraph.alertLabelThree");
                    case ALERT_STATE:
                        return formatForId(intl, "pages.device.meteogram.statusGraph.stateLabel");
                    case ALERT_DEVICE_OFFLINE:
                        return formatForId(intl, "pages.device.meteogram.statusGraph.offlineLabel");
                    case ALERT_FAILURE:
                        return formatForId(intl, "pages.device.meteogram.statusGraph.failureLabel");
                    default:
                        return "";
                }
            },
            labelFontSize: 14,
        },
        data,
        rangeChanging,
    };
}
