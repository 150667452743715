import React, {useEffect} from "react";
import ReactTooltip from "react-tooltip";

export function FastTooltip({title, children}) {
    return <div data-tip={title}>{children}</div>;
}

export function useFastTooltipRescan(value) {
    useEffect(() => {
        const timeout = setTimeout(() => {
            console.log("rebuilding tooltips")
            ReactTooltip.rebuild();
        }, 500);
        return () => clearTimeout(timeout);
    }, [value]);
}