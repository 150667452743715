import React from "react";
import { globalRequestLimiterEventName } from "../../Components/Map/Nira/VectorGrid/RequestLimiter";

export function useNiraLoading() {
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        const handler = (evt) => {
            setLoading(evt.data.isDownloading);
        };
        document.addEventListener(globalRequestLimiterEventName, handler);
        return () => {
            document.removeEventListener(globalRequestLimiterEventName, handler);
        };
    }, []);
    return loading;
}
