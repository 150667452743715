
export function filterBy(store, {field, value})
{
   store.dispatch({type: "METEO::DATA_LIST::FILTER", payload: {field, value}});
}

export function sortBy(store, {field, isAscending})
{
   store.dispatch({type: "METEO::DATA_LIST::SORT", payload: {field, isAscending}});
}

export function resetFilterBy(store, {field})
{
   store.dispatch({type: "METEO::DATA_LIST::RESET_BY",payload: {field}});
}

export function resetFilter(store)
{
   store.dispatch({type: "METEO::DATA_LIST::RESET"});
}

export function reloadList(store)
{
   store.dispatch({type: "METEO::DATA_LIST::RELOAD"});
}