import {useParams} from "react-router-dom";


export function useGetDomainGroup() {
    const {id, group} = useParams();

    return {domainId: id && parseInt(id, 10), groupId: group && parseInt(group, 10)};
}

export function useGetDomainDevice() {
    const {id, device} = useParams();

    return {domainId: id && parseInt(id, 10), deviceId: device && parseInt(device, 10)};
}