import React, { useEffect, useMemo } from "react";

import MasterDetailView from "../../Components/MasterDetail/MasterDetailView";
import DomainDetail from "./DomainDetail";

import Button from "@material-ui/core/Button";

import { DomainIcon, DomainsIcon, UserIcon } from "../../Components/Icons/ToolbarIcons";
import { useOpenedDetailEntity, useSafeOpenDetail } from "../../Utils/Data/hooks/masterDetail";
import MasterDetailContextMenu from "../../Components/MasterDetail/MasterDetailContextMenu";
import { DetailContextMenu } from "../../Components/MasterDetail";
import ActionToolbar from "../../Components/MasterDetail/ActionToolbar";

import MasterDetailTable from "../../Components/MasterDetail/MasterDetailTable";
import { useGetInMemoryDataList } from "../../Utils/Data/hooks/dataList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { faDeleteRight } from "@fortawesome/pro-light-svg-icons";
import { useStore } from "react-redux";
import { openDialog } from "../../Utils/Data/actions/gui";
import { CreateDeleteButtonForList } from "./DeletionDialog";
import { useIntl } from "react-intl";

export function Domains() {
    const safeOpenDetail = useSafeOpenDetail();
    const intl = useIntl();
    const store = useStore();
    const dataSource = useGetInMemoryDataList({
        url: "domain/list/",
        defaultSort: { field: "name", isAscending: true },
    });

    const columns = useMemo(
        () => [
            { field: "id", sortable: true, width: 100, type: "number" },
            {
                field: "name",
                sortable: true,
                width: 150,
                renderCell: (params) => (
                    <Button>
                        <DomainIcon />
                        {params.row.name}
                    </Button>
                ),
            },

            { field: "support_contact", headerName: "Support Contact", width: 200 },
            { field: "default_language", headerName: "Language", width: 150 },
            { field: "is_active", headerName: "Active", width: 50, type: "boolean" },
            CreateDeleteButtonForList(intl, store, "domain/"),
        ],
        [dataSource]
    );

    return (
        <MasterDetailView
            loading={dataSource.loading}
            loadingError={dataSource.error}
            onRenderContextMenu={({ isDetailOpened, viewMode }) => (
                <MasterDetailContextMenu
                    isDetailOpened={isDetailOpened}
                    viewMode={viewMode}
                    onRenderDetailMenu={() => <DetailContextMenu onRenderBeforeLabel={() => <DomainIcon />} />}
                    onRenderMasterMenu={() => (
                        <ActionToolbar
                            localizationContext={"pages.domains"}
                            actions={[
                                {
                                    id: "create",
                                    onClick() {
                                        safeOpenDetail("domain", {
                                            isNew: true,
                                            is_active: true,
                                        });
                                    },
                                },
                            ]}
                            searchFieldName={"name"}
                        />
                    )}
                />
            )}
            onRenderDetail={() => <DomainDetail />}
        >
            <MasterDetailTable dataSource={dataSource} entityType={"domain"} columns={columns} />
        </MasterDetailView>
    );
}
