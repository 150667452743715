import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useTheme, withStyles } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { Halign } from "../../../Components/Flex";
import _ from "lodash";
import { useNotification } from "../../../Notification";
import { usePostMutate } from "../../../Api";
import { useHasAnyPermission } from "../../../Utils/Permissions/RequireAnyPermission";
import { DialogConfirmSelectionCancelButtonsOnSubmit } from "../../../Components/MasterDetail/DialogSaveCancelButtons";
import { useIntl } from "react-intl";
import { SvgView } from "./SvgView";
import { useTimeoutWhen, useToggle } from "rooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { RnrViewTooltip } from "./RnrDeviceTooltip";

function TrafficStateItem({ trafficState, selected, requested, enabled, onClick, fallbacked }) {
    const theme = useTheme();

    const borderColor = useMemo(() => {
        let color = "grey";
        if (enabled) {
            color = "black";
        }
        if (requested) {
            color = "yellow";
        }
        if (selected) {
            color = "lime";
        }
        if (fallbacked) {
            color = "red";
        }
        return color;
    }, [selected, requested, enabled]);

    const icon = useMemo(() => {
        let icon = trafficState.selectableIcon;

        if (requested && trafficState.pendingIcon) {
            icon = trafficState.pendingIcon;
        }
        if (selected && trafficState.currentIcon) {
            icon = trafficState.currentIcon;
        }
        if (fallbacked && trafficState.fallbackIcon) {
            icon = trafficState.fallbackIcon;
        }
        if (icon) {
            return JSON.parse(icon);
        } else return null;
    }, [selected, requested, enabled, trafficState, fallbacked]);

    const baseStyle = {
        cursor: enabled ? "pointer" : "default",
    };

    const displayText = trafficState?.displayName ? trafficState?.displayName : trafficState.name;
    const hidden = trafficState?.hidden;

    if (hidden) {
        return null;
    }

    if (icon?.data) {
        return (
            <RnrViewTooltip title={displayText}>
                <div style={{ ...baseStyle, height: 52, display: "flex" }} onClick={() => enabled && onClick(trafficState.name)}>
                    <img src={`data:image/svg+xml;base64,${icon?.data}`} style={{ height: "100%", width: "100%" }} />
                </div>
            </RnrViewTooltip>
        );
    } else {
        return (
            <Halign
                style={{
                    ...baseStyle,
                    border: `2px solid ${borderColor}`,
                    backgroundColor: "#939598",
                    height: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    color: "black",
                    minWidth: 100,
                    flex: 1,
                }}
                onClick={() => enabled && onClick(trafficState.name)}
            >
                <Typography variant="subtitle1">{displayText}</Typography>
            </Halign>
        );
    }
}

function TrafficStateControl({ trafficStates, rnrTrafficStateData, reloadData, style, onTrafficStateSelected, domainId, deviceStates }) {
    const hasPermRnrSetState = useHasAnyPermission({ permission: "rnr__set_state", domainID: domainId });

    const isTrafficStateActive = useMemo(() => {
        if (!rnrTrafficStateData?.active_traffic_state_detail?.data) {
            return false;
        }

        let ret = true;
        for (const trafficState of rnrTrafficStateData.active_traffic_state_detail.data) {
            const rnrState = deviceStates?.[trafficState.rnr_id];
            const endDeviceState = rnrState?.state?.current_state?.slices?.[trafficState.nr_id]?.[trafficState.device_id];
            const currentSymbols = endDeviceState?.current_display?.symbols || [];
            const hasCurrentSymbols = _.isEqual(currentSymbols, trafficState.symbols);
            if (!hasCurrentSymbols) {
                ret = false;
            }
        }
        return ret;
    }, [deviceStates, rnrTrafficStateData]);

    const trafficStatesDataSorted = useMemo(() => {
        return trafficStates?.data?.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    }, [trafficStates]);

    const fallbackedTrafficState = useMemo(() => {
        if (trafficStatesDataSorted) {
            const activeStateName = rnrTrafficStateData?.active_traffic_state?.name;
            const activeTrafficState = trafficStatesDataSorted.find((state) => state.name === activeStateName);

            if (activeTrafficState?.hidden) {
                //fallbacked indication should be shown only when selected traffic state is hidden
                return trafficStatesDataSorted.find((state) => state.safe_state === activeStateName);
            }
        }
    }, [rnrTrafficStateData, trafficStatesDataSorted]);

    if (_.isEmpty(trafficStatesDataSorted)) {
        return null;
    }

    return (
        <div
            style={{
                display: "flex",
                flexBasis: "50%",
                gridGap: 4,
                margin: 2,
                width: "100%",
                ...style,
                justifyContent: "center",
            }}
        >
            {trafficStatesDataSorted?.map((trafficState) => {
                return (
                    <TrafficStateItem
                        trafficState={trafficState}
                        selected={isTrafficStateActive && rnrTrafficStateData?.active_traffic_state?.name === trafficState.name}
                        requested={
                            rnrTrafficStateData?.requested_traffic_state?.name === trafficState.name || (!isTrafficStateActive && rnrTrafficStateData?.active_traffic_state?.name === trafficState.name)
                        }
                        enabled={hasPermRnrSetState}
                        onClick={(trafficState) => onTrafficStateSelected(trafficState)}
                        fallbacked={fallbackedTrafficState?.name === trafficState.name}
                    />
                );
            })}
        </div>
    );
}

export const CustomDialog = withStyles({
    paper: {
        margin: 0,
    },
})(Dialog);

export const CustomContent = withStyles({
    root: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 4,
        paddingTop: 4,
    },
})(DialogContent);

function TrafficStateSelectionDialog({ open, onClose, selectedTrafficState, endDevices, trafficStates, rnrTrafficStateData, reloadData, rnrDriverId, domainId, slices }) {
    const intl = useIntl();
    const theme = useTheme();
    const notification = useNotification();
    const [refresh, setRefresh] = useToggle(true);
    const { postData } = usePostMutate("/rnr-project/traffic-state/");

    const { selectedTrafficStateData, stateInfo } = useMemo(() => {
        const data = selectedTrafficState.left
            ? trafficStates.leftTrafficStates.data.find((state) => state.name === selectedTrafficState.left)
            : trafficStates.rightTrafficStates.data.find((state) => state.name === selectedTrafficState.right);

        if (data) {
            return {
                stateInfo: data,
                selectedTrafficStateData: data.data.map((state) => {
                    return {
                        id: `${state.rnr_id}_${state.nr_id}_${state.device_id}`,
                        symbols: state.symbols,
                        isMandatory: state.is_mandatory,
                        delay: state.delay,
                        nrId: `${state.rnr_id}_${state.nr_id}`,
                    };
                }),
            };
        }
    }, [selectedTrafficState]);

    const isSelectedTrafficStateActive = useMemo(
        () => rnrTrafficStateData?.left_lane?.active_traffic_state?.name === selectedTrafficState.left || rnrTrafficStateData?.right_lane?.active_traffic_state?.name === selectedTrafficState.right,
        [rnrTrafficStateData, rnrTrafficStateData]
    );

    useEffect(() => {
        setRefresh();
    }, [open, rnrTrafficStateData]);

    useTimeoutWhen(
        () => {
            setRefresh();
        },
        100,
        open
    );

    return (
        <CustomDialog onClose={onClose} open={open} fullWidth maxWidth={"xl"} fullScreen>
            <IconButton
                color="inherit"
                onClick={onClose}
                style={{
                    position: "absolute",
                    right: theme.spacing(1),
                    top: theme.spacing(1),
                }}
            >
                <FontAwesomeIcon icon={faTimes} />
            </IconButton>
            <DialogTitle>
                <Halign horizontal={"flex-start"}>
                    {stateInfo?.selectableIcon ? (
                        <div style={{ height: 52, display: "flex", marginLeft: -8 }}>
                            <img src={`data:image/svg+xml;base64,${JSON.parse(stateInfo.selectableIcon)?.data}`} style={{ height: "100%", width: "100%" }} />
                        </div>
                    ) : stateInfo?.displayName ? (
                        stateInfo.displayName
                    ) : (
                        stateInfo.name
                    )}
                </Halign>
            </DialogTitle>
            <CustomContent>
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}
                >
                    {stateInfo?.note && (
                        <Typography
                            style={{
                                paddingLeft: 12,
                                paddingBottom: 12,
                                paddingRight: 12,
                                alignSelf: "flex-start",
                                whiteSpace: "pre-wrap",
                            }}
                            variant={"subtitle1"}
                        >
                            {stateInfo.note}
                        </Typography>
                    )}

                    <div
                        style={{
                            height: "95%",
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            flexDirection: "column",
                            overflow: "hidden",
                        }}
                    >
                        <SvgView
                            slices={slices}
                            endDevices={endDevices}
                            rnrTrafficStateData={rnrTrafficStateData}
                            symbolsOverride={selectedTrafficStateData}
                            fillParent={false}
                            refreshTrigger={refresh}
                        />
                    </div>
                </div>
            </CustomContent>
            <DialogActions>
                <DialogConfirmSelectionCancelButtonsOnSubmit
                    onClose={onClose}
                    onSubmit={async () => {
                        await notification.showApiMessage(
                            postData({
                                rnr_project_device_id: rnrDriverId,
                                left_state: selectedTrafficState.left,
                                right_state: selectedTrafficState.right,
                            })
                        );
                        reloadData();
                        onClose();
                    }}
                    confirmDisabled={isSelectedTrafficStateActive}
                />
            </DialogActions>
        </CustomDialog>
    );
}

export function TrafficStatesControl({ trafficStates, rnrTrafficStateData, reloadData, rnrDriverId, domainId, endDevices, slices }) {
    const [selectedTrafficState, setSelectedTrafficState] = useState(null);

    return (
        <>
            <TrafficStateControl
                trafficStates={trafficStates.leftTrafficStates}
                rnrTrafficStateData={rnrTrafficStateData?.left_lane}
                deviceStates={rnrTrafficStateData?.device_states}
                reloadData={reloadData}
                style={{ marginBottom: 2 }}
                onTrafficStateSelected={(trafficState) =>
                    setSelectedTrafficState({
                        left: trafficState,
                        right: undefined,
                    })
                }
                domainId={domainId}
            />
            <TrafficStateControl
                trafficStates={trafficStates.rightTrafficStates}
                rnrTrafficStateData={rnrTrafficStateData?.right_lane}
                deviceStates={rnrTrafficStateData?.device_states}
                reloadData={reloadData}
                style={{ marginTop: 2 }}
                onTrafficStateSelected={(trafficState) =>
                    setSelectedTrafficState({
                        left: undefined,
                        right: trafficState,
                    })
                }
                domainId={domainId}
            />

            {selectedTrafficState && (
                <TrafficStateSelectionDialog
                    rnrTrafficStateData={rnrTrafficStateData}
                    selectedTrafficState={selectedTrafficState}
                    trafficStates={trafficStates}
                    reloadData={reloadData}
                    rnrDriverId={rnrDriverId}
                    domainId={domainId}
                    open={true}
                    onClose={() => setSelectedTrafficState(null)}
                    endDevices={endDevices}
                    slices={slices}
                />
            )}
        </>
    );
}
