import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography, useTheme, withStyles } from "@material-ui/core";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import React, { useEffect, useMemo, useState } from "react";
import { useHistoryEndDate, useLocale } from "../../Utils/Data/hooks/gui";
import { makeStyles } from "@material-ui/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useIntl } from "react-intl";
import { stopUsingHistoryData, useIsHistoryActive } from "../../Utils/Data/hooks/server";
import clsx from "clsx";
import { HistoryCountdown, ResetHistory } from "../../Pages/View/ViewToolbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStore } from "react-redux";
import moment from "moment";
import { FastTooltip as FastTooltip } from "../FastTooltip";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

export const AlertHistoryDialogWrapper = withStyles({
    paper: {
        padding: 0,
        paddingBottom: 8,
    },
})(Dialog);

const AlertHistoryDialogWrapperContentApi = withStyles({
    root: {
        padding: 0,
        paddingTop: "0px !important",
    },
})(DialogContent);

export function translateCols(intl, root, cols) {
    for (const col of cols) {
        col.headerName = formatForId(intl, root + "" + col.field);
    }
    return cols;
}

export function TooltipedColumn({ row }) {
    return <FastTooltip title={row.value}>{row.value}</FastTooltip>;
}

export function useLocaleComparator() {
    const [locale] = useLocale();

    return useMemo(() => (a, b) => a.localeCompare(b, locale), [locale]);
}

function AlertGroupTitle({ title }) {
    return (
        <Typography gutterBottom variant="h6">
            {title}
        </Typography>
    );
}

export function AlertGroup({ title, children, style, rowCount }) {
    return (
        <div style={style}>
            {rowCount ? <AlertGroupTitle title={`${title} (${rowCount})`} /> : <AlertGroupTitle title={title} />}
            {children}
        </div>
    );
}

export const useAlertHistoryStyles = makeStyles((theme) => ({
    footerContainer: {
        display: "none",
    },
    root: {
        " & .MuiDataGrid-columnsContainer": {
            position: "block",
            zIndex: 1,
            backgroundColor: theme.palette.background.default,
            borderRadius: "7px 7px 0px 0px",
        },
        " & .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
            borderBottom: "none",
        },
    },
    row: {
        zIndex: -1,
    },
    severitySelect: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
}));

export const DataGridProps = {
    disableColumnFilter: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
    disableDensitySelector: true,
    autoHeight: true,
};

const useRefreshButtonsStyles = makeStyles((theme) => ({
    rangeButtons: {
        paddingLeft: theme.spacing(3),
    },
    button: {
        minWidth: 90,
        paddingLeft: 11,
        paddingRight: 11,
        paddingTop: 4,
        paddingBottom: 4,
    },
    buttonHistory: {
        "&>.MuiToggleButton-label": {
            color: theme.palette.historyActiveColor,
        },
    },
    root: {
        display: "flex",
    },
}));

const useAlertHistoryDialogStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        minHeight: 40,
    },
    countdown: {
        paddingLeft: theme.spacing(1.5),
        display: "flex",
    },
}));

export function useAlertRefresh(refetch, refetchTrigger, data, loadingResetDeps) {
    const historyActive = useIsHistoryActive();

    const [enabled, setEnabled] = React.useState(!historyActive);
    const [showLoading, setShowLoading] = useState(true);
    const [disabledDate, setDisabledDate] = useState(null);

    useEffect(() => {
        setEnabled(!historyActive);
    }, [historyActive]);

    useEffect(() => {
        if (enabled) {
            refetch();
        }
    }, [refetchTrigger]);

    useEffect(() => {
        if (data.loaded) {
            setShowLoading(false);
        }
    }, [data]);

    useEffect(() => {
        setShowLoading(true);
    }, loadingResetDeps);

    useEffect(() => {
        if (enabled) {
            setShowLoading(true);
            setDisabledDate(null);
            refetch();
        } else if (!historyActive) {
            setDisabledDate(moment().add(15, "minute"));
        }
    }, [enabled]);

    return {
        onChange: (enabled) => setEnabled(enabled),
        enabled: enabled,
        historyActive,
        showLoading,
        disabledDate,
    };
}

export function AlertHistoryDialogTitle({ intlId, icon, alertRefresh }) {
    const theme = useTheme();
    const intl = useIntl();
    const endDate = useHistoryEndDate();
    const classes = useAlertHistoryDialogStyles();
    const store = useStore();

    const historyActionsComputed = useMemo(() => {
        if (endDate !== null) {
            return {
                endDate: endDate,
                onExpiration: null,
                onReset: () => stopUsingHistoryData(store),
                tooltipIntlId: "actions.remainingHistoryTimeTooltip",
            };
        } else if (alertRefresh.disabledDate !== null) {
            return {
                endDate: alertRefresh.disabledDate,
                onExpiration: () => alertRefresh.onChange(true),
                onReset: () => alertRefresh.onChange(true),
                tooltipIntlId: "alertHistory.refresh.control.remainingTimeTooltip",
            };
        } else {
            return {
                endDate: null,
                onExpiration: null,
                onReset: null,
                tooltipIntlId: null,
            };
        }
    }, [endDate, alertRefresh.disabledDate]);

    return (
        <DialogTitle>
            <div className={classes.root}>
                <FontAwesomeIcon icon={icon} size={"lg"} style={{ paddingRight: theme.spacing(1) }} />
                {formatForId(intl, intlId)}
                <AlertRefreshButtons alertRefresh={alertRefresh} />
                {historyActionsComputed.endDate !== null && (
                    <>
                        <div className={classes.countdown}>
                            <ResetHistory historyActive={alertRefresh.historyActive} onReset={historyActionsComputed.onReset} />
                        </div>
                        <Tooltip title={formatForId(intl, historyActionsComputed.tooltipIntlId)}>
                            <div className={classes.countdown}>
                                <HistoryCountdown onExpiration={historyActionsComputed.onExpiration} endDate={historyActionsComputed.endDate} />
                            </div>
                        </Tooltip>
                    </>
                )}
            </div>
        </DialogTitle>
    );
}

export function AlertRefreshButtons({ alertRefresh }) {
    const classes = useRefreshButtonsStyles();
    const intl = useIntl();

    const intlId = useMemo(() => {
        if (alertRefresh.historyActive) {
            return "alertHistory.refresh.control.history";
        } else if (alertRefresh.enabled) {
            return "alertHistory.refresh.control.enabled";
        }
        return "alertHistory.refresh.control.disabled";
    }, [alertRefresh.enabled, alertRefresh.historyActive]);

    return (
        <ToggleButtonGroup
            className={classes.rangeButtons}
            value={alertRefresh.enabled ? "enabled" : "disabled"}
            exclusive
            onChange={(event, value) => {
                alertRefresh.onChange(value === "enabled");
            }}
        >
            <ToggleButton value="enabled" className={alertRefresh.historyActive ? clsx(classes.button, classes.buttonHistory) : classes.button} disabled={alertRefresh.historyActive}>
                {formatForId(intl, intlId)}
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

export function AlertHistoryDialogWrapperContent({ children, onClose }) {
    const theme = useTheme();
    return (
        <AlertHistoryDialogWrapperContentApi>
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
                <div
                    style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 20,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {children}
                </div>
                <IconButton
                    color="inherit"
                    onClick={onClose}
                    style={{
                        position: "absolute",
                        right: theme.spacing(1),
                        top: theme.spacing(1),
                    }}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </IconButton>
            </div>
        </AlertHistoryDialogWrapperContentApi>
    );
}
