export default {
   detailOpened: false,
   detailEditable: false,
   entityType: null,
   entity: null,
   userChanged: false,

   ///custom form state
   form: null,
   focusedFieldId: null,
};