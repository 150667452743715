import React from "react";

export const Halign = ({ children, style, vertical, horizontal, width, gap, reverse, ...props }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: reverse ? "row-reverse" : "row",
                justifyContent: horizontal || "center",
                alignItems: vertical || "center",
                width: width || "auto",
                gap: gap,

                ...style,
            }}
            {...props}
        >
            {children}
        </div>
    );
};

export const Valign = ({ children, style, vertical, horizontal, gap, reverse, ...props }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: reverse ? "column-reverse" : "column",
                justifyContent: vertical || "center",
                alignItems: horizontal || "center",
                gap: gap,
                ...style,
            }}
            {...props}
        >
            {children}
        </div>
    );
};
