export function setCompactDrawer(store, compactDrawer) {
    store.dispatch({ type: "METEO::GUI", payload: { compactDrawer } });
}

export function setViewDimension(store, dimensions) {
    store.dispatch({ type: "METEO::GUI", payload: { view: dimensions } });
}

export function openDialog(store, { type, entity }) {
    window.history.pushState(null, null, "?openedDialog=1");
    store.dispatch({ type: "METEO::GUI", payload: { openedDialog: { type, entity } } });
}

export function openLoadingDialog(store, { type, entity }) {
    store.dispatch({ type: "METEO::GUI", payload: { openedLoadingDialog: { type, entity } } });
}

export function closeDialog(store) {
    store.dispatch({ type: "METEO::GUI", payload: { openedDialog: null } });
    window.history.replaceState(null, null, window.location.pathname);
}

export function closeLoadingDialog(store) {
    store.dispatch({ type: "METEO::GUI", payload: { openedLoadingDialog: null } });
}

export function setTableViewSelectedLine(store, lineIdx) {
    store.dispatch({ type: "METEO::GUI", payload: { tableViewSelectedLine: lineIdx } });
}

export function setTableViewPinnedLine(store, pinned) {
    store.dispatch({ type: "METEO::GUI", payload: { tableViewPinnedLine: pinned } });
}

export function setCompactTableView(store, compactTableView) {
    store.dispatch({ type: "METEO::GUI", payload: { compactTableView } });
}

export function setCompactTableViewLabel(store, compactTableViewLabel) {
    store.dispatch({ type: "METEO::GUI", payload: { compactTableViewLabel } });
}

export function setCustomAppBar(store, visible) {
    store.dispatch({ type: "METEO::GUI", payload: { customAppBar: visible } });
}

export function setMenuOpen(store, menu) {
    store.dispatch({ type: "METEO::MENU", payload: { openMenu: menu } });
}

export function setTableViewFirstVisibleItem(store, idx) {
    store.dispatch({ type: "METEO::GUI", payload: { tableViewFirstVisibleItem: idx } });
}

export function setTableViewHeight(store, height) {
    store.dispatch({ type: "METEO::GUI", payload: { tableViewHeight: height } });
}

export function setHistoryEndDate(store, date) {
    store.dispatch({ type: "METEO::GUI", payload: { historyEndDate: date } });
}
