import UserActions from "./Navigation/UserActions";
import AppBar from "@material-ui/core/AppBar";
import React from "react";
import ViewsButton from "./Navigation/VIewsButton";
import Toolbar from "@material-ui/core/Toolbar";

export function MeteoAppBarBase({children}) {

    return (<AppBar position="sticky" elevation={0}>
        <Toolbar>
            <ViewsButton/>
            <div style={{flexGrow: 1, display: "flex", alignItems: "center"}}>
                {children}
            </div>
            <UserActions/>
        </Toolbar>
    </AppBar>);
}