import React, {useMemo, useState} from "react";
import MasterDetailView from "../../../Components/MasterDetail/MasterDetailView";
import Button from "@material-ui/core/Button";
import {formatForId, translateColumns} from "../../../Utils/Lang/IntlHelper";
import {useIntl} from "react-intl";
import _ from "loadsh";
import {useSafeOpenDetail} from "../../../Utils/Data/hooks/masterDetail";
import MasterDetailContextMenu from "../../../Components/MasterDetail/MasterDetailContextMenu";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import {DetailContextMenu} from "../../../Components/MasterDetail";
import {openDialog} from "../../../Utils/Data/actions/gui";
import {useStore} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MasterDetailTable from "../../../Components/MasterDetail/MasterDetailTable";
import {faEye, faUsers} from "@fortawesome/pro-solid-svg-icons";
import DataExportDetail from "./DataExportDetail";
import {faFileExport} from "@fortawesome/pro-light-svg-icons";
import {useGetInMemoryDataList} from "../../../Utils/Data/hooks/dataList";

export default function DataExport() {
    const intl = useIntl();
    const store = useStore();

    const columns = useMemo(() => [
        {
            field: 'name', sortable: true, width: 200, renderCell: (params) => {
                return <Button><FontAwesomeIcon icon={faFileExport}/>&nbsp;{params.row.name}</Button>
            },

        },
        {
            field: 'output_type', width: 200, renderCell: ({row}) => {
                return <div>{row.data_format_type}@{row.output_type}
                    <div style={{whiteSpace: "nowrap", overflowX: "hidden"}}>
                        {row.output_type === "URL" &&
                            <a href={window.location.protocol + "//" + window.location.host + "/public-api/data-export/" + row.export_file_name}
                               target="_blank">
                                {window.location.protocol + "//" + window.location.host}/public-api/data-export/<span
                                style={{color: "red"}}>{row.export_file_name}</span>
                            </a>}

                        {row.output_type === "FILE" && row.export_file_name}
                    </div>
                </div>;
            }
        },
        {field: 'is_active', width: 150, type: "boolean"}], []);

    const safeOpenDetail = useSafeOpenDetail();
    const dataSource = useGetInMemoryDataList({
        url: "data-export/list/",
        defaultSort: {field: "name", isAscending: true}
    });


    return <MasterDetailView loading={dataSource.loading} loadingError={dataSource.error}
                             onRenderContextMenu={({isDetailOpened, viewMode}) =>
                                 <MasterDetailContextMenu isDetailOpened={isDetailOpened} viewMode={viewMode}
                                                          onRenderDetailMenu={() => <DetailContextMenu
                                                              onRenderBeforeLabel={() => <FontAwesomeIcon
                                                                  icon={faEye}/>}/>}
                                                          onRenderMasterMenu={() => <ActionToolbar
                                                              localizationContext={"pages.data-export"}
                                                              actions={[{
                                                                  id: "create",
                                                                  onClick() {
                                                                      safeOpenDetail("data-export", {
                                                                          isNew: true,
                                                                          is_active: true
                                                                      });
                                                                  }
                                                              }]} searchFieldName={"name"}/>}/>}
                             onRenderDetail={() => <DataExportDetail/>}>

        <MasterDetailTable dataSource={dataSource} entityType={"data-export"} columns={columns}/>
    </MasterDetailView>

}