import React, {useEffect, useMemo, useRef} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {IconButton, InputAdornment, TextField, Typography} from "@material-ui/core";
import {useIntl} from "react-intl";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import Mousetrap from "mousetrap";
import {faXmark} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setActiveDeviceDataViewSearchKey} from "../../Utils/Data/actions/deviceDataView";
import {useStore} from "react-redux";
import _ from "loadsh";
import {useDeviceDataActiveViewDevices} from "../../Utils/Data/hooks/deviceDataView";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        flexGrow: 1,
        display: "flex"
    }
}));

export default function DeviceSearch({onChange, value}) {
    const classes = useStyles();
    const intl = useIntl();
    const inputRef = useRef();
    const store = useStore();

    const devices = useDeviceDataActiveViewDevices();

    const deviceCount = useMemo(() => {
        if (devices) {
            return devices.length
        } else return ""
    }, [devices]);

    useEffect(() => {
        Mousetrap.bind("ctrl+f", (e) => {
            e.preventDefault();
            if (inputRef && inputRef.current) {
                inputRef.current.focus();
            }
        });
        return () => Mousetrap.unbind("ctrl+f");
    }, []);


    return <div className={classes.root}>
        <TextField
            size={"small"}
            variant={"outlined"}
            inputRef={inputRef}
            value={value}
            onChange={onChange}
            InputProps={{
                endAdornment: !_.isEmpty(value) && <InputAdornment position="end">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Typography variant={"body1"}
                                    color={"textSecondary"}>{deviceCount}</Typography>
                        <IconButton onClick={() => setActiveDeviceDataViewSearchKey(store, "")}>
                            <FontAwesomeIcon icon={faXmark} size={"xs"}/>
                        </IconButton>
                    </div>

                </InputAdornment>,
            }}
            placeholder={formatForId(intl, "forms.search")}
        />
    </div>
}