import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import { useMemo } from "react";

export const ItemWidth = 215;
export const ItemHeight = 20;
export const DoubleItemHeight = 40;
export const MaxItemHeight = 45;
export const CompactItemWidth = 135;
export const LabelWidth = 215;
export const CompactLabelWidth = 94;

export const CompactTableItemHeight = 1135;
export const TableItemHeight = 1185;

export const AlertHeight = 132;
export const CompactAlertHeight = 122;
export const CompactCameraHeight = 87;
export const ForecastHeight = 90;
export const CompactForecastHeight = 120;
export const CameraHeight = 107;
export const StateHeight = 94;
export const CompactStateHeight = 120;
export const TitleHeight = 59;

export const TableViewItemPadding = {
    paddingTop: 8,
    paddingBottom: 8,
};

const useTableViewStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexFlow: "row noWrap",
        width: "fit-content",
        "&.compactView $sameItemWidth": {
            width: CompactItemWidth + "px!important",
        },
        "&.compactViewLabel $labelsLeft": {
            width: CompactLabelWidth + "px!important",
        },
        "&.compactView $sectionForecast": {
            height: CompactForecastHeight + "px!important",
        },
        "&.compactView $sectionAlert": {
            height: CompactAlertHeight + "px!important",
        },
        "&.compactView $sectionCamera": {
            height: CompactCameraHeight + "px!important",
        },
        "&.compactView $sectionState": {
            height: CompactStateHeight + "px!important",
        },
    },
    itemGeneral: {
        borderBottom: "solid 2px rgb(81, 81, 81)",
        borderRight: "solid 2px rgb(81, 81, 81)",
        display: "flex",
        flexFlow: "column noWrap",
        flexShrink: 0,
    },
    sameItemWidth: {
        width: ItemWidth,
    },
    item: {
        alignItems: "center",
    },
    labelsLeftRoot: {
        zIndex: 2,
        position: "sticky !important",
        left: 0,
    },
    labelsLeft: {
        width: LabelWidth,
        zIndex: 2,
        left: 0,
        backgroundColor: theme.palette.background.paper,
        borderRight: "solid 2px rgb(110, 110, 110)",
        opacity: 1,
    },
    values: {
        display: "flex",
        flexFlow: "column noWrap",
        alignItems: "center",
    },
    valueItemBase: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    valueItemData: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        height: "98%",
    },
    valueItem: {
        borderTop: "solid 1px rgb(81, 81, 81)",
    },
    valueItemSelected: {
        backgroundColor: theme.palette.hover + "!important",
    },
    valueItemOdd: {
        backgroundColor: theme.palette.divider,
    },
    section: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        justifyContent: "space-between",
        display: "flex",
        flexFlow: "row noWrap",
        alignItems: "center",
    },
    sectionTitle: {
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(0.25),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: TitleHeight,
        width: "100%",
        borderBottom: "solid 2px rgb(110, 110, 110)",
        opacity: 1,
        zIndex: 1,
        position: "sticky",
        top: 0,
        overflow: "hidden",
    },
    sectionTitleItem: {},
    sectionState: {
        height: StateHeight,
        ...TableViewItemPadding,
    },
    sectionAlert: {
        ...TableViewItemPadding,
        height: AlertHeight,
    },
    sectionForecast: {
        height: ForecastHeight,
        width: "100%",
        ...TableViewItemPadding,
    },
    sectionCamera: {
        overflow: "hidden",
        height: CameraHeight,
        ...TableViewItemPadding,
    },
    sectionSingleValue: {
        borderTop: "solid 1px rgb(81, 81, 81)",
    },
    headerColumn: {
        maxWidth: "100%",
        paddingLeft: theme.spacing(1),
    },
    noData: {
        padding: theme.spacing(1),
    },
    value: {
        textAlign: "center",
    },
}));

const sameItemWidth = ["item", "actions", "valueItem", "sectionState", "sectionAlert", "sectionForecast"];

export const doubleHeightValueItems = ["dateTime", "precip_type", "road_status", "road_status2", "zpi_road_surface", "zpi_road_surface2", "its_road_surface"];

export function useStyles() {
    const classes = useTableViewStyles();

    return useMemo(() => {
        const result = { ...classes };
        for (const key of sameItemWidth) {
            result[key] = clsx(result[key], classes.sameItemWidth);
        }

        return result;
    }, []);
}
