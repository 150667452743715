import React, { useMemo, useState } from "react";
import GroupDetail from "./UserGroupDetail";
import MasterDetailView from "../../../Components/MasterDetail/MasterDetailView";
import Button from "@material-ui/core/Button";
import { formatForId, translateColumns } from "../../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import _ from "loadsh";
import { useSafeOpenDetail } from "../../../Utils/Data/hooks/masterDetail";
import MasterDetailContextMenu from "../../../Components/MasterDetail/MasterDetailContextMenu";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { DetailContextMenu } from "../../../Components/MasterDetail";
import { openDialog } from "../../../Utils/Data/actions/gui";
import { useStore } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MasterDetailTable from "../../../Components/MasterDetail/MasterDetailTable";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import { useGetInMemoryDataList } from "../../../Utils/Data/hooks/dataList";

export default function UserGroups() {
    const intl = useIntl();
    const store = useStore();

    const columns = useMemo(
        () => [
            {
                field: "name",
                sortable: true,
                width: 200,
                renderCell: (params) => {
                    return (
                        <Button>
                            <FontAwesomeIcon icon={faUsers} />
                            &nbsp;{params.row.name}
                        </Button>
                    );
                },
            },
            { field: "is_active", width: 150, type: "boolean" },
            {
                field: "roles",
                width: 120,
                renderCell: (params) => (
                    <Button
                        style={{ textAlign: "center" }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            openDialog(store, { type: "userGroupEditRoles", entity: params.row });
                        }}
                    >
                        {formatForId(intl, "pages.userGroup.editRoles")}
                    </Button>
                ),
            },
            {
                field: "members",
                width: 120,
                renderCell: (params) => (
                    <Button
                        style={{ textAlign: "center" }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            openDialog(store, { type: "userGroupMembers", entity: params.row });
                        }}
                    >
                        {formatForId(intl, "pages.userGroup.members")}
                    </Button>
                ),
            },
        ],
        []
    );

    const safeOpenDetail = useSafeOpenDetail();

    const dataSource = useGetInMemoryDataList({
        url: "group/list/",
        defaultSort: { field: "name", isAscending: true },
    });

    return (
        <MasterDetailView
            loading={dataSource.loading}
            loadingError={dataSource.error}
            onRenderContextMenu={({ isDetailOpened, viewMode }) => (
                <MasterDetailContextMenu
                    isDetailOpened={isDetailOpened}
                    viewMode={viewMode}
                    onRenderDetailMenu={() => <DetailContextMenu onRenderBeforeLabel={() => <FontAwesomeIcon icon={faUsers} />} />}
                    onRenderMasterMenu={() => (
                        <ActionToolbar
                            localizationContext={"pages.userGroup"}
                            actions={[
                                {
                                    id: "create",
                                    onClick() {
                                        safeOpenDetail("userGroup", {
                                            isNew: true,
                                            is_active: true,
                                        });
                                    },
                                },
                            ]}
                            searchFieldName={"name"}
                        />
                    )}
                />
            )}
            onRenderDetail={() => <GroupDetail />}
        >
            <MasterDetailTable dataSource={dataSource} entityType={"userGroup"} columns={columns} />
        </MasterDetailView>
    );
}
