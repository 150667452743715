import { useIntl } from "react-intl";
import { Chip, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton } from "@material-ui/core";
import React, { useMemo } from "react";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { makeStyles } from "@material-ui/styles";
import _ from "loadsh";
import { EntitySelectionDialog } from "../EntitySelectionDialog";
import { getDefaultLocale, getYamlIntlCatalog } from "../../Utils/Lang/LangLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";

export const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    editIcon: {
        marginRight: theme.spacing(2),
    },
}));

function getChoices(intl) {
    const locale = getDefaultLocale();
    const catalog = getYamlIntlCatalog(locale);

    let ret = [];
    for (const [key, value] of Object.entries(catalog.alert.type)) {
        if (key !== "boschung") {
            ret.push({ id: `${key}`, name: `${formatForId(intl, `alert.type.${key}.name`)} (${key})` });
        }
    }
    for (const [key, value] of Object.entries(catalog.alert.type.boschung)) {
        ret.push({ id: `boschung.${key}`, name: `${formatForId(intl, `alert.type.boschung.${key}.name`)} (${key})` });
    }

    ret = ret.filter((item) => !item.name.includes("alert.type."));

    return ret;
}

export function AlertSelectionField({ inputRef, fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete }) {
    const intl = useIntl();

    const classes = useStyles();

    const choices = useMemo(() => getChoices(intl), [intl]);
    const compact = fieldDef.props?.compact || false;
    const [dialog, setDialog] = React.useState(false);
    const closeDialog = () => {
        setDialog(false);
    };

    const openDialog = () => {
        setDialog(true);
    };

    const onSelect = (selection) => {
        changeHandle({ target: { name: fieldId, value: selection } });
        closeDialog();
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControl fullWidth required={!!required} {...fieldDef.props?.formControl}>
                <FormLabel component="legend" disabled={disabled}>{label}</FormLabel>
                <FormControlLabel
                    style={{ padding: "10px 17px" }}
                    label={""}
                    control={
                        <div className={classes.chips}>
                            {_.isEmpty(value) ? (
                                compact ? (
                                    <Chip disabled={disabled} onClick={openDialog} label={formatForId(intl, "AlertSelectionDialog.EmptyChip")}></Chip>
                                ) : (
                                    <></>
                                )
                            ) : (
                                value.map((alert) => {
                                    return (
                                        <Chip
                                            disabled={disabled}
                                            onClick={compact ? openDialog : () => {}}
                                            key={alert}
                                            label={formatForId(intl, `alert.type.${alert}.name`)}
                                            className={classes.chip}
                                        />
                                    );
                                })
                            )}
                        </div>
                    }
                />
                {touched && Boolean(errors) && <FormHelperText error={true}>{errors}</FormHelperText>}
            </FormControl>
            {!disabled && !compact && (
                <IconButton onClick={openDialog} className={classes.button}>
                    <FontAwesomeIcon icon={faEdit} className={classes.editIcon} />
                    {formatForId(intl, `forms.${fieldId}.edit`)}
                </IconButton>
            )}
            <EntitySelectionDialog open={dialog} localizationContext={"AlertSelectionDialog"} choices={choices} onSelect={onSelect} onClose={closeDialog} selection={value} />
        </div>
    );
}
