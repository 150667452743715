import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import {Paper} from "@material-ui/core";
import EventsData from "./EventsData";
import RequireAdminPermissions from "../../Utils/Permissions/RequireAdminPermission";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        border: "none",
        borderRadius: 0
    },
    header: {
        paddingBottom: theme.spacing(3)
    }
}));



function EventList({selectedItem, subMenuIdx, ...props}) {
    const classes = useStyles();
    const intl = useIntl();


    return <RequireAdminPermissions> <Paper className={classes.root}>
        <EventsData limit={1000} />
    </Paper></RequireAdminPermissions>
}

export default EventList;
