import {useIntl} from "react-intl";
import React from "react";
import {CircularProgress, Dialog, DialogContent, Typography} from "@material-ui/core";
import {formatForId} from "../Utils/Lang/IntlHelper";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: 100,
        minHeight: 100,

    },
    progress: {
        marginBottom: 8
    }
})

export function LoadingDialog({open, error}) {
    const intl = useIntl();
    const classes = useStyles();


    return <Dialog open={open}>
        <DialogContent>
            <div className={classes.root}>
                <Typography variant={"h5"}>
                    {formatForId(intl, error ? "messagess.loadingError" : "messagess.loading")}
                </Typography>
                {error ? JSON.stringify(error) : <CircularProgress className={classes.progress}/>}
            </div>
        </DialogContent>
    </Dialog>
}
