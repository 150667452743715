import React, { useMemo } from "react";
import UserDetail from "./UserDetail";
import MasterDetailView from "../../../Components/MasterDetail/MasterDetailView";
import Button from "@material-ui/core/Button";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import _ from "loadsh";
import { UserIcon } from "./../../../Components/Icons/ToolbarIcons";
import { useSafeOpenDetail } from "../../../Utils/Data/hooks/masterDetail";
import MasterDetailContextMenu from "../../../Components/MasterDetail/MasterDetailContextMenu";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { DetailContextMenu } from "../../../Components/MasterDetail";
import { openDialog } from "../../../Utils/Data/actions/gui";
import { useStore } from "react-redux";
import MasterDetailTable from "../../../Components/MasterDetail/MasterDetailTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { useGetInMemoryDataList } from "../../../Utils/Data/hooks/dataList";
import { formatTime } from "../../../Utils/Data/Time";
import { Tooltip } from "@material-ui/core";

export function Users() {
    const intl = useIntl();
    const store = useStore();

    const columns = useMemo(
        () => [
            { field: "id", width: 100, type: "number", hidden: true },
            {
                field: "email_address",
                sortable: true,
                headerName: "Email",
                width: 200,
                renderCell: (params) => {
                    return (
                        <Button>
                            <FontAwesomeIcon icon={faUser} />
                            &nbsp;{params.row.login}
                        </Button>
                    );
                },
            },
            { field: "is_active", width: 150, type: "boolean", align: "center" },
            {
                field: "roles",
                width: 120,
                renderCell: (params) => (
                    <Button
                        style={{ textAlign: "center" }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            openDialog(store, { type: "userEditRoles", entity: params.row });
                        }}
                    >
                        {formatForId(intl, "pages.users.editRoles")}
                    </Button>
                ),
            },
            {
                field: "password",
                width: 120,
                renderCell: (params) => (
                    <Tooltip title={params.row.last_pwd_change_ts ? formatTime(params.row.last_pwd_change_ts) : formatForId(intl, "pages.users.passwordNotSet")}>
                        <Button
                            style={{ textAlign: "center", align: "left" }}
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                openDialog(store, { type: "userChangePassword", entity: params.row });
                            }}
                        >
                            {formatForId(intl, "pages.users.changePassword")}
                        </Button>
                    </Tooltip>
                ),
            },
            {
                field: "groups",
                width: 120,
                renderCell: (params) => (
                    <Button
                        style={{ textAlign: "center" }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            openDialog(store, { type: "userGroups", entity: params.row });
                        }}
                    >
                        {formatForId(intl, "pages.users.groups")}
                    </Button>
                ),
            },
        ],
        []
    );

    const safeOpenDetail = useSafeOpenDetail();
    const dataSource = useGetInMemoryDataList({
        url: "users/",
        defaultSort: { field: "email_address", isAscending: true },
        preprocessData: (apiUsers) =>
            _.map(apiUsers, (user) => {
                user.name = user.login;
                return user;
            }),
    });

    return (
        <MasterDetailView
            loading={dataSource.loading}
            loadingError={dataSource.error}
            onRenderContextMenu={({ isDetailOpened, viewMode }) => (
                <MasterDetailContextMenu
                    isDetailOpened={isDetailOpened}
                    viewMode={viewMode}
                    onRenderDetailMenu={() => <DetailContextMenu onRenderBeforeLabel={() => <UserIcon />} />}
                    onRenderMasterMenu={() => (
                        <ActionToolbar
                            localizationContext={"pages.users"}
                            actions={[
                                {
                                    id: "create",
                                    onClick() {
                                        safeOpenDetail("user", {
                                            isNew: true,
                                            is_active: true,
                                        });
                                    },
                                },
                            ]}
                            searchFieldName={"login"}
                        />
                    )}
                />
            )}
            onRenderDetail={() => <UserDetail />}
        >
            <MasterDetailTable dataSource={dataSource} entityType={"user"} columns={columns} />
        </MasterDetailView>
    );
}
