export const ViewMode = {
    MasterDetail: "MasterDetail",
    Dialog: "Dialog",
};

export default {
    compactDrawer: true,
    view: { width: window.innerWidth, height: window.innerHeight },
    viewMode: ViewMode.MasterDetail,
    openedDialog: null,
    openedLoadingDialog: null,
    tableViewSelectedLine: -1,
    tableViewPinnedLine: false,
    compactTableView: localStorage.getItem("tableCompactView") === "true",
    compactTableViewLabel: localStorage.getItem("compactTableViewLabel") === "true",
    customAppBar: false,
    tableViewFirstVisibleItem: 0,
    tableViewHeight: 0,
    historyEndDate: null,
};
