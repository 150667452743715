import {IconButton, SvgIcon, Tooltip} from "@material-ui/core";
import React from "react";
import {useCompactDrawer} from "../../Utils/Data/hooks/gui";
import {useStore} from "react-redux";
import {setCompactDrawer} from "../../Utils/Data/actions/gui";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {useIntl} from "react-intl";
import {ReactComponent as Logo} from "../../Icons/logo_dark_white_only.svg";


export default function DrawerButton({...props}) {
    const compact = useCompactDrawer();
    const store = useStore();
    const intl = useIntl();

    const changeCompact = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        setCompactDrawer(store, !compact);
    }

    return <Tooltip title={formatForId(intl, "pages.main.menu.settings")}>
        <IconButton
            {...props}
            onClick={changeCompact}>
            <SvgIcon style={{fontSize: "2rem"}}>
                <Logo/>
            </SvgIcon>
        </IconButton>
    </Tooltip>
}