import { useMap } from "react-leaflet";
import L from "leaflet";
import React, { forwardRef, useEffect, useState } from "react";
import TooltipContainer from "../TooltipContainer";
import * as ReactDOMServer from "react-dom/server";

const IconStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    color: "#ededed",
    lineHeight: 2,
    width: 15,
    height: 15,
    marginRight: 8,
    flexBasis: 15,
};

function TooltipContent({ data, color }) {
    return (
        <TooltipContainer>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                }}
            >
                <div style={{ ...IconStyles, background: color }}></div>

                <div
                    style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        flex: 1,
                    }}
                >
                    {data}
                </div>
            </div>
        </TooltipContainer>
    );
}

export const NiraAlertLayer = forwardRef(({ initialData, onClick, showInfo = true }, ref) => {
    const map = useMap();

    const [wasCentered, setWasCentered] = useState(false);

    useEffect(() => {
        const geoJson = L.geoJSON(initialData, {
            onEachFeature: (feature, layer) => {
                const color = feature.properties.color || "whitesmoke";
                if (showInfo) {
                    layer
                        .bindTooltip(
                            ReactDOMServer.renderToString(<TooltipContent color={feature.properties.color} data={feature.properties.name} />),

                            { permanent: true, direction: "auto", interactive: true }
                        )
                        .openTooltip();
                }
                layer.setStyle({ fillColor: color, fillOpacity: 0.25, opacity: 0.5, color: color });
                layer.on("click", (e) => {
                    if (onClick) {
                        onClick(feature.properties);
                    }
                });
            },

            pane: "overlayPane",
            interactive: false,
        });
        map.addLayer(geoJson);

        const bounds = geoJson.getBounds();
        if (!wasCentered) {
            if (bounds.isValid()) {
                map.fitBounds(bounds);
            }
            setWasCentered(true);
        }

        return () => {
            map.pm.removeControls();
            map.removeLayer(geoJson);
        };
    }, [map, initialData]);

    return <></>;
});
