import React, { useEffect, useMemo, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import { useAppAccess } from "../../Utils/Data/hooks/server";
import _ from "loadsh";
import { SingleEntitySelectionDialog } from "../SingleEntitySelectionDialog";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNiraAlertConfig } from "../../Api";
import { makeStyles } from "@material-ui/styles";
import { faRotateLeft } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";

const Dialogs = {
    AreaGroups: 0,
    Areas: 1,
};

const Selection = ["areaGroup", "area"];

function Delim() {
    return (
        <div style={{ color: "lightgray" }}>
            <FontAwesomeIcon icon={faArrowRight} />
        </div>
    );
}

const useDeviceFilterStyles = makeStyles((theme) => ({
    filterButton: {
        minWidth: 0,
        "&>.MuiButton-label": {
            display: "inline-block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textAlign: "center",
        },
    },
    allFilterButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 32,
        "&>.MuiButton-label": {
            display: "inline-block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textAlign: "center",
        },
    },
}));

function DeviceFilterRow({ value, disabled, onChange, allowUnselectLast, defaultValue }) {
    const appAccess = useAppAccess();
    const intl = useIntl();
    const [dialog, setDialog] = useState(-1);
    const classes = useDeviceFilterStyles();
    const [defaultWasSet, setDefaultWasSet] = useState(false);

    const { data } = useNiraAlertConfig(true);

    const areaGroups = useMemo(
        () =>
            _.map(data, (areaGroup) => ({
                id: areaGroup.id,
                name: areaGroup.name,
            })),
        [data]
    );

    const areas = useMemo(() => {
        if (_.size(value) > Dialogs.AreaGroups) {
            const areaGroup = _.find(data, (areaGroup) => areaGroup.id === value[Dialogs.AreaGroups].id);
            if (areaGroup) {
                return areaGroup.areas.map((area, idx) => ({ name: area.name, id: idx }));
            } else {
                return [];
            }
        } else return [];
    }, [areaGroups, value]);
    const closeDialog = () => {
        setDialog(-1);
    };

    useEffect(() => {
        if (defaultValue && !defaultWasSet && !_.isEmpty(areaGroups)) {
            const areaGroup = _.find(data, (areaGroup) => areaGroup.id === defaultValue.area_group_id);
            if (areaGroup) {
                const area = areaGroup.areas[defaultValue.area_idx];
                if (area) {
                    onChange([
                        { id: areaGroup.id, name: areaGroup.name },
                        { name: area.name, id: defaultValue.area_idx },
                    ]);
                    setDefaultWasSet(true);
                }
            }
        }
    }, [defaultValue, areaGroups, areas]);

    const onSelect = (selection) => {
        const newVal = _.clone(value || []);

        const newSelection = _.clone(selection);
        delete newSelection.permissions;

        newVal[dialog] = newSelection;

        if (_.isEmpty(newSelection)) {
            newVal.splice(dialog);
        } else if (dialog < _.size(value) - 1) {
            newVal.splice(dialog + 1);
        }

        onChange(newVal);
        closeDialog();
    };

    const valueSize = _.size(value);

    return (
        <>
            <div style={{ display: "flex", direction: "row", alignItems: "baseline", paddingLeft: 8 }}>
                {valueSize > Dialogs.AreaGroups && (
                    <Tooltip title={value[Dialogs.AreaGroups].name}>
                        <Button disabled={disabled} className={classes.filterButton} onClick={() => setDialog(Dialogs.AreaGroups)}>
                            {value[Dialogs.AreaGroups].name}
                        </Button>
                    </Tooltip>
                )}
                {valueSize > Dialogs.Areas && (
                    <>
                        <Delim />
                        <Tooltip title={value[Dialogs.Areas].name}>
                            <Button disabled={disabled} className={classes.filterButton} onClick={() => setDialog(Dialogs.Areas)}>
                                {value[Dialogs.Areas].name}
                            </Button>
                        </Tooltip>
                    </>
                )}

                {valueSize < Dialogs.Areas + 1 && (
                    <>
                        {valueSize > 0 && <Delim />}
                        <Tooltip title={formatForId(intl, `GeoAlertQueryFilter.${Selection[valueSize]}`)}>
                            <Button disabled={disabled} className={classes.allFilterButton} onClick={() => setDialog(valueSize)}>
                                {formatForId(intl, `GeoAlertQueryFilter.${Selection[valueSize]}`)}
                            </Button>
                        </Tooltip>
                    </>
                )}
                {allowUnselectLast && valueSize > 0 && (
                    <Tooltip title={formatForId(intl, `GeoAlertQueryFilter.clear`)}>
                        <Button
                            disabled={disabled}
                            className={classes.allFilterButton}
                            onClick={() => {
                                onChange(_.without(value, value[valueSize - 1]));
                            }}
                        >
                            <FontAwesomeIcon icon={faRotateLeft} />
                        </Button>
                    </Tooltip>
                )}
            </div>

            {dialog === Dialogs.AreaGroups && (
                <SingleEntitySelectionDialog
                    selection={value[Dialogs.AreaGroups]}
                    onSelect={onSelect}
                    open={true}
                    onClose={closeDialog}
                    choices={areaGroups}
                    localizationContext={"AreaGroupSelectionDialog"}
                />
            )}

            {dialog === Dialogs.Areas && (
                <SingleEntitySelectionDialog selection={value[Dialogs.Areas]} onSelect={onSelect} open={true} onClose={closeDialog} choices={areas} localizationContext={"AreaSelectionDialog"} />
            )}
        </>
    );
}

export function SimpleOutlinedGeoAlertQueryField({ value, onChange, allowUnselectLast, disabled, outline, dense, defaultValue }) {
    const [focused, setFocused] = useState(false);

    const rootClassName = outline ? clsx("MuiOutlinedInput-root", focused && "Mui-focused") : null;
    const className = outline ? clsx("DateRangeField-outline", dense && "MuiInputBase-inputMarginDense", dense && "MuiOutlinedInput-inputMarginDense") : null;

    return (
        <div className={rootClassName} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
            <div className={className} style={dense && { paddingTop: 3, paddingBottom: 3 }}>
                <DeviceFilterRow disabled={disabled} value={value} onChange={onChange} allowUnselectLast={allowUnselectLast} defaultValue={defaultValue} />
            </div>
            {outline && <NotchedOutline labelWidth={1} notched={false} className={"MuiOutlinedInput-notchedOutline"} /* style={{top:"-2px"}}*/ />}
        </div>
    );
}
