import { RequirePermission } from "../../../Utils/Permissions/RequirePermission";
import LoadingScope from "../../../Components/LoadingScope";
import { useHistoryGetData } from "../../../Utils/Data/hooks/server";
import { useHistoryFilter, useRnrAuditLog } from "../../../Api";
import { Last7DaysType } from "../../../Components/Forms/DateRangeField/hooks";
import { useIntl } from "react-intl";
import { DataGridProps, translateCols, useAlertHistoryStyles } from "../../../Components/Charts/AlertHistoryDialogWrapper";
import { Grid, useTheme } from "@material-ui/core";
import DateRangePicker from "../../../Components/Forms/DateRangePicker";
import _ from "loadsh";
import { NoDataMessage } from "../../../Components/Forms/NoDataMessage";
import { DataGrid } from "@material-ui/data-grid";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import React, { useMemo } from "react";
import { useRnrViewContext } from "../../../Utils/Data/actions/RnrData";
import { formatTime } from "../../../Utils/Data/Time";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComputerClassic, faUser } from "@fortawesome/pro-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        height: "100%",
        width: "100%",
        padding: 8,
        paddingTop: 16,
        paddingBottom: 16,
        display: "flex",
        flexDirection: "column",
    },
}));

function useColumns(intl) {
    const rnrViewContext = useRnrViewContext();

    const getTrafficStateName = (params) => {
        const trafficStates = params.row.lane === "left" ? rnrViewContext.trafficStates.leftTrafficStates : rnrViewContext.trafficStates.rightTrafficStates;
        const displayName = trafficStates?.data?.find((state) => state.name === params.value)?.displayName;
        return displayName ? displayName : params.value;
    };

    return useMemo(
        () =>
            translateCols(intl, "rnrAuditLog.cols.", [
                {
                    field: "time",
                    valueFormatter: (row) => {
                        return formatTime(row.value);
                    },
                    sortable: true,
                    type: "dateTime",
                    flex: 1,
                },
                {
                    field: "user",
                    renderCell: (params) => {
                        if (params.value) {
                            return (
                                <>
                                    <FontAwesomeIcon icon={faUser} style={{ paddingRight: 3 }} />
                                    &nbsp;{params.value.name}
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <FontAwesomeIcon icon={faComputerClassic} style={{ paddingRight: 3 }} />
                                    &nbsp;{formatForId(intl, "rnrAuditLog.system")}
                                </>
                            );
                        }
                    },
                    sortable: true,
                    flex: 1,
                },
                {
                    field: "lane",
                    renderCell: (params) => formatForId(intl, `rnrAuditLog.${params.value}`),
                    sortable: true,
                    flex: 1,
                },
                {
                    field: "state",
                    sortable: true,
                    renderCell: (params) => getTrafficStateName(params),
                    flex: 1,
                },
                {
                    field: "state_change_reason",
                    minWidth: 500,
                    sortable: true,
                    flex: 2,
                },
            ]),
        [rnrViewContext]
    );
}

export function RnrAuditLog({}) {
    const historyDate = useHistoryGetData();
    const history = useHistoryFilter(Last7DaysType, historyDate);
    const intl = useIntl();
    const classes = useAlertHistoryStyles();
    const columns = useColumns(intl);
    const theme = useTheme();
    const { trafficStates, rnrDriverId, endDevices, domainId, rnrDevices, slices } = useRnrViewContext();
    const localClasses = useStyles();
    const { auditLogData, loading } = useRnrAuditLog(true, rnrDriverId, history);

    return (
        <RequirePermission permission={"rnr__audit_log"} domainID={domainId}>
            <div className={localClasses.root}>
                <Grid container={true} spacing={1} direction={"row"} alignItems={"center"}>
                    <Grid item={true} xs={12} md={12}>
                        <DateRangePicker dense={true} value={history.value} disabled={false} onChange={(value) => history.setValue(value)} />
                    </Grid>
                </Grid>
                <div style={{ height: theme.spacing(2) }}>&nbsp;</div>
                <LoadingScope loading={loading}>
                    {_.isEmpty(auditLogData) ? (
                        <NoDataMessage />
                    ) : (
                        <DataGrid
                            {...DataGridProps}
                            style={{ height: "100%" }}
                            classes={classes}
                            columns={columns}
                            rows={auditLogData}
                            pagination
                            autoHeight={false}
                            autoPageSize={true}
                            componentsProps={{
                                pagination: {
                                    labelRowsPerPage: formatForId(intl, "dataGrid.pagination.rowsPerPage"),
                                    labelDisplayedRows: ({ from, to, count }) =>
                                        formatForId(intl, "dataGrid.pagination.displayedRows", {
                                            from,
                                            to,
                                            count,
                                        }),
                                },
                            }}
                        />
                    )}
                </LoadingScope>
            </div>
        </RequirePermission>
    );
}
