import {useAppAccess} from "../../../Utils/Data/hooks/server";
import React, {useMemo} from "react";
import _ from "loadsh";
import {NoDataMessage} from "../../../Components/Forms/NoDataMessage";
import {useHistory} from "react-router-dom";
import {Card, CardActionArea, CardContent, Typography} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/pro-solid-svg-icons";
import {faCog} from "@fortawesome/pro-light-svg-icons";
import {makeStyles} from "@material-ui/core/styles";

function hasDomainSettingsPermission(acl) {
   return !!_.find(acl.permissions, perm => perm === "others__domain_settings")
}

const useStyles = makeStyles(theme => ({
   root: {
      padding: theme.spacing(4)
   },
   card:
      {
         marginBottom: theme.spacing(2)
      },
   icon:
      {
         marginRight: theme.spacing(2)
      }
}))


export function DomainSettingsLanding() {
   const classes = useStyles();
   const appAccess = useAppAccess();
   const domains = useMemo(() => _.orderBy(
      hasDomainSettingsPermission(appAccess) ?
      appAccess.domains : _.filter(appAccess.domains, hasDomainSettingsPermission), [(d) => d.name.toLowerCase()], ["asc"]),
      [appAccess]);

   const history = useHistory();


   return (<div className={classes.root}>{_.map(domains, domain =>
      <Card key={domain.id} className={classes.card}>
         <CardActionArea onClick={() => {
            history.push("./domain/" + domain.id + "/");
         }}>
            <CardContent>
               <Typography gutterBottom variant="h5" component="h2">
                  <FontAwesomeIcon icon={faCog} size={"lg"} className={classes.icon}/>&nbsp;{domain.name}
               </Typography>
            </CardContent>
         </CardActionArea>
      </Card>
   )}</div>);

}