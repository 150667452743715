import {baseFontSize} from "../../../index";


export default function TooltipContainer({children}) {
    //DO NOT USE MATERIAL HERE - IT IS RENDERED OUTSIDE THE MAIN SCOPE

    return <div style={{
        backgroundColor: "rgba(97, 97, 97, 0.9)",
        color: "#ededed",
        display: "flex",
        flexDirection: "row",
        padding: 8,
        alignItems: "flex-start",
        justifyContent: "center",
        fontSize: baseFontSize,
        fontWeight: 500,
        lineHeight: "1.4em",
        fontFamily: ["Roboto", "Helvetica", "Arial"],
        borderRadius: 4,
    }}>
        {children}
    </div>
}