import React, {useMemo} from "react";
import {Typography} from "@material-ui/core";
import {DeviceGroups, DeviceGroupsContextMenu} from "./DeviceGroups";
import {Redirect, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import {RequirePermission} from "../../../Utils/Permissions/RequirePermission";
import {ShowError} from "../../../Components/ShowError";
import DataSources from "./DataSources";
import ConfigurationTemplates from "./ConfigurationTemplates";


export function Domain() {
    const {id} = useParams();
    const {path, url} = useRouteMatch();
    const domainId = useMemo(() => parseInt(id, 10), [id]);
    return (
        <Switch>
            <Redirect exact={true} path={path} to={`${url}deviceGroups/`}/>
            <Route path={`${path}/deviceGroups/`}>
                <RequirePermission domainID={domainId} permission={"others__domain_settings"}>
                    <DeviceGroups/>
                </RequirePermission>
            </Route>
            <Route path={`${path}/dataSources/`}>
                <RequirePermission domainID={domainId} permission={"others__domain_settings"}>
                    <DataSources/>
                </RequirePermission>
            </Route>
            <Route path={`${path}/configurationTemplates/`}>
                <RequirePermission domainID={domainId} permission={"others__domain_settings"}>
                    <ConfigurationTemplates />
                </RequirePermission>
            </Route>

            {  /* <Route path={`${path}/domainSettings/`}>
                <RequirePermission domainID={domainId} permission={"others__domain_settings"}>
                    <Settings/>
                </RequirePermission>
            </Route>*/}
        </Switch>);

}

function Settings() {
    return <Typography variant={"h5"}>{"Domain Settings"}</Typography>
}
