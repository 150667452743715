import { useIntl } from "react-intl";
import { formatForId } from "../Lang/IntlHelper";
import React, { useContext, useMemo } from "react";
import _ from "underscore";
import { COLORS } from "./Colors";
import { formatTime } from "./Time";
import { formatWMOCode } from "./WMOUtils";
import { Tooltip } from "@material-ui/core";
import { useBeaufort, useWindUnit } from "./hooks/gui";

const water_thick_base = {
    unit: "milimeter",
    type: "number",
    guiVisible: true,
    precision: 2,
    graphType: "column2",
    warningDeps: [{ key: "alarm_it_senc_pws" }, { key: "zpi_road_status" }, { key: "zpi_frost_2", minLevel: 2 }],
};

const ground_temp_base = {
    unit: "celsius",
    type: "number",
    guiVisible: true,
    precision: 1,
    formatter: undegroundTempFormatter,
};

const temp_froz_comp_base = {
    unit: "celsius",
    type: "number",
    guiVisible: true,
    precision: 1,
    formatter: frozCompFormatter,
    warningDeps: [{ key: "pws_road_temp" }, { key: "alarm_it_senc_pws" }],
};

const salt_residue_base = {
    unit: "percent",
    type: "number",
    guiVisible: true,
    precision: 1,
    formatter: saltFomratter,
};

const salt_factor_base = {
    unit: "gramsPerSquareMeter",
    type: "number",
    guiVisible: true,
    precision: 0,
    formatter: saltFactorFormatter,
};

const salt_factor_content_base = {
    unit: "percent",
    type: "number",
    guiVisible: true,
    precision: 1,
    formatter: saltFactorFormatter,
};
const temp_froz_mea_base = {
    unit: "celsius",
    type: "number",
    guiVisible: true,
    precision: 1,
    formatter: frozCompFormatter,
    warningDeps: [
        { key: "frost_it_senc_pws", minLevel: 2 },
        { key: "alarm_it_arctis_pws" },
        { key: "boschung.A1" },
        { key: "boschung.A2" },
        { key: "boschung.A3" },
        { key: "boschung.A3S" },
        { key: "boschung.A3NI" },
        { key: "boschung.A3TP" },
        { key: "zpi_pws_road_temp" },
        { key: "zpi_dew_point" },
        { key: "zpi_frost_1" },
        { key: "zpi_road_status" },
        { key: "zpi_snow" },
        { key: "zpi_black_ice" },
        { key: "zpi_frost_2" },
    ],
};

const road_temp_base = {
    unit: "celsius",
    type: "number",
    guiVisible: true,
    precision: 1,
    warningDeps: [
        { key: "dew_point" },
        { key: "pws_road_temp" },
        { key: "frost_it_senc_pws", minLevel: 2 },
        { key: "alarm_it_senc_pws" },
        { key: "alarm_it_arctis_pws" },
        { key: "boschung.A1" },
        { key: "boschung.A2" },
        { key: "boschung.A3" },
        { key: "boschung.A3S" },
        { key: "boschung.A3NI" },
        { key: "boschung.A3TP" },
        { key: "zpi_pws_road_temp" },
        { key: "zpi_dew_point" },
        { key: "zpi_frost_1" },
        { key: "zpi_road_status" },
        { key: "zpi_snow" },
        { key: "zpi_black_ice" },
        { key: "zpi_frost_2" },
    ],
};

const road_surface_base = {
    unit: "",
    type: "string",
    guiVisible: true,
    formatter: surfaceFormatter,
    graphType: "stepArea",
    warningDeps: [
        { key: "alarm_it_senc_pws" },
        { key: "boschung.A1" },
        { key: "boschung.A2" },
        {
            key: "boschung.A3",
        },
        { key: "boschung.A3S" },
        { key: "boschung.A3NI" },
        { key: "boschung.A3TP" },
    ],
};

const road_status_base = {
    unit: "",
    type: "number",
    guiVisible: true,
    shownInGraph: false,
    formatter: roadStatusFormatter,
};

const ita_road_temp_base = {
    unit: "celsius",
    type: "number",
    guiVisible: true,
    precision: 1,
    warningDeps: [
        { key: "dew_point" },
        { key: "pws_road_temp" },
        {
            key: "frost_it_senc_pws",
            minLevel: 2,
        },
        { key: "alarm_it_senc_pws" },
        { key: "alarm_it_arctis_pws" },
    ],
    driverTypes: ["znp-device", "zpi-device"],
};
const ita_road_surface_base = {
    unit: "",
    type: "string",
    guiVisible: true,
    formatter: zpiRoadSurfaceFormatter,
    graphType: "stepArea",
    warningDeps: [{ key: "alarm_it_senc_pws" }],
    driverTypes: ["znp-device", "zpi-device"],
};

const zpi_road_surface_base = {
    unit: "",
    type: "string",
    guiVisible: true,
    formatter: meteoRoadSurfaceFormatter,
    graphType: "stepArea",
    driverTypes: ["zpi-device"],
    warningDeps: [{ key: "zpi_road_status" }, { key: "zpi_frost_2", minLevel: 2 }],
};

const VALUES_METEO_V1 = {
    dateTime: {
        unit: "",
        type: "dateTime",
        guiVisible: true,
        formatter: dateTimeFormatter,
        shownInGraph: false,
    },
    temp: {
        unit: "celsius",
        type: "number",
        nopeId: 1,
        guiVisible: true,
        precision: 1,
        warningDeps: [{ key: "pws_air_temp" }, { key: "zpi_pws_air_temp" }, { key: "zpi_pws_road_temp" }, { key: "zpi_dew_point" }, { key: "zpi_snow" }, { key: "zpi_black_ice" }],
    },
    humidity: {
        unit: "percent",
        type: "number",
        nopeId: 4,
        guiVisible: true,
    },
    dewpoint: {
        unit: "celsius",
        type: "number",
        nopeId: 12,
        guiVisible: true,
        precision: 1,
        warningDeps: [{ key: "dew_point" }, { key: "boschung.A3TP" }, { key: "zpi_dew_point" }],
    },
    pressure: {
        unit: "hpa",
        type: "number",
        nopeId: 10,
        guiVisible: true,
    },
    wind_speed: {
        type: "number",
        nopeId: 5,
        guiVisible: true,
        precision: 1,
        formatter: windFormatterBeaufort,
        graphFormatter: windFormatter,
        warningDeps: [{ key: "strong_side_wind" }, { key: "strong_wind" }, { key: "zpi_strong_side_wind" }, { key: "zpi_strong_wind" }],
    },
    gust_speed: {
        type: "number",
        nopeId: 6,
        guiVisible: true,
        precision: 1,
        formatter: windFormatter,
        warningDeps: [{ key: "strong_side_wind" }, { key: "strong_wind" }, { key: "zpi_strong_side_wind" }, { key: "zpi_strong_wind" }],
    },
    wind_dir: {
        unit: "deg",
        type: "number",
        nopeId: 7,
        guiVisible: true,
        graphType: "scatter",

        formatter: windDirFormatter,
    },
    wheater_code: {
        unit: "",
        type: "string",
        nopeId: 79,
        guiVisible: false,
        shownInGraph: false,
    },
    precip_type: {
        unit: "",
        type: "string",
        nopeId: 68,
        guiVisible: true,
        shownInGraphValueOnly: true,
        formatter: wheaterCodeFormatter,
        warningDeps: [
            { key: "pws_air_temp" },
            { key: "pws_road_temp" },
            { key: "zpi_pws_air_temp" },
            { key: "visibility" },
            { key: "alarm_it_senc_pws", minLevel: 2 },
            { key: "alarm_it_arctis_pws", minLevel: 2 },
            { key: "boschung.A3S" },
            { key: "boschung.A3NI" },
            { key: "zpi_snow" },
            { key: "zpi_black_ice" },
            { key: "zpi_frost_2" },
        ],
    },
    precip: {
        unit: "mmPerHour",
        type: "number",
        nopeId: 78,
        guiVisible: true,
        precision: 1,
        graphType: "column1",
        warningDeps: [
            { key: "pws_air_temp" },
            { key: "zpi_pws_air_temp" },
            { key: "pws_road_temp" },
            { key: "zpi_pws_road_temp" },
            { key: "visibility" },
            {
                key: "alarm_it_senc_pws",
                minLevel: 2,
            },
            { key: "alarm_it_arctis_pws", minLevel: 2 },
            { key: "zpi_snow" },
            { key: "zpi_black_ice" },
            { key: "zpi_frost_2" },
        ],
    },
    new_snow: {
        unit: "mmPerHour",
        type: "number",
        nopeId: 9,
        guiVisible: false,
        precision: 1,
    },
    visibility: {
        unit: "meter",
        type: "number",
        nopeId: 35,
        guiVisible: true,
        formatter: visibilityFormatter,
        graphType: "area",
        warningDeps: [{ key: "pws_air_temp" }, { key: "pws_road_temp" }, { key: "visibility" }, { key: "zpi_visibility" }],
    },
    pws_status: {
        unit: "",
        type: "boolean",
        nopeId: 203,
        guiVisible: false, //alarm
    },
    road_surface: {
        ...road_surface_base,
        nopeId: 124,
    },
    road_surface2: {
        ...road_surface_base,
        nopeId: 223,
    },
    water_thick: {
        ...water_thick_base,
        nopeId: 120,
    },
    water_thick2: {
        ...water_thick_base,
        nopeId: 74,
    },
    road_temp: {
        ...road_temp_base,
        nopeId: 2,
    },
    road_temp2: {
        ...road_temp_base,
        nopeId: 40,
    },
    ground_temp: {
        ...ground_temp_base,
        nopeId: 8,
    },
    ground_temp2: {
        ...ground_temp_base,
        nopeId: 14,
    },
    salt_residue: {
        ...salt_residue_base,
        nopeId: 125,
    },
    salt_residue2: {
        ...salt_residue_base,
        nopeId: 72,
    },
    temp_froz_comp: {
        ...temp_froz_comp_base,
        nopeId: 126,
    },
    temp_froz_comp2: {
        ...temp_froz_comp_base,
        nopeId: 127,
    },
    its_a1: {
        unit: "",
        type: "boolean",
        nopeId: 215,
        guiVisible: false, //alarm
    },
    its_a2: {
        unit: "",
        type: "boolean",
        nopeId: 333,
        guiVisible: false,
    },
    its_a3: {
        unit: "",
        type: "boolean",
        nopeId: 334,
        guiVisible: false,
    },
    salt_factor: {
        ...salt_factor_base,
        nopeId: 121,
    },
    salt_factor2: {
        ...salt_factor_base,
        nopeId: 132,
    },
    salt_factor_content: {
        ...salt_factor_content_base,
        nopeId: 121,
    },
    salt_factor_content2: {
        ...salt_factor_content_base,
        nopeId: 132,
    },
    temp_froz_mea: {
        ...temp_froz_mea_base,
        nopeId: 3,
    },
    temp_froz_mea2: {
        ...temp_froz_mea_base,
        nopeId: 102,
    },
    ita_a1: {
        unit: "",
        type: "boolean",
        nopeId: 215,
        guiVisible: false,
    },
    ita_a2: {
        unit: "",
        type: "boolean",
        nopeId: 328,
        guiVisible: false,
    },
    ita_a3: {
        unit: "",
        type: "boolean",
        nopeId: 329,
        guiVisible: false,
    },
    battery_volt: {
        unit: "voltage",
        type: "number",
        nopeId: 77,
        guiVisible: true,
        precision: 1,
    },
    battery_dis: {
        unit: "",
        type: "boolean",
        nopeId: 209,
        guiVisible: false,
    },
    battery_full: {
        unit: "",
        type: "boolean",
        nopeId: 208,
        guiVisible: false,
    },
    wrn_1: {
        unit: "",
        type: "",
        nopeId: 0,
        guiVisible: false,
    },
    wrn_2: {
        unit: "",
        type: "",
        nopeId: 0,
        guiVisible: false,
    },
    wrn_3: {
        unit: "",
        type: "",
        nopeId: 0,
        guiVisible: false,
    },
    status: {
        unit: "",
        type: "",
        nopeId: 0,
        guiVisible: false,
    },
    failures: {
        unit: "",
        type: "",
        nopeId: 0,
        guiVisible: false,
    },
    precip_day: {
        unit: "milimeter",
        type: "number",
        nopeId: 11,
        guiVisible: true,
        precision: 1,
    },
    precip_intens: {
        unit: "",
        type: "number",
        nopeId: 44,
        guiVisible: true,
        graphType: "stepArea",
        formatter: precipIntensFormatter,
    },
    road_status: {
        ...road_status_base,
        nopeId: 70,
    },
    road_status2: {
        ...road_status_base,
        nopeId: 150,
    },
    xflag: {
        unit: "",
        type: "",
        nopeId: 0,
        guiVisible: false,
    },
    ita_road_temp: {
        ...ita_road_temp_base,
    },
    ita_road_temp2: {
        ...ita_road_temp_base,
    },
    ita_road_surface: {
        ...ita_road_surface_base,
    },
    ita_road_surface2: {
        ...ita_road_surface_base,
    },
    its_road_surface: {
        unit: "",
        type: "string",
        nopeId: 270,
        guiVisible: true,
        formatter: zpiRoadSurfaceFormatter,
        graphType: "stepArea",
        warningDeps: [{ key: "alarm_it_senc_pws" }],
        driverTypes: ["znp-device", "zpi-device"],
    },
    zpi_road_surface: {
        ...zpi_road_surface_base,
        nopeId: 370,
    },
    zpi_road_surface2: {
        ...zpi_road_surface_base,
        nopeId: 350,
    },
    // TODO salt_factor
    snow_height: {
        unit: "centimeter",
        type: "number",
        nopeId: 9,
        guiVisible: true,
        precision: 0,
    },
    brightness: {
        unit: "lux",
        type: "number",
        nopeId: 52,
        guiVisible: true,
        precision: 1,
    },
    friction: {
        unit: "",
        type: "number",
        nopeId: 114,
        guiVisible: true,
        precision: 1,
        formatter: frictionFormatter,
        warningDeps: [{ key: "zpi_friction" }],
    },
    pyrano: {
        unit: "wm2",
        type: "number",
        nopeId: 75,
        guiVisible: true,
        precision: 0,
    },
    ice_percentage: {
        guiVisible: true,
        type: "number",
        unit: "percent",
        precision: 0,
    },
    visibility_precip: {
        guiVisible: true,
        type: "number",
        unit: "meter",
        precision: 0,
    },
    precip_snow: {
        guiVisible: true,
        type: "number",
        unit: "mmPerHour",
        precision: 1,
    },
    precip_water: {
        guiVisible: true,
        type: "number",
        unit: "mmPerHour",
        precision: 1,
    },
    at_trend: {
        guiVisible: true,
        nopeId: 36,
        type: "number",
        unit: "celsius",
        precision: 1,
    },
    gt_trend: {
        guiVisible: true,
        nopeId: 37,
        type: "number",
        unit: "celsius",
        precision: 1,
    },
};

//Keep this here (jan@vitrix.cz)
//console.log("XML export formatting:", _.filter(VALUES_METEO_V1, val => val.hasOwnProperty("nopeId") && val.hasOwnProperty("precision")).map(val => "{" + val.nopeId + ",\"{:." + val.precision + "}\"sv}").join(","));

const VALUES_FORECAST_NUM_V22 = {
    temp: {
        type: "number",
        nopeId: 1,
    },
    humidity: {
        type: "boolean",
        nopeId: 4,
    },
    dewpoint: {
        type: "number",
        nopeId: 12,
    },
    pressure: {
        type: "number",
        nopeId: 10,
    },
    wind_speed: {
        type: "number",
        nopeId: 5,
    },
    top_wind_speed: {
        type: "number",
        nopeId: 6,
    },
    wind_dir: {
        type: "number",
        nopeId: 7,
    },
    snow_falls_limit: {
        type: "number",
    },
    precip_type: {
        type: "string",
        nopeId: 68,
    },
    precip: {
        type: "number",
        nopeId: 11,
    },
    new_snow: {
        type: "number",
        nopeId: 9,
        graphType: "stepArea",
    },
    visibility: {
        type: "number",
        nopeId: 35,
    },
    road_temp: {
        type: "number",
        nopeId: 2,
    },
    cloud_cover: {
        type: "boolean",
    },
    cloud_type_low: {
        type: "boolean",
    },
    cloud_type_medium: {
        type: "boolean",
    },
    lower_cloud_limit: {
        type: "boolean",
    },
    global_radiation: {
        type: "number",
        nopeId: 75,
    },
    pasquill_stability_class: {
        type: "string",
    },
};

const ValueInfoContext = React.createContext(null);

export default function ValueInfoProvider({ children }) {
    const [valueInfosGuiOnly, findValueInfoGuiOnly] = useValueInfosInternal(true);
    const [valueInfos, findValueInfo] = useValueInfosInternal(false);

    return (
        <ValueInfoContext.Provider
            value={{
                valueInfos,
                findValueInfo,
                valueInfosGuiOnly,
                findValueInfoGuiOnly,
            }}
        >
            {children}
        </ValueInfoContext.Provider>
    );
}

export function useValueInfo(valueKey) {
    const { findValueInfo } = useContext(ValueInfoContext);
    return useMemo(() => findValueInfo(valueKey), [findValueInfo]);
}

export function useValueInfos(guiOnly) {
    const { valueInfosGuiOnly, findValueInfoGuiOnly, valueInfos, findValueInfo } = useContext(ValueInfoContext);

    if (guiOnly) {
        return [valueInfosGuiOnly, findValueInfoGuiOnly];
    } else return [valueInfos, findValueInfo];
}

export function getMeteoV1() {
    return VALUES_METEO_V1;
}

export function getForecastNumV22() {
    return VALUES_FORECAST_NUM_V22;
}

function useValueInfosInternal(guiOnly, dataStoreId = "meteo_v1") {
    const intl = useIntl();
    const [windUnit] = useWindUnit();
    const [beaufort] = useBeaufort();

    let values = [];

    switch (dataStoreId) {
        case "meteo_v1":
            values = VALUES_METEO_V1;
            break;
    }

    const [valueInfos, index] = useMemo(() => {
        let ret = [];
        let index = new Map();

        Object.entries(values).map((item) => {
            const valueKey = item[0];
            const nameFormatted = formatForId(intl, "value." + dataStoreId + ".name." + valueKey);
            const shortcutFormatted = formatForId(intl, "value." + dataStoreId + ".shortcut." + valueKey);
            let valueInfo = values[valueKey];
            if (!valueInfo.hasOwnProperty("formatter")) {
                valueInfo.formatter = defaultFormatter;
            }

            if (!valueInfo.hasOwnProperty("graphFormatter")) {
                valueInfo.graphFormatter = valueInfo.formatter;
            }

            const unitFormatted = formatForId(intl, "value.unit." + valueInfo.unit);
            const color = getColor(valueInfo.nopeId);
            const forecastNum22Key = getForecastNum22Key(valueInfo.nopeId);

            const indexedObj = {
                valueKey,
                nameFormatted,
                shortcutFormatted,
                unitFormatted,
                intl,
                color,
                forecastNum22Key,
                windUnit,
                beaufort,
                ...valueInfo,
            };
            ret.push(indexedObj);
            index.set(valueKey, indexedObj);
        });

        if (guiOnly) {
            ret = ret.filter((item) => item.guiVisible);
        }

        return [ret, index];
    }, [guiOnly, windUnit, beaufort]);

    const findValueInfo = (valueKey) => index.get(valueKey);

    return [valueInfos, findValueInfo];
}

export function useValueInfosNameList() {
    const [valueInfos] = useValueInfos(true);
    return useMemo(() => valueInfos.filter((vi) => vi.valueKey !== "dateTime").map((vi) => vi.valueKey), [valueInfos]);
}

export function getForecastNum22Key(nopeId) {
    return Object.entries(VALUES_FORECAST_NUM_V22).find(([key, value]) => value.nopeId === nopeId)?.[0];
}

export function hasValue(value) {
    return !(value === null || value === undefined);
}

function notAvaiableFormatter() {
    return (
        <Tooltip title={formatForId(this.intl, "value.name.notAvailable")}>
            <div
                style={{
                    display: "flex",
                    minWidth: 24,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <>&lowast;</>
            </div>
        </Tooltip>
    );
}

function frozCompFormatter(val) {
    return defaultFormatter.call(this, val);
}

function undegroundTempFormatter(val) {
    if (val === -3276.8) {
        return "-";
    }
    return defaultFormatter.call(this, val);
}

function visibilityFormatter(val) {
    if (val === 2000) {
        return "≥ 2000 " + this.unitFormatted;
    } else if (val > 2000) {
        return "> 2000 " + this.unitFormatted;
    } else {
        return defaultFormatter.call(this, val);
    }
}

function saltFomratter(val, includeZeros) {
    if ((val === undefined || val === 0 || val === 255) && !includeZeros) {
        return notAvaiableFormatter.call(this);
    }
    return defaultFormatter.call(this, val);
}

function saltFactorFormatter(val, includeZeros) {
    if ((val === undefined || val === 0 || val === 255) && !includeZeros) {
        return notAvaiableFormatter.call(this);
    }

    return defaultFormatter.call(this, Math.ceil(val));
}

export function windFormatter(val) {
    if (!isValidValue(val)) {
        return notAvaiableFormatter.call(this);
    }
    if (this.windUnit === "kmh") {
        return `${trimPrecision(val * 3.6, this.precision)} ${formatForId(this.intl, "value.unit.kilometersPerHour")}`;
    } else {
        return `${trimPrecision(val, this.precision)} ${formatForId(this.intl, "value.unit.metersPerSecond")}`;
    }
}

export function windFormatterBeaufort(val) {
    if (!isValidValue(val)) {
        return notAvaiableFormatter.call(this);
    }

    if (!this.beaufort) {
        return windFormatter.call(this, val);
    }

    let level = 0;
    if (val >= 32.7) {
        level = 12;
    } else if (val >= 28.5) {
        level = 11;
    } else if (val >= 24.5) {
        level = 10;
    } else if (val >= 20.8) {
        level = 9;
    } else if (val >= 17.2) {
        level = 8;
    } else if (val >= 13.9) {
        level = 7;
    } else if (val >= 10.8) {
        level = 6;
    } else if (val >= 8) {
        level = 5;
    } else if (val >= 5.5) {
        level = 4;
    } else if (val >= 3.4) {
        level = 3;
    } else if (val >= 1.6) {
        level = 2;
    } else if (val >= 0.3) {
        level = 1;
    }

    return formatForId(this.intl, "value.beaufort." + level);
}

function surfaceFormatter(val) {
    if (val >= 0 && val <= 4) {
        return formatForId(this.intl, "value.surfaceValues." + val);
    } else if (val === undefined) {
        return notAvaiableFormatter.call(this);
    }
    return formatForId(this.intl, "value.surfaceValues.other");
}

function zpiRoadSurfaceFormatter(val) {
    if (val >= 0 && val <= 6) {
        return formatForId(this.intl, "value.zpiSurfaceValues." + val);
    } else if (val === undefined) {
        return notAvaiableFormatter.call(this);
    }
    return formatForId(this.intl, "value.zpiSurfaceValues.other");
}

function meteoRoadSurfaceFormatter(val) {
    return formatForId(this.intl, "value.meteoSurfaceValues." + val);
}

function roadStatusFormatter(val) {
    switch (val) {
        case 0:
        case 1:
        case 32:
        case 64:
        case 65:
        case 66:
        case 67:
            return formatForId(this.intl, "value.roadSurfaceValues." + val);
        default:
            return notAvaiableFormatter.call(this);
    }
}

function precipIntensFormatter(val) {
    if (!isValidValue(val)) {
        return notAvaiableFormatter.call(this);
    }
    switch (val) {
        case 0:
        case 1:
        case 2:
        case 3:
            return formatForId(this.intl, "value.precipIntensValues." + val);
        default:
            return notAvaiableFormatter.call(this);
    }
}

function trimPrecision(val, precision) {
    return val.toFixed(precision).replace(".", ",");
}

export function isValidValue(value) {
    return value !== undefined && value !== null && value !== -3276.8 && value.toString().replace(".", "") !== "32767" && !(value > 327.6 && value < 327.7);
}

function defaultFormatter(value, shortcut) {
    if (!hasValue(value)) {
        return "";
    } else if (this.type === "boolean") {
        return formatForId(this.intl, value === 0 ? "value.unit.boolean.0" : "value.unit.boolean.1");
    } else if (this.type === "string" || _.isEmpty(this.unit)) {
        return value;
    } else {
        if (isNaN(value)) {
            return value + " " + this.unitFormatted;
        } else if (!isValidValue(value)) {
            return notAvaiableFormatter.call(this);
        } else {
            return trimPrecision(value, this.hasOwnProperty("precision") ? this.precision : 0) + " " + this.unitFormatted;
        }
    }
}

function dateTimeFormatter(value, shortcut) {
    return formatTime(value, true);
}

export function windDirFormatter(value, shortcut = true) {
    const isInInterval = (min, max) => value >= min && value <= max;

    const getFormatted = (key) => formatForId(this.intl, `value.windDirValues.${key}.${"shortcut"}`) + ` (${defaultFormatter.call(this, value)})`;

    if (isInInterval(12, 33)) {
        return getFormatted("SSV");
    } else if (isInInterval(34, 56)) {
        return getFormatted("SV");
    } else if (isInInterval(57, 78)) {
        return getFormatted("VSV");
    } else if (isInInterval(79, 101)) {
        return getFormatted("V");
    } else if (isInInterval(102, 123)) {
        return getFormatted("VJV");
    } else if (isInInterval(124, 146)) {
        return getFormatted("JV");
    } else if (isInInterval(147, 168)) {
        return getFormatted("JJV");
    } else if (isInInterval(169, 191)) {
        return getFormatted("J");
    } else if (isInInterval(192, 213)) {
        return getFormatted("JJZ");
    } else if (isInInterval(214, 236)) {
        return getFormatted("JZ");
    } else if (isInInterval(237, 258)) {
        return getFormatted("ZJZ");
    } else if (isInInterval(259, 281)) {
        return getFormatted("Z");
    } else if (isInInterval(282, 303)) {
        return getFormatted("ZSZ");
    } else if (isInInterval(304, 326)) {
        return getFormatted("SZ");
    } else if (isInInterval(327, 348)) {
        return getFormatted("SSZ");
    } else if (isInInterval(349, 360) || isInInterval(0, 11)) {
        return getFormatted("S");
    } else return defaultFormatter.call(this, value);
}

function wheaterCodeFormatter(value) {
    return formatWMOCode(this.intl, value);
}

function frictionFormatter(value) {
    if (hasValue(value)) {
        return trimPrecision(value, 1);
    } else {
        return "";
    }
}

function getColor(index = 0) {
    if (index === 9) return "lightblue";

    if (index === 78) return "DodgerBlue";

    if (index === 35) return "#c09000";

    return COLORS[index % 99].find((color) => {
        const getBri = (hex) => {
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

            const rgb = {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
            };

            return (299 * rgb.r + 587 * rgb.g + 114 * rgb.b) / 1000;
        };

        if (getBri(color) > 120) {
            return color;
        }
    });
}

export function useGraphValueKeys() {
    const [valueInfos] = useValueInfos(true);
    return useMemo(() => valueInfos.filter((vi) => !vi.hasOwnProperty("shownInGraph") || vi.shownInGraph).map((vi) => vi.valueKey), [valueInfos]);
}
