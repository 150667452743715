import { ReactComponent as Arrow } from "../../Icons/camera/circle-arrow-right-to-line.svg";

import { SvgIcon } from "@material-ui/core";
import { svgOptions } from "./PictogramIcons";

export function ArrowIcon() {
    return (
        <SvgIcon fontSize={"inherit"}>
            <Arrow {...svgOptions} />
        </SvgIcon>
    );
}
