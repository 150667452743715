import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useCanvasJsResizableChartRef, useCrosshair, useCrossHairViewOptions } from "./hooks";

import CanvasJSReact from "../../../Charts/canvasjs.stock.react";

import _ from "loadsh";

import { useIntl } from "react-intl";
import { Paper, Typography, useTheme } from "@material-ui/core";

import { useValueInfos } from "../../../../Utils/Data/ValueMapper";
import { formatForId } from "../../../../Utils/Lang/IntlHelper";
import { createChartOptions } from "./data/MeteoDataChart";
import "./style.css";
import { setZoomActive } from "../../../../Utils/Data/actions/meteogram";
import { useStore } from "react-redux";

const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

export function MainChart({ data, columns, history, alertData, forecastData, forecastActive, height, alertsViewSettings, beginTime, hiddenColumns, compact }) {
    const intl = useIntl();
    const chartRef = useCanvasJsResizableChartRef();
    const [valueInfos, findValueInfo] = useValueInfos(true);
    const theme = useTheme();
    const [dataVersion, setDataVersion] = useState(Date.now());
    const store = useStore();

    useEffect(() => {
        setDataVersion((st) => Date.now());
    }, [history, data]);

    const [onCrosshairChanged, onCrosshairClicked, onIgnoreNextClick] = useCrosshair(chartRef, dataVersion, data, forecastData, columns, alertData, intl, theme);

    const rangeChanging = useCallback(
        (e) => {
            setZoomActive(store, e.trigger !== "reset");

            if (chartRef.current) {
                const stockChart = chartRef.current.stockChart;
                if (stockChart) {
                    stockChart._axisXMin = e.axisX[0].viewportMinimum;
                    stockChart._axisXMax = e.axisX[0].viewportMaximum;
                }
                chartRef.current.stockChart.render();
            }
        },
        [chartRef.current]
    );

    const options = useMemo(() => {
        return createChartOptions({
            history,
            findValueInfo,
            data,
            forecastData,
            forecastActive,
            intl,
            theme,
            alertData,
            columns,
            alertsViewSettings,
            beginTime,
            hiddenColumns,
            rangeChanging,
        });
    }, [history, data, alertData, columns, forecastData, forecastActive, alertsViewSettings, beginTime, rangeChanging]);

    useEffect(() => {
        const stockChart = chartRef.current.stockChart;
        if (stockChart) {
            stockChart._axisXMin = null;
            stockChart._axisXMax = null;
        }
        setZoomActive(store, false);
    }, [history]);

    const optionsWithHeight = useMemo(() => {
        let chartSizes;
        if (compact) {
            chartSizes = [
                [0.25, 0.75],
                [0.2, 0.2, 0.6],
            ];
        } else {
            chartSizes = [
                [0.25, 0.75],
                [0.16, 0.16, 0.68],
            ];
        }

        options.height = height;

        options.charts[0].height = compact ? 140 : 170;
        const heightWithoutState = height - 170;

        if (options.charts.length > 1) {
            if (options.charts.length === 2) {
                options.charts[1].height = heightWithoutState;
            } else if (options.charts.length === 3) {
                options.charts[1].height = chartSizes[0][0] * heightWithoutState;
                options.charts[2].height = chartSizes[0][1] * heightWithoutState;
            } else if (options.charts.length === 4) {
                options.charts[1].height = chartSizes[1][0] * heightWithoutState;
                options.charts[2].height = chartSizes[1][1] * heightWithoutState;
                options.charts[3].height = chartSizes[1][2] * heightWithoutState;
            }
        }

        return options;
    }, [options, height, compact]);

    const optionsWithCrossHair = useCrossHairViewOptions(
        theme,
        optionsWithHeight,
        dataVersion,
        formatForId(intl, "pages.device.meteogram.now"),
        onCrosshairChanged,
        onCrosshairClicked,
        onIgnoreNextClick
    );

    const emptyData = _.isEmpty(data) && ((_.isEmpty(forecastData) && forecastActive) || !forecastActive);

    return (
        <div className={"main-chart"} style={{ position: "relative" }} onClick={onCrosshairClicked}>
            <CanvasJSStockChart key={dataVersion + emptyData} options={optionsWithCrossHair} ref={chartRef} />

            {emptyData && (
                <>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            height: "100%",
                        }}
                    ></div>
                    <div style={{ position: "absolute", top: 50, width: "100%" }}>
                        <Paper
                            style={{
                                padding: 22,
                                width: 500,
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: "#343B46",
                                textAlign: "center",
                            }}
                        >
                            <Typography variant={"h5"}>{formatForId(intl, "pages.device.meteogram.noData")}</Typography>
                        </Paper>
                    </div>
                </>
            )}
        </div>
    );
}
