import React from "react";
import {IconButton} from "@material-ui/core";
import {faSync} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {useIntl} from "react-intl";


export function RefreshButton({onClick})
{
   const intl =useIntl();
   return <IconButton onClick={onClick} title={formatForId(intl, "forms.refresh")}>
      <FontAwesomeIcon icon={faSync} />
   </IconButton>
}