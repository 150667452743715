import React, {useEffect} from 'react';
import {Provider} from "react-redux";
import {connectToWs} from "./actions/server";
import store from "./store";

export default function ModelProvider({children}) {
    useEffect(() => connectToWs(store), []);

    return <Provider store={store}>
        {children}
    </Provider>;
}
