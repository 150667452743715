import { makeStyles } from "@material-ui/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faChevronsLeft, faChevronsRight } from "@fortawesome/pro-solid-svg-icons";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import moment from "moment";
import { Last24HoursType, Last36HoursType, Last7DaysType, Last8HoursType } from "../../Forms/DateRangeField/hooks";

const useStyles = makeStyles((theme) => ({
    control: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
    content: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

function ControlButton({ icon, onClick, tooltipId, disabled }) {
    const intl = useIntl();
    return (
        <Tooltip title={formatForId(intl, tooltipId)}>
            <IconButton onClick={onClick} disabled={disabled}>
                <FontAwesomeIcon icon={icon} size={"xs"}></FontAwesomeIcon>
            </IconButton>
        </Tooltip>
    );
}

function getShiftedTime(shift, forward, half, value) {
    return forward ? value.add(half ? shift / 2 : shift, "hours").startOf("hours") : value.subtract(half ? shift / 2 : shift, "hour").startOf("hours");
}

function getHistoryValue(forward, half, history) {
    const end = history.value.end ? history.value.end : moment();
    const begin = history.value.begin;
    const diff = Math.abs(moment.duration(end.startOf("hours").diff(begin.startOf("hour"))).asHours());

    return {
        ...history.value,
        begin: getShiftedTime(diff, forward, half, begin),
        end: getShiftedTime(diff, forward, half, end),
    };
}

export function TimeShiftControl({ history, children, contentWidth = 200 }) {
    const classes = useStyles();
    const enabled = history.value.type === Last8HoursType || history.value.type === Last36HoursType || history.value.type === Last24HoursType || history.value.type === Last7DaysType;

    return (
        <div className={classes.control}>
            <ControlButton
                icon={faChevronsLeft}
                onClick={() => {
                    history.setValue(getHistoryValue(false, false, history));
                }}
                disabled={!enabled}
                tooltipId={"pages.device.meteogram.timeShift.full"}
            />
            <ControlButton
                icon={faChevronLeft}
                onClick={() => {
                    history.setValue(getHistoryValue(false, true, history));
                }}
                disabled={!enabled}
                tooltipId={"pages.device.meteogram.timeShift.half"}
            />
            <div className={classes.content} style={{ width: contentWidth }}>
                {children}
            </div>
            <ControlButton
                icon={faChevronRight}
                onClick={() => {
                    history.setValue(getHistoryValue(true, true, history));
                }}
                disabled={!enabled}
                tooltipId={"pages.device.meteogram.timeShift.half"}
            />
            <ControlButton
                icon={faChevronsRight}
                onClick={() => {
                    history.setValue(getHistoryValue(true, false, history));
                }}
                disabled={!enabled}
                tooltipId={"pages.device.meteogram.timeShift.full"}
            />
        </div>
    );
}
