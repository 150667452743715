import React, { memo, useMemo } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, withStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { ActiveCamera, centeredFlexStyle } from "./CameraView";
import { makeStyles } from "@material-ui/styles";
import CameraPlayer, { useCameraPlayerControl } from "../../Pages/View/CameraPlayer";
import useWindowDimensions from "../../Utils/Data/hooks/dimensions";
import { isMobile } from "react-device-detect";
import { CancelButton } from "../CommonDialog";

const useStyles = makeStyles((theme) => ({
    newTap: {
        display: "absolute",
        top: 6,
        right: 20,
    },
    dialogContent: {
        height: "100%",
    },
    cameraContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        padding: theme.spacing(0.5),
        height: "100%",
    },
    dialogCamera: {
        width: "100%",
        ...centeredFlexStyle,
    },
    headerDialog: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "1.5%",
    },
    dialogWrapper: {
        overflowY: "hidden !important",
    },
    player: {
        paddingTop: theme.spacing(0.5),
    },
}));

const CustomDialogContent = withStyles({
    root: {
        paddingBottom: 4,
        paddingTop: 4,
        height: "100%",
    },
})(DialogContent);

const CustomDialogTitle = withStyles({
    root: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
        paddingTop: 8,
    },
})(DialogTitle);

const CustomDialog = withStyles({
    paper: {
        margin: 8,
        height: "calc(100% - 32px)",
    },
})(Dialog);

function SingleCamera({ id, entity }) {
    const cameraControl = useCameraPlayerControl();
    const classes = useStyles();

    const { height } = useWindowDimensions();

    const cameraHeight = useMemo(() => {
        if (isMobile) {
            if (height > 600) {
                return "65vh";
            }
            return "55vh";
        }
        if (height > 1200) {
            return "86vh";
        } else if (height > 1000) {
            return "83vh";
        } else if (height > 800) {
            return "81vh";
        } else if (height > 700) {
            return "78vh";
        } else if (height > 600) {
            return "75vh";
        }
        return "70vh";
    }, [height]);
    return (
        <div className={classes.cameraContent}>
            <div className={classes.dialogCamera} style={{ height: cameraHeight }}>
                <ActiveCamera key={id} variant={"big"} device={entity} id={id} frame={cameraControl.frame} disableOpenDialog={true} onLoaded={(e) => cameraControl.setLastPlayedFrame(e.frame)} />
            </div>
            <div className={classes.player}>
                <CameraPlayer control={cameraControl} />
            </div>
        </div>
    );
}

function CameraDialog({ entity, open, onClose }) {
    const camerasId = useMemo(() => (typeof entity.cameraId != "undefined" ? [entity.cameraId] : entity.camera_devices.map((cam) => cam.id)), [entity]);

    const classes = useStyles();

    const dialogWrapperClasses = useMemo(() => {
        if (camerasId && camerasId.length > 1) {
            return {};
        } else return classes.dialogWrapper;
    }, [camerasId, classes]);

    const openInNewWindow = () => {
        let url = window.location.origin + `/domain/${entity.domain_id}/group/${entity.group_id}/device/${entity.id}/camera/`;
        if (entity.cameraId) {
            url += entity.cameraId + "/";
        }

        window.open(url, "_blank");
    };
    return (
        <CustomDialog open={open} onClose={onClose} fullScreen={isMobile} maxWidth={"xl"}>
            <div className={classes.headerDialog}>
                <CancelButton onClose={onClose} />

                <CustomDialogTitle>{entity.name}</CustomDialogTitle>
                <div className={classes.toolBar}>
                    <IconButton color="inherit" className={classes.newTap} onClick={openInNewWindow}>
                        <FontAwesomeIcon icon={faExternalLink} size={"xs"} />
                    </IconButton>
                </div>
            </div>

            <CustomDialogContent className={dialogWrapperClasses}>
                <div className={classes.dialogContent}>
                    {camerasId.map((id) => (
                        <SingleCamera id={id} entity={entity} />
                    ))}
                </div>
            </CustomDialogContent>
        </CustomDialog>
    );
}

export default memo(CameraDialog);
