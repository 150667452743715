import { Tooltip, Typography, useTheme, withStyles } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiWifi, mdiWifiOff } from "@mdi/js";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { formatForId } from "../Utils/Lang/IntlHelper";
import { formatTime } from "../Utils/Data/Time";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import RequireAdminPermissions from "../Utils/Permissions/RequireAdminPermission";
import { RequireAnyPermission } from "../Utils/Permissions/RequireAnyPermission";

function getDeviceStateColor(theme, state) {
    switch (state) {
        case 0:
            return theme.palette.warning.main;
        case 1:
            return theme.palette.error.main;
        case 2:
            return theme.palette.success.main;
    }
    return "lightgray";
}

const deviceStates = ["unknown", "offline", "online", "error"];
const commState = ["idle", "requesting"];

function formatOptionalTime(intl, field, value) {
    if (!value) {
        return formatForId(intl, field);
    } else {
        return formatTime(value, true);
    }
}

const useStyles = makeStyles((props) => ({
    colHead: {
        textAlign: "left",
        paddingRight: "10px",
        width: "180px",
    },
    line: {
        borderBottom: "1px solid gray",
        height: "5px",
        marginBottom: "5px",
    },
}));

const useDeviceNameStyles = makeStyles((theme) => ({
    online: {
        color: theme.palette.text.primary,
    },
    offline: {
        color: theme.palette.text.secondary,
    },
    wrap: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
    },
    wrap_1: {
        "-webkit-line-clamp": 1,
    },
    wrap_2: {
        "-webkit-line-clamp": 2,
    },
    wrap_3: {
        "-webkit-line-clamp": 3,
    },
    wrap_4: {
        "-webkit-line-clamp": 4,
    },
    wrap_5: {
        "-webkit-line-clamp": 5,
    },
}));

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        minWidth: 350,
    },
}))(Tooltip);

export function DeviceNameWithState({ device, deviceState, noWrap = true, textVariant = "h6", showTooltip = true, wrapAfter = 0, size }) {
    const [isOverflowed, setIsOverflow] = useState(false);

    const classes = useDeviceNameStyles();

    const classNames = useMemo(() => {
        let className = deviceState.state === 2 ? classes.online : classes.offline;
        if (wrapAfter > 0) {
            className = clsx(className, classes.wrap, classes[`wrap_${wrapAfter}`]);
        }
        return className;
    }, [deviceState]);

    const textElementRef = useRef();

    useEffect(() => {
        if (textElementRef.current) {
            setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
        }
    }, [size]);

    return showTooltip ? (
        <DeviceStateTooltip deviceState={deviceState} device={device}>
            <Typography className={classNames} variant={textVariant} noWrap={noWrap}>
                {device.name}
            </Typography>
        </DeviceStateTooltip>
    ) : (
        <Tooltip title={device.name} disableHoverListener={!isOverflowed}>
            <Typography className={classNames} variant={textVariant} ref={textElementRef} noWrap>
                {device.name}
            </Typography>
        </Tooltip>
    );
}

export function formatDriverName(intl, device) {
    return formatForId(intl, "driver." + device.driver);
}

export function CommunicationStateItems({ deviceState }) {
    const intl = useIntl();

    const classes = useStyles();
    if (!deviceState) {
        return false;
    }
    const deviceStateString = formatForId(intl, `DeviceStateTooltip.deviceStates.${deviceStates[deviceState.state]}`);
    const commStateString = formatForId(intl, `DeviceStateTooltip.commStates.${commState[deviceState.comm_state]}`);

    return (
        <>
            <tr>
                <th className={classes.colHead}>{formatForId(intl, "DeviceStateTooltip.deviceState")}</th>
                <td>
                    {deviceStateString} {formatOptionalTime(intl, "DeviceStateTooltip.missing.state_set_time", deviceState.state_set_time)}
                </td>
            </tr>
            <tr>
                <th className={classes.colHead}>{formatForId(intl, "DeviceStateTooltip.lastDataRead")}</th>
                <td>{formatOptionalTime(intl, "DeviceStateTooltip.missing.lastDataRead", deviceState.last_communication)}</td>
            </tr>
            <tr>
                <th className={classes.colHead}>{formatForId(intl, "DeviceStateTooltip.nextCommunication")}</th>
                <td>
                    {deviceState.comm_state === 0
                        ? formatOptionalTime(intl, "DeviceStateTooltip.missing.nextCommunication", deviceState.next_comm_state_transition_time)
                        : formatForId(intl, "DeviceStateTooltip.now")}
                </td>
            </tr>
            <tr>
                <th className={classes.colHead}>{formatForId(intl, "DeviceStateTooltip.commState")}</th>
                <td>{commStateString}</td>
            </tr>
        </>
    );
}

export function DeviceStateTooltip({ children, deviceState, device }) {
    const intl = useIntl();

    const classes = useStyles();
    if (!deviceState) {
        return false;
    }

    const deviceStateString = formatForId(intl, `DeviceStateTooltip.deviceStates.${deviceStates[deviceState.state]}`);
    const commStateString = formatForId(intl, `DeviceStateTooltip.commStates.${commState[deviceState.comm_state]}`);
    return (
        <CustomTooltip
            title={
                <table>
                    <tbody>
                        <tr>
                            <th className={classes.colHead}>{formatForId(intl, "DeviceStateTooltip.customId")}</th>
                            <td> {device.custom_id}</td>
                        </tr>
                        <tr>
                            <th className={classes.colHead}>{formatForId(intl, "forms.deviceDetail.road")}</th>
                            <td> {device.road}</td>
                        </tr>
                        <tr>
                            <th className={classes.colHead}>{formatForId(intl, "forms.deviceDetail.road_km")}</th>
                            <td> {`${device?.road_km} ${formatForId(intl, "value.unit.km")}`}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div className={classes.line}></div>
                            </td>
                        </tr>
                        <CommunicationStateItems deviceState={deviceState} />
                        <RequireAdminPermissions noMessage={() => false}>
                            <tr>
                                <th className={classes.colHead}>{formatForId(intl, "DeviceStateTooltip.driver")}</th>
                                <td>{formatDriverName(intl, device)}</td>
                            </tr>
                        </RequireAdminPermissions>
                        <RequireAnyPermission permission={"others__system_info"} onErrorComponent={() => false}>
                            <tr>
                                <th className={classes.colHead}>{formatForId(intl, "DeviceStateTooltip.systemId")}</th>
                                <td>{device.id}</td>
                            </tr>
                        </RequireAnyPermission>
                    </tbody>
                </table>
            }
        >
            {children}
        </CustomTooltip>
    );
}

export function DeviceStateIcon({ deviceState, device }) {
    const theme = useTheme();
    const online = deviceState?.state === 2;

    return (
        <DeviceStateTooltip deviceState={deviceState} device={device}>
            <Icon color={getDeviceStateColor(theme, deviceState?.state)} path={online ? mdiWifi : mdiWifiOff} size={1} />
        </DeviceStateTooltip>
    );
}
