import { useState, useLayoutEffect } from "react";

const useHasScrollbar = (ref, deps = []) => {
    const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
    const [hasHorizontalScrollbar, setHasHorizontalScrollbar] = useState(false);

    useLayoutEffect(() => {
        const checkForScrollbar = () => {
            if (ref.current) {
                setHasVerticalScrollbar(ref.current.offsetHeight < ref.current.scrollHeight);
                setHasHorizontalScrollbar(ref.current.offsetWidth < ref.current.scrollWidth);
            }
        };

        checkForScrollbar();

        window.addEventListener("resize", checkForScrollbar);

        return () => {
            window.removeEventListener("resize", checkForScrollbar);
        };
    }, [ref, ...deps]);

    return { hasVerticalScrollbar, hasHorizontalScrollbar };
};

export default useHasScrollbar;
