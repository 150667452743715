import {
    Checkbox, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    LinearProgress,
    ListItem,
    ListItemText,
    MenuItem,
    Select
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, {useEffect, useMemo, useState} from "react";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import {useGet, useMutate} from "restful-react";
import {useIntl} from "react-intl";
import _ from "underscore";
import LoadingScope from "../../../Components/LoadingScope";
import {CommonDialog} from "../../../Components/CommonDialog";
import {NoDataMessage, OutlinedCard} from "../../../Components/Forms/NoDataMessage";
import {formatForId} from "../../../Utils/Lang/IntlHelper";
import {
    DialogCancelButton, DialogCloseButton,
    DialogSaveCancelButtons,
    DialogSaveCancelButtonsOnSubmit
} from "../../../Components/MasterDetail/DialogSaveCancelButtons";
import {useNotification} from "../../../Notification";
import {usePostMutate} from "../../../Api";
import {MenuProps, useStyles as useDropDownStyles} from "../../../Components/Forms/DropDownMenuProps";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "80%",
        minHeight: "80%"
    },
    select: {
        minWidth: 250
    },
    groupList: {
        paddingLeft: theme.spacing(4),
    },
    domain: {
        paddingBottom: theme.spacing(1),
    }

}));


function Domain({user, domain, intl, roles, onChange}) {
    const classes = useStyles();

    const {data: deviceGroups, refetch, loading} = useGet({
        path: "domain/" + domain.id + "/device-group/list/"
    });

    const domainRoles = domain.roles?.find((role) => !_.isEmpty(role.domain) && _.isEmpty(role.device_group))?.roles;
    const hasDomainRoles = !_.isEmpty(domainRoles);

    return <div>
        <ListItem>
            <ListItemText>
                {domain.name}
            </ListItemText>
            <RoleSelect roles={roles} multiple
                        onChange={(e) => {
                            onChange(e.target.value, domain.id);
                        }}
                        value={hasDomainRoles ? domainRoles.map((role) => role.name) : []}
                        intl={intl} id={"pages.users.roles.roleDropdown"}
                        classes={classes}
            />
        </ListItem>
        <List className={classes.groupList}>
            {_.map(_.filter(deviceGroups, group => group.id > 0), group => {
                const groupRoles = domain.roles?.find((role) => role.device_group?.id === group.id)?.roles

                return <ListItem key={group.id}>
                    <ListItemText>
                        {group.name}
                    </ListItemText>
                    <RoleSelect
                        disabled={hasDomainRoles}
                        roles={roles} multiple
                        value={groupRoles ? groupRoles.map((role) => role.name) : []}
                        intl={intl} id={"pages.users.roles.roleDropdown"}
                        classes={classes}
                        onChange={(e) => {
                            onChange(e.target.value, domain.id, group.id);
                        }}/>
                </ListItem>
            })}
        </List>
    </div>
}


function RoleSelect({onChange, multiple, id, value, classes, roles, ...props}) {
    const selected = useMemo(() => {
        const result = new Set(value || []);
        return result;
    }, [value]);


    const dropDownClasses = useDropDownStyles();
    return <Select
        MenuProps={MenuProps}
        className={classes.select} id={id}
        multiple={multiple}
        name={id}
        value={value}
        onChange={onChange}
        renderValue={(sel) =>
            (<div className={dropDownClasses.chips}>
                {_.map(sel, (v, idx) => (
                    <Chip key={v} label={v}
                          className={dropDownClasses.chip}
                    />))}
            </div>)
        }
        {...props}>
        {
            roles?.map(role => (
                <MenuItem key={role.name} value={role.name}>
                    <Checkbox
                        checked={Boolean(selected.has(role.name))}/>
                    {role.name}
                </MenuItem>
            ))
        }
    </Select>
}

export function RoleDialog({entityType, entity, open, onClose}) {
    const classes = useStyles();
    const intl = useIntl();

    const [result, setResult] = useState([]);
    const [superUser, setSuperUser] = useState(false);
    const notification = useNotification();

    const {data: domains, refetch: refetchDomains, loading: loadingDomains, error: errorDomains} = useGet({
        path: "domain/list/"
    });

    const {data: userRoles, refetch, loading, error} = useGet({
        path: entityType + "/" + entity.id + "/role/list/",
    });

    const {data: roles, refetch: refetchRoles, loadingRoles, errorRoles} = useGet({
        path: "role/list/",
    });

    const {postData} = usePostMutate(entityType + "/" + entity.id + "/role/");

    const isLoading = () => {
        return loading || loadingRoles || loadingDomains;
    }

    const hasError = () => {
        return error || errorDomains || errorRoles;
    }

    useEffect(() => {
        if (!domains || !userRoles) {
            return;
        }

        const isSuperUser = !!_.find(userRoles, scope => {
            return !scope.domain && !scope.device_group && !!_.find(scope.roles, role => role.id === -1);
        });
        setSuperUser(isSuperUser);

        setResult(domains.map((domain) => {
            let roles = [];

            for (const userRole of userRoles) {
                if (!userRole.domain) {
                    continue;
                }

                if (userRole.domain.id === domain.id) {
                    roles.push(userRole);
                }
            }

            return {...domain, roles: roles};
        }));
    }, [domains, userRoles])


    const mergedDataForDomain = (domainId) => {
        return result.find((domain) => domain.id === domainId);
    }

    const roleNameToId = (roleName) => {
        return roles?.find((role) => role.name === roleName)?.id;
    }

    const onChange = (roles, domainId, deviceGroupId) => {
        const idx = result.findIndex((domain) => domain.id === domainId);
        let copyResult = [...result];
        let domain = copyResult[idx];

        let roleIdx = domain.roles.findIndex((role) => role.domain?.id === domainId && role.device_group?.id === deviceGroupId);

        const roleObj = {
            roles: roles.map((role) => {
                return {id: roleNameToId(role), name: role}
            }), domain: {id: domainId}, device_group: deviceGroupId ? {id: deviceGroupId} : null
        }

        if (roleIdx === -1) {
            domain.roles.push(roleObj);
        } else {
            domain.roles[roleIdx] = roleObj;
        }

        copyResult[idx] = domain;
        setResult(copyResult);
    }

    const saveChanges = async () => {
        let data = [];

        result.forEach((domain) => {
            domain.roles?.forEach((role) => {
                data.push(role);
            })
        });

        await notification.showApiMessage(postData(data));
        onClose();
    }

    return <CommonDialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>{entity.name}</DialogTitle>
        <DialogContent>
            <LoadingScope loading={isLoading()} error={hasError()}>
                {superUser ? <OutlinedCard
                        title={formatForId(intl, "roles.adminUser.title")}
                        message={formatForId(intl, "roles.adminUser.message")}/> :
                    <List>
                        {result?.map((domain) => {
                            return <div key={"div" + domain.id} className={classes.domain}>
                                <Domain key={domain.id} domain={mergedDataForDomain(domain.id)}
                                        user={entity}
                                        intl={intl}
                                        roles={roles}
                                        onChange={onChange}/>
                                <Divider/>
                            </div>
                        })
                        }
                    </List>
                }
            </LoadingScope>
        </DialogContent>
        <DialogActions>
            {
                superUser ? <DialogCloseButton onClose={onClose}/> :
                    <DialogSaveCancelButtonsOnSubmit onClose={onClose} onSubmit={saveChanges}/>
            }

        </DialogActions>
    </CommonDialog>
}
