import React, { useMemo } from "react";
import { Checkbox, makeStyles, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    square: {
        display: "inline-block",
        width: "1rem",
        height: "1rem",
        marginLeft: 5,
        marginRight: 10,
        borderRadius: "5px",
    },
    value: {
        whiteSpace: "break-spaces",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    item: {
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    input: {
        padding: "5px 14px", //remove padding because of item padding
    },
}));

function ColoredValue({ classes, color, children }) {
    return (
        <span>
            <span className={classes.item}>
                {children}
                <div className={classes.square} style={{ backgroundColor: color }} />
            </span>
        </span>
    );
}

function MenuItemWithCheckBox({ children, ...props }) {
    return (
        <MenuItem {...props}>
            <Checkbox checked={props.selected} />
            {children}
        </MenuItem>
    );
}

export default function ColorOptionSelect(props) {
    const { options, onClose } = props;
    const mappedOptions = useMemo(
        () =>
            options.map((value) => ({
                key: value.key,
                color: value.color,
                label: value.name,
            })),
        [options]
    );

    const findMappedOption = (key) => mappedOptions.find((item) => item.key === key);

    const classes = useStyles();
    return (
        <Select
            multiple={true}
            renderValue={(values) => (
                <div className={classes.value}>
                    {values.map((value) => (
                        <ColoredValue classes={classes} color={findMappedOption(value)?.color}>
                            {findMappedOption(value)?.label}
                        </ColoredValue>
                    ))}
                </div>
            )}
            onClose={onClose}
            {...props}
            classes={{ root: classes.input }}
        >
            {mappedOptions.map(({ key, color, label }) => (
                <MenuItemWithCheckBox key={key} value={key}>
                    <ColoredValue classes={classes} color={color}>
                        {label}
                    </ColoredValue>
                </MenuItemWithCheckBox>
            ))}
        </Select>
    );
}
