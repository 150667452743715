import React, { useMemo } from "react";
import { formatForId } from "../../../Utils/Lang/IntlHelper";

export const PRECIP_TYPE = "precip";
export const WATER_THICK_TYPE = "water_thick";

const LEGEND_DEFINITIONS = {
    water_thick: [
        { key: 1, color: "#00c030" },
        { key: 2, color: "#1E90FF" },
        { key: 3, color: "#c09000" },
        { key: 4, color: "#949494" },
        { key: 5, color: "#D42C88" },
        { key: 6, color: "#c00030" },
        { key: 7, color: "#ABC321" },
    ],
    precip: [
        { key: 1, color: "#00c030" },
        { key: 2, color: "#c09000" },
        { key: 3, color: "#1E90FF" },
        { key: 4, color: "#949494" },
        { key: 5, color: "#c00030" },
        { key: 6, color: "#D42C88" },
    ],
};

function LegendRow({ color, text }) {
    return (
        <div style={{ display: "flex", height: 24, width: "100%", alignItems: "center", justifyContent: "center" }}>
            <div style={{ width: 18, height: 18, backgroundColor: "#18202c", opacity: 1 }}>
                <div style={{ width: "100%", height: "100%", backgroundColor: color, opacity: 0.7 }} />
            </div>
            <span style={{ paddingLeft: 8 }}>{text}</span>
        </div>
    );
}

function Legend({ def, intl, color, intlId }) {
    const item = useMemo(() => def.find((item) => item.color === color), [color, def]);

    if (item) {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <LegendRow color={item.color} key={item.key} text={formatForId(intl, `${intlId}.${item.key}`)}></LegendRow>
            </div>
        );
    } else return <></>;
}

export default function ColorLegend({ intl, type, selectedColor, ...props }) {
    if (type === WATER_THICK_TYPE) {
        return (
            <div {...props}>
                <Legend intl={intl} def={LEGEND_DEFINITIONS.water_thick} intlId={"pages.device.meteogram.deviceState.colorLegend.water_thick"} color={selectedColor} />
            </div>
        );
    } else if (type === PRECIP_TYPE) {
        return (
            <div {...props}>
                <Legend intl={intl} def={LEGEND_DEFINITIONS.precip} intlId={"pages.device.meteogram.deviceState.colorLegend.precip"} color={selectedColor} />
            </div>
        );
    }

    return <></>;
}
