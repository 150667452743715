import { Dialog, DialogContent, DialogTitle, withStyles } from "@material-ui/core";

export const LargeDialogTitle = withStyles({
    root: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 0,
        paddingTop: 8,
    },
})(DialogTitle);

export const LargeDialogContent = withStyles({
    root: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 4,
        paddingTop: 4,
    },
})(DialogContent);

export const LargeDialog = withStyles({
    paper: {
        margin: 8,
    },
})(Dialog);
