import { useIntl } from "react-intl";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, useTheme } from "@material-ui/core";
import React from "react";
import { ListField, SimpleDeviceQueryField } from "./GlobalDeviceQueryField";
import ColorOptionSelect from "./ColorOptionSelect";
import { getAlertColor, WarningSeverity } from "../../Utils/Data/AlertFormatter";
import { formatForId } from "../../Utils/Lang/IntlHelper";

export function AlertFilter({ inputRef, fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete }) {
    const theme = useTheme();
    const intl = useIntl();
    const currentValue = value || {
        filter: [],
        levelFilter: [],
    };

    const onFilterChange = (filter) => {
        changeHandle({ target: { name: fieldId, value: { ...currentValue, filter } } });
    };

    const onLevelFilterChange = (levelFilter) => {
        changeHandle({ target: { name: fieldId, value: { ...currentValue, levelFilter } } });
    };

    const warningServerity = WarningSeverity.map((severity, idx) => ({
        key: severity,
        name: formatForId(intl, `pages.device.alert.title.${severity}`),
        color: getAlertColor(severity, theme)?.primary,
    }));

    return (
        <FormControl fullWidth required={!!required} {...fieldDef.props?.formControl}>
            <FormLabel component="legend">{label}</FormLabel>
            <FormControlLabel
                style={{ padding: "10px 17px" }}
                label={""}
                control={
                    <Grid container={true} spacing={1} direction={"row"} alignItems={"center"}>
                        <Grid item={true} xs={12}>
                            <SimpleDeviceQueryField value={currentValue.filter} onChange={onFilterChange} allowUnselectLast={true} disabled={disabled} />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <ColorOptionSelect
                                style={{ width: "100%", marginRight: theme.spacing(2) }}
                                maring={"normal"}
                                options={warningServerity}
                                value={currentValue.levelFilter}
                                onChange={(evt) => {
                                    evt.target.value.sort();
                                    onLevelFilterChange(evt.target.value);
                                }}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                }
            />
            {touched && Boolean(errors) && <FormHelperText error={true}>{errors}</FormHelperText>}
        </FormControl>
    );
}
