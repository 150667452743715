import React from "react";
import { useGlobalChangeHandler } from "./Utils/Data/hooks/server";
import { useStore } from "react-redux";
import { useGetCurrentPagePath, useSiteMenu } from "./AppPages";
import { sendToWs } from "./Utils/Data/actions/server";
import { MainDrawer } from "./Components/MainDrawer";
import { MeteoAppBar } from "./Components/MeteoAppBar";
import { makeStyles } from "@material-ui/core/styles";
import { useCustomAppBar } from "./Utils/Data/hooks/gui";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
    },
    mainContent: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "auto",
    },
}));

export default function AppMenu({ children }) {
    const classes = useStyles();
    const menu = useSiteMenu();
    const path = useGetCurrentPagePath(menu);

    const globalChangeToken = useGlobalChangeHandler();

    const store = useStore();
    React.useEffect(() => {
        const time = setTimeout(() => {
            if (globalChangeToken > 0) {
                sendToWs(store, ["refresh"]);
            }
        }, 500);

        return () => clearTimeout(time);
    }, [globalChangeToken]);

    const customAppBar = useCustomAppBar();
    return (
        <div className={classes.root}>
            <MainDrawer menu={menu} currentPath={path} />
            <div className={classes.mainContent}>
                {!customAppBar && <MeteoAppBar menu={menu} currentPath={path} />}
                {children}
            </div>
        </div>
    );
}
