import { useSelector, useStore } from "react-redux";
import useWindowDimensions from "./dimensions";
import { useEffect, useMemo } from "react";
import { setViewDimension } from "../actions/gui";
import { useSessionstorageState } from "rooks";
import { getUserCustomFields } from "../../Permissions/PermissionHelper";
import { getDefaultLocale } from "../../Lang/LangLoader";
import { available_locales } from "../../../Pages/Settings/User/UserDetail";
import _ from "loadsh";

export function useCompactDrawer() {
    return useSelector(({ gui }) => {
        return gui?.compactDrawer;
    });
}

export function useCustomAppBar() {
    return useSelector(({ gui }) => {
        return gui?.customAppBar;
    });
}

export function useViewMode() {
    return useSelector(({ gui }) => gui.viewMode);
}

export function useUpdatableViewMode() {
    const { width, height } = useWindowDimensions();
    const store = useStore();

    useEffect(() => {
        setViewDimension(store, { width, height });
    }, [width]);

    return useViewMode();
}

export function useOpenedAppDialog() {
    return useSelector(({ gui }) => gui.openedDialog);
}

export function useOpenedLoadingDialog() {
    return useSelector(({ gui }) => gui.openedLoadingDialog);
}

export function useTableViewSelectedLine() {
    return useSelector(({ gui }) => gui.tableViewSelectedLine);
}

export function useTableViewPinnedLine() {
    return useSelector(({ gui }) => gui.tableViewPinnedLine);
}

export function useCompactTableView() {
    return useSelector(({ gui }) => {
        return gui?.compactTableView;
    });
}

export function useCompactTableViewLabel() {
    return useSelector(({ gui }) => {
        return gui?.compactTableViewLabel;
    });
}

export function useTableViewFirstVisibleItem() {
    return useSelector(({ gui }) => {
        return gui?.tableViewFirstVisibleItem;
    });
}

export function useTableViewHeighht() {
    return useSelector(({ gui }) => {
        return gui?.tableViewHeight;
    });
}

export function useAudioAlertEnabled() {
    const [soundAlertEnabled, setSoundAlertEnabled] = useSessionstorageState("meteo:soundAlertEnabled", getUserCustomFields().sound_alert_enabled > 0);

    const level = useMemo(() => {
        if (soundAlertEnabled) {
            return getUserCustomFields().sound_alert_enabled;
        } else return 0;
    }, [soundAlertEnabled, getUserCustomFields().sound_alert_enabled]);

    return [level, soundAlertEnabled, setSoundAlertEnabled, getUserCustomFields().sound_alert_enabled];
}

export function useWindUnit() {
    const [windUnit, setWindUnit] = useSessionstorageState("meteo:windUnit", getUserCustomFields().wind_unit);

    useEffect(() => {
        //#292
        if ((windUnit === undefined || windUnit === "undefined") && (getUserCustomFields().wind_unit !== undefined || getUserCustomFields().wind_unit !== "undefined")) {
            setWindUnit(getUserCustomFields().wind_unit);
        }
    }, [windUnit]);

    return [windUnit, setWindUnit];
}

export function useBeaufort() {
    const user_setting = getUserCustomFields().beaufort_scale;
    const [beaufort, setBeaufort] = useSessionstorageState("meteo:beaufort", user_setting === undefined ? false : user_setting);
    return [beaufort, setBeaufort];
}

export function useDisabledAggregation() {
    const user_setting = getUserCustomFields().disabled_aggregation;
    const [disabledAggregation, setDisabledAggregation] = useSessionstorageState("meteo:disabledAggregation", user_setting === undefined ? false : user_setting);
    return [disabledAggregation, setDisabledAggregation];
}

export function useLocale() {
    const user_setting = getUserCustomFields().locale;
    const [locale, setLocale] = useSessionstorageState("meteo:locale", "");

    const localeComputed = useMemo(() => {
        if (_.some(available_locales, (loc) => locale === loc)) {
            return locale;
        }
        if (_.some(available_locales, (loc) => user_setting === loc)) {
            return user_setting;
        }
        return getDefaultLocale();
    }, [locale, user_setting]);

    return [localeComputed, setLocale];
}

export function useHistoryEndDate() {
    return useSelector(({ gui }) => {
        return gui?.historyEndDate;
    });
}
