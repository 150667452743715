import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import {useIntl} from "react-intl";
import {formatForId} from "../../../../Utils/Lang/IntlHelper";
import DeviceBlock from "../../../../Components/Device/DeviceBlock";
import {MODE} from "../../../../Components/Device/ViewMode";


export function DeviceStatusDialog({device, onClose, open}) {
    const intl = useIntl();

    return <Dialog open={open} onClose={onClose} fullWidth>
        <DialogContent>
            <DeviceBlock device={device} mode={MODE.large}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary" variant="outlined">
                {formatForId(intl, "actions.cancel")}
            </Button>
        </DialogActions>
    </Dialog>
}
