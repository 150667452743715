import React from "react"
import {IconButton, Tooltip, useTheme} from "@material-ui/core";
import {useIntl} from "react-intl";
import Badge from '@material-ui/core/Badge';
import {useActiveTextForecast} from "../../Utils/Data/hooks/server";
import {makeStyles} from "@material-ui/styles";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope} from "@fortawesome/pro-solid-svg-icons";


const useStyles = makeStyles({
    badge: {
        backgroundColor: props => props.theme.palette.warnings.level_3.primary,
    },
});

export function ActiveTextForecast({onClick}) {
    const intl = useIntl();
    const {isRead} = useActiveTextForecast();

    const theme = useTheme();


    const classes = useStyles({theme});

    const title = isRead ? formatForId(intl, "ActiveTextForecast.allRead") : formatForId(intl, "ActiveTextForecast.newForecast");

    return <Tooltip title={title}>
        <IconButton onClick={onClick}>
            <Badge badgeContent={isRead ? 0 : 1} classes={classes}><FontAwesomeIcon icon={faEnvelope}/></Badge>
        </IconButton>
    </Tooltip>
}