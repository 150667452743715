import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useIntl} from "react-intl";
import {formatForId} from "../Utils/Lang/IntlHelper";
import {SvgIcon} from "@material-ui/core";
import {ReactComponent as Logo} from "../Icons/logo_dark.svg";


const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.background.default,
        },
    },
    root: {
        height: "100%",
        width: 280
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
        height: "100%"
    },
    logoContainer: {
        width: 200,
        flex: 1,
        display: "flex",
        flexDirection: 'column',
        justifyContent: "flex-end",
        alignItems: 'center',
        marginBottom: theme.spacing(3.5),
    },
    logo: {
        width: "100%",
        height: 200,
    },
    form: {
        width: 280,
        marginTop: theme.spacing(3.5),
        flex: 1
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    labelShrink: {
        transform: "translate(14px, -18px) !important"
    },
    inputRoot: {
        "& .MuiOutlinedInput-notchedOutline legend": {maxWidth: "0 !important"}
    },
    textField: {
        paddingLeft: 0,
        paddingRight: 0
    }
}));

export default function Login() {
    const classes = useStyles();
    const [failedLogin, setstate] = useState(window.location.href.search("failed=1"))
    const intl = useIntl();


    useEffect(() => {
        window.sessionStorage.clear();
    }, [])

    return (
        <Container component="main" maxWidth="xs" className={classes.root}>
            <CssBaseline/>

            <div className={classes.paper}>
                <div className={classes.logoContainer}>
                    <SvgIcon className={classes.logo}>
                        <Logo/>
                    </SvgIcon>
                </div>

                <form className={classes.form} noValidate method='post' action='/login/submit/'>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label={formatForId(intl, "pages.login.username")}
                        name="username"
                        autoComplete="off"
                        autoFocus
                        classes={{root: classes.textField}}
                        InputProps={{classes: {root: classes.inputRoot}}}
                        InputLabelProps={{
                            classes: {
                                shrink: classes.labelShrink
                            }
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={formatForId(intl, "pages.login.password")}
                        type="password"
                        id="password"
                        autoComplete="off"
                        classes={{root: classes.textField}}
                        InputProps={{classes: {root: classes.inputRoot}}}
                        InputLabelProps={{
                            classes: {
                                shrink: classes.labelShrink
                            }
                        }}
                    />
                    {failedLogin !== -1 ? <Typography
                        color='secondary'>
                        {formatForId(intl, "pages.login.invalidLogin")}</Typography> : null}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {formatForId(intl, "pages.login.login")}
                    </Button>
                </form>
            </div>
        </Container>
    );
}