export default class ZoomAbortManager {
    constructor() {
        this._zoom = null;
        this._abortManager = null;
    }

    getAbortSignal(zoom) {
        if (this._zoom === null) {
            this._abortManager = new AbortController();
        } else if (this._zoom !== zoom) {
            this._abortManager.abort();
            this._abortManager = new AbortController();
            this._zoom = zoom;
        }

        return this._abortManager.signal;
    }
}
