import React, {useMemo} from "react";
import {useGet} from "restful-react";
import MasterDetailView from "../../../Components/MasterDetail/MasterDetailView";
import RoleDetail from "./RoleDetail";
import {useIntl} from "react-intl";
import {translateColumns, formatForId} from "../../../Utils/Lang/IntlHelper";
import Button from "@material-ui/core/Button";
import MyTable from "../../../Components/Forms/MyTable";
import {RoleIcon, RolesIcon, UserIcon} from "../../../Components/Icons/ToolbarIcons";
import {
    useOpenedDetailEntity,
    useSafeOpenDetail
} from "../../../Utils/Data/hooks/masterDetail";
import MasterDetailContextMenu from "../../../Components/MasterDetail/MasterDetailContextMenu";
import {DetailContextMenu} from "../../../Components/MasterDetail";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import MasterDetailTable from "../../../Components/MasterDetail/MasterDetailTable";
import _ from "loadsh";
import {useGetInMemoryDataList} from "../../../Utils/Data/hooks/dataList";


export function Roles() {
    const intl = useIntl();

    const columns = [
        {
            field: 'name',
            width: 150,
            sortable: true,
            renderCell: (params) => <Button><RoleIcon/> {params.row.name}</Button>
        },
        {
            field: "permissions",
            width: 300,
            renderCell: (params) =>
                <div>{params.row.permissions.map((row, idx) =>
                    <><span>{idx === 0 ? "" : ", "}</span><span> {formatForId(intl, "permissions." + row)}</span></>)
                }</div>

        }];


    const safeOpenDetail = useSafeOpenDetail();

    const dataSource = useGetInMemoryDataList({
        url: "/role/list/",
        defaultSort: {field: "name", isAscending: true}
    });

    return <MasterDetailView loading={dataSource.loading} loadingError={dataSource.error}
                             onRenderContextMenu={({isDetailOpened, viewMode}) =>
                                 <MasterDetailContextMenu isDetailOpened={isDetailOpened} viewMode={viewMode}
                                                          onRenderDetailMenu={() => <DetailContextMenu
                                                              onRenderBeforeLabel={() => <UserIcon/>}/>}
                                                          onRenderMasterMenu={() => <ActionToolbar
                                                              localizationContext={"pages.roles"} actions={[{
                                                              id: "create",
                                                              onClick() {
                                                                  safeOpenDetail("role", {isNew: true});
                                                              }
                                                          }]} searchFieldName={"name"}/>}/>}
                             onRenderDetail={() => <RoleDetail/>}>

        <MasterDetailTable dataSource={dataSource} entityType={"role"} columns={columns}/>
    </MasterDetailView>


}