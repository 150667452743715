import React, {useMemo} from "react";
import {CustomForm} from "../../../Components/Forms/CustomForm";
import {useMasterDetailFormSave, useOpenedDetailEntity} from "../../../Utils/Data/hooks/masterDetail";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import {useStore} from "react-redux";
import {openDialog} from "../../../Utils/Data/actions/gui";
import {DEFAULT_APP_FIELDS_ORDER, DEFAULT_DATA_FIELDS_ORDER} from "../../../Utils/Data/ViewsDefinitions";
import _ from "loadsh";
import {getMeteoV1} from "../../../Utils/Data/ValueMapper";
import TimeZones from "../../../Utils/Lang/TimeZones";

const formConfig =
    [
        {
            id: "name",
            type: "TextField",
            props: {
                required: true,
            }
        },
        {
            id: "is_active",
            type: "CheckboxField",
            props: {
                required: true
            }
        },
        {
            id: "data_format_type",
            type: "DropdownField",
            props: {
                multiple: false,
                allowChangeOrder: false,
                items: ["XML", "XML/PPA"],
                localizationContext: "forms.data-export.dataFormat",


            },
            defaultValue: "XML",
        },
        {
            id: "output_type",
            type: "DropdownField",
            props: {
                allowChangeOrder: false,
                multiple: false,
                items: ["URL", "FILE"],
                localizationContext: "forms.data-export.outputChoices",
            },
            defaultValue: "URL",

        },
        {
            id: "export_file_name",
            type: "TextField",
            props: {
                required: true
            },
        },
        {
            id: "export_interval_seconds",
            type: "TimeoutField",
            props: {
                required: true
            },
            defaultValue: 120
        },
        {
            id: "time_zone",
            type: "DropdownField",
            props: {
                items: TimeZones,
            },
            defaultValue: "Europe/Bratislava"

        },
        {
            id: "data_format_type_settings",
            type: "DropdownField",
            props: {
                allowChangeOrder: false,
                multiple: true,
                items: ["exportTimestamp", "exportFlags", "exportNotSetValues", "exportNotSetFlags", "useDotFormatStNo", "doNotRoundNumbers"],
                localizationContext: "forms.data-export.data_format_type_settings_choices",
            },
            defaultValue: []
        },
        {
            id: "ip_filter_client",
            type: "FormList",

            props: {
                formId: "ipFilter",
                formConfig: [
                    {
                        id: "ipValue", type: "IpFieldV4",
                        props: {
                            required: true
                        }
                    }]
            },

            defaultValue: []
        },
        {
            id: "data_query",
            type: "QueryField",
            props: {},

        },


    ]
;


export default function DataExportDetail({reloadCallback}) {
    const {makeSubmitCallback} = useMasterDetailFormSave("data-export/");
    const {entity, timeOpened} = useOpenedDetailEntity();
    const clientEntity = useMemo(() => ({
        ...entity,
        ip_filter_client: _.map(entity.ip_filter, (ipValue) => ({ipValue}))
    }), [entity]);
    const store = useStore();


    return <CustomForm key={entity.id}
                       timeOpened={timeOpened}
                       formConfig={formConfig}
                       formData={clientEntity}
                       onSubmit={makeSubmitCallback({
                           onBeforeSubmit(values) {
                               values.ip_filter = _.map(values.ip_filter_client, (filter) => filter.ipValue);
                           }
                       })}
                       formId={"data-export"}/>
        ;
}