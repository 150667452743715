import { useGetInMemoryDataList } from "../../Utils/Data/hooks/dataList";
import _ from "loadsh";
import { useOpenedDetailEntity, useSafeOpenDetail } from "../../Utils/Data/hooks/masterDetail";
import React, { useEffect, useMemo } from "react";
import { translateColumns } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import MyTable from "../Forms/MyTable";
import LoadingScope from "../LoadingScope";

export default function MasterDetailTable({ dataSource, entityType, columns, refresh = false }) {
    const intl = useIntl();
    const { data, sortColumn, sortBy, loading, error } = dataSource;

    const { entity } = useOpenedDetailEntity();
    const translatedColumns = useMemo(() => translateColumns(intl, columns, entityType), [columns]);
    const safeOpenDetail = useSafeOpenDetail();

    useEffect(() => {
        if (refresh && entity) {
            const newEntity = data && data.find((e) => e.id === entity.id);
            if (newEntity) {
                safeOpenDetail(entityType, newEntity);
            }
        }
    }, [data]);

    return <MyTable sortColumn={sortColumn} setSortColumn={sortBy} columnsDef={translatedColumns} rows={data} setSelected={(entity) => safeOpenDetail(entityType, entity)} selected={entity} />;
}
