import { useNiraAlertsEnabled } from "../../Utils/Data/hooks/map";
import { useActiveView, useDeviceDataActiveView } from "../../Utils/Data/hooks/deviceDataView";
import { useEffect, useMemo } from "react";
import { openDialog } from "../../Utils/Data/actions/gui";
import { useStore } from "react-redux";
import { NiraAlertLayer } from "./Nira/VectorGrid/NiraAlertLayer";
import { alertsToGeoJson } from "../../Utils/Data/GeoJsonUtils";
import { useNiraAlerts } from "../../Api";
import { useActiveGeoAlertRefresh } from "../../Utils/Data/hooks/server";
export function NiraAlertsLayer({}) {
    const enabled = useNiraAlertsEnabled();
    const activeView = useActiveView();
    const store = useStore();
    const geoAlertsRefresh = useActiveGeoAlertRefresh();

    const { data, refetch } = useNiraAlerts(enabled, activeView?.geo_alert_groups);

    useEffect(() => {
        if (enabled) {
            refetch();
        }
    }, [geoAlertsRefresh]);

    const geojsonData = useMemo(() => alertsToGeoJson(data.active), [data]);
    const onClick = (properties) => {
        openDialog(store, { type: "niraAlertHistory", entity: { singleArea: properties.alert } });
    };

    if (!enabled) {
        return <></>;
    }

    return <NiraAlertLayer onClick={onClick} initialData={geojsonData} />;
}
