import { useSelector } from "react-redux";

export function useCrosshairData() {
    return useSelector(({ meteogram }) => {
        return meteogram?.crosshairData;
    });
}

export function useCrosshairDataTimePosition() {
    return useSelector(({ meteogram }) => {
        return meteogram?.crosshairData?.timePosition;
    });
}

export function useCrosshairStickSelectionReset() {
    return useSelector(({ meteogram }) => {
        return meteogram?.stickSelectionReset;
    });
}

export function useForecastActive() {
    return useSelector(({ meteogram }) => {
        return meteogram?.forecastActive;
    });
}

export function useZoomActive() {
    return useSelector(({ meteogram }) => {
        return meteogram?.zoomActive;
    });
}
