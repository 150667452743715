import React, { memo, useEffect, useMemo, useState } from "react";
import _ from "loadsh";
import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraSlash, faChevronDoubleLeft, faChevronDoubleRight } from "@fortawesome/pro-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import { useCameraImageState } from "../../Utils/Data/hooks/server";
import { useStore } from "react-redux";
import { openDialog } from "../../Utils/Data/actions/gui";
import { useIntl } from "react-intl";
import { formatForId } from "../../Utils/Lang/IntlHelper";

const useActiveCameraStyles = makeStyles((theme) => ({
    small: {
        width: ({ firstLoaded }) => (firstLoaded ? "100%" : "0px"),
        height: ({ firstLoaded }) => (firstLoaded ? "auto" : "0px"),
        objectFit: "contain",
    },
    placeholder: {
        width: "28%",
        height: "28%",
        textAlign: "center",
        ...centeredFlexStyle,
    },
    big: {
        width: ({ firstLoaded }) => (firstLoaded ? "100%" : "0px"),
        height: ({ firstLoaded }) => (firstLoaded ? "100%" : "0px"),
        objectFit: "contain",
    },
    custom: {
        width: ({ firstLoaded }) => (firstLoaded ? "100%" : "0px"),
        height: ({ firstLoaded }) => (firstLoaded ? "100%" : "0px"),
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    },
    imageHolder: {
        textAlign: "center",
        display: "inline-block",
    },
    loadingError: {
        height: "80%",
        flexGrow: 1,
        ...centeredFlexStyle,
        flexDirection: "column",
    },
    textCamera: {
        fontSize: 15,
        color: "#808080",
    },
    cancelButton: {
        color: theme.palette.secondary.main,
        cursor: "pointer",
        fontSize: 24,
    },
    cameraIcon: {
        width: "75%",
        height: "75%",
    },
}));

function NoCamera({ device }) {
    return <div>&nbsp;</div>;
}

function getOnLoadObject(url) {
    const ret = {
        frame: 0,
        cameraId: undefined,
        time: 0,
    };

    const params = new URLSearchParams(url.split("?")[1]);
    if (params.has("frame")) {
        ret.frame = parseInt(params.get("frame"));
    }
    if (params.has("camera_id")) {
        ret.cameraId = parseInt(params.get("camera_id"));
    }
    if (params.has("time")) {
        ret.time = parseInt(params.get("time"));
    }
    return ret;
}

export function ActiveCamera({ device, id, variant, disableOpenDialog = false, frame = 0, singleCameraDialog = false, onLoaded = () => {} }) {
    const store = useStore();
    const intl = useIntl();

    const cameraImageTime = useCameraImageState(id);
    const [loadTime, setLoadTime] = useState(Date.now());

    useEffect(() => {
        const timer = setTimeout(() => setLoadTime(Date.now()), 30 * 60 * 1000);
        return () => clearTimeout(timer);
    }, [loadTime, cameraImageTime]);

    const url = useMemo(
        () => `/api/domain/${device.domain_id}/device-group/${device.group_id}/device/${device.id}/camera/pic/?camera_id=${id}&time=${cameraImageTime}&loadTime=${loadTime}&frame=${frame}`,
        [device, id, cameraImageTime, loadTime, frame]
    );

    const [{ error, firstLoaded }, setState] = useState({ error: false, firstLoaded: false });

    const classes = useActiveCameraStyles({ firstLoaded });
    const imageStyle = useMemo(() => {
        const result = { cursor: disableOpenDialog ? "default" : "pointer" };
        if (error) {
            result.display = "none";
        }
        return result;
    }, [error, disableOpenDialog]);

    const CameraError = () => (
        <Tooltip title={formatForId(intl, "alert.type.device_offline.name")}>
            <div className={classes.placeholder}>
                <div className={classes.loadingError}>
                    <FontAwesomeIcon color="#808080" icon={faCameraSlash} className={classes.cameraIcon} />
                </div>
            </div>
        </Tooltip>
    );

    return (
        <>
            {!firstLoaded && !error && <div className={classes.placeholder} />}
            {error && <CameraError />}
            <img
                key={"camera_" + id}
                className={classes[variant]}
                src={url}
                onLoad={(e) => {
                    setState({ firstLoaded: true, error: false });
                    onLoaded(getOnLoadObject(e.target.src));
                }}
                onError={(e) => {
                    setState((value) => ({ ...value, error: true }));
                    onLoaded(getOnLoadObject(e.target.src));
                }}
                onClick={
                    disableOpenDialog
                        ? () => {}
                        : () =>
                              openDialog(store, {
                                  type: "cameraView",
                                  entity: singleCameraDialog ? device : { ...device, cameraId: id },
                              })
                }
                style={imageStyle}
            />
        </>
    );
}

const useCameraBrowserStyles = makeStyles((theme) => ({
    cameraBrowser: {
        display: "flex",
        alignItems: "center",
    },
}));

export const centeredFlexStyle = { display: "flex", justifyContent: "center", alignItems: "center" };

function CameraBrowser({ device, compact }) {
    const cameras_ids = useMemo(() => _.map(device.camera_devices, (camera) => camera.id), [device?.camera_devices]);
    const [activeCamera, setActiveCamera] = useState(null);
    const theme = useTheme();

    const classes = useCameraBrowserStyles(theme);

    useEffect(() => {
        setActiveCamera(cameras_ids.length === 0 ? null : 0);
    }, [cameras_ids]);

    const buttonHidden = compact || cameras_ids.length < 2;

    return (
        cameras_ids.length > 0 && (
            <div className={classes.cameraBrowser}>
                {/*FIXME this is mess*/}
                <div style={centeredFlexStyle}>
                    {!buttonHidden && (
                        <IconButton disabled={activeCamera === 0} onClick={() => setActiveCamera((activeCamera - 1) % cameras_ids.length)}>
                            <FontAwesomeIcon icon={faChevronDoubleLeft} size={"xs"} />
                        </IconButton>
                    )}
                    {cameras_ids[activeCamera] && <ActiveCamera key={"a" + cameras_ids[activeCamera]} device={device} id={cameras_ids[activeCamera]} variant={"small"} singleCameraDialog={true} />}
                    {!buttonHidden && (
                        <IconButton disabled={activeCamera === cameras_ids.length - 1} onClick={() => setActiveCamera((activeCamera + 1) % cameras_ids.length)}>
                            <FontAwesomeIcon icon={faChevronDoubleRight} size={"xs"} />
                        </IconButton>
                    )}
                </div>
            </div>
        )
    );
}

function CameraView({ device, compact }) {
    return device?.camera_devices?.length ? <CameraBrowser device={device} compact={compact} /> : <NoCamera device={device} />;
}

export default memo(CameraView);
