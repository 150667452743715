import {useDataListFilter} from "../../Utils/Data/hooks/dataList";
import {IconButton, Paper} from "@material-ui/core";
import _ from "loadsh";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {useIntl} from "react-intl";

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackspace} from "@fortawesome/pro-solid-svg-icons";
import {useStore} from "react-redux";
import {resetFilter} from "../../Utils/Data/actions/dataList";

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
        textAlign: "center"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    buttonLabel: {
        marginLeft: theme.spacing(1)
    }
}));

export function OutlinedCard({title, message, action, className}) {
    const classes = useStyles();


    return (
        <Card className={`${classes.root} ${className ? className : ""}`} variant="outlined">
            <CardContent>
                <Typography variant="h5" component="h2">
                    {title}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {message}
                </Typography>
            </CardContent>
            {action &&
                <CardActions>
                    {action}
                </CardActions>
            }
        </Card>
    );
}

export function NoDataMessage() {
    const intl = useIntl();
    const store = useStore();
    const filters = useDataListFilter();
    const classes = useStyles();

    return _.isEmpty(filters) ? <OutlinedCard
        title={formatForId(intl, "noData.title")}
        message={formatForId(intl, "noData.message")}/> : <OutlinedCard title={formatForId(intl, "noData.title")}
                                                                        message={formatForId(intl, "noData.messageWithFilter")}
                                                                        action={
                                                                            <Button size={"large"} variant={"outlined"}
                                                                                    onClick={() => resetFilter(store)}
                                                                                    title={formatForId(intl, "forms.resetSearch")}>
                                                                                <FontAwesomeIcon icon={faBackspace}/>
                                                                                <span
                                                                                    className={classes.buttonLabel}>{formatForId(intl, "forms.resetSearch")}</span>
                                                                            </Button>}/>
}

export function NoRunningDrivers() {
    const intl = useIntl();

    return <OutlinedCard
        title={formatForId(intl, "noDrivers.title")}
        message={formatForId(intl, "noDrivers.message")}/>
}

export function NoDataMessageLocal({hasFilters, onClearFilter, className}) {
    const intl = useIntl();
    const classes = useStyles();

    return !hasFilters ? <OutlinedCard
            className={className}
            title={formatForId(intl, "noData.title")}
            message={formatForId(intl, "noData.message")}/> :
        <OutlinedCard className={className} title={formatForId(intl, "noData.title")}
                      message={formatForId(intl, "noData.messageWithFilter")}
                      action={
                          <Button size={"large"} variant={"outlined"} onClick={onClearFilter}
                                  title={formatForId(intl, "forms.resetSearch")}>
                              <FontAwesomeIcon icon={faBackspace}/>
                              <span className={classes.buttonLabel}>{formatForId(intl, "forms.resetSearch")}</span>
                          </Button>}/>
}


export function NoAccessMessage({permission}) {
    const intl = useIntl();
    console.log(permission);

    return permission ? <OutlinedCard
            title={formatForId(intl, "noAccess.title")}
            message={formatForId(intl, "noAccess.messageWithPerm", {permission: formatForId(intl, `permissions.${permission}`)})}/> :
        <OutlinedCard
            title={formatForId(intl, "noAccess.title")}
            message={formatForId(intl, "noAccess.message")}/>;
}
