import MyTable from "../Components/Forms/MyTable";
import { makeStyles } from "@material-ui/core/styles";
import React, { useMemo } from "react";
import { formatForId } from "../Utils/Lang/IntlHelper";
import { useGetInMemoryDataList } from "../Utils/Data/hooks/dataList";
import { formatTime } from "../Utils/Data/Time";
import { useIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashUndo } from "@fortawesome/pro-solid-svg-icons";
import { usePostMutate } from "../Api";
import { useStore } from "react-redux";
import { CommonDialog } from "../Components/CommonDialog";
import { DialogActions, DialogContent, DialogTitle, Tooltip } from "@material-ui/core";
import { DialogConfirmSelectionCancelButtonsOnSubmit, DialogDeleteConfirmSelectionCancelButtonsOnSubmit } from "../Components/MasterDetail/DialogSaveCancelButtons";
import { reloadList } from "../Utils/Data/actions/dataList";
import { openDialog } from "../Utils/Data/actions/gui";
import { useNotification } from "../Notification";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        height: 24,
        padding: theme.spacing(1),
    },
}));

export default function DeletionLog({}) {
    const classes = useStyles();
    const intl = useIntl();
    const store = useStore();

    const dataSource = useGetInMemoryDataList({
        url: "recycle/list/",
        defaultSort: { field: "deleted_time", isAscending: false },
    });

    const { data, sortColumn, sortBy, loading, error } = dataSource;

    const columns = useMemo(
        () => [
            {
                headerName: formatForId(intl, "pages.deletionLog.table.header.time"),
                field: "deleted_time",
                width: 200,
                renderCell: (params) => {
                    return <div className={classes.column}>{formatTime(params.row.deleted_time, true)}</div>;
                },
            },
            {
                headerName: formatForId(intl, "pages.deletionLog.table.header.entityType"),
                field: "entityType",
                renderCell: (params) => {
                    return <div className={classes.column}>{formatForId(intl, `pages.deletionLog.table.typeValue.${params.row.type}`)}</div>;
                },
            },
            {
                headerName: formatForId(intl, "pages.deletionLog.table.header.name"),
                field: "name",
                renderCell: (params) => {
                    return <div className={classes.column}>{params.row.entity.name}</div>;
                },
            },

            {
                headerName: formatForId(intl, "pages.deletionLog.table.header.user"),
                field: "user",
                renderCell: (params) => {
                    return <div className={classes.column}>{params.row.user.name}</div>;
                },
            },

            {
                headerName: formatForId(intl, "pages.deletionLog.table.header.undo"),
                field: "undo",
                align: "center",
                width: 160,
                renderCell: (params) => (
                    <Tooltip title={formatForId(intl, "pages.deletionLog.table.header.undo")}>
                        <Button
                            onClick={(evt) => {
                                openDialog(store, { type: "recycleRestore", entity: { ...params.row } });
                                evt.preventDefault();
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashUndo} />
                        </Button>
                    </Tooltip>
                ),
            },
            {
                headerName: formatForId(intl, "pages.deletionLog.table.header.deleteForever"),
                field: "undo",
                align: "center",
                width: 160,
                renderCell: (params) => (
                    <Tooltip title={formatForId(intl, "pages.deletionLog.table.header.deleteForever")}>
                        <Button
                            onClick={(evt) => {
                                openDialog(store, { type: "recycleDeleteForever", entity: { ...params.row } });
                                evt.preventDefault();
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Tooltip>
                ),
            },
        ],
        []
    );

    return (
        <div className={classes.root}>
            <MyTable columnsDef={columns} rows={data} entityType={"niraConfig"} sortColumn={sortColumn} setSortColumn={sortBy} />
        </div>
    );
}

export function RestoreDialog({ entity, onClose }) {
    const intl = useIntl();
    const { postData } = usePostMutate("recycle/restore/");
    const notification = useNotification();
    const store = useStore();

    return (
        <CommonDialog onClose={onClose}>
            <DialogTitle>{entity.name}</DialogTitle>
            <DialogContent>{formatForId(intl, "pages.deletionLog.dialog.restore.question", { name: entity.name })}</DialogContent>
            <DialogActions>
                <DialogConfirmSelectionCancelButtonsOnSubmit
                    onClose={onClose}
                    onSubmit={async () => {
                        await notification.showApiMessage(postData({ id: entity.id, entity_id: entity.entity.id }));

                        reloadList(store);

                        onClose();
                    }}
                />
            </DialogActions>
        </CommonDialog>
    );
}

export function DeleteForeverDialog({ entity, onClose }) {
    const intl = useIntl();
    const { postData } = usePostMutate("recycle/delete/");
    const notification = useNotification();
    const store = useStore();

    return (
        <CommonDialog onClose={onClose}>
            <DialogTitle>{entity.name}</DialogTitle>
            <DialogContent>{formatForId(intl, "pages.deletionLog.dialog.delete.question", { name: entity.name })}</DialogContent>
            <DialogActions>
                <DialogDeleteConfirmSelectionCancelButtonsOnSubmit
                    onClose={onClose}
                    onSubmit={async () => {
                        await notification.showApiMessage(postData({ id: entity.id, entity_id: entity.entity.id }));

                        reloadList(store);

                        onClose();
                    }}
                />
            </DialogActions>
        </CommonDialog>
    );
}
