export function setActiveDeviceDataView(store, view) {
    store.dispatch({type: "METEO::DEVICEVIEW:SET", payload: view});
}

export function clearActiveDeviceDataView(store) {
    store.dispatch({type: "METEO::DEVICEVIEW:CLEAR", payload: null});
}

export function setActiveDeviceDataViewLoadingState(store, {loading, error}) {
    store.dispatch({type: "METEO::DEVICEVIEW:SET_LOADING", payload: {loading, error}});
}


export function setActiveDeviceDataViewSearchKey(store, value) {
    store.dispatch({type: "METEO::DEVICEVIEW:SET_SEARCH", payload: {value}});
}
